const getFaviconUrl = () => {
  const link = document.querySelector("link[rel='icon']");
  return link ? link.href : null;
};

const sendSystemNotification = (notifList = []) => {
  const faviconUrl = getFaviconUrl();
  const notificationTrigger = () => notifList.forEach(({ title }) => (
    new Notification('HRLytics', { body: title, icon: faviconUrl })
  ));
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    return;
  }

  // Check if permission is granted
  if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    notificationTrigger();
  } else if (Notification.permission !== 'denied') {
    // Otherwise, we need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        notificationTrigger();
      }
    });
  }
};

export { sendSystemNotification, getFaviconUrl };
