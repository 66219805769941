// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.mailMasterHeader__3hEmh {\n  margin: 3em 5.7em;\n  border-radius: 9px;\n  overflow: hidden;\n  min-height: 45vh;\n}\n.mailMasterHeader__3hEmh > div:first-child > div:first-child > a {\n  background-color: var(--color-tusk) !important;\n  color: var(--color-black);\n}\n.mailMasterHeader__3hEmh > div:first-child > div:first-child > a[class=\"active item\"] {\n  background-color: var(--color-zuccini) !important;\n  color: var(--color-white) !important;\n}\n.mailMasterHeader__3hEmh > div:first-child > div:last-child {\n  border: none !important;\n  padding: 0 !important;\n}\n.mailMasterHeader__3hEmh > div:first-child > div:last-child > div {\n  padding: 0;\n}\n.bulkUserDeactive__2kSuj > div:first-child {\n  background-color: var(--dark-green);\n  color: var(--color-white);\n  border-radius: 0.5rem;\n  padding: 1rem !important;\n  margin-bottom: 1.5rem;\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"mailMasterHeader": "mailMasterHeader__3hEmh",
	"bulkUserDeactive": "bulkUserDeactive__2kSuj"
};
module.exports = exports;
