import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, Grid, Image, Label, Table, Icon,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import styles from './UserWiseCourseScreen.module.less';

export default function UserWiseAssessmentList({ courseList, hrlsessionid, setState }) {
  const { t } = useTranslation('admin');
  const { assessment_list = [] } = courseList;

  const [assessmentData, setAssessmentData] = useState([...assessment_list]);

  const enableEditMode = (hrl_session_id) => {
    setState((prevState) => ({
      ...prevState,
      hrlsessionid: hrl_session_id,
    }));
  };

  const handleAssessActionOnChange = (fieldName, idx, value) => {
    setAssessmentData((prevAssessList) => {
      const tempList = prevAssessList;

      tempList[idx] = {
        ...tempList[idx],
        [fieldName]: value,
      };

      return [...tempList];
    });
  };

  const saveChanges = (idx) => {
    const {
      start_time, end_time, visible: is_visible, team_id,
    } = assessmentData[idx];

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage && data) {
        setState((prevState) => ({
          ...prevState,
          hrlsessionid: '',
          courseList: {
            ...prevState.courseList,
            assessment_list: assessmentData,
          },
        }));
      }
    }, resturls.updateSessionsandTeamtimingDetailForAdmin, {
      update_obj: { start_time, end_time, is_visible },
      hrl_session_id: hrlsessionid,
      team_id,
    }, 'POST');
  };

  const getTimeConstraint = (fieldName, idx) => {
    let { start_time, end_time } = assessmentData[idx];
    start_time = new Date(start_time);
    end_time = new Date(end_time);
    const timeObj = {
      minTime: undefined,
      maxTime: undefined,
    };

    if (start_time.toDateString() === end_time.toDateString()) {
      if (fieldName === 'start_time') {
        timeObj.maxTime = end_time.setMilliseconds(end_time.getMilliseconds() - 840000);
        const endTimeObj = new Date(end_time);
        endTimeObj.setHours(0);
        endTimeObj.setMinutes(0);
        endTimeObj.setSeconds(0);
        endTimeObj.setMilliseconds(0);
        timeObj.minTime = endTimeObj;
      } else if (fieldName === 'end_time') {
        timeObj.minTime = start_time.setMilliseconds(start_time.getMilliseconds() + 840000);
        const endTimeObj = new Date(start_time);
        endTimeObj.setHours(23);
        endTimeObj.setMinutes(59);
        endTimeObj.setSeconds(59);
        endTimeObj.setMilliseconds(999);
        timeObj.maxTime = endTimeObj;
      }
    }

    return timeObj;
  };

  return (
    <div className={styles.userWiseCourseListMainPanel}>
      {assessmentData.length > 0 ? (
        <>
          {assessmentData.map((assessment, idx) => (
            <Card className={styles.userWiseCourseCard} key={assessment.hrl_session_id}>
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <div className={styles.userWiseCourselogo}>
                    <Image src={assessment.logo || '/images/org_table.svg'} size="tiny" />
                    <Label><p fontas="gamma">{assessment.team_name}</p></Label>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                  <h3>{assessment.assessment_name}</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={2}>
                  <p>{t('role')}</p>
                  <p>{courseList.role}</p>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className={styles.assessmentTableAction}
                >
                  <Table singleLine celled unstackable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('start_time')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('end_time')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('visibile')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('action')}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Cell>
                        {assessment.hrl_session_id === hrlsessionid ? (
                          <div className={styles.field}>
                            <DatePicker
                              fontas="beta"
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              selected={new Date(assessment.start_time)}
                              maxDate={new Date(assessment.end_time)}
                              onChange={(date) => handleAssessActionOnChange('start_time', idx, new Date(date))}
                              {...getTimeConstraint('start_time', idx)}
                            />
                          </div>
                        ) : (
                          <span>{moment(assessment.start_time).format('DD-MM-YYYY, h:mm A')}</span>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {assessment.hrl_session_id === hrlsessionid ? (
                          <div className={styles.field}>
                            <DatePicker
                              fontas="beta"
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              selected={new Date(assessment.end_time)}
                              minDate={new Date(assessment.start_time)}
                              onChange={(date) => handleAssessActionOnChange('end_time', idx, new Date(date))}
                              {...getTimeConstraint('end_time', idx)}
                            />
                          </div>
                        ) : (
                          <span>{moment(assessment.end_time).format('DD-MM-YYYY, h:mm A')}</span>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {assessment.hrl_session_id === hrlsessionid ? (
                          <Icon
                            size="large"
                            name={assessment.visible ? 'eye' : 'hide'}
                            onClick={() => handleAssessActionOnChange('visible', idx, !assessment.visible)}
                          />
                        ) : (
                          <>
                            {assessment.visible ? (
                              <Icon size="large" name="eye" />
                            ) : (
                              <Icon size="large" name="hide" />
                            )}
                          </>

                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {assessment.hrl_session_id === hrlsessionid ? (
                          <Icon
                            size="large"
                            name="save"
                            onClick={() => saveChanges(idx)}
                          />
                        ) : (
                          <Icon size="large" name="pencil alternate" onClick={() => enableEditMode(assessment.hrl_session_id)} />
                        )}
                      </Table.Cell>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid>
            </Card>
          ))}
        </>
      ) : (
        <div fontas="beta header" className={styles.notTaggedCourses}>{t('user_not_tagged_in_assessment')}</div>
      )}
    </div>
  );
}
