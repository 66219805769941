import React from 'react';
import { withTranslation } from 'react-i18next';
import { HRLFormik, HRLCkEditor } from '@hrlytics/corelytics';
import {
  Button, Checkbox, Dropdown, Form, Grid, Input,
} from 'semantic-ui-react';
import styles from './less/questionType.module.less';

const YesOrNoQuestionType = (props) => {
  const {
    t, createAndEditQuestionnaireQuestion, editQuestionValue,
  } = props;

  const {
    name = '',
    description = '',
    required = false,
    filter = false,
    questionnaire_question_type_obj: {
      yes_score = 0,
      no_score = 0,
      weightage = 0,
    } = {},
  } = editQuestionValue || {};

  const gradeOptions = [];
  for (let i = 1; i <= 100; i += 1) {
    gradeOptions.push({ key: i, text: i.toString(), value: i });
  }

  return (
    <div className={styles.yesOrNoQuestionType}>
      <HRLFormik
        initialValues={{
          name: name || '',
          description: description || '',
          required: required || false,
          filter_question: filter || false,
          weightage: weightage || 0,
          score_for_yes: yes_score || 0,
          score_for_no: no_score || 0,
        }}
        // validationSchema={schema}
        onSubmit={(values) => createAndEditQuestionnaireQuestion(values)}
      // onSubmit={(values) => console.log('values :>> ', values)}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSelect,
          handleSelectiveBlur,
          handleRadioChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('name')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    required
                    fontas="beta"
                    onChange={handleChange}
                    control={Input}
                    name="name"
                    width={4}
                    value={values.name}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('description')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <HRLCkEditor
                    name="description"
                    required={false}
                    value={values.description}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={touched.description && errors.description}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('required')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    control={Checkbox}
                    name="required"
                    value={values.required}
                    checked={values.required}
                    onChange={handleRadioChange}
                    onBlur={handleBlur}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('filter_question')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    control={Checkbox}
                    name="filter_question"
                    value={values.filter_question}
                    checked={values.filter_question}
                    onChange={handleRadioChange}
                    onBlur={handleBlur}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('weightage')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    name="weightage"
                    options={gradeOptions}
                    value={values.weightage}
                    onChange={handleSelect}
                    width={4}
                    onBlur={handleSelectiveBlur}
                    control={Dropdown}
                    required
                    selection
                    error={touched.weightage && errors.weightage}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.rangeInput}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('score_for_yes')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={11}>
                  <Form.Input
                    min={0}
                    max={100}
                    name="score_for_yes"
                    onChange={handleChange}
                    step={1}
                    type="range"
                    value={values.score_for_yes}
                  />
                  <span fontas="alpha">{values.score_for_yes}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.rangeInput}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('score_for_no')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={11}>
                  <Form.Input
                    min={0}
                    max={100}
                    name="score_for_no"
                    onChange={handleChange}
                    step={1}
                    type="range"
                    value={values.score_for_no}
                  />
                  <span fontas="alpha">{values.score_for_no}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Button disabled={isSubmitting} type="submit">{t('save_question')}</Button>
          </Form>
        )}
      </HRLFormik>
    </div>
  );
};

export default withTranslation('recruiter')(YesOrNoQuestionType);
