import React from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgressBar } from '@hrlytics/corelytics';
import styles from './supportManager.module.less';

const SupportManagerStatistics = (props) => {
  const { t, analysisData } = props;

  const {
    solved_ticket_count = '',
    opened_ticket_count = '',
    total_users_count = 0,
    total_doubts = '',
    sla_completion = 0,
  } = analysisData;
  return (
    <div className={styles.dataLayout}>
      <div className={styles.dataCard}>
        <div className={styles.text}>{t('learners')}</div>
        <div className={styles.dataValue}>
          <div className={styles.value}>
            {total_users_count}
          </div>
        </div>
      </div>
      <div className={styles.dataCard}>
        <div className={styles.text}>{t('total_doubts')}</div>
        <div className={styles.dataValue}>
          <div className={styles.total_attempt}>
            <span>{total_doubts}</span>
          </div>
        </div>
      </div>
      <div className={styles.dataCard}>
        <div className={styles.text}>{t('resolved_unresolved')}</div>
        <div className={styles.dataValue}>
          <div className={styles.total_attempt}>
            <span>{solved_ticket_count}</span>
            <span>{` / ${opened_ticket_count}`}</span>
          </div>
        </div>
      </div>
      <div className={styles.dataCard}>
        <div className={styles.text}>{t('sla')}</div>
        <div className={styles.dataValue}>
          <div className={styles.completionPercent}>
            <CircularProgressBar
              strokeWidth="8"
              sqSize="65"
              percentage={sla_completion}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('programManager')(SupportManagerStatistics);
