import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';
import { processLogout, updateCookieBasedOnRole } from '@utils/index';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import useComponentDidMount from '@utils/useComponentDidMount';
import { ToastMessage } from '@hrlytics/corelytics';
import { useHistory } from 'react-router-dom';
import UserInfo from '@models/UserInfo';
import getRoleUrl from '@utils/RoleBasedURL';
import ls from 'local-storage';
import NotificationPanel from '../notification/NotificationPanel';
import styles from './HeaderLayOut.module.less';
import HeaderLayOut from './HeaderLayOut';

function DefaultHeader(props) {
  const {
    children, t, tabShow = false, findOptions = [],
    showHome = true,
  } = props;

  const initTempContentObj = {
    leftContent: '',
    centerContent: '',
    rightContent: '',
  };

  const [content, updateContent] = useState(initTempContentObj);

  const { leftContent, centerContent, rightContent } = content;

  const [componentTypeList, setComponentTypeList] = useState([]);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [userManagementSubDropdownShow, setUserManagementSubDropdownShow] = useState(false);
  const [notificationEnabled, updateNotificationStatus] = useState(false);
  const [managementSubdropdown, setManagementSubdropdown] = useState(false);

  const [
    institutionManagementSubDropdownShow, setInstitutionManagementSubDropdownShow,
  ] = useState(false);

  const [toast, setToast] = useState((<></>));
  const [adminOptions, setAdminOptions] = useState([]);

  const roleName = UserInfo.getRole();
  const history = useHistory();

  const navBarOptions = [{
    title: 'dashboard',
    // suboptions: ['training', 'hiring', 'assessment', 'litcertification'],
  }, {
    title: 'talentpulse',
  }, {
    title: 'assessment',
    suboptions: [{ title: 'hiring_assessment', path: '/assessment' }, { title: 'personalised_assessment', path: '/personalisedassessment' }],
  }];

  const [tabOptions, setTabOptions] = useState(navBarOptions);

  const { pathname } = history.location;

  const isAdmin = ls.get('isAdmin');

  const updateChildrenContent = () => {
    const newChildren = children.length ? children : [children];
    const tempContentObj = initTempContentObj;
    newChildren.forEach((element) => {
      const position = element.props ? (element.props.align || '') : '';
      switch (position) {
        case 'left':
          Object.assign(tempContentObj, { leftContent: element });
          break;
        case 'center':
          Object.assign(tempContentObj, { centerContent: element });
          break;
        case 'right':
          Object.assign(tempContentObj, { rightContent: element });
          break;
        default:
          break;
      }
    });
    updateContent({ ...tempContentObj });
  };

  useEffect(() => {
    if (children) updateChildrenContent(children);
  }, [children]);

  // const commonDropdownOptions = [
  //   { label: 'logout', onClickFn: () => processLogout(true) },
  // ];

  const obtainComponentTypesForArchivedProblems = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setComponentTypeList(
            data.component_type_list.map((e) => (
              {
                key: e.id,
                text: e.name,
                value: e.id,
              }
            )),
          );
        }
      }, resturls.obtainComponentTypesForArchivedProblems, {}, 'GET', 'cdn',
    );
  };

  const userManagement = [
    { label: 'user_tracking', onClickFnctn: () => history.push('/userwisecourse') },
    { label: 'change_password', onClickFnctn: () => history.push('/admin/changepassword') },
    { label: 'search_user', onClickFnctn: () => history.push('/searchuser') },
    { label: 'user_list', onClickFnctn: () => history.push('/showuserlist') },
    { label: 'add_user', onClickFnctn: () => history.push('/addnewuser') },
    { label: 'deactivated_users', onClickFnctn: () => history.push('/deactivatedusers') },
    { label: 'thive_user_management', onClickFnctn: () => history.push('/admin/thiveusermanagement') },
  ];
  const institutionManagement = [
    {
      label: 'school',
      onClickFnctn: () => history.push('/admin/schoolmanagement'),
    },
    {
      label: 'college',
      onClickFnctn: () => history.push('/admin/collegemanagement'),
    },
    {
      label: 'company',
      onClickFnctn: () => history.push('/admin/companymanagement'),
    },
  ];

  const archivedProblems = () => {
    setDropdownShow(true);
    if (!componentTypeList.length > 0) {
      obtainComponentTypesForArchivedProblems();
    }
  };

  const commonOptions = [
    { icon: 'user circle', label: 'profile', onClickFn: () => history.push('/profile') },
    { icon: 'log out', label: 'logout', onClickFn: () => processLogout(true) },
  ];

  const orgAdminOptions = [
    { icon: 'user circle', label: 'profile', onClickFn: () => history.push('/profile') },
    { icon: 'key', label: 'license_consumption', onClickFn: () => history.push('/licenseconsumption') },
    { icon: 'users', label: 'deactivate_license', onClickFn: () => history.push('/deactivatelicense') },
    { icon: 'log out', label: 'logout', onClickFn: () => processLogout(true) },
  ];

  const adminviewOptions = [
    {
      label: 'user_management',
      onMouseEnter: () => setUserManagementSubDropdownShow(true),
      onMouseLeave: () => setUserManagementSubDropdownShow(false),
      userManagementSubDropdown: true,
      options: userManagement,
    },
    {
      label: 'institution_management',
      onMouseEnter: () => setInstitutionManagementSubDropdownShow(true),
      onMouseLeave: () => setInstitutionManagementSubDropdownShow(false),
      institutionManagementSubDropdownShow: true,
      options: institutionManagement,
    },
    { label: 'event_management', onClickFn: () => history.push('/admin/eventmanagement') },
    { label: 'ust_report', onClickFn: () => history.push('/admin/ustreport') },
    { icon: 'television', label: 'live_users', onClickFn: () => history.push('/liveusers') },
    { icon: 'user circle', label: 'profile', onClickFn: () => history.push('/profile') },
    { icon: 'log out', label: 'logout', onClickFn: () => processLogout(true) },
  ];

  const userManagementSubDropdownOption = [
    { label: 'user_tracking', onClickFnctn: () => history.push('/userwisecourse') },
  ];

  const managementDropdown = [
    {
      icon: 'users',
      label: 'team_management',
      onClickFnctn: () => history.push('/teammanagement'),
    },
    {
      icon: 'mail square',
      label: 'mail_log',
      onClickFnctn: () => history.push('/admin/mailmaster/maillog'),
    },
    { icon: 'user circle', label: 'user_tracking', onClickFnctn: () => history.push('/userwisecourse') },
    { label: 'deactivated_users', onClickFnctn: () => history.push('/deactivatedusers') },
  ];

  const rhDropdownOptions = [
    { icon: 'user circle', label: 'my_profile', onClickFn: null },
    {
      icon: 'archive',
      label: 'archived_problems',
      onMouseEnter: archivedProblems,
      onMouseLeave: () => setDropdownShow(false),
      subdropdown: true,
      options: componentTypeList || [],
    },
    {
      icon: 'servicestack',
      label: 'services',
      onMouseEnter: () => setManagementSubdropdown(true),
      onMouseLeave: () => setManagementSubdropdown(false),
      options: managementDropdown,
      managementSubdropdown: true,
    },
    {
      icon: 'registered outline',
      label: 'reset_password',
      onClickFn: () => window.open('/resetpassword', '_blank'),
    }, ...commonOptions,
  ];

  // const adminDropdownOptions = [...commonOptions];

  // const proManagerDropdownOptions = [...commonOptions];

  // const orgAdminDropdownOptions = [...commonOptions];

  const getTextForRole = (rolename) => {
    let txt = '';
    const rolesplit = rolename.split('_');
    if (rolesplit.length > 1) {
      rolesplit.forEach((name) => {
        txt += name.charAt(0);
      });
      txt = txt.toUpperCase();
    } else {
      txt = rolename.charAt(0).toUpperCase() + rolename.slice(1);
    }
    return `${txt} View`;
  };

  const getSwitchoptions = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { role_list } } = respdata;
        if (estatus && emessage) {
          let roleswitchtab = [];
          roleswitchtab = role_list.map((role) => (
            {
              role: role.name,
              onClickFn: () => updateCookieBasedOnRole(role.name, role.segment_type_id,
                UserInfo.getOrgUnitId()),
              label: getTextForRole(role.name),
            }
          ));
          roleswitchtab = roleswitchtab.filter(({ role }) => role !== roleName);
          setAdminOptions(roleswitchtab);
        }
      }, resturls.obtainSegmentAndRolesForAdmin,
      {}, 'GET', 'cdn',
    );
  };

  let dropdownOptions = [];
  if (roleName === 'recruiter_head' || roleName === 'assessment_manager') {
    dropdownOptions = rhDropdownOptions;
  } else if (roleName === 'admin') {
    dropdownOptions = adminviewOptions;
  } else if (roleName === 'organization_admin') {
    dropdownOptions = orgAdminOptions;
  } else if (roleName === 'program_manager') {
    dropdownOptions = commonOptions;
  } else {
    dropdownOptions = commonOptions;
  }
  dropdownOptions = [...(isAdmin ? adminOptions : []), ...dropdownOptions];
  if (pathname.includes('orgunits')) {
    dropdownOptions = dropdownOptions.filter((option) => (
      option.label !== 'team_management'
      && option.label !== 'archived_problems'
      && option.label !== 'live_users'
      && option.label !== 'services'
    ));
  }

  const obtainArchivedProblemsForOrgUnit = (id, name) => {
    GlobalService.generalSelect(
      (respdata) => {
        const {
          estatus, emessage, data, data: { status },
        } = respdata;
        if (estatus && emessage) {
          if (status !== 'There is no archived problem avilable') {
            ls.set('selectedComponentType', name);
            history.push(`/archievedproblems/${id}/${data.question_bank_id}`);
            window.location.reload();
          } else {
            setToast(
              <ToastMessage
                open
                options={{
                  type: 'error',
                  title: status,
                }}
              />,
            );
          }
        }
      }, `${resturls.obtainArchivedProblemsForOrgUnit}?component_type_id=${id}`, {}, 'GET',
    );
  };

  const tabSection = () => (
    <div className={styles.headerNav} fontas="alpha">
      {tabOptions.map(({ title, suboptions = [] }) => {
        const isSubOptnNotExists = suboptions.length === 0;
        return (
          isSubOptnNotExists ? (
            <div
              className={pathname.includes(title) ? styles.activeTab : null}
              onClickCapture={() => history.push(`/${title}`)}
              key={title}
            >
              {t(title)}
              <div />
            </div>
          ) : (
            <Dropdown
              className={`${pathname.includes(title) ? styles.activeTab : ''} ${styles.parentAssessmentDropdown}`}
              inline
              key={title}
              text={(
                <div
                  className={pathname.includes(title) ? styles.activeTab : null}
                >
                  {t(title)}
                  <div />
                </div>
              )}
              selectOnBlur={false}
              onChange={(_, { value }) => history.push(value)}
              options={suboptions.map((opt) => ({
                key: opt.title,
                value: opt.path,
                text: t(opt.title),
                className: pathname.includes(opt.path)
                  ? styles.styleActiveSubDropdown
                  : styles.inActiveSubDropdown,
              }))}
            />
          )
        );
      })}
    </div>
  );

  const getCorrectHeaderTabs = () => {
    if (findOptions.length) {
      setTabOptions(findOptions);
    }
  };

  useEffect(() => { getCorrectHeaderTabs(); }, [findOptions]);

  useComponentDidMount(() => {
    if (isAdmin) getSwitchoptions();
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { is_notification_enabled } } = respdata;
        if (estatus && emessage) {
          updateNotificationStatus(is_notification_enabled === 'True');
        }
      }, resturls.checkNotificationEnable,
      {}, 'GET',
    );
  });

  const getDropdownOption = (option) => {
    if (option.subdropdown) {
      return (
        <Dropdown
          pointing="right"
          open={dropdownShow}
          text={(
            <>
              {option.icon && <Icon name={option.icon} />}
              {t(option.label)}
            </>
          )}
        >
          <Dropdown.Menu
            onMouseLeave={option.onMouseLeave}
          >
            {option.options.map((ele) => (
              <Dropdown.Item
                key={ele.key}
                onClick={() => obtainArchivedProblemsForOrgUnit(ele.value, ele.text)}
              >
                {t(ele.text)}
              </Dropdown.Item>

            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (option.userManagementSubDropdown) {
      return (
        <Dropdown
          pointing="right"
          open={userManagementSubDropdownShow}
          text={(
            <>
              {option.icon && <Icon name={option.icon} />}
              {t(option.label)}
            </>
          )}
        >
          <Dropdown.Menu
            onMouseLeave={option.onMouseLeave}
          >
            {option.options.map((ele) => (
              <Dropdown.Item
                key={ele.label}
                onClick={ele.onClickFnctn}
              >
                {t(ele.label)}
              </Dropdown.Item>

            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (option.institutionManagementSubDropdownShow) {
      return (
        <Dropdown
          pointing="right"
          open={institutionManagementSubDropdownShow}
          text={(
            <>
              {option.icon && <Icon name={option.icon} />}
              {t(option.label)}
            </>
          )}
        >
          <Dropdown.Menu
            onMouseLeave={option.onMouseLeave}
          >
            {option.options.map((ele) => (
              <Dropdown.Item
                key={ele.label}
                onClick={ele.onClickFnctn}
              >
                {t(ele.label)}
              </Dropdown.Item>

            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (option.managementSubdropdown) {
      return (
        <Dropdown
          pointing="right"
          open={managementSubdropdown}
          text={(
            <>
              {option.icon && <Icon name={option.icon} />}
              {t(option.label)}
            </>
          )}
        >
          <Dropdown.Menu
            onMouseLeave={option.onMouseLeave}
          >
            {option.options.map((ele) => (
              <Dropdown.Item
                key={ele.label}
                onClick={ele.onClickFnctn}
              >
                {
                  ele.options?.length > 0 ? (
                    <Dropdown
                      pointing="right"
                      open={userManagementSubDropdownOption}
                    >
                      <Dropdown.Menu
                        className={styles.userManagementSubdropdown}
                        onMouseLeave={option.onMouseLeave}
                      >
                        {ele.options.map((el) => (
                          <Dropdown.Item
                            key={el.label}
                            onClick={el.onClickFnctn}
                          >
                            {t(el.label)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    t(ele.label)
                  )
                }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <>
        {option.icon && <Icon name={option.icon} />}
        {t(option.label)}
      </>
    );
  };

  return (
    <>
      {toast}
      <HeaderLayOut {...props}>
        {leftContent && (
          <div align="left">
            {leftContent?.props.children}
          </div>
        )}
        {(centerContent || tabShow) && (
          <div align="center">
            {tabShow ? (
              tabSection()
            ) : (
              centerContent?.props.children
            )}
          </div>
        )}
        <div align="right">
          {rightContent}
          {notificationEnabled && <NotificationPanel />}
          <div className={styles.headerRightDropDown}>
            {showHome && (
              <Icon size="large" name="home" onClick={() => history.push(getRoleUrl())} />
            )}
            <Dropdown
              trigger={(
                <span fontas={window.innerWidth > 768 ? 'beta header' : 'beta'}>
                  {UserInfo.getName()}
                </span>
              )}
              onClick={() => setDropdownShow(false)}
              icon="dropdown"
            >
              <Dropdown.Menu>
                {dropdownOptions.map((option) => (
                  <Dropdown.Item
                    className={styles.dropdownItems}
                    onClick={option.onClickFn}
                    onMouseEnter={option.onMouseEnter}
                    onMouseLeave={option.onMouseLeave}
                    key={`key_${option.label}`}
                  >
                    {getDropdownOption(option)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </HeaderLayOut>
    </>
  );
}

export default withTranslation('common')(DefaultHeader);
