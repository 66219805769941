import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Accordion, Icon, Image,
} from 'semantic-ui-react';
import { FullPageLoader } from '@hrlytics/corelytics';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import UserInfo from '@models/UserInfo';
import ls from 'local-storage';
// import getRoleUrl from '@utils/RoleBasedURL';
import { updateCookieBasedOnRole } from '@utils/index';
import { useHistory } from 'react-router-dom';
import RoleBasedRouteModal from '@screens/orgunits/RoleBasedRouteModal';
import styles from './commonComponents.module.less';

function OrgTreeStructure(props) {
  const {
    t, isMemberAddable, setAssignMemberModalOpen, orgListData, setSelectedOrgId, searchtext,
    accordOpenList, setAccordOpenList, selectedOrgId, activeIndex,
    setActiveIndex, adminRoleObj = {}, showRoleList = false,
  } = props;

  const history = useHistory();

  const [roleBasedModal, setRoleBasedModal] = useState(false);
  const [roleList, setRoleList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const currentRole = UserInfo.getRole();

  const activeOrgRef = useRef('');

  const getRoleList = (org_unit_id) => {
    setIsLoading(true);
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage && data) {
          const { role_segment_list } = data;
          activeOrgRef.current = org_unit_id;
          if (role_segment_list.length > 1) {
            setRoleList(role_segment_list);
            setRoleBasedModal(true);
            setIsLoading(false);
          } else if (role_segment_list.length === 1) {
            const { role, role_segment_type_id } = role_segment_list[0];
            updateCookieBasedOnRole(role, role_segment_type_id, org_unit_id);
          }
        }
      }, `${resturls.obtainRoleListForUser}?org_unit_id=${org_unit_id}`, {}, 'GET',
    );
  };

  const renderAccordion = (org, parIds) => {
    if (((org.name).toLowerCase()).includes(searchtext) && searchtext !== '') {
      if (!accordOpenList.includes(org.id)) {
        setAccordOpenList([...accordOpenList, ...parIds]);
      }
    }

    return (
      <>
        {isLoading && <FullPageLoader />}
        <Accordion>
          <Accordion.Title
            className={`${styles.orgAccordionTitle} ${(((org.name).toLowerCase()).includes(searchtext) && searchtext !== '') ? styles.searchTab : null} ${org.id === selectedOrgId ? styles.selectedOrg : null}`}
            active={activeIndex.includes(org.id) || accordOpenList.includes(org.id) || currentRole === 'Recruiter Head'}
            onClick={() => {
              if (currentRole === 'organization_admin') {
                const temp = activeIndex;
                if (temp.includes(org.id)) {
                  temp.splice(temp.indexOf(org.id), 1);
                } else {
                  temp.push(org.id);
                }
                if (!isMemberAddable) {
                  setSelectedOrgId(org.id === selectedOrgId ? null : org.id);
                  ls.set('selectedOrgId', org.id);
                }
                setActiveIndex([...temp]);
              } else {
                const { role, callBack } = adminRoleObj;

                if (role !== 'admin') {
                  getRoleList(org.id);
                } else {
                  callBack(org.id);
                }
              }
            }}
          >
            <div>
              <Icon
                name={org.org_unit_list?.length > 0 ? 'dropdown' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  const temp = activeIndex;
                  if (temp.includes(org.id)) {
                    temp.splice(temp.indexOf(org.id), 1);
                  } else {
                    temp.push(org.id);
                  }
                  setActiveIndex([...temp]);
                }}
              />
              <Image avatar src="/images/sliderimg1.png" />
              <span fontas="alpha">{org.name}</span>

              {(showRoleList && org.role_list?.length > 0) && (
                <span fontas="gamma" className={styles.roleListWrapper}>
                  <span>{org.role_list?.length > 1 ? `${t('assigned_roles')} : ` : `${t('assigned_role')} : `}</span>
                  <span>{org.role_list?.map((role, i) => (i === 0 ? t(role) : `, ${t(role)}`))}</span>
                </span>
              )}
            </div>
            {isMemberAddable && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOrgId(org.id);
                  ls.set('selectedOrgId', org.id);
                  if (window.innerWidth > 560) {
                    setAssignMemberModalOpen(true);
                  } else {
                    history.push('/orgadmin/assignmember');
                  }
                }}
                tabIndex={-1}
                role="button"
                onKeyDown={null}
              >
                <Icon name="add circle" />
                <span fontas="beta">{t('assign_member')}</span>
              </div>
            )}
          </Accordion.Title>
          {org.org_unit_list !== undefined && (
            <Accordion.Content
              active={activeIndex.includes(org.id) || accordOpenList.includes(org.id)}
              className={styles.orgAccordionContent}
            >
              {org.org_unit_list.map((e) => renderAccordion(e, [...parIds, e.id]))}
            </Accordion.Content>
          )}
        </Accordion>
      </>
    );
  };

  return (
    <>
      {orgListData?.map((ele) => renderAccordion(ele, [ele.id]))}
      {roleBasedModal && (
        <RoleBasedRouteModal
          roleList={roleList}
          setRoleBasedModal={setRoleBasedModal}
          updateCookieBasedOnRole={updateCookieBasedOnRole}
          org_unit_id={activeOrgRef.current}
          setRoleList={setRoleList}
        />
      )}
    </>
  );
}

export default withTranslation('orgAdmin')(OrgTreeStructure);
