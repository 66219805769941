import { processLogout } from '@utils/index';
import { useHistory } from 'react-router-dom';
import useComponentDidMount from '@utils/useComponentDidMount';
import UserInfo from '@models/UserInfo';
import OpHeaderInitOptions from './OpHeaderInitOptions';

export default function OpHeaderData() {
  const { options, callBacks } = OpHeaderInitOptions();

  const { componentTypeList } = options;

  const { handleDeletedCourses, archivedProblems } = callBacks;

  const history = useHistory();

  const roleName = UserInfo.getRole();

  useComponentDidMount(() => {
    archivedProblems();
  });

  const archived = [
    {
      key: 'problems',
      value: 'Problems',
      text: 'Problems',
      problemTypeSubDropdown: true,
      options: componentTypeList,
      onClickFn: null,
    },
    {
      key: 'courses', value: 'Courses', text: 'Courses', onClickFn: handleDeletedCourses,
    },
  ];

  let commonDropdownOptions = [
    { icon: 'users', label: 'team_management', onClickFn: () => history.push('/teammanagement') },
    { icon: 'users', label: 'batch_management', onClickFn: () => history.push('/batchmanagement') },
    {
      icon: 'archive',
      label: 'archived',
      subdropdown: true,
      options: archived || [],
    },
    {
      icon: 'mail square',
      label: 'mail_log',
      onClickFn: () => history.push('/admin/mailmaster/maillog'),
    },
    { icon: 'registered outline', label: 'reset_password', onClickFn: () => window.open('/resetpassword', '_blank') },
    { icon: 'log out', label: 'logout', onClickFn: () => processLogout(true) },
  ];

  if (roleName === 'mentor') {
    commonDropdownOptions = commonDropdownOptions.filter((option) => option.label !== 'team_management');
  }

  return { archived, commonDropdownOptions };
}
