import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import OperationHeader from '@globalComps/header/OperationHeader';
import { HRLFormik, useComponentDidMount, FullPageLoader } from '@hrlytics/corelytics';
import writeXlsxFile from 'write-excel-file';
import {
  Button, ButtonContent, Dropdown, Form, Icon,
} from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import styles from './services.module.less';

const CourseWiseReport = (props) => {
  const { t } = props;

  const [batchReportList, setBatchReportList] = useState({
    streamOptions: [],
    trackOptions: [],
    batchOptions: [],
    overallReportData: [],
    courseOptions: [],
    isFullPageLoading: false,
    dataFetching: false,
  });
  const [selectionOrder, setSelectionOrder] = useState([]);

  const {
    streamOptions, trackOptions, batchOptions,
    overallReportData, courseOptions, isFullPageLoading,
    dataFetching,
  } = batchReportList;

  const removeDuplicates = (options) => {
    const seenKeys = new Set();
    return options.filter((option) => {
      const duplicate = seenKeys.has(option.key);
      seenKeys.add(option.key);
      return !duplicate;
    });
  };

  const obtainInstitutionLearningCourseFunct = () => {
    setBatchReportList((prev) => ({
      ...prev,
      dataFetching: true,
    }));
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { course_detail_list } } = respData;
      if (estatus && emessage) {
        setBatchReportList((prev) => ({
          ...prev,
          streamOptions: removeDuplicates(course_detail_list.map((e) => ({
            key: e.stream_id,
            value: e.stream_id,
            text: e.stream_name,
            track_config_id: e.id,
          }))),
          trackOptions: removeDuplicates(course_detail_list.map((e) => ({
            key: e.track_id,
            value: e.track_id,
            text: e.track_name,
            track_config_id: e.id,
          }))),
          batchOptions: removeDuplicates(course_detail_list.map((e) => ({
            key: e.batch_id,
            value: e.batch_id,
            text: e.batch_name,
            track_config_id: e.id,
          }))),
          courseOptions: removeDuplicates(course_detail_list.map((e) => ({
            key: e.course_id,
            value: e.course_id,
            text: e.course_name,
            track_config_id: e.id,
          }))),
          overallReportData: course_detail_list,
          dataFetching: false,
        }));
      }
    }, resturls.obtainInstitutionLearningCourseList, {}, 'GET');
  };
  useComponentDidMount(obtainInstitutionLearningCourseFunct);

  const obtainOverallCourseDetailsForBatchReport = (values) => {
    const courseList = values.course.map((courseId) => {
      const tracks = overallReportData.filter((e) => e.course_id === courseId);
      const uniqueTrackIds = Array.from(new Set(tracks.map((e) => e.track_id)));

      const params = [];

      uniqueTrackIds.forEach((uniqueTrackId) => {
        const batches = overallReportData.filter((e) => e.track_id === uniqueTrackId);
        const uniqueBatchIds = Array.from(new Set(batches.map((e) => e.batch_id)));

        params.push({ track_id: uniqueTrackId, batch_list: uniqueBatchIds });
      });

      return { id: courseId, track_list: params };
    });

    setBatchReportList((prev) => ({
      ...prev,
      isFullPageLoading: true,
    }));

    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { course_list } } = respdata;
        if (estatus && emessage) {
          setBatchReportList((prev) => ({
            ...prev,
            isFullPageLoading: false,
          }));
          const commonHeaderStyles = {
            backgroundColor: '#133a1b',
            align: 'center',
            fontWeight: 'bold',
            borderColor: '#000000',
            fontSize: 9,
          };

          const excelHeader2 = [
            { value: 'Section', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Module Name', rowSpan: 2, ...commonHeaderStyles },
            { value: 'No of Learners', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Attempted', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Idle', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Overall Completion of Scheduled Modules', span: 4, ...commonHeaderStyles },
          ];

          const excelHeader3 = [
            null, null, null, null, null,
            { value: '0 to 30%', ...commonHeaderStyles },
            { value: '30% to 50%', ...commonHeaderStyles },
            { value: '50% to 70%', ...commonHeaderStyles },
            { value: '70% and above', ...commonHeaderStyles },
          ];

          const sheetData = [];
          const sheetName = course_list.map((e, i) => e.course_name || `Batch${i + 1}`);

          course_list.forEach((course) => {
            const header1Data = [
              { value: course.course_name, span: 9, ...commonHeaderStyles },
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ];
            const excelBody = [];
            course.batch_list.forEach((batch) => {
              batch.module_list.forEach((module, index) => {
                const moduleData = [
                  index === 0 ? {
                    value: batch.name, rowSpan: batch.module_list.length, align: 'center', borderColor: '#000000',
                  } : null,
                  { value: module.name },
                  { value: module.no_of_learners },
                  { value: module.attempted },
                  { value: module.idle },
                  { value: module.overall_completion_of_scheduled_modules['0-30'] },
                  { value: module.overall_completion_of_scheduled_modules['30-50'] },
                  { value: module.overall_completion_of_scheduled_modules['50-70'] },
                  { value: module.overall_completion_of_scheduled_modules['70-100'] },
                ];
                excelBody.push(moduleData);
              });
            });
            sheetData.push([
              header1Data,
              excelHeader2,
              excelHeader3,
              ...excelBody,
            ]);
          });

          const columnWidths = [
            {}, { width: 20 }, { width: 35 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 },
          ];

          const columns = new Array(course_list.length).fill(columnWidths);

          writeXlsxFile(
            sheetData,
            {
              columns,
              fileName: 'userBatchReport.xlsx',
              sheets: sheetName,
            },
          );
        }
      },
      resturls.obtainOverallCourseDetailsForBatchReport,
      { course_list: courseList },
      'POST',
    );
  };

  const updateAssociatedFields = (name, selectedItems = [], setFieldValue) => {
    const associatedFields = {
      stream: new Set(),
      track: new Set(),
      batch: new Set(),
      course: new Set(),
    };

    selectedItems.forEach((itemId) => {
      overallReportData.forEach((item) => {
        if (item[`${name}_id`] === itemId) {
          associatedFields.stream.add(item.stream_id);
          associatedFields.track.add(item.track_id);
          associatedFields.batch.add(item.batch_id);
          associatedFields.course.add(item.course_id);
        }
      });
    });

    Object.entries(associatedFields).forEach(([fieldName, associatedValues]) => {
      if (fieldName !== name) {
        setFieldValue(fieldName, Array.from(associatedValues));
      }
    });
  };

  const handleSelection = (name, value, values, setFieldValue) => {
    if (!selectionOrder.includes(name)) {
      setSelectionOrder([...selectionOrder, name]);
    }

    const selectedStreams = name === 'stream' ? value : values.stream;
    const selectedTracks = name === 'track' ? value : values.track;
    const selectedBatches = name === 'batch' ? value : values.batch;
    const selectedCourses = name === 'course' ? value : values.course;

    const selectedItems = {
      stream: name === 'stream' ? value : values.stream,
      track: name === 'track' ? value : values.track,
      batch: name === 'batch' ? value : values.batch,
      course: name === 'course' ? value : values.course,
    };

    updateAssociatedFields(name, selectedItems[name], setFieldValue);

    const allSelectedConfigIds = new Set();

    selectedStreams.forEach((streamId) => {
      const stream = streamOptions.find((option) => option.value === streamId);
      if (stream) allSelectedConfigIds.add(stream.track_config_id);
    });

    selectedTracks.forEach((trackId) => {
      const track = trackOptions.find((option) => option.value === trackId);
      if (track) allSelectedConfigIds.add(track.track_config_id);
    });

    selectedBatches.forEach((batchId) => {
      const batch = batchOptions.find((option) => option.value === batchId);
      if (batch) allSelectedConfigIds.add(batch.track_config_id);
    });

    selectedCourses.forEach((courseId) => {
      const course = courseOptions.find((option) => option.value === courseId);
      if (course) allSelectedConfigIds.add(course.track_config_id);
    });

    setBatchReportList((prev) => ({
      ...prev,
      selectedConfigIds: Array.from(allSelectedConfigIds),
    }));
  };

  const handleSelectAll = (name, values, setFieldValue) => {
    let allValues = [];
    if (name === 'stream') {
      allValues = streamOptions.map((option) => option.value);
    } else if (name === 'track') {
      allValues = trackOptions.map((option) => option.value);
    } else if (name === 'batch') {
      allValues = batchOptions.map((option) => option.value);
    } else if (name === 'course') {
      allValues = courseOptions.map((option) => option.value);
    }

    setFieldValue(name, allValues);
    handleSelection(name, allValues, values, setFieldValue);
  };

  const handleUnselectAll = (field, setFieldValue) => {
    setFieldValue('stream', []);
    setFieldValue('track', []);
    setFieldValue('batch', []);
    setFieldValue('course', []);
  };

  const dropdownOptions = (values) => {
    let options;
    if (dataFetching) {
      options = [{ key: 'fetching_course', value: null, text: t('fetching_course') }];
    } else if (!dataFetching && courseOptions.length === 0) {
      options = [{ key: 'no_courses_here', value: null, text: t('no_courses_here') }];
    } else if (!dataFetching && courseOptions.length > 0 && courseOptions.length === values.course.length) {
      options = [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...courseOptions];
    } else {
      options = [{ key: 'all', value: 'all', text: t('select_all') }, ...courseOptions];
    }
    return options;
  };

  return (
    <div>
      {isFullPageLoading && <FullPageLoader />}
      <OperationHeader />
      <div className={styles.userBatchReport}>
        <div fontas="beta header">{t('course_wise_report')}</div>
        <div>
          <HRLFormik
            initialValues={{
              stream: [],
              track: [],
              batch: [],
              course: [],
            }}
            onSubmit={obtainOverallCourseDetailsForBatchReport}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleSelect,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Field
                    control={Dropdown}
                    selection
                    width={4}
                    search
                    options={
                      streamOptions.length === values.stream.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...streamOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...streamOptions]
                    }
                    multiple
                    label={t('stream')}
                    placeholder={t('search_stream')}
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('stream', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('stream', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('stream', d.value, values, setFieldValue);
                      }
                    }}
                    name="stream"
                    value={values.stream}
                    onBlur={handleBlur}
                    error={touched.stream && errors.stream}
                  />
                  <Form.Field
                    control={Dropdown}
                    selection
                    width={4}
                    label={t('track')}
                    placeholder={t('search_track')}
                    search
                    options={
                      trackOptions.length === values.track.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...trackOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...trackOptions]
                    }
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('track', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('track', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('track', d.value, values, setFieldValue);
                      }
                    }}
                    multiple
                    name="track"
                    value={values.track}
                    onBlur={handleBlur}
                    error={touched.track && errors.track}
                  />
                  <Form.Field
                    control={Dropdown}
                    selection
                    width={4}
                    options={
                      batchOptions.length === values.batch.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...batchOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...batchOptions]
                    }
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('batch', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('batch', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('batch', d.value, values, setFieldValue);
                      }
                    }}
                    label={t('batch')}
                    placeholder={t('search_batch')}
                    search
                    multiple
                    name="batch"
                    value={values.batch}
                    onBlur={handleBlur}
                    error={touched.batch && errors.batch}
                  />
                  <Form.Field
                    control={Dropdown}
                    selection
                    width={4}
                    search
                    options={dropdownOptions(values)}
                    multiple
                    label={t('course')}
                    placeholder={t('search_course')}
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('course', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('course', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('course', d.value, values, setFieldValue);
                      }
                    }}
                    name="course"
                    value={values.course}
                    onBlur={handleBlur}
                    error={touched.course && errors.course}
                  />
                </Form.Group>
                {
                  (
                    values.stream.length > 0
                    && values.track.length > 0
                    && values.batch.length > 0
                    && values.course.length > 0
                  ) && (
                    <div className={styles.downloadBatchReportBtn}>
                      <Button type="submit" animated>
                        <ButtonContent visible>{t('download')}</ButtonContent>
                        <ButtonContent hidden>
                          <Icon name="download" />
                        </ButtonContent>
                      </Button>
                    </div>
                  )
                }
              </Form>
            )}
          </HRLFormik>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('recruiter')(CourseWiseReport);
