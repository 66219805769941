import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import UserInfo from '@models/UserInfo';
import GlobalService from '@services/GlobalService';
import { FullPageLoader } from '@hrlytics/corelytics';
import { resturls } from '@utils/apiurls';
import {
  Button,
  Confirm,
  Icon, Input, Pagination, Popup, Table,
} from 'semantic-ui-react';
import CommonHeader from '@globalComps/header/CommonHeader';
import styles from './services.module.less';

const DeactivatedUsers = (props) => {
  const {
    t,
  } = props;
  const history = useHistory();
  const orgUnitId = UserInfo.getOrgUnitId();
  const currentRole = UserInfo.getRole();

  const [deactivatedUsers, setDeactivatedUsers] = useState({
    deactiveUserList: [],
    isFullPageLoading: false,
    searchKeyValue: '',
    totalPagenationCount: 0,
    activePageInfo: 1,
    isConfirmActivateUser: false,
    selectedUserId: '',
  });

  const {
    deactiveUserList, isFullPageLoading, searchKeyValue,
    totalPagenationCount, activePageInfo,
    isConfirmActivateUser, selectedUserId,
  } = deactivatedUsers;

  const obtainDeactivatedUsers = (activePage = 1) => {
    setDeactivatedUsers((prev) => ({
      ...prev,
      isFullPageLoading: true,
      activePageInfo: activePage,
    }));

    const adjustedUpper = 10;
    const startIndex = (activePage - 1) * adjustedUpper;

    let url = '';
    if (currentRole === 'recruiter_head' || currentRole === 'program_manager') {
      url = `${resturls.obtainDeactivatedUsers}?search_key=${searchKeyValue && searchKeyValue.length >= 3 ? encodeURIComponent(searchKeyValue) : ''}&upper_limit=${adjustedUpper * activePage}&lower_limit=${startIndex}&org_unit_id=${orgUnitId && orgUnitId}`;
    } else {
      url = `${resturls.obtainDeactivatedUsers}?search_key=${searchKeyValue && searchKeyValue.length >= 3 ? encodeURIComponent(searchKeyValue) : ''}&upper_limit=${adjustedUpper * activePage}&lower_limit=${startIndex}`;
    }
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data: { user_list } } = respdata;
      if (estatus && emessage) {
        setDeactivatedUsers((prev) => ({
          ...prev,
          deactiveUserList: user_list || [],
          isFullPageLoading: false,
        }));
      }
    }, url, {}, 'GET');
  };

  const activateAndDeactivateUserFunct = () => {
    let url = '';
    if (currentRole === 'recruiter_head' || currentRole === 'program_manager') {
      url = `${resturls.deactivateUserFromCourse}?user_id=${selectedUserId}&function=${true}&org_unit_id=${orgUnitId}`;
    } else {
      url = `${resturls.activateAndDeactivateUser}?user_id=${selectedUserId}&function=${true}`;
    }
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage && data) {
        setDeactivatedUsers((prevState) => ({
          ...prevState,
          deactiveUserList: prevState.deactiveUserList.filter((e) => e.id !== selectedUserId),
          isConfirmActivateUser: false,
        }));
      }
    }, url, {}, 'GET');
  };

  useEffect(() => {
    if (searchKeyValue.length >= 3 || searchKeyValue.length === 0) {
      obtainDeactivatedUsers();
    }
  }, [searchKeyValue]);

  return (
    <>
      {
        currentRole !== 'program_manager' && (
          <CommonHeader />
        )
      }
      {isFullPageLoading && <FullPageLoader />}
      <div className={currentRole === 'program_manager' ? styles.pmDeactivatedUsers : styles.deactivatedUsers}>
        <div className={currentRole === 'program_manager' ? styles.pmDeactivatedUsersHeader : styles.deactivatedUsersHeader}>
          <div>
            <Icon size="big" name="arrow alternate circle left outline" onClick={() => history.goBack()} />
            <span fontas="alpha">{t('deactivated_users')}</span>
          </div>
          <div>
            <Input
              fontas="alpha"
              placeholder="Search User"
              name="name"
              type="text"
              onChange={
                (e, d) => setDeactivatedUsers((prev) => ({
                  ...prev,
                  searchKeyValue: d.value,
                }))
              }
              icon="search"
              iconPosition="right"
            />
          </div>
        </div>
        <div className={styles.deactivatedUsersListTable}>
          <Table basic="very" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={styles.deactivateusertableHeader}><div>{t('sno')}</div></Table.HeaderCell>
                <Table.HeaderCell className={styles.deactivateusertableHeader}><div>{t('user_name')}</div></Table.HeaderCell>
                <Table.HeaderCell className={styles.deactivateusertableHeader}><div>{t('email')}</div></Table.HeaderCell>
                <Table.HeaderCell className={styles.deactivateusertableHeader}><div>{t('org_name')}</div></Table.HeaderCell>
                <Table.HeaderCell className={styles.deactivateusertableHeader}><div>{t('action')}</div></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(deactiveUserList.map((ele, i) => (
                <Table.Row>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>
                    {ele.name}
                  </Table.Cell>
                  <Table.Cell>
                    {ele.email}
                  </Table.Cell>
                  <Table.Cell>
                    {ele.org_name}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={styles.deactivateUser}>
                      <span>
                        {t('deactivated')}
                      </span>
                      <Popup
                        content={() => {
                          if (currentRole === 'admin') {
                            t('click_to_activete_user');
                          } else if (ele.admin_active && currentRole !== 'admin') {
                            return t('click_to_activete_user');
                          } else {
                            return t('activate_admin_access');
                          }
                          return t('click_to_activete_user');
                        }}
                        position="top center"
                        trigger={(
                          <Icon
                            name="eye slash"
                            disabled={currentRole !== 'admin' && !ele.admin_active}
                            onClick={
                              () => setDeactivatedUsers((prev) => ({
                                ...prev,
                                selectedUserId: ele.id,
                                isConfirmActivateUser: true,
                              }))
                            }
                          />
                        )}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              )))}
            </Table.Body>
          </Table>
          <div className={styles.pagination}>
            {totalPagenationCount > 1 && (
              <Pagination
                onPageChange={(e, { activePage }) => {
                  obtainDeactivatedUsers(activePage);
                }}
                activePage={activePageInfo}
                nextItem={{
                  'aria-label': 'Next item',
                  content: '⟩ Next',
                }}
                prevItem={{
                  'aria-label': 'Previous item',
                  content: 'Previous ⟨',
                }}
                firstItem={null}
                lastItem={null}
                secondary
                siblingRange={1}
                totalPages={totalPagenationCount}
              />
            )}
          </div>
          <Confirm
            open={isConfirmActivateUser}
            content={t('activate_user')}
            size="mini"
            cancelButton={<Button basic>{t('cancel')}</Button>}
            onCancel={
              () => setDeactivatedUsers((prev) => ({
                ...prev,
                isConfirmActivateUser: false,
              }))
            }
            onConfirm={activateAndDeactivateUserFunct}
          />
        </div>
      </div>
    </>
  );
};
export default withTranslation('recruiter')(DeactivatedUsers);
