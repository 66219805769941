// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../../../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.radioButtonNewChoice__qFjgg {\n  border: 1px solid var(--color-tusk);\n  margin-bottom: 2rem;\n}\n.radioButtonNewChoice__qFjgg > div {\n  margin: 2rem;\n}\n.radioButtonNewChoice__qFjgg > form {\n  padding: 3rem;\n  background: #fff;\n}\n.radioButtonNewChoice__qFjgg > form > div:last-child > button {\n  display: block;\n  margin: auto;\n}\n.radioButtonNewChoice__qFjgg .radioButtonNewChoiceTable__2xzEc {\n  margin-bottom: 1.5rem;\n}\n.rangeInput__3ZPG5 > div:last-child {\n  display: flex !important;\n  align-items: center;\n  gap: 2rem;\n}\n.rangeInput__3ZPG5 > div:last-child > span {\n  margin-bottom: 1.5rem;\n}\n.rangeChoiceInput__29qkN > div:last-child {\n  display: flex !important;\n  align-items: center;\n  gap: 2rem;\n}\n.deleteQuestionModal__20mK4 {\n  padding: 1.5rem 4.5rem !important;\n}\n.deleteQuestionModal__20mK4 > p {\n  text-align: center;\n}\n.deleteQuestionModal__20mK4 > div {\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"radioButtonNewChoice": "radioButtonNewChoice__qFjgg",
	"radioButtonNewChoiceTable": "radioButtonNewChoiceTable__2xzEc",
	"rangeInput": "rangeInput__3ZPG5",
	"rangeChoiceInput": "rangeChoiceInput__29qkN",
	"deleteQuestionModal": "deleteQuestionModal__20mK4"
};
module.exports = exports;
