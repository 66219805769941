// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--7-1!../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.test__3Nw8x {\n  padding-left: 100px;\n  background-color: var(--light-primary-color);\n}\n", ""]);
// Exports
exports.locals = {
	"test": "test__3Nw8x"
};
module.exports = exports;
