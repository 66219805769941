import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Route, useRouteMatch, Switch, useHistory,
} from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import OperationHeader from 'globals/components/header/OperationHeader';
import UserInfo from '@models/UserInfo';
import InstanceCreationPage from './InstanceCreationPage';
import CreateNewInstanceForm from './CreateNewInstanceForm';

function VirtualMachineLandingPage(props) {
  const { t } = props;
  const history = useHistory();
  const roleName = UserInfo.getRole();
  const { path } = useRouteMatch();
  return (
    <div>
      {roleName === 'program_manager' && (
        <OperationHeader>
          <div fontas="beta header">
            <Icon name="arrow left" fontas="beta" onClick={() => history.push('/programmanager/services')} />
            <span>{t('vm_management')}</span>
          </div>
        </OperationHeader>
      )}
      <>
        <Switch>
          <Route path="/VirtualMachine/createInstance/:track_id" component={CreateNewInstanceForm} />
          <Route path={`${path}`} component={InstanceCreationPage} />
        </Switch>
      </>
    </div>
  );
}

export default withTranslation('recruiter')(VirtualMachineLandingPage);
