import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import {
  Divider, Icon, Feed, Button,
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import styles from './notification.module.less';

const NotificationListContainer = (
  {
    notificationList, dispatch, newMesgsIdList, t, updateMessageStatus,
  },
) => {
  const removeNotification = (notification_id) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { is_deleted } } = respdata;
        if (estatus && emessage && is_deleted) {
          let tempNotfctnList = notificationList;
          tempNotfctnList = tempNotfctnList.filter(({ id }) => id !== notification_id);

          dispatch(({ type: 'NOTIFICATION_LIST', data: tempNotfctnList }));
        }
      }, resturls.removeUserNotification,
      { notification_id }, 'POST',
    );
  };

  return (
    <Feed>
      {notificationList?.map((notf) => {
        // Notification Read Status:
        // 0: New notification - The notification has not been viewed yet.
        // 1: Viewed - The notification has been seen in the notification panel but no action has been taken.
        // 2: Action Taken - The user has taken action on the notification from the notification panel.
        const notViewed = notf.is_read === 1;
        const isViewed = notf.is_read === 2;
        const responseNotifctn = notf.destination_url === '';
        return (
          <React.Fragment key={notf.id}>
            <Feed.Event id={notf.id}>
              <Feed.Content
                className={`${newMesgsIdList.current?.includes(notf.id) ? styles.newMesgs : ''} ${styles.notificationContent}`}
              >
                <Feed.Summary>
                  {(notViewed && !responseNotifctn) && <p className={styles.notViewed} />}
                  <span fontas="gamma header">{`${notf.title}:`}</span>
                  <span fontas="gamma">{notf.message}</span>
                  <p>{notf.sent_by}</p>
                </Feed.Summary>
                <Feed.Date>
                  {`${formatDistanceToNow(new Date(notf.raised_date))} ago`}
                  {(notViewed && !responseNotifctn) && (
                    <Button onClick={() => updateMessageStatus('VIEWED', [notf.id])}>{t('view')}</Button>
                  )}
                  {(isViewed || responseNotifctn) && (
                    <Icon
                      name='times circle outline'
                      onClick={() => removeNotification(notf.id)}
                      size='large'
                    />
                  )}
                </Feed.Date>
              </Feed.Content>
            </Feed.Event>
            <Divider />
          </React.Fragment>
        );
      })}
    </Feed>
  );
};

export default withTranslation('common')(NotificationListContainer);
