import React from 'react';
import { withTranslation } from 'react-i18next';
import { HRLFormik } from '@hrlytics/corelytics';
import { useHistory } from 'react-router-dom';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import {
  Button, Checkbox, Dropdown, Form, Grid, Input, TextArea,
} from 'semantic-ui-react';
import QuestionnaireHeader from './QuestionnaireHeader';
import styles from './questionnaire.module.less';

const CreateQuestionnaire = (props) => {
  const { t } = props;
  const history = useHistory();

  const gradeOptions = [];
  for (let i = 1; i <= 100; i += 1) {
    gradeOptions.push({ key: i, text: i.toString(), value: i });
  }

  const submitQuestionnaire = (value) => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        history.push(`/questionnaire/show/${data.u_id}`);
      }
    }, resturls.createAndEditQuestionnaire, {
      questionnaire_obj: value,
    }, 'POST');
  };
  return (
    <div>
      <QuestionnaireHeader headerText={t('create_qestionnaire')} />
      <div className={styles.createQuestionnaire}>
        <HRLFormik
          initialValues={{
            name: '',
            description: '',
            comments_enabled: false,
            grade: 1,
            numbering_enabled: false,
          }}
          // validationSchema={schema}
          onSubmit={submitQuestionnaire}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSelect,
            handleSelectiveBlur,
            handleRadioChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row className={styles.questionnaireRow}>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <span fontas="alpha">
                      {t('name')}
                    </span>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field
                      required
                      fontas="beta"
                      onChange={handleChange}
                      control={Input}
                      name="name"
                      width={4}
                      value={values.name}
                      onBlur={handleBlur}
                      error={touched.name && errors.name}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <span fontas="alpha">
                      {t('description')}
                    </span>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field
                      required
                      fontas="beta"
                      control={TextArea}
                      name="description"
                      width={4}
                      onChange={handleChange}
                      value={values.description}
                      onBlur={handleBlur}
                      error={touched.description && errors.description}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className={styles.questionnaireRow}>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <span fontas="alpha">
                      {t('comments')}
                    </span>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field
                      control={Checkbox}
                      name="comments_enabled"
                      value={values.comments_enabled}
                      checked={values.comments_enabled}
                      onChange={handleRadioChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <span fontas="alpha">
                      {t('grade')}
                    </span>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field
                      name="grade"
                      options={gradeOptions}
                      value={values.grade}
                      onChange={handleSelect}
                      width={4}
                      onBlur={handleSelectiveBlur}
                      control={Dropdown}
                      required
                      selection
                      error={touched.grade && errors.grade}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className={styles.questionnaireRow}>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <span fontas="alpha">
                      {t('numbering_enabled')}
                    </span>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field
                      control={Checkbox}
                      name="numbering_enabled"
                      value={values.numbering_enabled}
                      checked={values.numbering_enabled}
                      onChange={handleRadioChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button type="submit">{t('create')}</Button>
            </Form>
          )}
        </HRLFormik>
      </div>
    </div>
  );
};
export default withTranslation('recruiter')(CreateQuestionnaire);
