const ProgramManager = {
  name: 'Name',
  dashboard: 'Dashboard',
  disussion: 'Disussion',
  services: 'Services',
  team_management: 'Team Management',
  logout: 'Logout',
  course_management: 'Course Management',
  add_new_course: 'Add New Course',
  edit: 'Edit',
  view: 'View',
  about_the_course: 'About the course',
  course_objective: 'Course Objective',
  course_outcome: 'Course Outcome',
  logo: 'Logo',
  team_count: 'Teams',
  track_count: 'Tracks',
  knowledge_questions_count: 'Knowledge Questions',
  programming_questions_count: 'Programming Questions',
  course_category: 'Course Category',
  start_time: 'Start Time',
  end_time: 'End Time',
  end_time_is_required: 'End time is required',
  start_time_is_required: 'Start time is required',
  threshold_is_required: 'Threshold is required',
  course_category_is_required: 'Course Category is required',
  course_code: 'Course Code',
  threshold: 'Threshold',
  critificate_template: 'Certificate Template',
  prerequisites_course: 'Prerequisite Courses',
  prerequisite_base_score: 'Prerequisite Base Score',
  prerequisites_is_required: 'Prerequisites is required',
  prerequisite_base_score_is_required: 'Prerequisite base score is required',
  score_must_be_at_least: 'Score must be at least 1',
  score_cannot_exceed: 'Score cannot exceed 100',
  cancel: 'Cancel',
  save: 'Save',
  no_file_choosen: 'No File Choosen',
  choose_file: 'Choose File',
  administration: 'Administration',
  ebox_path: ' / E Box',
  create_course: 'Create Course',
  name_should_have_at_least_2_characters: 'Name should have at least 2 characters',
  name_is_required: 'Name is required',
  course_code_is_required: 'Course  Code is required',
  about_the_course_is_required: 'About the Course is Required',
  logo_is_required: 'Logo is required',
  start_date_must_be_later_than_current_date_time: 'Start date must be later than current date/time',
  // start_time_is_required: 'Start Time is Required',
  end_date_must_be_later_than_startdate: 'End date must be later than startdate',
  create_new_batch: 'Create New Batch',
  disable_enable_learners: 'Disable/Enable Learners',
  batch_transfer: 'Batch transfer',
  live_users: 'Live Users',
  reports: 'Reports',
  tagged_outcomes: 'Tagged Outcomes',
  epic_creation: 'Epic Creation',
  user_management: 'User Management',
  track_management: 'Track Management',
  question_bank_management: 'Question Bank Management',
  visibility: 'Visibility',
  home: 'home',
  courses: 'Courses',
  my_cart: 'My Cart',
  profile: 'profile',
  hrLytics_private_Ltd: 'HRLytics Private Ltd',
  kuvembu_road: 'No. 354/B Kuvembu Road',
  mg_road_Banglore: 'Mg Road,Banglore',
  pin: '560 048',
  mail: 'mail@amphisoft.in',
  call: '+123 456 789',
  saved_successfully: 'Saved Successfully',
  create_successfully: 'Created Successfully',
  updated_successfully: 'Updated Successfully',
  required_field: 'Required Field',
  create_track: 'Create Track',
  amphisoft_path: 'Amphisoft path / E Box',
  description_is_required: 'Description is required',
  track_objective_is_required: 'Track objective is required',
  track_outcome_is_required: 'Track outcome is required',
  track_code_is_required: 'Track Code is required',
  create_new_track: 'Create New Track',
  track_outcome: 'Track Outcome',
  description: 'Description',
  prerequisites_track: 'Prerequisite Tracks',
  track_objective_short_description: 'Track Objective / Short Description',
  track_category: 'Track Category',
  tagged_track: 'Tagged Track',
  tagged_courses: 'Tagged Courses',
  track_code: 'Track Code',
  road_map: 'Road Map',
  website_link: 'Website Link',
  add: 'Add',
  delete: 'Delete',
  create: 'Create',
  outcomes: 'Outcomes',
  short_description: 'Short Description',
  please_enter_a_numeric_value: 'Please enter a numeric Value',
  please_select_a_file: 'Please select a File',
  course_objective_is_required: 'Course Objective is required',
  course_outcome_is_required: 'Course Outcome is required',
  end_date: 'End Date',
  required_Field: 'Required Field',
  show_courses: 'Show Courses',
  start_date: 'Start Date',
  s_no: 'S.No',
  course: 'Course',
  action: 'Action',
  java_basics_brief: 'Java basics Brief',
  deleted_content: 'Selected group will be deleted and the modules will be moved outside the group.',
  are_you_sure: 'Are you sure to proceed?',
  view_all: 'View All',
  modules: 'Modules',
  grouped_module_2: 'Grouped Module 2',
  create_module: 'Create Module',
  group_module: 'Group Module',
  deleted_successfully: 'Deleted Successfully',
  remove: 'Remove',
  show_courses_track: 'Show Courses Track',
  add_module: 'Add Module',
  group_name: 'Group Name',
  selected_modules: 'Selected Modules',
  group_description: 'Group Description',
  module_list_is_required: 'Module List is Required',
  minimum_module_list_is_required: 'Minimum 2 Module list is Required',
  create_module_path: 'Create Module',
  module_details: 'Module Details',
  show_module: 'Show Module',
  import_hrl_session: 'Import HRL Session',
  import_components: 'Import Components',
  team_mode: 'Team Mode',
  sessions: 'Sessions',
  components: 'Components',
  visible: 'Visible',
  prequisite_base_score: 'Prequisite Base Score',
  prequisites: 'Prequisites',
  update: 'Update',
  importComponent: 'ImportComponent',
  importHrlSession: 'ImportHrlSession',
  show_course: 'Show Course',
  no_data_found: 'No Data Found',
  track_viewing: 'Track Viewing',
  re_arrange: 'Re-Arrange Sessions',
  // Create Session
  create_session: 'Create Session',
  new_session: 'New Session',
  no_sessions_added: 'No sessions added',
  assessment: 'Assessment',
  springboot_assessment: 'Springboot Assessment',
  exixting_sessions: 'Exixting Sessions',
  next: 'Next',
  create_amphiSession: 'Create AmphiSession',
  start_date_time: 'Start Date & Time',
  end_date_time: 'End Date & Time',
  intermediate_end_time: 'Intermediate End Time',
  allow_teams: 'Allow Teams',
  attempts_allowed: 'Attempts Allowed',
  duration: 'Duration(mins)',
  show_results: 'Show Results',
  hard_end_time: 'Hard End Time',
  scoring_enabled: 'Scoring Enabled',
  organize_session: 'Organize Session',
  scheduled_alert: 'Scheduled Alert',
  adaptive_assessment: 'Adaptive Assessment',
  restrict_attemt_in_app: 'Restrict Attemt In App',
  show_results_after_session_end: 'Show Results After Session End',
  advanced_settings: 'Advanced Settings',
  session_type: 'Session Type',
  code: 'Code',
  custom_instructions: 'Custom Instructions',
  skip_instructions: 'Skip Instructions',
  pass_score: 'Pass Score',
  weightage: 'Weightage',
  include_in_course_progress: 'Include In Course Progress',
  answer_all_questions: 'Answer All Questions',
  secure_window: 'Secure Window',
  controlled_session: 'Controlled Session',
  show_page_count: 'Show Page Count',
  show_qa_forum: 'Show QA Forum',
  enable_slot: 'Enable Slot',
  verify_team_timing: 'Verify Team Timing',
  effort_unit: 'Effort Unit',
  effort_multiplier: 'Effort Multiplier',
  notice_baord: 'Notice Baord',
  segment_type: 'Segment Type',
  penalty_enabled: 'Penalty Enabled',
  show_completion_duration: 'Show Completion Duration',
  manual_publish: 'Manual Publish',
  enable_terms_conditions: 'Enable Terms & Conditions',
  show_last_page_finish_button: 'Show Last Page Finish Button',
  finish_message: 'Finish Message',
  session_instructions: 'Session Instructions',
  result_page: 'Result Page',
  session_statistics: 'Session Statistics',
  tracking_type: 'Tracking Type',
  track_type: 'Track Type',
  ldp_course_type: 'LDP Course Type',
  enable_problem_wise_tracking: 'Enable Problem Wise Tracking',
  enable_plugin: 'Enable Plugin',
  enable_regenerate_password: 'Enable Regenerate Password',
  group_event: 'Group Event',
  group_size: 'Group Size',
  section_controlled_attempt: 'Section Controlled Attempt',
  enable_meeting: 'Enable Meeting',
  secret_key_notify_email: 'Secret Key Notify Email',
  allow_question_mistake: 'Allow Question Mistake',
  finish_page: 'Finish Page',
  credits: 'Credits',
  live_assessment: 'Live Assessment',
  enable_meeting_room: 'Enable Meeting Room',
  enable_interviewer_chat: 'Enable Interviewer Chat',
  mandate_enrollment: 'Mandate Enrollment',
  send_completion_mail: 'Send Completion Mail',
  dynamic_pool_selection: 'Dynamic Pool Selection',
  send_status_mail: 'Send Status Mail',
  status_notify_email: 'Status Notify Email',
  enable_full_screen: 'Enable Full Screen',
  enable_ranking: 'Enable Ranking',
  key_settings: 'Key Settings',
  initial_key: 'Initial Key',
  random_initial_key: 'Random Initial Key',
  intermediate_key: 'Intermediate Key',
  common_initial_key_for_all: 'Common Initial Key For All',
  proctor_settings: 'Proctor Settings',
  enable_cam: 'Enable Cam',
  cam_proctored: 'Cam Proctored',
  assessment_warning: 'Assessment Warning',
  cam_proctored_intervals: 'Cam Proctored Intervals(Sec)',
  screen_proctered: 'Screen Proctered',
  screen_proctored_intervals: 'Screen Proctored Intervals(Sec)',
  ip_filtering: 'IP Filtering',
  ip_list: 'IP List',
  verify_identity: 'Verify Identity',
  online_learning_settings: 'Online Learning Settings',
  small_logo: 'Small Logo',
  medium_logo: 'Medium Logo',
  large_logo: 'Large Logo',
  unstructured_learning_type: 'Unstructured Learning Type',
  attempt_mode: 'Attempt Mode',
  attempt_theme: 'Attempt Theme',
  angular_springboot_assessment: 'Angular Spring Boot Assessment',
  mean_mern_stack: 'Mean/Mern Stack',
  // LESSON////////////////////////////////////////////
  create_lesson_component: 'Create Lesson Component ',
  create_lesson: 'Create Lesson',
  hrl_session: 'HRL Session',
  sample_video: 'Sample Video',
  resource_type: 'Resource Type',
  video: 'VIDEO',
  video_file_type: '(File Type: webm l .mp4 l .flv l .ogg)',
  cloud: 'CLOUD',
  cloud_file_type: '(File Type: S3 video link)',
  pdf: 'PDF',
  pdf_file_type: '(File Type: .pdf)',
  ppt: 'PPT',
  web: 'WEB',
  web_file_type: '(File Type: .html l .php l .asp)',
  you_tube: 'YOUTUBE',
  you_tube_file_type: '(File Type: no extension)',
  source_path: 'Source Path',
  video_name: 'Video Name',
  video_path: 'Video Path',
  video_script: 'Video Script :',
  check_points: 'Check Points',
  slide_control: 'Slide Control',
  teammode: 'Teammode',
  assessment_mode: 'Assessment Mode',
  component_type: 'component Type',
  show_result_on_check_point: 'Show Result On Check Point',
  lesson_component: 'Lesson Component',
  add_check_point_here: 'Add Check Point here',
  finalize_check_points: 'Finalize Check Points',
  check_point_for_video_sample: 'Check Point for Video Sample',
  save_check_point: 'Save Check Point',
  session_map: 'Session Map',
  check_point_position: 'Check Point Position',
  create_new_check_point: 'Create New Check Point',
  show_lesson_component: 'Show Lesson Component',
  video_sample: 'Video Sample',
  resource_path: 'Resource Path',
  lesson: 'Lesson',
  current_check_point_list: 'Current Check Point List',
  out_of_100: '(out of 100)',
  mapped_session: 'Mapped Session:',
  component: 'Component',
  pages_from: 'Pages From',
  pages_to: 'Pages To',
  position: 'Position:',
  seconds: 'seconds',
  from_page_no: 'From Page No.',
  to_page_no: 'To Page No.',
  code_is_required: 'Code is Required',
  finish_message_is_required: 'Finish Message is Required',
  session_instructions_required: 'Session Instructions is Required',
  organize_session_is_required: 'Organize Session is Required',
  overall_score_is_required: 'Overall Score is Required',
  segment_type_is_required: 'Segment Type is Required',
  are_you_sure_no_more_checkpoints_manipulations_takes_place: 'Are you sure No more checkpoints Manipulations takes place?',
  ok: 'OK',
  lesson_component_summary: 'Lesson Component Summary',
  check_point_position_is_required: 'Check Point Position is Required',
  session_map_is_required: 'Session Map is Required',
  overall_score: 'Overall Score',
  // createBatch///////
  settings: 'Settings',
  create_a_new_training_batch: 'Create a New Training Batch',
  add_learners: 'Add Users / Learners',
  batch_details: 'Batch Details',
  batch_save: 'Save & Proceed',
  stream: 'Stream',
  batch_name: 'Batch Name',
  track_name: 'Track',
  location: 'Location',
  intake: 'Intake',
  new: 'New',
  back: 'Back',
  add_all_users: 'Add All Users',
  user_learner_upload: 'User/Learner Upload',
  total_stream_count: 'Stream',
  total_batch_count: 'Active Batches',
  total_user_count: 'Active Learners',
  batch: 'Batch',
  add_user: 'Add User',
  email: 'Email',
  contact: 'Contact',
  remarks: 'Remarks',
  max_file_size_is: 'Max File size is',
  invalid_file_upload: 'Invalid file upload',
  template: 'Template',
  download_template: 'Download Template',
  drag_and_drop_your_file_here: 'Drag and drop your file here',
  click_here_to_browse: 'Click here to Browse',
  or: 'Or',
  release_to_upload: 'Release to upload',
  uploaded_file: 'Uploaded file',
  upload_users: 'Upload Users',
  add_all_user: 'Add All User',
  team_name: 'Team Name',
  create_new_location: 'Create New Location',
  create_new_intake: 'Create New Intake',
  create_new_stream: 'Create New Stream',
  location_is_required: 'Location is Required',
  intake_is_required: 'Intake is Required',
  stream_is_required: 'Stream is Required',
  track_is_required: 'Track is Required',
  batch_is_required: 'Batch is Required',
  add_existing_users: 'Add Existing Users',
  re_upload: 'Re Upload',
  download_error: 'Download Error',
  batches: 'Batches',
  active: 'Active',
  completed: 'Completed',
  learners: 'Learners',
  go_to_top: 'Go to Top',
  file_contains_error: "File contains error. Can't upload Users",
  create_quiz_session: 'Create Quiz Session',
  create_quiz: 'Create Quiz',
  quiz_details: 'Quiz Details',
  questions: 'Questions',
  question_per_page: 'Question Per Page',
  grade: 'Grade',
  quiz_grading_type: 'Quiz Grading Type',
  quiz_purpose: 'Quiz Purpose',
  penalty: 'Penalty',
  shuffle_options: 'Shuffle Options',
  complex_category: 'Complex Category',
  show_details_in_eesult: 'Show Details in Result',
  enable_penalty: 'Enable Penalty',
  dynamic_quiz_selection: 'Dynamic Quiz Selection',
  adaptive_mode: 'Adaptive Mode',
  titbit: 'Titbit',
  show_answer_status_in_result: 'Show Answer Status in Result',
  display_name_in_attempt: 'Display Name in Attempt',
  adaptive_question_count: 'Adaptive Question Count',
  upload_workout_images: 'Upload Workout Images',
  duration_must_be_in_positive_integers: 'Duration must be in Positive Integers',
  duration_is_required: 'Duration is Required',
  new_category: 'New Category',
  overall_score_must_be_in_positive_integer: 'Overall Score must be in Positive Integer',
  skip_instruction: 'Skip Instruction',
  overall_score_must_be_at_least: 'Overall Score must be at least 1',
  organize_session_type_is_required: 'Organize Session type is required',
  overall_score_cannot_exceed: 'Overall Score cannot Exceed',
  session_type_is_required: 'Session Type is Required',
  custom_instruction_is_required: 'Custom Instruction is Required',
  // component_type: 'Component Type',
  // segment_type_is_required: 'Segment Type is Required',
  existing_sessions: 'Existing Sessions',
  no: 'NO',
  yes: 'YES',
  are_you_sure_you_want_to_untag_the_course: 'Are you sure! Do you want to untag the Course ?',
  are_you_sure_you_want_to_delete_the_track: 'Are you sure! Do you want to Delete the Track ?',
  prerequisites: 'Prerequisites',
  group_module_name_is_already_exists: 'Group Module name is already exists',
  module_name_is_already_exists: 'Module name is already exists',
  are_you_sure_to_delete_the_module: 'Are you sure! Do you want to Delete the Module ?',
  track_name_is_already_exists: 'Track name is already exists',
  course_name_is_already_exists: 'Course name is already exists',
  removed_successfully: 'Removed Successfully',
  create_group_module: 'Create Group Module',
  discussions: 'Discussions',
  value_already_exists: 'already exists',
  create_new: 'Create New',
  users_added_successfully: 'Users Added Successfully',
  general_feedback: 'General Feedback',
  feedback: 'FeedBack',
  base_score_is_required: 'Base score is required',
  edit_module: 'Edit Module',
  is_required: 'is Required',
  search: 'Search',
  select_stream: 'Select Stream',
  select_track: 'Select Track',
  select_batch: 'Select Batch',
  preview: 'Preview',
  excel: 'Excel',
  add_new_track: 'Create New Track',
  module_name_already_exists: 'Module Name already exists',
  reArrange_sessions: 'Re Arrange Sessions',
  new_component: 'New Component',
  add_check_points: 'Add Check Points',
  rearrange_lessons: 'Re Arrange Lessons',
  rearrange_videos: 'Re Arrange Videos',
  video_duration: 'Video Duration : ',
  question_count: 'Question Count',
  check_point_created_successfully: 'Check Point Created Successfully',
  show_session_settings: 'Show Session Settings',
  view_session: 'View Session',
  concept_trees: 'Concept Trees',
  session_details: 'Sessions Details',
  skill_questions: 'Skill Questions',
  tracks: 'Tracks',
  track: 'Track',
  course_count: 'Course Count',
  active_learners: 'Active Learners',
  participation: 'Participation',
  assessment_count: 'Assessment Count',
  add_new_component: 'Add New Component',
  source_name: 'Source Name',
  source_script: 'Source Script',
  are_you_sure_you_want_to_delete_resource: 'Are you sure you want to Delete Resource',
  upload_vtt: 'Upload VTT',
  check_point_updated_successfully: 'Check Point Updated Successfully',
  are_you_sure_to_delete_the_checkpoint: 'Are you Sure to Delete the Checkpoint',
  from_page_no_is_required: 'From Page No is Required',
  to_page_no_is_required: 'To Page No is Required',
  source_names: 'Source Name',
  source_paths: 'Source Path',
  video_link: 'Video Link : ',
  are_you_sure_to_delete_the_lesson: 'Are you sure to Delete the Lesson',
  team_finish_setup: 'Team Finish SetUp',
  check_points_finalized_successfully: 'Check Points Finalized Successfully',
  source: 'Source',
  Kindly_select: 'Kindly Select',
  username: 'UserName',
  user_name: 'User Name',
  teams: 'Teams',
  statistics: 'Statistics',
  resources: 'Resources',
  view_report: 'View Report',
  view_detailed_report: 'View Detailed Report',
  users: 'Users',
  checkpoint_status: 'CheckPoint Status',
  recompute_all: 'ReCompute All',
  recompute: 'ReCompute',
  get_report: 'Get Report',
  get_all_report: 'Get All Report',
  completed_percentage: 'Completed Percentage',
  number_of_users: 'Number of Users',
  status: 'Status',
  finish_setup_successfully_updated: 'Finish Setup Successfully Updated',
  dashboard_batches: 'Batch : ',
  assessment_details: 'Assessment Details',
  certificates: 'Certificates',
  completed_batches: 'Completed Batches',
  stream_summary: 'Stream Summary - ',
  no_of_learners: 'No. Of Learners',
  batch_owner: 'Batch Owner',
  assessment_type: 'Assessment Type',
  actions: 'Actions',
  hide: 'Hide',
  performance: 'Performance',
  time_spent: 'Time Spent',
  track_completion: 'Track Completion (%)',
  course_completion: 'Course Completion',
  are_you_sure_to_delete_this_session: 'Are you sure to Delete this Session',
  course_name: 'Course Name',
  rating: 'Rating',
  paritipation: 'Paritipation',
  go_to_components: 'Go to Components',
  filter_teams: 'Filter Teams',
  resource_name_is_required: 'Resource Name is Required',
  schedule: 'Schedule',
  session_name: 'Session Name',
  attempts: 'Attempts',
  active_session: ' Active Session',
  learners_details: 'Learners Details',
  live_classes: 'Live Classes',
  activity_stack: 'Activity Stack',
  participant_active_details: 'Participant Active Details',
  view_scores: 'View Scores',
  participant_summary: 'Participant Summary',
  infinite: 'Infinite',
  update_session: 'Update Session',
  off: 'OFF',
  on: 'ON',
  select_team: 'Select Team',
  select_module: 'Select Module',
  select_session: 'Select Session',
  select_component: 'Select Component',
  select_user: 'Select User',
  select_status: 'Select Status',
  select_problem: 'Select Problem',
  team: 'Team',
  session: 'Session',
  module: 'Module',
  problem: 'Problem',
  team_wise_statistics: 'Team Wise Statistics',
  on_going_teams: 'On-going Teams',
  completed_teams: 'Completed Teams',
  practice: 'Practice / Assessment Scor Details',
  report: 'Report',
  details_performance_xls: 'Details Performance XLS',
  session_wise_score: 'Session Wise Score',
  problem_solved_count: 'Problem Solved Count',
  user_completion_xls: 'User Completion XLS',
  effectiveness: 'Effectiveness',
  attempts_no: 'Attempts No',
  starts_on: 'Starts On',
  activeness: 'Activeness',
  avg_completion: 'Avg. Completion(%)',
  idle: 'Idle',
  selected_teams: 'Selected Teams',
  more: 'More',
  course_completed_count: 'Course Completed Count',
  total_members: 'Total Members',
  participation_statistics: 'Participation Statistics',
  module_name: 'Module Name',
  avg_solved_total_problem: 'Solved/Total (Problem)',
  assessments: 'Assessments',
  not_yet_covered: 'Not Yet Covered',
  assessment_score_details: 'Practice / Assessment Score Details',
  continue_attempt: 'Continue Attempt',
  finish_attempt: 'Finish Attempt',
  delete_attempt: 'Delete Attempt',
  extend_time: 'Extend Time',
  grace_time: 'Grace Time',
  reason: 'Reason',
  negative_grace_time_will_decrease_the_duration_of_the_attempt: 'Negative grace time will decrease the duration of the Attempt',
  min: 'min',
  grace_time_should_not_be: 'Grace Time should not be',
  are_you_sure_to_want_this: 'Are You Sure!',
  you_want_to_delete_the_selected_attempts: 'You want to delete the selected Attempts',
  you_want_to_update_attempt_status_as_completed_for_the_selected_attempts: 'You want to Update attempt status as "Completed" for the selected Attempts',
  you_want_to_update_attempt_status_as_in_progress_for_the_selected_attempts: 'You want to Update attempt status as "In Progress" for the selected Attempts',
  update_stack: 'Update Stack',
  subject_number: 'Sub. Number',
  received_time: 'Received Time',
  test_case_pass_percentage: 'Test Case Pass %',
  select_language: 'Select Language',
  language: 'Language',
  min_percentage: 'Min. Percentage',
  max_percentage: 'Max. Percentage',
  learner_details: 'Learner Details',
  time_spent_hrs: 'Time Spent(Hrs)',
  session_comp: 'Session Comp.',
  practice_score: 'Practice Score',
  participation_score: 'Participation Score',
  submission_number: 'Submission No.',
  no_courses_available: 'No Courses Available',
  attempted: 'Attempted',
  schedule_session: 'Schedule Session',
  activeness_duration: 'Activeness (last 48 hrs)',
  participant_score: 'Participant Score',
  total_users: 'Total Users',
  challenging_users: 'Challenging User(s)',
  excellent_users: 'Excellent User(s)',
  session_completion: 'Session Completion',
  top_Performances: 'Top 10 Performances',
  average_Performances: 'Average Performances',
  not_visited_in_Days: 'Not Visited In 2 Days',
  no_Asses_taken_in_days: 'No Asses.Taken In 2 Days',
  register_no: 'Register No.',
  last_attempted: 'Last Attempted',
  last_active: 'Last Active',
  no_matching_records_found: 'No matching records found',
  java_basic_belief: 'Java Basic Belief',
  skill_questions_count: 'Skill Questions',
  from_page_no_must_be_at_least: 'From page no must be at least 1',
  to_page_no_must_be_greater_than_from_page_no: 'To page no must be greater than From page no',
  performance_score: 'Performance Score',
  not_attempted: 'Not Attempted',
  count: 'Count',
  overall_team_activeness: 'OverAll Team Activeness',
  overall_batch_statistics: 'OverAll Batch Statistics',
  team_activeness: 'Team Activeness',
  team_statistics: 'Team Statistics',
  liveClass: 'Live Classes',
  createMeeting: 'Create Meeting',
  start_meeting: 'Start Meeting',
  attendance: 'Attendance',
  feedback_report: 'Feedback Report',
  completed_meeting: 'Completed Meeting',
  exit_time: 'Exit Time',
  entry_time: 'Entry Time',
  selectHere: 'Select Here',
  userName: 'Username',
  total_meetings: 'Total Meetings',
  attended: 'Attended',
  percentage: 'Percentage',
  moreDetails: 'More Details',
  noMeetings: 'No Meetings Available',
  upcoming: 'Upcoming',
  startNow: 'Start Now',
  are_you_sure_to_delete_this_meeting: 'Are you sure to Delete this Meeting',
  noUsers: 'No Users Available',
  source_url: 'Source Url',
  tag_atleast_one_course_to_track: 'Tag atleast one Course to Track',
  mail_sent_successfully: 'Email Sent Successfully',
  failed_to_sent: 'Failed to sent',
  compose_mail: 'Compose Email',
  save_and_send: 'Save and Send',
  meeting_name: 'Meeting Name',
  meeting_purpose: 'Meeting Purpose',
  meeting_type: 'Meeting Type',
  invite: 'Invite',
  send_mail: 'Send Mail',
  enable_feedback: 'Enable Feedback',
  join_url: 'Join URL',
  invite_type: 'Invite Type',
  organizer: 'Organizer',
  start_data_time: 'Start Date and Time',
  sunday: 'SUN',
  monday: 'MON',
  tuesday: 'TUE',
  wednesday: 'WED',
  thursday: 'THU',
  friday: 'FRI',
  saturday: 'SAT',
  selectdays: 'Select the days',
  submit: 'Submit',
  view_questions: 'View Questions',
  failureToast: 'Something went wrong please try again',
  successToast: 'Meeting Created Successfully',
  end_datetime: 'End Date and Time',
  duration_must_be_at_least: 'Duration must be at least 1',
  check_point_is_required: 'Check point is required',
  resource_url_is_required: 'resource url is required',
  attempt_status_cannot_be_updated_as_in_progress_for_these_attempts: 'Attempt status cannot be updated as in progress for these attempts',
  time_cannot_be_extended_for_these_attempts: 'Time cannot be extended for these attempts',
  attempt_no: 'Attempt No',
  do_you_want_to_proceed_for_the_rest_of_the_users: 'Do you want to proceed for the rest of the users',
  user_id: 'User Id',
  this_session_is_already_attempted: 'This session is already attempted.You cannot delete the component',
  show_finish_button: 'Show Finish Button',
  this_session_is_already_attempt: 'This session is already attempted.You cannot Add CheckPoint the component',
  // Attempts
  kindly_select_team: 'Kindly, select (your) team before performing further actions.',
  show_session_attempts: 'Attempts',
  teams_and_keys: 'Teams & Keys',
  evaluation_mode: 'Evaluation Mode',
  overall_team_statistics: 'Overall Team Statistics',
  attemps_allowed: 'Attemps Allowed',
  performances: 'Performance (%)',
  attemps_allowed_is_a_required_field: 'Attemps allowed is a required field',
  attemps_allowed_cannot_be_zero: 'Attemps allowed cannot be zero',
  attemps_allowed_must_be_at_least: 'Attemps allowed must be at least -1',
  idle_users: 'Idle Users',
  attempted_users: 'Attempted Users',
  add_users: 'Add Users',
  show_course_details: 'Show Course Details',
  no_batches_available: 'No Batches Available',
  no_modules_available_for: 'No Modules Available For',
  bulk_recompute: 'Bulk Recompute',
  grade_component: 'Grade Component',
  register_number: 'Register Number',
  score: 'Score (%)',
  look_up: 'LookUp',
  get_attempts: 'Get Attempts',
  attempt_num: 'Attempt Num',
  last_access_time: 'Last Access Time',
  finish_type: 'Finish Type',
  grace_time_mins: 'Grace Time(mins)',
  remaining_time: 'Remaining Time (mins)',
  sys_attempt_time: 'Sys Attempt Time',
  block: 'Block',
  finish_attempts: 'Finish Attempts',
  delete_attempts: 'Delete Attempts',
  continue_attempts: 'Continue Attempts',
  xls: 'XLS',
  no_attempt_available: 'No Attempts Available',
  not_finished: 'In Progress',
  finished: 'Completed',
  edit_course: 'Edit Course',
  are_you_sure_want_to_delete_the_course: 'Are You sure want to delete the Course',
  participation_performance_quadrant: 'Participation vs Performance - Quadrant Split',
  active_inactive_user_graph: 'Active vs Inactive User Graph',
  get_score: 'Get Score',
  score_distribution: 'Score Distribution',
  recompute_score: 'Recompute Score',
  unAttempted_xls: 'UnAttempted Xls',
  email_notification: 'Email Notification',
  system_notification: 'System Notification',
  sms_notification: 'Sms Notification',
  mobile_notification: 'Mobile Notification',
  att_no: 'Att.No',
  is_max: 'is Max',
  scores: 'Scores',
  select_team_before_performing_further_action: 'Kindly,select(your) team before performing further action.',
  there_is_no_user_attempt_list: 'There is no user attempt list',
  total: 'Total',
  user_list: 'User List',
  user_count: 'User Count',
  not_attempted_users: 'Not attempted users',
  recompute_score_sucessfully: 'Recompute Score Sucessfully',
  sear: 'Search :',
  there_is_no_not_attempted_user_list: 'There is no Not attempted user list',
  helpRequest: 'Help Request',
  meeting_name_required: 'Meeting name is required',
  meeting_type_required: 'Meeting type is required',
  team_required: 'Select at least one Team',
  joinurl_required: 'Join URL is required',
  invalidZoom_url: 'Invalid Zoom Meeting URL',
  zoom_url_required: 'Join URL is required for Zoom Meetings',
  invalidMeet_url: 'Invalid Google Meet URL',
  meet_url_required: 'Join URL is required for Google Meet',
  invalidTeam_url: 'Invalid Microsoft Teams Meeting URL',
  team_url_required: 'Join URL is required for Teams Meetings',
  organizer_required: 'Organizer is required',
  start_time_required: 'StartDatetime is required',
  end_time_required: 'EndDateTime is required',
  end_time_error: 'End date must be later than startdate',
  active_mentors: 'Active Mentors',
  sla_completion: 'SLA Completion',
  pending_tickets: 'Pending Tickets',
  proactive_support: 'Pro-Active Support',
  overall_rating: 'Overall Rating',
  skill: 'Skill',
  skill_is_required: 'Skill is required',
  active_user_percentage: 'Active Users (%)',
  inactive_user_percentage: 'Inactive User (%)',
  batch_activeness: 'Batch Activeness',
  last_three_months_summary: 'last 3 Months Summary',
  userwise_activeness: 'Userwise Activeness',
  inactive_users: 'Inactive Users',
  active_users: 'Active Users',
  activeness_rate: 'Activeness Rate',
  weeks: 'Weeks',
  no_of_weeksinactive: 'No. of Weeks Inactive',
  course_name_already_exists: 'Course name already exists',
  schedule_teams: 'Schedule Teams',
  created_by: 'Created By',
  updated_by: 'Updated By',
  last_updated: 'Last Updated',
  save_close: 'Save & Close',
  override_timings: 'Override Timings',
  there_is_no_team_timing_list: 'There is no team timing list',
  start_time_must_be_greater_than_current_time_5_mins: '*Start Time must be Greater Than Current Time + 5 mins',
  session_name_already_exists: 'Session name already exists',
  check_point_name_already_exists: 'Check point name already exists',
  create_lesson_component_summary: 'Create Lesson Component Summary',
  show_lesson: 'Show Lesson',
  go_to_component: 'Go to component',
  download: 'Download',
  Part: 'Participation',
  Perf: 'Performance',
  Cert: 'Ceritification',
  RSS: 'Reading Session Status\n(AttemptedVideos/TotalVideos)',
  LHrs: 'Learning Hours',
  stream_analysis: 'Stream Analysis',
  get_attempt: 'Get Attempt',
  show: 'Show',
  attempt_number: 'Attempt Number',
  course_clone: 'Course Clone',
  course_clone_details: 'Course Clone Details',
  clone_type: 'Clone Type',
  organization_type: 'Organization Type',
  organization_name: 'Organization Name',
  organization_unit_name: 'Organization Unit Name',
  clone: 'Clone',
  course_rearrange: 'Course Rearrange',
  bulk_settings_update: 'Bulk Settings Update',
  assign_batches: 'Assign Batches',
  assign_users: 'Assign Users',
  course_plan: 'Course Plan',
  user_team_transfer: 'User Team Transfer',
  all_dashboards: 'All Dashboards',
  report_template: 'Report Template',
  meeting_room: 'Meeting Room',
  report_analytics: 'Report Analytics',
  scodhvest_analytics: 'Scodhvest Analytics',
  invite_users: 'Invite users',
  send_notification: 'Send Notification',
  new_edit: 'New Edit',
  log_out: 'Log Out',
  where_to_clone: 'Where To Clone',
  question_bank: 'Question Bank',
  module_level_setings: 'Module Level Setings',
  team_level_setings: 'Team Level Setings',
  bulk_settings_note: 'Note: Kindly ensure the session and module is selected when you change the settings.',
  parallel_sessions: 'Parallel Sessions',
  select: 'Select',
  scrolling_enabled: 'Scrolling Enabled',
  show_last_page_finish: 'Show Last Page Finish',
  skill_based_problem_count: 'Skill Based Problem Count',
  knowledge_based_problem_count: 'Knowledge Based Problem Count',
  between_0_and_100: 'Value must between 0 and 100',
  'greater_than_-1': 'Value must be greater than -1',
  submission_stack: 'Submission Stack',
  question_paper: 'Question Paper',
  select_question_paper: 'Select Question Paper',
  random_question: 'Random Question',
  club_module_and_move_within_course: 'Club Module And Move Within Course ',
  choose_modules: 'Choose Modules',
  selected_sessions: 'Selected Sessions',
  to_module: 'To Module',
  move: 'Move',
  choose_team: 'Choose Team',
  Get_plan: 'Get Plan',
  download_xls: 'Download XLS',
  team_names: 'Team Name  : ',
  module_group_name: 'Module Group Name',
  team_start_time: 'Team Start Time',
  team_end_time: 'Team End Time',
  team_visibility: 'Team Visibility',
  start_time_date: 'Start Time  :',
  end_time_data: 'End Time  :',
  is_visible: 'Visible  :',
  session_settings: 'Session Settings',
  enddate_must_be_later_than_startdate: 'End date must be later than Start date',
  dates_cant_be_equal: 'Start date and End Date can\'t be equal',
  select_number: 'Select Number',
  select_all: 'Select All',
  de_select_all: 'Deselect All',
  session_list: 'Session List',
  report_filter: 'Report Filter',
  grade_type: 'Grade Type',
  detail_user_report: 'Detail User Report',
  eval_comp: 'Eval Comp',
  bulk_users: 'Bulk Users',
  generate_report: 'Generate Report',
  analysis_report: 'Analysis Report',
  sent_mail: 'Sent Mail',
  markreports: 'MarkReports',
  ordered_by_role_number: 'Report for selected session one by one. Ordered by Role number.',
  attempts_rankwise: 'Attempts Rankwise',
  ordered_by_rank: 'Report for selected session one by one. Ordered by Rank.',
  consolidated_score: 'Consolidated Score',
  Ordered_by_register_number: 'Consolidated report for selected sessions. Ordered by Register number.',
  consolidated_score_rankwise: 'Consolidated Score Rankwise',
  consolidated_report_ordered_by_rank: 'Consolidated report for selected sessions. Ordered by Rank.',
  consolidated_rankwise: 'Consolidated Rankwise',
  heat_grid: 'Heat Grid',
  heat_grid_for_selected_session: 'Heat grid for selected session',
  order_by_username: 'Order By Username',
  order_by_score: 'Order by score',
  xls_order_by_score: 'Xls order by score',
  heat_grid_analytics: 'Heat Grid Analytics',
  source_team: 'Source Team',
  user: 'User',
  destination_team: 'Destination Team',
  transfer: 'Transfer',
  create_team: 'Create Team',
  add_description: 'Add Description',
  type_min_3_letter: 'Type Min 3 Letters',
  assign_new_user: 'Assign New User',
  show_team_detail: 'Show Team Detail',
  assign_user: 'Assign User',
  existing_user: 'Existing User',
  search_user: 'Search User',
  course_manager: 'Course Manager',
  user_add_successfully: 'user added successfully',
  no_user: '<---No User--->',
  invalid_email: 'Invalid email',
  attempt_number_only_for_heat_grid: '*Attempt number only for heat grid',
  doubtThread: 'Doubt Thread',
  doubtthread: 'Doubt Thread',
  unAssingn: 'Un assign',
  assign_me: 'Assign to me',
  assign_mentor: 'Assign to Mentor',
  nameCopied: 'Name copied',
  mentorCopied: 'Mentor name copied',
  endDate: 'End Date',
  startDate: 'Start Date',
  reOpened: 'Re-opened',
  solve: 'Solve',
  typeReply: 'Type your reply...',
  raiseQuery: 'Please raise your query / doubt here...',
  selectStartDate: 'Please select the start date',
  selectEndDate: 'Please select the end date',
  endDateEqual: 'End date must be equal or later than start date',
  selectOrg: 'Select Org unit',
  selectCourse: 'Select course',
  chooseSkills: 'Choose skills',
  selectIssue: 'Select issue type',
  clear_filter: 'Clear Filter',
  apply_filter: 'Apply Filter',
  orgUnit: 'Org Unit',
  issueType: 'Issue Type',
  doubtId: 'Doubt Id',
  skillTicket: 'Skill Ticket',
  skillticketRate: 'Skill Ticket Rate',
  search_username: 'Search Username',
  lookup: 'Lookup',
  component_name: 'Component Name',
  solved_problems: 'Solved Problems',
  total_problems: 'Total Problems',
  download_excel: 'Download Excel',
  max_score: 'Max Score',
  average_score: 'Average Score',
  last_attempt_score: 'Last Attempt Score',
  first_attempt_score: 'First Attempt Score',
  download_pdf: 'Download PDF',
  source_file: 'Source File',
  check_point_for_pdf_sample: 'Check Point for PDF sample',
  total_page: 'Total Page: ',
  total_slides: 'Total Slides: ',
  page: 'Page',
  slides: 'Slides',
  check_point_for_ppt_sample: 'Check Point for PPT sample',
  atleast_select_one_sessionlist: '*Atleast select one session from session list',
  atleast_select_one_sessionlist_and_fill_required_fields: '*Atleast select one session from session list and fill the all required fields',
  prev: 'Prev',
  search_by_name: 'Search By Name',
  check_point_for_cloud_sample: 'Check Point for CLOUD sample',
  check_point_for_youtube_sample: 'Check Point for YOUTUBE sample',
  edit_track: 'Edit Track',
  track_name_already_exists: 'Track name already exists',
  showCourse: 'Show Course',
  completionThreshold: 'Completion Threshold',
  questionDescription: 'Question Description',
  category: 'Category',
  optionType: 'Option Type',
  options: 'Options',
  question: 'Question',
  mandatory: 'Mandatory',
  noData: 'No Data Available',
  feedback_time: 'Feedback Time',
  response: 'Response',
  average_feedback: 'Average Feedback Table',
  individual_feedback: 'Individual Feedback',
  individual_table: 'Individual Feedback Table',
  batch_management: 'Batch Management',
  u_id: 'U Id',
  daily_start_time: 'Daily Start Time',
  daily_end_time: 'Daily End Time',
  daily_meeting: 'Daily Meeting',
  'positive_or_-1': 'Value must be Positive or -1',
  create_dashboard: 'Create Dashboard',
  title: 'Title',
  logo_left: 'Logo Left',
  logo_right: 'Logo Right',
  dashboard_name: 'Dashboard Name',
  please_select_the_start_date: 'Please select the start date',
  please_select_the_end_date: 'Please select the end date',
  invite_candidate: 'Invite Candidate',
  license_purchased: 'License Purchased',
  license_used: 'License Used',
  deactivated_users: 'Deactivated Users',
  license_cancelled: 'License Cancelled',
  completed_license: 'Completed License',
  license_consumption: 'License Consumption',
  license_types: 'License Types',
  license_name: 'License Name',
  number_of_license: 'Number of License',
  consumed_license: 'Consumed License',
  unused_license: 'Unused License',
  skillytics_license: 'Skillytics License',
  assessment_license: 'Assessment License',
  stream_name: 'Stream Name',
  trackname: 'Track Name',
  license_deactivated_users: 'License Deactivated Users',
  assessment_name: 'Assessment Name',
  assessment_date: 'Assessment Date',
  consumed_count: 'Consumed Count',
  participant: 'Participant',
  team_is_required: 'Team is Required',
  add_batch_name: 'Add Batch Name',
  user_existing_teams: 'User Existing Teams',
  user_existing_batches: 'User Existing Batches',
  send_sms: 'Send SMS',
  send_email: 'Send Email',
  create_new_team: 'Create new Team',
  mobile_number: 'Mobile Number',
  user_status: 'User Status',
  scheduled_count: 'Scheduled Count',
  upload_file: 'Upload File',
  invite_multiple_candidates: 'Invite Multiple Candidates',
  select_file: 'Select File',
  name_should_only_contain_letters: 'Name should only contain letters',
  delete_dashboard: 'Delete Dashboard',
  are_you_sure_to_delete_the_dashboard: 'Are you sure to Delete the Dashboard?',
  are_you_sure_to_delete_the_slide: 'Are you sure to Delete the Slide?',
  edit_slide: 'Edit Slide',
  create_slide: 'Create Slide',
  delete_slide: 'Delete Slide',
  Session_level: 'Session Level',
  add_widget: 'Add Widget',
  select_components: 'Select Components',
  select_teams: 'Select Teams',
  create_assessment_type: 'Create Assessment Type',
  select_category: 'Select Category',
  assessment_types: 'Assessment Types',
  delete_assessment_type: 'Delete Assessment Type',
  are_you_sure_you_want_to_delete_the_assessment_type: 'Are you sure! Do you want to Delete the Assessment Type ?',
  add_assessment: 'Add Assessment',
  choose_the_options: 'Choose the Options',
  select_assessment: 'Select Assessment',
  note: 'Note:',
  select_assessment_note_content: 'Please Select all the options on the left to create the assessment',
  assessment_batch: 'Assessment Batch',
  external_assessment: 'External Assessment',
  skillytics_assessment: 'Skillytics Assessment',
  new_team: 'New Team',
  existing_team: 'Existing Team',
  apply: 'Apply',
  select_users: 'Select Users',
  select_course: 'Select Course',
  search_team: 'Search Team',
  add_to_inserted_user: 'Add to Inserted User',
  done: 'Done',
  scheduled_date: 'Scheduled Date',
  edit_users: 'Edit Users',
  view_learners: 'View Learners',
  assessment_report: 'Assessment Report',
  must_be_positive: 'Must be Positive Value',
  please_enter_a_whole_number: 'Please enter a whole number',
  consolidated_report: 'Consolidated Report',
  required: 'Required',
  delete_assessment: 'Delete Assessment',
  are_you_sure_you_want_to_delete_the_assessment: 'Are you sure! Do you want to Delete the Assessment?',
  it_already_has_attempts:'It already has attempts',
  courseless_launch: 'Courseless Launch',
  none_selected: 'None Selected',
  assessment_start_date: 'Assessment Start Date',
  assessment_end_date: 'Assessment End Date',
  true: 'True',
  false: 'False',
  candidate_disclaimer: 'Candidate Disclaimer',
  'component_wise_score%': ' Component Wise Score (%)',
  archived_problems: 'Archived Problems',
  my_progression: 'My Progression',
  estimated_completion_time: 'Estimated Completion Time',
  activity_summary: 'Activity Summary',
  batch_rank: 'BATCH RANK',
  hours_spent: 'Hours Spent',
  work_pattern_chart: 'Work Pattern Chart',
  course_completion_data: 'Course Completion Data',
  overall_track_completion: 'Overall Track Completion',
  completion_percentage: 'Completion%',
  course_threshold: 'Course Threshold',
  no_data_available: 'No Data Available',
  average_of_all_course_completion: 'Average Of All Course Completion %',
  switch_module: 'Switch Module',
  course_progress_report: 'Course Progress Report',
  topic: 'Topic',
  no_sessions: '--',
  skill_problems: 'Skill Problems',
  knowledge_problems: 'Knowledge Problems',
  video_count: 'Video Count',
  project_management: 'Project Management',
  create_new_project: 'Create New Project',
  project_code: 'Project Code',
  create_project: 'Create Project',
  project_name: 'Project Name',
  only_the_following_formats_are_accepted: 'Only the following formats are accepted',
  in_active: 'Inactive',
  edit_project: 'Edit Project',
  browse: 'Browse',
  add_epic: 'Add Epic',
  create_epic: 'Create Epic',
  edit_epic: 'Edit Epic',
  epic_name: 'Epic Name',
  epic_code: 'Epic Code',
  epic: 'Epic',
  save_and_proceed: 'Save and Proceed',
  save_and_proceed_later: 'Save and Proceed Later',
  rearrange_stories: 'Rearrange Stories',
  loading: 'Loading',
  'project_code_should_be_+ve': 'Project Code should be +ve',
  learning_progression_report: 'Learning Progression Report',
  participation_performance: 'Participation %',
  performance_percentage: 'Performance %',
  overall_completion: 'Overall Completion',
  learning_progression: 'Learning Progression',
  session_score: 'Session Score',
  currently_attending_problem: 'Currently Att. Prob.',
  attempt_status: 'Attempt Status',
  sub_cnt_for_current_problem: 'Sub.Cnt.For Current Prob',
  activity_status: 'Activity Status',
  avg_solved_problems: 'Avg. Solved Prob.',
  solved_problem_count: 'Solved Problem Count',
  download_idle_users_list: 'Download Idle Users List',
  logged_out: 'Logged Out',
  download_errors: 'Download Errors',
  upload: 'Upload',
  insert_users: 'Insert Users',
  send_invite: 'Send Invite',
  assessment_progress_report: 'Assessment Progress Report',
  not_yet_published: 'Not yet Published',
  published: 'Published',
  search_by_email: 'Search by Email',
  clone_session: 'Clone Session',
  clone_module: 'Clone Module',
  select_org_unit_type: 'Select Org Unit type',
  org_unit_type: 'Org Unit type',
  select_organization: 'Select Organization',
  organization: 'Organization',
  select_org_unit: 'Select Org Unit',
  org_unit: 'Org Unit',
  filter: 'Filter',
  license_expiry: 'License Expiry',
  license_consumption_details: 'License Consumption Details',
  deactivate_license: 'Deactivate License',
  user_already_exist: 'User Already Exist',
  deactivate_user_license: 'Deactivate User License',
  enable_license: 'Enable License',
  user_not_found: 'User Not Found',
  non_licensed_user: 'Non Licensed User',
  team_rank: 'TEAM RANK',
  clone_component: 'Clone Component',
  reset_password: 'Reset Password',
  projects: 'Projects',
  task_assign: 'Task Assign',
  search_projects: 'Search Projects',
  add_projects: 'Add Projects',
  no_projects_available: 'No Projects Available',
  created_date: 'Created Date',
  no_records_found: 'No Records Found',
  epics: 'Epics',
  sprints: 'Sprints',
  bulk_upload: 'Bulk Upload',
  create_sprint: 'Create Sprint',
  to_do: 'To Do',
  in_progress: 'In Progress',
  start_sprint: 'Start Sprint',
  drag_and_drop_a_epic: 'Drag and drop a epic',
  sprint_title: 'Sprint Title',
  sprint_code: 'Sprint Code',
  sprint_title_is_required: 'Sprint Title is required',
  sprint_code_is_required: 'Sprint Code is required',
  week: 'Week',
  month: 'Month',
  duration_f: 'Duration',
  edit_sprint: 'Edit Sprint',
  start_date_and_time: 'Start Date & Time',
  start_date_is_required: 'Start Date is required',
  end_date_is_required: 'End Date is required',
  end_date_and_time: 'End Date & Time',
  day: 'Day',
  duration_type_is_required: 'Duration type is required',
  duration_should_be_pos_ve: 'Duration should be +ve',
  end_date_must_be_greater_than_start_date: 'End date must be greater than start date',
  end_time_must_be_greater_than_start_time: 'End time must be greater than start time',
  track_completion_report: 'Track Completion Report',
  sms_content: 'SMS Content',
  subject: 'Subject',
  variable_validation_error: 'Text inside the curly braces cannot be changed',
  default_template: 'Default Template',
  teams_meeting_options: 'Teams Meeting Options',
  passcode: 'Passcode',
  enable_camera: 'Enable Camera',
  enable_mic: 'Enable Mic',
  allow_reactions: 'Allow Reactions',
  announce_entry_and_exit: 'Announce Entry and Exit',
  automatic_record: 'Automatic Record',
  require_passcode: 'Require Passcode',
  copy_join_url: 'Copy Join URL',
  passcode_copied: 'Passcode Copied',
  link_copied: 'Link Copied',
  please_enter_the_valid_email: 'Please Enter the Valid Email',
  user_added_successfully: 'User Added Successfully',
  copy_passcode: 'Copy Passcode',
  please_type: 'Please type at least 3 letters',
  no_results_found: 'No results found',
  refresh_list: 'Refresh List',
  no_checkpoints: 'No Checkpoints',
  total_count: 'Total Count',
  completed_count: 'Completed Count',
  time_taken: 'Time Taken',
  available_resources: 'Available Resources',
  endDateShouldBeGreater: 'EndDate Should Be Greater',
  question_paper_is_not_evaluate: 'Question paper is not evaluate',
  rearrange: 'Rearrange',
  question_description: 'Question Description',
  question_category: 'Question Category',
  re_evaluate: 'ReEvaluate',
  complexity: 'Complexity',
  re_evaluate_score_updated: 'Re Evaluate Updated',
  team_name_already_exists: 'Team name already exists',
  add_team_name: 'Add Team Name',
  check_point: 'Check Point',
  total_no_of_questions: 'Total no. of Questions',
  number_of_questions_assigned: 'Number of questions assigned',
  random_un_uniform_questions: 'Random Ununiform Questions',
  random_uniform_questions: 'Random Uniform Questions',
  random_question_list: 'Random Question List',
  hide_attendees: 'Hide Attendees',
  mail_content: 'Mail Content',
  location_required: 'Location Required',
  mail_content_required: 'Mail Content Required',
  invite_type_required: 'Invite Type Required',
  track_id: 'Track Id',
  delete_course: 'Delete Course',
  session_id: 'Session Id',
  attempted_track_list: 'Attempted Track List',
  attempted_session_list: 'Attempted Session List',
  no_sessions_found: 'No Sessions Found',
  no_track_found: 'No Track Found',
  deleted_courses: 'Deleted Courses',
  retrieve_course: 'Retrieve Course',
  archived: 'Archived',
  close: 'Close',
  problem_description: 'Problem Description',
  question_type: 'Question Type',
  'score/grade': 'Score / Grade',
  feed_back: 'Feedback',
  other_teams: 'Other Teams',
  total_doubts: 'Total Doubts',
  resolved_unresolved: 'Resolved-Unresolved',
  pro_active_support: 'Pro-active Support',
  getStatistics: 'Get Statistics',
  sla: 'SLA',
  startdate: 'Start Date',
  enddate: 'End Date',
  mentor: 'Mentor',
  mentor_performance: 'Mentor Performance',
  mentor_daywise_report: 'Mentor Day Wise Report',
  assigned_count: 'Assigned Count',
  solved_count: 'Solved Count',
  pro_active_count: 'Pro-active Count',
  total_solved_count: 'Total Solved',
  avg_solved_count: 'Average Solved/Day',
  doubt_details: 'Doubt Details',
  created_time: 'Created Time',
  resolved_last_respond_time: 'Resolved (or) Last respond time',
  sla_time: 'SLA Time(hours)',
  conversation: 'Conversation',
  sla_matrix: 'SLA Metrics',
  user_tracking: 'User Tracking',
  mail_log: 'Mail Log',
  this_is_required_field: 'This is Required field',
  only_zip_files_are_accepted: 'Only .zip files are accepted',
  zip_file_name_must_not_contain_special_characters_or_spaces: 'Zip File Name must not contain special characters or spaces',
  file_size_should_be_less_than_or_equal_to_10mb: 'File size should be less than or equal to 10MB',
  only_video_files_are_allowed: 'Only video/* files are allowed',
  video_file_name_must_not_contain_special_characters_or_spaces: 'Video File Name must not contain special characters or spaces',
  bulk_user_deactive: 'Bulk User Deactive',
  bulk_active_deactive_user: 'Bulk Active/Deactive - Users ',
  batch_report: 'Batch Report',
  all_batch_analysis: 'All Batch Analysis',
  course_wise_report: 'Course Wise Report',
  recieved_time: 'Received Time',
  testcase_pass_percentage: 'Testcase Pass %',
  id: 'Id',
  download_report: 'Download Report',
  recomputed_successfully: 'Recomputed Successfully!',
  team_assignment: 'Team Assignment',
  assigned_teams: 'Assigned Teams',
  project_to_be_assigned: 'Project to be Assigned',
  search_users: 'Search Users',
  users_list: 'Users List',
  auto_split_teams: 'Auto Split Teams',
  create_project_team: 'Create Project Team',
  team_created_successfully: 'Team Created Successfully',
  project_team_name: 'Project Team Name',
  no_of_teammates: 'No. of Teammates',
  user_count_per_team: 'User Count per Team',
  no_sprint_in_project: 'No Sprint found in this Project! Please choose any other Project.',
  delete_team: 'Delete Team',
  delete_sub_team_instruction: 'Are you sure to delete this sub team?',
  delete_sub_team_error: 'Cannot delete the this team since users in this team already have attempts',
  user_count_error: 'User count must be a positive integer',
  no_users_found: 'No Users Found',
  task_assignment: 'Task Assignment',
  task_bulk_assigns: 'Task Bulk Assigns',
  auto_split_tasks: 'Auto Split Tasks',
  project_assigned: 'Project Assigned',
  team_attempt: 'Team Attempt',
  auto_aplit_tasks_confirm_content: 'If you proceed, all currently assigned tasks will be untagged and then automatically reassigned to users through auto-splitting. If you agree to this, please click the OK button.',
  select_sub_team: 'Select Sub Team',
  task_count_is_less_than_user_count: 'Task count is less than user count',
  select_project: 'Select Project',
  team_attempt_is_disabled: 'Team attempt is disabled',
  finish_setup: 'Finish Setup',
  setup_process_completed_successfully: 'Setup process completed successfully',
  setup_process_unsuccessful: 'Setup process unsuccessful',
  task_allocation_unsuccessful: 'Task allocation unsuccessful',
  vm_creation: 'VM Creation',
  prerequisite: 'Prerequisite',
  completion: 'Completion %',
  course_completion_completion: 'Completion',
  knowledge_hub: 'Knowledge Hub',
  discover_zone: 'Discover Zone',
  blueprint: 'Blueprint',
  achieve: 'Achieve',
  course_wise_completion: 'Course Wise Completion',
  assessment_type_name: 'Assessment Type Name',
  not_published: 'Not Published',
  attempted_date: 'Attempted Date',
  no_module_available: 'No Modules Available',
  event_name: 'Event Name',
  team__rank: 'Team Rank',
  solved: 'Solved',
  points: 'Points',
  national_rank: 'National Rank',
  event_summary_report: 'Event Summary Report',
  ab: 'AB',
  coachingReport: 'Coaching Report',
  attempted_time: 'Attempted Time',
  attempt_score: 'Score',
  no_sess: 'No Sessions',
  userStrength: 'User Strength',
  add_recording: 'Add Recording',
  enter_recording_url: 'Enter Recording Url',
  invalid_url: 'Invalid URL',
  no_recording_found: 'No recording found for this meeting',
  recording_url: 'Recording URL',
  update_recording: 'Update Recording',
  url_copied: 'URL Copied Successfully',
  url_updated: 'URL Updated Successfully',
  cant_rearrange: 'Can\'t Rearrange',
  selected_prerequisite: 'Selected Prerequisite',
  vm_course: 'VM Course',
  vm_management: 'VM Management',
  view_meeting_chat: 'View Meeting Chat',
  meeting_chat: 'Meeting Chat',
  select_sprint: 'Select Sprint',
  select_epic: 'Select Epic',
  select_story: 'Select Story',
  select_task: 'Select Task',
  get_submissions: 'Get Submissions',
  select_tasks: 'Select Tasks',
  submission_no: 'Submission No.',
  select_submission_no: 'Select Submission No.',
  languages: 'Languages',
  submission_status: 'Submission Status',
  preview_problem: 'Preview Problem',
};

export default ProgramManager;
