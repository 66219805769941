import React, { useState } from 'react';
import { resturls } from '@utils/apiurls';
import GlobalService from '@services/GlobalService';
import DefaultHeader from '@globalComps/header/DefaultHeader';
import useComponentDidMount from '@utils/useComponentDidMount';
import { ToastMessage } from '@hrlytics/corelytics';
import {
  Icon, Input, Button, Table, Checkbox, Dropdown,
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styles from './licenseConsumption.module.less';

const LicenseDeactive = (props) => {
  const { t } = props;

  const [searchKeyValue, setSearchKeyValue] = useState('');
  const [toast, setToast] = useState(<></>);

  const [licenseDeactivate, setLicenseDeactivate] = useState({
    licenseUserData: [],
    selectedLicenseUserData: [],
    selectedUserInd: [],
    orgUnitList: [],
    selectedOrgUnit: '',
  });

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const {
    licenseUserData, selectedLicenseUserData, selectedUserInd, orgUnitList,
    selectedOrgUnit,
  } = licenseDeactivate;

  const deactivatedUserLicense = () => {
    const requestData = {
      user_obj_list: selectedLicenseUserData.map((userData) => ({
        user_id: userData.user_id,
        track_id: userData.track_id,
        completion: userData.completion,
      })),
    };

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data: { is_deactivate } } = respdata;
      if (estatus && emessage) {
        if (is_deactivate) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'success',
                title: t('user_deactivate_successfully'),
              }}
            />,
          );
        }
        setLicenseDeactivate((prevState) => {
          const updatedList = [...prevState.licenseUserData];
          updatedList[selectedUserInd.map((e) => e)] = { ...updatedList[selectedUserInd.map((e) => e)], status: false };

          return {
            ...prevState,
            licenseUserData: updatedList,
          };
        });
      }
    }, `${resturls.deactivatedUserLicense}`, requestData, 'POST');
  };

  const enableUserLicense = () => {
    const requestData = {
      user_obj_list: selectedLicenseUserData.map((userData) => ({
        user_id: userData.user_id,
        track_id: userData.track_id,
        completion: userData.completion,
        ...(userData.course_team_config_id && { course_team_config_id: userData.course_team_config_id }),
      })),
    };

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data: { is_enable } } = respdata;
      if (estatus && emessage) {
        if (is_enable) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'success',
                title: t('user_activate_successfully'),
              }}
            />,
          );
          setLicenseDeactivate((prevState) => {
            const updatedList = [...prevState.licenseUserData];
            updatedList[selectedUserInd.map((e) => e)] = { ...updatedList[selectedUserInd.map((e) => e)], status: true };

            return {
              ...prevState,
              licenseUserData: updatedList,
            };
          });
        }
      }
    }, `${resturls.enableUserLicense}`, requestData, 'POST');
  }

  const showUser = () => {
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data: { user_detail_list, is_valid_user, licensed_user } } = respdata;
      if (estatus && emessage) {

        if (!is_valid_user) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'success',
                title: t('user_not_found'),
              }}
            />,
          );
        } else if (is_valid_user && !licensed_user) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'warning',
                title: t('non_licensed_user'),
              }}
            />,
          );
        }

        const existingUser = licenseUserData.find(user => user.user_id === user_detail_list[0].user_id);

        if (existingUser) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'success',
                title: t('user_already_exist'),
              }}
            />,
          );
        } else {
          const addedLicenseUserData = licenseUserData.concat(user_detail_list);
          setLicenseDeactivate((prevState) => ({
            ...prevState,
            licenseUserData: addedLicenseUserData
          }));
        }

      }
    }, `${resturls.obtainLicensedUserForOrgUnit}?user_name=${searchKeyValue}&org_unit_id=${selectedOrgUnit}`, {}, 'GET');
  };

  const selectHandler = (type, value, checked, i) => {
    if (checked) {
      if (type === 'all') {
        const allData = value.map((e) => e.user_id);
        setSelectedUserIds((prev) => [...prev, ...allData]);
        setLicenseDeactivate((prevstate) => ({
          ...prevstate,
          selectedLicenseUserData: [...prevstate.selectedLicenseUserData, ...value],
        }));
      } else {
        setSelectedUserIds((prev) => [...prev, value.user_id]);
        setLicenseDeactivate((prevstate) => ({
          ...prevstate,
          selectedLicenseUserData: [...prevstate.selectedLicenseUserData, value],
          selectedUserInd: [...prevstate.selectedUserInd, i],
        }));
      }
    } else if (type === 'all') {
      setSelectedUserIds([]);
      setLicenseDeactivate((prevstate) => ({
        ...prevstate,
        selectedLicenseUserData: [],
      }));
    } else {
      const updatedSelectedUserIds = selectedUserIds.filter((id) => id !== value.user_id);
      setSelectedUserIds(updatedSelectedUserIds);

      setLicenseDeactivate((prevstate) => ({
        ...prevstate,
        selectedLicenseUserData: prevstate.selectedLicenseUserData.filter(
          (item) => item.user_id !== value.user_id,
        ),
        selectedUserInd: [],
      }));
    }
  };

  useComponentDidMount(() => {
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage) {
        setLicenseDeactivate((prevState) => ({
          ...prevState,
          orgUnitList: data?.map((e) => (
            { key: e.org_unit_id, value: e.org_unit_id, text: e.org_unit_name }))
        }));
      }
    }, resturls.obtainAllOrgUnits, {}, 'GET');
  })

  return (
    <div className={styles.licenseUserManagement}>
      {toast}
      <DefaultHeader />
      <div className={styles.deactivateLicense}>
        <div className={styles.deactivateLicenseHeader}>
          <Button onClick={showUser}>{t('add_user')}</Button>
          <Input
            fontas="beta"
            placeholder="Search For User"
            name="name"
            type="text"
            onChange={(e, { value }) => {
              if (value.length >= 3 || value.length === 0) {
                setSearchKeyValue(value)
              }
            }}
            icon="search"
            iconPosition="right"
          />
          <Dropdown
            search
            selection
            clearable
            options={orgUnitList}
            value={selectedOrgUnit}
            onChange={(e, { value }) => {
              setLicenseDeactivate((prevState) => ({
                ...prevState,
                selectedOrgUnit: value
              }));
            }}
          />
        </div>
        {
          licenseUserData.length > 0 && (
            <div className={styles.licenseUserTable}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className={styles.miniColumn}>
                      <div>
                        <Checkbox
                          checked={licenseUserData.length > 0 && licenseUserData.every(
                            (e) => selectedUserIds.includes(e.user_id)
                          )}
                          onChange={(e, data) => {
                            selectHandler('all', licenseUserData.map((item) => item), data.checked);
                          }}
                        />
                      </div>
                    </Table.HeaderCell>
                    <Table.HeaderCell><div>{t('s_no')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('name')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('user_name')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('batch_name')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('track_name')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('complitation')}</div></Table.HeaderCell>
                    <Table.HeaderCell><div>{t('status')}</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {
                  licenseUserData.map((el, i) => (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Checkbox
                            onChange={(e, data) => {
                              selectHandler('uni', el, data.checked, i);
                            }}
                            checked={selectedUserIds.includes(el.user_id)}
                          />
                        </Table.Cell>
                        <Table.Cell>{i + 1}</Table.Cell>
                        <Table.Cell>{el.name}</Table.Cell>
                        <Table.Cell>{el.user_name}</Table.Cell>
                        <Table.Cell>{el.batch_name}</Table.Cell>
                        <Table.Cell>{el.track_name}</Table.Cell>
                        <Table.Cell>{el.completion}</Table.Cell>
                        <Table.Cell>
                          {(el.status) ? (
                            <Icon name='eye' />
                          ) : (
                            <Icon name='eye slash' />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  ))
                }
                <Table.Row>
                  <Table.Cell colSpan={8}>
                    <Button
                      onClick={() => enableUserLicense()}
                      disabled={
                        selectedLicenseUserData.every((e) => e.status === true) ||
                        selectedLicenseUserData.some((e) => e.status === true)
                      }
                    >
                      {t('enable_license')}
                    </Button>
                    <Button
                      onClick={() => deactivatedUserLicense()}
                      disabled={
                        selectedLicenseUserData.every((e) => e.status === false) ||
                        selectedLicenseUserData.some((e) => e.status === false)
                      }
                    >
                      {t('deactivate_license')}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table>
            </div>
          )
        }
      </div>
    </div>
  );
};
export default withTranslation('programManager')(LicenseDeactive);
