// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.ReclyticsHeader__3-s0v {\n  padding: 1.2em 5em;\n  display: flex;\n  align-items: center;\n}\n.ReclyticsHeader__3-s0v > i:first-child {\n  margin-right: 15px !important;\n}\n.ReclyticsHeader__3-s0v > span:nth-child(1) {\n  display: flex;\n  align-items: center;\n  gap: 1em;\n}\n.ReclyticsHeader__3-s0v > div:last-child {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n}\n.ReclyticsHeader__3-s0v > div:last-child > img {\n  margin-right: 15px !important;\n}\n@media only screen and (max-width: 560px) {\n  .ReclyticsHeader__3-s0v {\n    padding: 0.7em;\n    display: flex;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"ReclyticsHeader": "ReclyticsHeader__3-s0v"
};
module.exports = exports;
