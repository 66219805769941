import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import ls from 'local-storage';
import UserInfo from '@models/UserInfo';
import getRoleUrl from '@utils/RoleBasedURL';

const RoleBasedRoute = () => {
  const role = UserInfo.getRole();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const catalogId = queryParams.get('catalogId');
  const costingLevel = queryParams.get('costingLevel');

  const redirectUrl = () => {
    switch (role) {
      case 'organization_admin':
        return '/orgadmin';
      case 'recruiter_head':
        return '/orgunits';
      case 'participant':
        if (catalogId) return `/participant/viewcart?catalogId=${catalogId}&costingLevel=${costingLevel}`;
        return '/participant';
      case 'program_manager':
        return '/orgunits';
      case 'admin':
        return '/admin';
      case 'mentor':
        return '/mentor';
      case 'support_manager':
        return '/orgunits';
      case 'assessment_manager':
        return '/orgunits';
      default:
        if (catalogId) return `/participant/viewcart?catalogId=${catalogId}&costingLevel=${costingLevel}`;
        return null;
    }
  };

  const getInitPage = () => {
    const isAdmin = ls.get('isAdmin');
    return isAdmin ? getRoleUrl() : redirectUrl();
  };

  return (
    <>
      {(getInitPage())
        ? (
          <Redirect to={getInitPage()} />
        ) : (
          <Redirect to={catalogId ? `/?catalogId=${catalogId}&costingLevel=${costingLevel}` : '/'} />
        )}
    </>
  );
};

export default RoleBasedRoute;
