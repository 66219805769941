import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card, Grid, Image, Button, Label,
} from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import styles from './UserWiseCourseScreen.module.less';
import UserWiseDeepTrack from './UserWiseDeepTrack';

const UserWiseCourseList = (props) => {
  const {
    t, courseList,
    isLoading,
    setState, sessionsandTeamtimingDetail,
    courseId, hrlsessionid, teamId,
  } = props;

  const obtainSessionsandTeamtimingDetailForAdmin = (team_id, course_id) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage) {
        setState((prev) => ({
          ...prev,
          sessionsandTeamtimingDetail: data,
          isLoading: false,

        }));
      }
    }, `${resturls.obtainSessionsandTeamtimingDetailForAdmin}?team_id=${team_id}&u_id=${course_id}`, {}, 'GET');
  };

  return (
    <>
      <div className={styles.userWiseCourseListMainPanel}>
        {courseList?.course_list?.length > 0 ? (
          <>
            {courseList.course_list?.map((course) => (
              <Card className={styles.userWiseCourseCard} key={course.id}>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={3}>
                    <div className={styles.userWiseCourselogo}>
                      <Image src={course.course_logo} size="tiny" />
                      <Label><p fontas="gamma">{course.team_name}</p></Label>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <a fontas="beta header" href={`/course/${course.course_id}`}>{course.course_name}</a>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={4}>
                    <p>{t('role')}</p>
                    <p>{courseList.role}</p>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={3}>
                    <Button
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          courseId: course.course_id,
                          teamId: course.team_id,
                        }));
                        obtainSessionsandTeamtimingDetailForAdmin(course.team_id,
                          course.course_id);
                      }}
                    >
                      <p fontas="beta">{t('deep_track')}</p>
                    </Button>
                  </Grid.Column>
                </Grid>
                {course?.course_id === courseId && (
                  <UserWiseDeepTrack
                    sessionsandTeamtimingDetail={sessionsandTeamtimingDetail}
                    isLoading={isLoading}
                    setState={setState}
                    hrlsessionid={hrlsessionid}
                    teamId={teamId}
                  />
                )}
              </Card>
            ))}
          </>
        ) : (
          <div fontas="beta header" className={styles.notTaggedCourses}>{t('user_not_tagged_in_course')}</div>
        )}
      </div>
    </>
  );
};

export default withTranslation('admin')(UserWiseCourseList);
