import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
// import { useRouteMatch } from 'react-router-dom';
import GlobalService from '@services/GlobalService';
import ls from 'local-storage';
import { resturls } from '@utils/apiurls';
import { DefaultLoader, HRLFormik } from '@hrlytics/corelytics';
import { useHistory } from 'react-router-dom';
import useComponentDidMount from '@utils/useComponentDidMount';
import {
  Button, Dropdown, Form, Input,
} from 'semantic-ui-react';
import styles from './less/CreateInstance.module.less';

function CreateNewInstanceForm(props) {
  const { t, match: { params: { track_id } } } = props;
  const initialValuesWidget = {
    user_team: '',
    user_list: '',
    platform: '',
    required_machine: '',
    subnet: '',
    start_hours: '',
    start_minutes: '',
    end_hours: '',
    end_minutes: '',
  };
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const batchId = ls.get('batch_id');
  const streamId = ls.get('stream_id');

  const [state, setState] = useState({
    teamList: [],
    userList: [],
    platformList: [],
    subnetList: [],
  });

  const {
    teamList, userList, platformList,
    subnetList,
  } = state;

  const obtainVmPlatformTypeList = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            platformList: data.vm_platform_type_list.map(
              (ele) => ({ key: ele.id, value: ele.id, text: ele.name }),
            ),
          }));
        }
      },
      resturls.obtainVmPlatformTypeList,
      {},
      'GET',
    );
  };

  const obtainVmSubnetList = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            subnetList: data.vm_subnet_list.map(
              (ele) => ({ key: ele.subnet_id, value: ele.subnet_id, text: ele.subnet_id }),
            ),
          }));
        }
      },
      resturls.obtainVmSubnetList,
      {},
      'GET', 'cdn',
    );
  };

  const obtainUserListFormTeamForVm = (id) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            userList: data.user_list.map(
              (ele) => ({ key: ele.id, value: ele.id, text: ele.name }),
            ),
          }));
        }
      },
      `${resturls.obtainUserListFormTeamForVm}?batch_id=${batchId}&team_id=${id}`,
      {},
      'GET',
    );
  };

  const obtainTeamListForVm = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            teamList: data.team_list.map(
              (ele) => ({ key: ele.id, value: ele.id, text: ele.name }),
            ),
          }));
        }
      },
      `${resturls.obtainTeamListForVm}?track_id=${track_id}`,
      {},
      'GET',
    );
  };

  const submitCreateWidget = (values) => {
    const {
      platform,
      required_machine, subnet,
      user_team,
    } = values;
    setIsLoading(true);
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage && data) {
          setIsLoading(false);
          history.push(`/VirtualMachine/${track_id}`);
        }
      },
      resturls.createVmMachines,
      {
        user_list: userList,
        team_id: user_team,
        platform_id: platform,
        required_machines: required_machine,
        subnet_id: subnet,
        stream_id: streamId,
        batch_id: batchId,
        start_time: { start_hours: values.start_hours, start_minutes: values.start_minutes },
        end_time: { end_hours: values.end_hours, end_minutes: values.end_minutes },
      },
      'POST',
    );
  };

  useComponentDidMount(() => {
    obtainTeamListForVm();
    obtainVmPlatformTypeList();
    obtainVmSubnetList();
  });

  return (
    <div className={styles.CreateNewInstanceForm}>
      {isLoading && <DefaultLoader />}
      <div>
        <h1>{t('create_virtual_machines')}</h1>
        <hr />
      </div>
      <HRLFormik
        initialValues={initialValuesWidget}
        onSubmit={submitCreateWidget}
      // validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleSelect,
          handleSelectiveBlur,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className={styles.createInstanceForm}>
              <Form.Group>
                <Form.Field
                  className={styles.formfieldsforwidget}
                  name="user_team"
                  options={teamList}
                  value={values.user_team}
                  onChange={(e, d) => {
                    handleSelect(e, d);
                    obtainUserListFormTeamForVm(d.value);
                  }}
                  onBlur={handleSelectiveBlur}
                  label={t('user_team')}
                  control={Dropdown}
                  required
                  selection
                  width={4}
                  error={touched.user_team && errors.user_team}
                />
                <Form.Field
                  className={styles.formfieldsforwidget}
                  name="user_list"
                  options={userList}
                  value={values.user_list}
                  onChange={handleSelect}
                  onBlur={handleSelectiveBlur}
                  label={t('user_list')}
                  control={Dropdown}
                  // required
                  selection
                  width={4}
                  error={touched.user_list && errors.user_list}
                />
                <Form.Field
                  className={styles.formfieldsforwidget}
                  name="platform"
                  options={platformList}
                  value={values.platform}
                  onChange={handleSelect}
                  onBlur={handleSelectiveBlur}
                  label={t('platform')}
                  control={Dropdown}
                  required
                  selection
                  width={4}
                  error={touched.platform && errors.platform}
                />
                <Form.Field
                  className={styles.formfieldsforwidget}
                  name="required_machine"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.required_machine}
                  width={3}
                  type="number"
                  required
                  control={Input}
                  label={t('required_machine')}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  error={touched.required_machine && errors.required_machine}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  className={styles.formfieldsforwidget}
                  name="subnet"
                  options={subnetList}
                  value={values.subnet}
                  onChange={handleSelect}
                  onBlur={handleSelectiveBlur}
                  label={t('subnet')}
                  control={Dropdown}
                  required
                  selection
                  width={4}
                  error={touched.subnet && errors.subnet}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5 style={{ marginBottom: '0%' }}>
                    {t('start_time')}
                  </h5>
                  <div className={styles.durationField}>
                    <span fontas="alpha">{t('hours')}</span>
                    <Form.Field
                      fontas="alpha"
                      control={Input}
                      className={styles.durationInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.start_hours}
                      name="start_hours"
                      type="number"
                      min={0}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      error={touched.start_hours && errors.start_hours}
                    />
                    <span fontas="alpha">{t('minutes')}</span>
                    <Form.Field
                      fontas="alpha"
                      control={Input}
                      className={styles.durationInput}
                      onChange={(e, d) => d.value < 60 && (handleChange(e, d))}
                      onBlur={handleBlur}
                      value={values.start_minutes}
                      name="start_minutes"
                      type="number"
                      min={0}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      error={touched.start_minutes && errors.start_minutes}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5 style={{ marginBottom: '0%' }}>
                    {t('end_time')}
                  </h5>
                  <div className={styles.durationField}>
                    <span fontas="alpha">{t('hours')}</span>
                    <Form.Field
                      fontas="alpha"
                      control={Input}
                      className={styles.durationInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.end_hours}
                      name="end_hours"
                      type="number"
                      min={0}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      error={touched.end_hours && errors.end_hours}
                    />
                    <span fontas="alpha">{t('minutes')}</span>
                    <Form.Field
                      fontas="alpha"
                      control={Input}
                      className={styles.durationInput}
                      onChange={(e, d) => d.value < 60 && (handleChange(e, d))}
                      onBlur={handleBlur}
                      value={values.end_minutes}
                      name="end_minutes"
                      type="number"
                      min={0}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      error={touched.end_minutes && errors.end_minutes}
                    />
                  </div>
                </div>
              </Form.Group>
              {/* <div align="center">
                <Button
                  fontas="alpha"
                  onClick={() => setInitialValuesWidget({
                    user_team: '',
                    user_list: '',
                    platform: '',
                    required_machine: '',
                    subnet: '',
                    start_hours: '',
                    start_minutes: '',
                    end_hours: '',
                    end_minutes: '',
                  })}
                  content={t('clear')}
                />
                <Button fontas="alpha" content={t('add')} />
              </div> */}
              <div align="center">
                <Button fontas="alpha" type="submit" content={t('create')} />
              </div>
            </Form>
          </>
        )}
      </HRLFormik>
    </div>
  );
}

export default withTranslation('recruiter')(CreateNewInstanceForm);
