import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import styles from './supportManager.module.less';

const MentorPerformanceTable = (props) => {
  const { t, mentorWisePerformanceData } = props;
  return (
    <div className={styles.mentorPerformanceTable}>
      <div>{t('mentor_performance')}</div>
      <div>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('s_no')}</Table.HeaderCell>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('username')}</Table.HeaderCell>
              <Table.HeaderCell>{t('email')}</Table.HeaderCell>
              <Table.HeaderCell>{t('assigned_count')}</Table.HeaderCell>
              <Table.HeaderCell>{t('solved_count')}</Table.HeaderCell>
              <Table.HeaderCell>{t('sla')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {mentorWisePerformanceData.map((event, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  {event.name}
                </Table.Cell>
                <Table.Cell>{event.username}</Table.Cell>
                <Table.Cell>{event.email}</Table.Cell>
                <Table.Cell>{event.assigned_count}</Table.Cell>
                <Table.Cell>{event.total_solved_count}</Table.Cell>
                <Table.Cell>{event.sla_completion_percentage}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
export default withTranslation('programManager')(MentorPerformanceTable);
