const UserProfile = {
  about_me: 'About Me',
  company_name: 'company_name',
  edit_employment: 'Edit Employment',
  designation: 'Designation',
  employment_type: 'Employment Type',
  organization: 'Organization',
  start_date: 'Start Date',
  end_date: 'End Date',
  save_btn: 'Save',
  employment_details: 'Employment Details',
  institution_id: 'Institution Name',
  education_details: 'Education Details',
  completion_year: 'Completion Year',
  score: 'score',
  state: 'State',
  qualification: 'Qualification',
  stream: 'Stream',
  skill_modal_input_placeholder: 'Skill Modal Input Placeholder',
  skills: 'Skills',
  skill_modal_add_btn: 'Add Skills',
  name: 'Name',
  issusing_organization: 'Issusing Organization',
  certificate_doc: 'Certificate Document',
  completion_date: 'Completion Date',
  upload_file: 'Upload File',
  edit_btn: 'Edit',
  remove_btn: 'Remove',
  required_field: 'Field Required',
  content_should_be_less_than_250: 'Content Should Be Less Than 250 Words',
  empty_about_me: 'Empty About Me',
  personal_details: 'Personal Details',
  mobile_number: 'Mobile number',
  date_of_birth: 'Date Of Birth',
  aadhar_number: 'Aadhar Number',
  education: 'Education',
  certifications: 'Certifications',
  certificate_required: 'Copy of your certificate is required',
  give_your_skills: 'Give your skills seperated by commas if you have more than 1',
  tenth: 'X',
  twelth: 'XII',
  diploma: 'DIPLOMA',
  ug: 'UG',
  pg: 'PG',
  school: 'SCHOOL',
  school_details: 'School Education',
  institution: 'Institution',
  percentage: 'Percentage',
  yoc: 'Year of Completion',
  choose_state: 'Choose State',
  choose_state_before_institution: 'Choose State Before Institution',
  enter_percentage: 'Enter your percentage',
  enter_yoc: 'Enter year of completion',
  mode_of_score: 'Mode of Score',
  degree: 'Degree',
  branch: 'Branch',
  class: 'CLASS',
  institution_name: 'INSTITUTION NAME',
  perc: 'PERCENTAGE',
  type_of_degree: 'DEGREE TYPE',
  degree_of_user: 'DEGREE',
  ug_qual: 'UG',
  cgpa: 'CGPA',
  branch_or_stream: 'STREAM',
  update: 'Update',
  enter_only_numbers: 'Enter only numbers ranging from 0 to 100',
  enter_valid_passout_year: 'Enter a proper passout year consisting of 4 digits',
  twelth_year_of_passing_error: 'Year of passing of twelth must be greater than that of 10th and must be within current year',
  diploma_yoc_error_for_x: 'Year of passing of diploma must be greater than that of 10th',
  diploma_yoc_error: 'Year of passing of diploma must be greater than that of 12th',
  ug_yoc_error: 'Year of passing of UG must be greater than that of 12th or diploma',
  pg_yoc_error: 'Year of passing of PG must be greater than that of UG',
  two_fifty_words: '250 Words',
  presently_working_here: 'Presently Working Here',
  house_number: 'House Number',
  at_present: 'At Present*',
  minimum_zero: 'Enter a value greater than or equal to 0',
  enter_cgpa: 'Enter your CGPA',
  resume_info_mismatch: 'Data mismatch detected with resume. Please update if necessary.',
  finish_setup: 'Finish Setup',
  add_employment_from_resume: 'This Employment Has Been Extracted From Your Resume',
  add_certifications_from_resume: 'This Certification Has Been Extracted From Your Resume',
  insights_from_resume: 'Insights Derived From Resume',
  other_technical_skills_list: 'Other Technical Skills List',
  technical_skills_list: 'Technical Skills List',
  ug_yoc_error_for_diploma: 'Year of Completion Must Be Greater Than That of Diploma',
  file_too_large: 'File Size Must Be Within 10 MB',
  unsupported_file_format: 'Supported File Formats include .jpg, .png, .jpeg, .pdf, .msword',
  no_skills_extracted: 'No Skills Extracted',
  no_file_choosen: 'No File Choosen',
  upload_till_date: 'Please Ensure to upload Only The Documents Completed Till Date',
  enter_yoc_pass_out: 'Year of Completion / Pass-Out Year',
  x_and_xii_must_be_till_date: 'Tenth and Twelth Must Be Within Current Year',
  end_date_must_be_greater_than_start_date: 'End Date Must Be Greater Than Start Date',
  end_date_must_be_before_or_equal_to_today: 'End date must be before or equal to today',
  start_date_must_be_atleast_today: 'Start date must be atleast today',
  complete_profile_setup: 'Complete Profile Setup',
};
export default UserProfile;
