import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import styles from './HeaderLayOut.module.less';

function HeaderLayOut(props) {
  const { children, logoClickable = true, logoOnClickFunc = () => null } = props;

  const [headerContent, setHeaderContent] = useState({});

  const history = useHistory();

  const currentpath = history.location.pathname;

  const updateChildrenContent = () => {
    const newChildren = children.length ? children : [children];
    const positionedContent = {};
    newChildren.forEach((element) => {
      const position = element.props ? (element.props.align || '') : '';
      switch (position) {
        case 'left':
          positionedContent[position] = element;
          break;
        case 'center':
          positionedContent[position] = element;
          break;
        case 'right':
          positionedContent[position] = element;
          break;
        default:
          break;
      }
    });
    setHeaderContent(positionedContent);
  };

  useEffect(() => {
    if (children) {
      updateChildrenContent(children);
    }
  }, [children]);

  const forAttemptBool = currentpath.startsWith('/attempt/') || currentpath.startsWith('/interview/');

  // const fetchLogoSrc = () => {
  //   if (!forAttemptBool) return '/images/logo.svg';

  //   return '/images/hrl_attempt_logo.svg';
  // };

  // const fetchLogoStyle = () => {
  //   if (!forAttemptBool) return styles.mainLogo;

  //   return styles.attemptMainLogo;
  // };

  const fetchLogoSrc = () => '/images/logo.svg';
  const fetchLogoStyle = () => styles.mainLogo;

  const { left, center, right } = headerContent;

  return (
    <header className={!forAttemptBool ? '' : styles.attemptHeader} id="default">
      <div className={styles.headerLeft}>
        <Link to={logoClickable ? '/' : currentpath}>
          <Image src={fetchLogoSrc()} className={fetchLogoStyle()} onClick={logoOnClickFunc} />
        </Link>
        {left && left?.props.children}
      </div>
      {center && (
        <div className={styles.headerMiddle}>
          {center?.props.children}
        </div>
      )}
      {right && (
        <div className={styles.headerRight}>
          {right?.props.children}
        </div>
      )}
    </header>
  );
}

export default withTranslation('common')(HeaderLayOut);
