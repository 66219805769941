const Admin = {
  sevices: 'Sevices',
  new_widget: 'New Widget',
  cardinality: 'Cardinality',
  component_type: 'Component Type',
  widget_action: 'Widget Action',
  session: 'Session',
  questionBank: 'Question Bank',
  courseManagement: 'Course Management',
  trackManagement: 'Track Management',
  widgetManagement: 'Widget Management',
  organizationManagement: 'Organization Management',
  licenseManagement: 'License Management',
  rolesAndCapabilities: 'Roles And Capabilities',
  userManagement: 'User Management',
  name: 'Name',
  width: 'Width',
  description: 'Description',
  context_level: 'Context Level',
  refresh_intervals: 'Refresh Intervals',
  active: 'Active',
  widget_controller: 'Widget Controller',
  choose_file: 'Choose File',
  no_file_choosen: 'No File Choosen',
  save_next: 'Save & Next',
  widget: 'Widget',
  refresh_interval: 'Refresh Interval',
  live_users: 'Live Users',
  s_no: 'S. No.',
  email: 'Email',
  batch: 'Batch',
  logged_in_time: 'Logged in time',
  ip: 'IP',
  logged_in_since: 'Logged in since',
  last_request_time: 'Last Request Time',
  device: 'Device',
  no_users_online: 'No Users Online',
  team_name: 'Team Name',
  server_ip: 'Server IP',
  total_users_count: 'Total Users Count',
  filter_by_team: 'Filter by Team',
  questionbank: 'Question Bank',
  coursemanagement: 'Course Management',
  trackmanagement: 'Track Management',
  widgetmanagement: 'Widget Management',
  organizationmanagement: 'Organization  Management',
  licensemanagement: ' License Management',
  rolescapabilities: 'Roles And capabilities',
  usermanagement: 'User Management',
  server_instance: 'Server Instance',
  showCapability: 'Show Capability',
  searchByActions: 'Search by Actions',
  controllers: 'Controllers',
  selectController: 'Select Controller',
  getActionMapping: 'Get Action Mapping',
  checkAll: 'Check All',
  unCheckAll: 'Un Check All',
  capabilityActions: 'Capability Actions',
  are_you_sure_you_want_to_check_all: 'Are you sure ? You want to check all',
  are_you_sure_you_want_to_un_check_all: 'Are you sure ? You want to uncheck all',
  yes: 'Yes',
  no: 'No',
  mailmaster: 'Mail Master',
  more_options: 'More Options',
  mail_master: 'Mail Master',
  new_mailer_template: 'New Mailer Template',
  search_mailer_template: 'Search Mailer Template',
  mail_log: 'Mail Log',
  subject: 'Subject',
  mail_content: 'Mail Content',
  date_created: 'Date Created',
  last_updated: 'Last Updated',
  action: 'Action',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete?',
  edit_mail_template: 'Edit Mail Template',
  from_address: 'From Address',
  to_address: 'To Address',
  cc_address: 'CC Address',
  bcc_address: 'BCC Address',
  reply_to_address: 'Reply To Address',
  org_units: 'Org Units',
  mailer_purpose: 'Mailer Purpose',
  save: 'Save',
  create_mail_template: 'Create Mail Template',
  name_is_required: 'Name is Required',
  subject_is_required: 'Subject is Required',
  mailer_purpose_is_required: 'Mailer Purpose is Required',
  search: 'Search',
  mail_subject: 'Mail Subject',
  mail_purpose: 'Mail Purpose',
  org_unit_name: 'Org Unit Name',
  search_mail: 'Search Mail',
  purpose: 'Purpose',
  sent_date: 'Sent Date',
  delivered: 'Delivered',
  view_mail_log: 'View Mail Log',
  is_read: 'Is Read',
  search_mail_template: 'Search Mail Template',
  hrl_compass: 'HRL Compass',
  repository: 'Repository',
  course_hub: 'Course Hub',
  track_directory: 'Track Directory',
  widget_control: 'Widget Control',
  organization_forge: 'Organization Forge',
  license_planner: 'LicensePlanner',
  access_matrix: 'Authority Matrix / Access Matrix',
  user_ops_pro: 'UserOpsPro',
  access_arc: 'AccessArc',
  select_org_name: 'Select Org Name',
  select_org_unit: 'Select Org Unit',
  reference_code_is_required: 'Reference Code Is Required',
  query_language_is_required: 'Query Language Is Required',
  description_is_required: 'Description Is Required',
  length_is_required: 'Length Is Required',
  width_is_required: 'Width Is Required',
  cardinality_is_required: 'Cardinality Is Required',
  widget_action_is_required: 'Widget Action Is Required',
  widget_controller_is_required: 'Widget Controller Is Required',
  context_level_required: 'Context Level Required',
  frefresh_intervals_is_required: 'Frefresh Intervals Is Required',
  please_select_a_file: 'Please select a file',
  courses_for_users: 'Courses for Users',
  get_courses: 'Get Courses',
  select_username: 'Select Username',
  username: 'Username :',
  current_courses_assessments: 'Current Courses / Assessments',
  deep_track: 'Deep Track',
  role: 'Role',
  search_username: 'Search Username',
  module: 'Module',
  team_details: 'Team Details',
  start_time: 'Start Time',
  end_time: 'End Time',
  visibile: 'Visibile',
  can_attempt: 'Can Attempt',
  no_data_found: 'No Data Found',
  team: 'Team',
  access: 'Access',
  user_not_tagged_in_course: 'User Not Tagged In Courses',
  event_management: 'Event Management',
  ust_report: 'UST Report',
  create: 'Create',
  create_event: 'Create Event',
  time_zone: 'Time Zone',
  modal_content: 'Modal Content',
  constraint: 'Constraint',
  constraint_value: 'Constraint Value',
  enabled: 'Enabled',
  event_type: 'Event Type',
  assessment: 'Assessment',
  download: 'Download',
  edit: 'Edit',
  delete: 'Delete',
  view_event: 'View Event',
  modifier: 'Modifier',
  delete_event: 'Delete Event',
  delete_event_modal_content: 'Are you sure to delete this event?',
  cancel: 'Cancel',
  update: 'Update',
  code: 'Code',
  city: 'City',
  district: 'District',
  state: 'State',
  edit_management: 'Edit Management',
  institution_management: 'Institution Management',
  create_institution: 'Create Institution',
  school_management: 'School Management',
  college_management: 'College Management',
  company_management: 'Company Management',
  create_school: 'Create School',
  create_college: 'Create College',
  create_company: 'Create Company',
  are_you_sure_want_to_delete: 'Are you Sure, Want to Delete!',
  logo: 'Logo',
  logo_: 'Logo (URL)',
  required_field: 'Required Field',
  assign_member: 'Assign Member',
  add_role: 'Add Role',
  new_role: 'New Role',
  role_list: 'Role List',
  priority: 'Priority',
  organization: 'Organization',
  org_unit: 'Org Unit',
  upload_file: 'Upload File:',
  select_file: 'Select File',
  db_diff: 'Db diff',
  db_difference: 'Db Difference',
  upload_contentdb_file: 'Upload Contentdb File:',
  upload_userdb_file: 'Upload Userdb File:',
  show_mail_template: 'Show Mail Template',
  verify_otp_to_proceed: 'Verify OTP to proceed',
  verify_otp: 'Verify OTP',
  resend_otp: 'Resend OTP',
  generate_otp: 'Generate OTP',
  search_mail_log: 'Search Mail Log',
  mail_creation_date: 'Mail Creation Date',
  includes_the_words: 'Includes the words',
  date_within: 'Date Within',
  coupon_type: 'Coupon Type',
  generate_code: 'Generate Code',
  coupon_validity_period: 'Coupon validity period',
  coupon_type_is_required: 'Coupon type is required',
  percentage_is_required: 'Percentage is required',
  coupon_code_is_required: 'Coupon code is required',
  coupon_validity_period_is_required: 'Coupon validity period is required',
  please_specify_if_the_coupon_is_active_or_not: 'Please specify if the coupon is active or not',
  coupon_code_created_successfully: 'Coupon code created successfully',
  add: 'Add',
  percentage: 'Percentage',
  coupon_code: 'Coupon code',
  coupon_type_name: 'Coupon Type',
  kindly_note: 'Kindly note the code is valid only till date and time',
  copy: 'Copy',
  close: 'Close',
  no_of_users: 'Number of Users',
  enter_positive_value: 'It must be a Positive value',
  code_copied: 'Code Copied',
  refreshed: 'Refreshed',
  date: 'Date',
  smsmaster: 'SMS Master',
  new_sms_template: 'New SMS Template',
  sms_content: 'SMS Content',
  create_sms_template: 'Create SMS Template',
  edit_sms_template: 'Edit SMS Template',
  organisation: 'Organisation',
  org_unit_required: 'Must select an Organisation',
  org_required: 'Must select an Org Unit',
  sms_content_is_required: 'SMS content is required',
  select_organisation: 'Select Organisation',
  sms_template_create_error: 'SMS template already available in this Org Unit',
  mail_template_create_error: 'Mail template already available in this Org Unit for this Purpose',
  show_capability: 'Show Capability',
  ignored_capabilities: 'Ignored Capabilities',
  controller: 'Controller',
  access_count: 'Access Count',
  last_access_date: 'Last Access Date',
  missing_capabilities: 'Missing Capabilities',
  reclytics: 'Reclytics',
  skillytics: 'Skillytics',
  or: 'or',
  user_tracking: 'User Tracking',
  user_not_tagged_in_assessment: 'User Not Tagged In Assessments',
  click_to_copy_email: 'Click to copy email',
  email_copied: 'Email copied !!',
  server_name: 'Server Name',
  user_count: 'User Count',
  no_server_ip: 'No server IP',
  property: 'Property',
  condition: 'Condition',
  value: 'Value',
  record_count: 'Record Count',
  select: 'Select',
  select_all: 'Select All',
  deselect_all: 'Deselect All',
  order: 'Order',
  reference_code: 'Reference Code',
  complexity: 'Complexity',
  remove: 'Remove',
  get_assesment: 'Get Assessment',
  mailer_template: 'Mailer Template',
  deactivated: 'Deactivated',
  click_to_deactivete_user: 'Click to Deactivate User',
  click_to_activete_user: 'Click to Activate User',
  current_assessments: 'Current Assessments',
  current_courses: 'Current Courses',
  user_status: 'User Status :-',
  deactivate_admin_access: 'Contact admin to Deactivate User',
  activate_admin_access: 'Contact admin to Activate User',
  email_id: 'E-mail ID',
  skills: 'Skills',
  experience: 'Experience',
  current_company: 'Current Company',
  mobile: 'Mobile',
  thive_user_management: 'THive Users',
  download_resume: 'Download Resume',
  users_without_resume: 'Users Without Resume',
  entries_per_page: 'Entries per Page',
  send_email: 'Send Email',
  send_sms: 'Send SMS',
  sms_count: 'SMS Count',
  mail_count: 'Mail Count',
  filters: 'Filters',
  mail_sent_count: 'Mail Sent Count',
  sms_sent_count: 'SMS Sent Count',
  content: 'Content',
  add_concept: 'Add Concept',
  active_status: 'Active Status',
  concepts: 'Concepts',
  skill_name: 'Skill Name',
  version: 'Version',
  min_experience: 'Min Experience',
  max_experience: 'Max Experience',
  simple: 'Simple',
  medium: 'Medium',
  complex: 'Complex',
  above: 'Above',
  actions: 'Actions',
  add_base_qtn: 'Add Base Qtn',
  upload_logo: 'Upload Logo',
  concept_saved: 'Concept Saved',
  concept: 'Concept',
  base_concepts: 'Base Concepts',
  tag_base_concept: 'Tag Base Concepts',
  tag: 'Tag',
  show_prompt_template: 'Show Prompt Template',
  prompt_template_list: 'Prompt Template List',
  create_prompt_template: 'Create Prompt Template',
  keywords: 'Keywords',
  add_keyword: 'Add Keyword',
  create_prompt_keyword: 'Create Prompt Keyword',
  value_type: 'Value Type',
  is_user_response: 'IsUserResponse',
  delete_prompt_keyword: 'Delete Prompt Keyword',
  edit_prompt_keyword: 'Edit Prompt Keyword',
  finalize_prompt: 'Finalize Prompt',
  prompt: 'Prompt',
  prompt_description: 'Prompt Description',
  edit_prompt_template: 'Edit Prompt Template',
  this_keyword_is_already_present: 'This keyword is already present',
  prompt_already_tagged: 'Prompt already tagged in a question',
  header_code: 'Header code',
  prompt_template_code: 'Prompt template code',
  languages: 'Languages',
  default_problem_id: 'Default Problem ID',
  default_component_id: 'Default Component ID',
  extension: 'Extension',
  catalogs: 'Catalogs',
  onlinecontexts: 'Online Contexts',
  products: 'Products',
  onlineservices: 'Online Services',
  create_catalog: 'Create Catalog',
  status: 'Status',
  online_service_name: 'Online Service Name',
  online_catalog_category_name: 'Online Catalog Category Name',
  online_context_name: 'Online Context Name',
  online_context_type: 'Online Context Type',
  create_online_context: 'Create Online Context',
  reference_id: 'Reference ID',
  audience: 'Audience',
  url: 'URL',
  product_segment_name: 'Product Segment Name',
  commission: 'Commission',
  create_product: 'Create Product',
  create_online_service: 'Create Online Service',
  add_technology: 'Add Technology',
  edit_technology: 'Edit Technology',
  add_skill: 'Add Skill',
  edit_skill: 'Edit Skill',
  edit_certificate: 'Edit Certificate',
  add_certificate: 'Add Certificate',
  new_technology: 'New Technology',
  new_skill: 'New Skill',
  new_certificate: 'New Certificate',
  Lit_certificate: 'Lit Certificate',
  Lit_certificate_skill: 'Lit Certificate Skill',
  certified_as: 'Certified as',
  hrl_session_u_id: 'hrl Session Id',
  duration_between_attempts: 'Days Between Attempts',
  parent_category: 'Parent Category',
  add_new_online_service: 'Add New Online Service',
  is_university_specific: 'is University Specific',
  online_service: 'Online Service',
  poc_name: 'POC Name',
  poc_email: 'POC Email',
  poc_mobile: 'POC Mobile',
  create_category: 'Create Category',
  context_type: 'Context Type',
  context: 'Context',
  start_date: 'Start Date',
  end_date: 'End Date',
  discount: 'Discount',
  detail_url: 'Detail url',
  trail_days: 'Trail Days',
  institution: 'Institution',
  redirect_action: 'Redirect Action',
  product: 'Product',
  trainer_support: 'Trainer Support',
  enable_trial: 'Enable trial',
  trending: 'Trending',
  catalog_image: 'Catalog Image',
  in_percentage: 'in %',
  in_inr: 'in ₹',
  in_usd: 'in $',
  self_study_amount: 'Self Study Amount',
  professional_level_amount: 'Professional Level Amount',
  lead_level_amount: 'Lead Level Amount',
  expert_level_amount: 'Expert Level Amount',
  intro_video_url: 'Intro Video URL',
  online_catalog_discount_bundle: 'Online Catalog Discount Bundle',
  create_successfully: 'Created Successfully',
  updated_successfully: 'Updated Successfully',
  amount: 'Amount',
};

export default Admin;
