import { useRef } from 'react';
import UserInfo from '@models/UserInfo';
import { restbaseurl } from '@utils/constants';
import useComponentWillUnmount from '@utils/useComponentWillUnmount';
import useComponentDidMount from '@utils/useComponentDidMount';
import { resturls } from '@utils/apiurls';
import { sendSystemNotification } from './SystemNotification';

const NotificationSSE = ({ dispatch, mesgReference }) => {
  const eventSourceRef = useRef(null);
  const setIntervalRef = useRef(null);
  const eventReconnectRef = useRef(false);

  const eventSourceOnError = () => {
    eventSourceRef.current.close();
  };

  const updateMessageHandler = (event) => {
    if (event.data !== 'False') {
      try {
        const cleanedData = event.data
          .replace(/True/g, 'true')
          .replace(/False/g, 'false')
          .replace(/None/g, '""')
          .replace(/'/g, '"');
        const startIndex = cleanedData.indexOf('[');
        const endIndex = cleanedData.lastIndexOf(']') + 1;
        const validJsonData = cleanedData.slice(startIndex, endIndex);
        const data = JSON.parse(validJsonData);
        if (data && data.length) {
          const updatelength = mesgReference.current.new_message_through_sse + data.length;
          // eslint-disable-next-line no-param-reassign
          mesgReference.current.new_message_through_sse = updatelength;
          dispatch({ type: 'NEW_NOTIFICATION_LIST', data });
          sendSystemNotification(data);
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  };

  const connectEventSource = () => {
    const { id } = UserInfo.getUserDetail();
    const getSSEurl = () => `${restbaseurl}${resturls.obtainUserNotifications}?user_id=${id}`;
    const eventStreamUrl = getSSEurl();
    eventSourceRef.current = new EventSource(eventStreamUrl);
    eventSourceRef.current.onmessage = updateMessageHandler;
    eventSourceRef.current.onerror = eventSourceOnError;
  };

  const eventSourceConnectionCheck = () => {
    if (eventSourceRef.current.readyState === EventSource.CLOSED) {
      eventReconnectRef.current = true;
      console.log('reconnecting...');
      connectEventSource();
    } else if (eventReconnectRef.current) {
      eventReconnectRef.current = false;
      console.log('reconnected!!!');
    }
  };

  useComponentDidMount(() => {
    // getNotifications('all');
    if (eventSourceRef.current === null) {
      connectEventSource();
      console.log('notification sse to--> connect');
      setIntervalRef.current = setInterval(eventSourceConnectionCheck, 3000);
    }
    // document.addEventListener('mousedown', handleClickOutside);
  });

  useComponentWillUnmount(() => {
    eventSourceOnError();
    console.log('notification sse to--> disconnect');
    clearInterval(setIntervalRef.current);
    // document.removeEventListener('mousedown', handleClickOutside);
  });

  return null;
};

export default NotificationSSE;
