import React, { useState } from 'react';
import {
  Button, Checkbox, Dropdown, Form, Input, TextArea, Grid,
  Confirm,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import { withTranslation } from 'react-i18next';
import { HRLFormik, useComponentDidMount } from '@hrlytics/corelytics';
import styles from './less/questionnaireShowPage.module.less';

const EditAndShowQuestionnaire = (props) => {
  const {
    t, questionnaireId, setEditQuestionValue, isAttemptedQuestion,
  } = props;

  const history = useHistory();

  const [state, setState] = useState({
    isEditQuestionnaire: false,
    initialValues: [],
    isQuestionnaireDelete: false,
  });

  const { isEditQuestionnaire, initialValues = [], isQuestionnaireDelete } = state;

  const gradeOptions = [];
  for (let i = 1; i <= 100; i += 1) {
    gradeOptions.push({ key: i, text: i.toString(), value: i });
  }

  const obtainQuestionnaire = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { questionnaire_list } } = respData;
      if (estatus && emessage) {
        setState((prev) => ({
          ...prev,
          initialValues: questionnaire_list[0],
        }));
      }
    }, `${resturls.obtainQuestionnaire}?questionnaire_u_id=${questionnaireId}`, {}, 'GET');
  };

  const deleteQuestionnaire = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage && data) {
        history.push('/questionnaire/list');
      }
    }, `${resturls.deleteQuestionnaire}?u_id=${questionnaireId}`, {}, 'GET');
  };

  const submitQuestionnaire = (value, { setSubmitting, resetForm }) => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage && data) {
        setState((prev) => ({
          ...prev,
          initialValues: value,
        }));
        setSubmitting(false);
        resetForm();
      }
    }, resturls.createAndEditQuestionnaire, {
      questionnaire_obj: value,
      questionnaire_u_id: questionnaireId,
    }, 'POST');
  };

  useComponentDidMount(() => {
    obtainQuestionnaire();
    setEditQuestionValue(null);
  });

  return (
    <div className={styles.editShowQuestionnaire}>
      <HRLFormik
        initialValues={{
          name: initialValues.name || '',
          description: initialValues.description || '',
          comments_enabled: initialValues.comments_enabled || false,
          grade: initialValues.grade || 1,
          numbering_enabled: initialValues.numbering_enabled || false,
        }}
        // validationSchema={schema}
        onSubmit={submitQuestionnaire}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSelect,
          handleSelectiveBlur,
          handleRadioChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row className={styles.questionnaireRow}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('name')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  {
                    isEditQuestionnaire ? (
                      <Form.Field
                        required
                        fontas="beta"
                        onChange={handleChange}
                        control={Input}
                        name="name"
                        width={4}
                        value={values.name}
                        onBlur={handleBlur}
                        error={touched.name && errors.name}
                      />
                    ) : (
                      <span fontas="alpha">
                        {values.name}
                      </span>
                    )
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('description')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  {
                    isEditQuestionnaire ? (
                      <Form.Field
                        required
                        fontas="beta"
                        control={TextArea}
                        name="description"
                        width={4}
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                        error={touched.description && errors.description}
                      />
                    ) : (
                      <span fontas="alpha">
                        {values.description}
                      </span>
                    )
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.questionnaireRow}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('comments')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  {
                    isEditQuestionnaire ? (
                      <Form.Field
                        control={Checkbox}
                        name="comments_enabled"
                        value={values.comments_enabled}
                        checked={values.comments_enabled}
                        onChange={handleRadioChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      <span fontas="alpha">
                        {values.comments_enabled ? 'True' : 'False'}
                      </span>
                    )
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('grade')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  {
                    isEditQuestionnaire ? (
                      <Form.Field
                        name="grade"
                        options={gradeOptions}
                        value={values.grade}
                        onChange={handleSelect}
                        width={4}
                        onBlur={handleSelectiveBlur}
                        control={Dropdown}
                        required
                        selection
                        error={touched.grade && errors.grade}
                      />
                    ) : (
                      <span fontas="alpha">
                        {values.grade}
                      </span>
                    )
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.questionnaireRow}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('numbering_enabled')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  {
                    isEditQuestionnaire ? (
                      <Form.Field
                        control={Checkbox}
                        name="numbering_enabled"
                        value={values.numbering_enabled}
                        checked={values.numbering_enabled}
                        onChange={handleRadioChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      <span fontas="alpha">
                        {values.numbering_enabled ? 'True' : 'False'}
                      </span>
                    )
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              isEditQuestionnaire ? (
                <Button
                  type="button"
                  onClick={() => setState((prev) => ({
                    ...prev,
                    isEditQuestionnaire: false,
                  }))}
                >
                  {t('save')}
                </Button>
              ) : (
                <Button
                  type="submit"
                  onClick={() => setState((prev) => ({
                    ...prev,
                    isEditQuestionnaire: true,
                  }))}
                >
                  {t('edit')}
                </Button>
              )
            }
            <Button
              disabled={isAttemptedQuestion}
              type="button"
              onClick={() => setState((prev) => ({
                ...prev,
                isQuestionnaireDelete: true,
              }))}
            >
              {t('delete')}
            </Button>
            {
              isAttemptedQuestion && (
                <div className={styles.disabledQuestion}>
                  {`*${t('attempted_ques')}`}
                </div>
              )
            }
          </Form>
        )}
      </HRLFormik>

      <Confirm
        open={isQuestionnaireDelete}
        content={
          <div className="content"><span fontas="alpha">{t('are_you_sure_you_want_to_delete')}</span></div>
        }
        size="mini"
        cancelButton={<Button basic>{t('cancel')}</Button>}
        onCancel={() => setState((prev) => ({
          ...prev,
          isQuestionnaireDelete: false,
        }))}
        onConfirm={deleteQuestionnaire}
      />
    </div>
  );
};
export default withTranslation('recruiter')(EditAndShowQuestionnaire);
