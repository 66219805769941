// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.questionnaireHeader__1h46h {\n  margin: 2rem 2rem 0 2rem;\n  display: flex;\n  justify-content: space-between;\n  background-color: var(--color-zuccini);\n  padding: 0.6em;\n  color: #ffff;\n  align-items: center;\n}\n.questionnaireHeader__1h46h > div:first-child {\n  display: flex !important;\n  align-items: center;\n}\n.questionnaireHeader__1h46h > div:last-child > div {\n  color: #ffff;\n  padding-right: 1.5rem;\n}\n.questionnaireHeader__1h46h > div:last-child > div > div[class=\"menu transition visible\"] {\n  right: 0;\n  top: 120%;\n  left: auto;\n}\n.questionnaireRow___6oos {\n  background: #f5f5f5;\n}\n.questionnaireListTable__11V1B {\n  margin: 0 2rem 2rem 2rem;\n  border: 1px solid var(--color-zuccini) !important;\n  background-color: var(--color-pantone);\n}\n.questionnaireListTable__11V1B > table > tbody > tr > td:first-child {\n  text-decoration: underline;\n  cursor: pointer;\n  color: var(--color-dark-green) !important;\n}\n.createQuestionnaire__2bsjy {\n  margin: 0 2rem;\n  border: 1px solid;\n  padding: 2rem;\n  background-color: var(--color-pantone);\n}\n.createQuestionnaire__2bsjy > form > div:first-child {\n  margin-bottom: 2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"questionnaireHeader": "questionnaireHeader__1h46h",
	"questionnaireRow": "questionnaireRow___6oos",
	"questionnaireListTable": "questionnaireListTable__11V1B",
	"createQuestionnaire": "createQuestionnaire__2bsjy"
};
module.exports = exports;
