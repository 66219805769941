import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { HRLModal, HRLFormik, ToastMessage } from '@hrlytics/corelytics';
import UserInfo from '@models/UserInfo';
import { processLogout } from '@utils/index';
import GlobalService from '@services/GlobalService';
import md5 from 'md5';
import writeXlsxFile from 'write-excel-file';
import * as Yup from 'yup';
import { resturls } from '@utils/apiurls';
import useComponentDidMount from '@utils/useComponentDidMount';
import {
  Button, Form, Icon, Input, Label,
} from 'semantic-ui-react';
import bulkUploadResetPasswordTemplate from '../../screens/assessment/problemHandler/bulkUpload/bulkUploadResetPasswordTemplate.csv';
import styles from './ResetPassword.module.less';

const ResetPasswordModal = (props) => {
  const { t } = props;

  const userDetails = UserInfo.getUserDetail();

  const { role_name } = userDetails;

  const [state, setState] = useState({
    isParticipant: false,
    nonParticipantError: false,
  });

  const user_name_ref = useRef();
  const user_role_ref = useRef();

  const { isParticipant } = state;

  const [toast, setToast] = useState((<></>));
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setOldShowPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterShowPassword] = useState(false);
  const [passwordForSingleUser, setPasswordForSingleUser] = useState('');
  const [resetPasswordUserUploadData, setResetPasswordUserUploadData] = useState({});
  const [sampleTemplateFile, setSampleTemplateFile] = useState('');
  const [isSuccesMessageModalOpen, setIsSuccesMessageModalOpen] = useState(false);
  const [isResetPasswordMultipleUserData, setIsResetPasswordMultipleUserData] = useState({});
  const [, updateRender] = useState(0);

  let schema = '';

  if (isParticipant) {
    schema = Yup.object().shape({
      old_password: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string().required('Old Password is required'),
        otherwise: Yup.string(),
      }),
      password: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string().test('no-spaces', t('no_beginning_and_ending_spaces'), (value) => !/^\s|\s$/.test(value) && !/^\s.*\s$/.test(value)).required('Password is required'),
        otherwise: Yup.string(),
      }),
      re_enter_password: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Password must match')
          .test('no-spaces', t('no_beginning_and_ending_spaces'), (value) => !/^\s|\s$/.test(value) && !/^\s.*\s$/.test(value))
          .required('Re-Enter Password is required'),
        otherwise: Yup.string(),
      }),
    });
  } else {
    schema = Yup.object().shape({
      user_name: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string().required('User Name is required'),
        otherwise: Yup.string(),
      }),
      password: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string().test('no_spaces', t('no_beginning_and_ending_spaces'), (value) => !/^\s|\s$/.test(value) && !/^\s.*\s$/.test(value)).required('Password is required'),
        otherwise: Yup.string(),
      }),
      re_enter_password: Yup.string().when('input_file_content', {
        is: (value) => !value,
        then: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Password must match')
          .test('no_spaces', t('no_beginning_and_ending_spaces'), (value) => !/^\s|\s$/.test(value) && !/^\s.*\s$/.test(value))
          .required('Re-Enter Password is required'),
        otherwise: Yup.string(),
      }),
    });
  }

  const inputFileRef = useRef(null);

  async function createFile() {
    const response = await fetch(bulkUploadResetPasswordTemplate);
    const data = await response.blob();
    const metadata = {
      type: 'application/vnd.ms-excel',
    };

    const templateFile = new File([data], 'sample_invite_template.xls', metadata);

    const reader = new FileReader();
    reader.readAsDataURL(templateFile);
    reader.onload = () => {
      setSampleTemplateFile(reader.result);
    };
  }

  const getLoginUserLoginPage = () => {
    if (isParticipant) {
      processLogout(true);
    }
  };

  const valueSubmit = (value) => {
    const resetPasswordMultipleUser = (obj) => {
      const fileData = obj.file_data;
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage, data } = respdata;
          if (estatus && emessage) {
            setIsResetPasswordMultipleUserData(data);
          }
          setIsSuccesMessageModalOpen(true);
        },
        resturls.resetPasswordForMultipleUser,
        { file_data: fileData },
        'POST',
      );
    };

    if (!value.input_file_content) {
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage, data } = respdata;
          if (estatus && emessage) {
            setIsMessageModalOpen(true);
            setPasswordForSingleUser(data);
          }
        },
        resturls.resetPasswordForSingleUser,
        {
          user_name: value.user_name,
          password: md5(value.password),
        },
        'POST',
      );
    } else {
      const formData = new FormData();
      formData.append('file', value.input_file_content);

      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage, data } = respdata;
          if (estatus && emessage) {
            setResetPasswordUserUploadData(data);
            if (data.is_valid) {
              resetPasswordMultipleUser(data);
            }
          }
        },
        resturls.validateUserUploadForResetPassword,
        formData,
        'POST',
      );
    }
    // setSubmitting(false);
    // resetForm();
  };

  const checkOldPasswordForUser = (value) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { is_valid } } = respdata;
        if (estatus && emessage) {
          if (is_valid) {
            valueSubmit(value);
            // clearCookies();
          } else {
            setToast(
              <ToastMessage
                open
                options={{
                  type: 'error',
                  title: 'Incorrect Old Password',
                }}
                onClose={() => setToast(<></>)}
              />,
            );
          }
        }
      },
      resturls.checkOldPasswordForUser,
      {
        user_name: userDetails.email,
        old_password: md5(value.old_password),
      },
      'POST',
    );
  };

  const downloadErrorsHandler = () => {
    const excelHeader = [
      { value: t('s_no'), fontWeight: 'bold' },
      { value: t('name'), fontWeight: 'bold' },
      { value: t('password'), fontWeight: 'bold' },
      { value: t('errors_in_excel'), fontWeight: 'bold' },
    ];

    const excelBody = resetPasswordUserUploadData.error.map((ele, i) => ([
      { type: Number, value: i + 1 },
      { type: String, value: ele.name },
      { type: String, value: ele.password },
      { type: String, value: ele.errors },
    ]));

    writeXlsxFile(
      [excelHeader, ...excelBody],
      {
        columns: [{ width: 7 }, { width: 35 }, { width: 35 }, { width: 55 }, { width: 25 }],
        fileName: `${t('reset_password_error_list')}.xlsx`,
      },
    );
  };

  useComponentDidMount(() => {
    if (role_name === 'participant') {
      setState((prev) => ({
        ...prev,
        isParticipant: true,
      }));
    }
    createFile();
  });

  return (
    <div>
      {toast}
      <HRLModal
        size="tiny"
        open
        className={styles.createResetPasswordModal}
        icon={false}
        modalHeaderContent={isParticipant ? t('reset_password') : t('reset_password_for_participant')}
        headerPosition="center"
      >
        <HRLFormik
          initialValues={
            isParticipant ? {
              user_name: userDetails.email,
              password: '',
              re_enter_password: '',
              input_file_content: '',
            } : {
              old_password: '',
              password: '',
              re_enter_password: '',
            }
          }
          onSubmit={isParticipant ? checkOldPasswordForUser : valueSubmit}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
            setFieldError,
            handleFileUpload,
          }) => {
            const handlePassword = () => {
              setShowPassword(!showPassword);
            };

            const handleOldPassword = () => {
              setOldShowPassword(!showOldPassword);
            };

            const handleReEnterPassword = () => {
              setShowReEnterShowPassword(!showReEnterPassword);
            };

            const verifyWhetherUserIsParticipant = () => {
              const { user_name } = values;
              user_name_ref.current = values.user_name;
              GlobalService.generalSelect(
                (respdata) => {
                  const { estatus, emessage, data } = respdata;
                  if (estatus && emessage) {
                    if (data.is_valid) {
                      if (data.role === 'participant') {
                        user_role_ref.current = data.role;
                      } else {
                        user_name_ref.current = '';
                        user_role_ref.current = '';
                        setFieldError('user_name',
                          t('not_a_participant'));
                      }
                    } else {
                      setFieldError('user_name', t('user_does_not_exist'));
                      user_name_ref.current = '';
                      user_role_ref.current = '';
                    }
                    updateRender((prev) => prev + 1);
                  }
                },
                `${resturls.checkUserRole}?email=${user_name || ''}`, {},
                'GET',
              );
            };

            const handleClose = () => {
              if (passwordForSingleUser.is_valid) {
                getLoginUserLoginPage();
                resetForm();
                user_name_ref.current = '';
                user_role_ref.current = '';
                updateRender((prev) => prev + 1);
                setIsMessageModalOpen(false);
                setFieldValue('user_name', '');
              } else {
                setIsMessageModalOpen(false);
              }
            };

            const handleMultipleModalClose = () => {
              if (isResetPasswordMultipleUserData.is_valid) {
                resetForm();
                setIsSuccesMessageModalOpen(false);
                setFieldValue('user_name', '');
              } else {
                setIsSuccesMessageModalOpen(false);
              }
            };

            return (
              <>
                <Form
                  onSubmit={handleSubmit}
                  className={styles.ResetPasswordModal}
                >
                  {
                    !isParticipant ? (
                      <div className={styles.usernameWithVerification}>
                        <Form.Field
                          name="user_name"
                          disabled={values?.input_file_content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.user_name}
                          width={10}
                          control={Input}
                          label={t('user_name')}
                          error={touched.user_name && errors.user_name}
                        />
                        <div>
                          <Button
                            type="button"
                            disabled={!values.user_name}
                            onClick={() => verifyWhetherUserIsParticipant()}
                          >
                            {t('verify')}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Form.Field
                        name="old_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.old_password}
                        width={16}
                        type={showOldPassword ? 'text' : 'password'}
                        icon={(
                          <Icon
                            name={showOldPassword ? 'eye' : 'eye slash'}
                            link
                            onClick={() => handleOldPassword()}
                          />
                        )}
                        iconPosition="right"
                        control={Input}
                        label={t('old_password')}
                        error={touched.old_password && errors.old_password}
                      />
                    )
                  }

                  <Form.Field
                    name="password"
                    onChange={handleChange}
                    disabled={((!isParticipant) && ((user_name_ref.current !== values.user_name) || (user_role_ref.current !== 'participant'))) || values.input_file_content}
                    onBlur={handleBlur}
                    value={values.password}
                    width={16}
                    type={showPassword ? 'text' : 'password'}
                    icon={(
                      <Icon
                        name={showPassword ? 'eye' : 'eye slash'}
                        link
                        onClick={() => handlePassword()}
                      />
                    )}
                    iconPosition="right"
                    control={Input}
                    label={t('password')}
                    error={touched.password && errors.password}
                  />
                  <Form.Field
                    name="re_enter_password"
                    onChange={handleChange}
                    disabled={((!isParticipant) && ((user_name_ref.current !== values.user_name) || (user_role_ref.current !== 'participant'))) || values.input_file_content}
                    onBlur={handleBlur}
                    value={values.re_enter_password}
                    width={16}
                    type={showReEnterPassword ? 'text' : 'password'}
                    icon={(
                      <Icon
                        name={showReEnterPassword ? 'eye' : 'eye slash'}
                        link
                        onClick={() => handleReEnterPassword()}
                      />
                    )}
                    iconPosition="right"
                    control={Input}
                    label={t('re_enter_password')}
                    error={touched.re_enter_password && errors.re_enter_password}
                  />
                  {!isParticipant && (
                    <>
                      <div className={styles.ResetPasswordOr} fontas="alpha">{t('or')}</div>
                      <Form.Field>
                        <div>
                          <span>
                            <p>{t('upload_csv_file')}</p>
                          </span>
                          <div className={`${styles.ResetPasswordModalForm} ${resetPasswordUserUploadData.is_valid === false ? styles.ErrorBackground : ''}`}>
                            <span>
                              {(values.input_file_content?.name || values.input_file_name) ? values.input_file_content?.name || values.input_file_name : t('no_file_choosen')}
                            </span>
                            <span>
                              <Button basic type="button" content={t('choose_file')} onClick={() => inputFileRef.current.click()} />
                              {touched.input_file_content && errors.input_file_content && (
                                <Label basic color="red" pointing="left">
                                  {errors.input_file_content}
                                </Label>
                              )}
                              <input
                                name="input_file_content"
                                type="file"
                                accept=".csv"
                                ref={inputFileRef}
                                onChange={(event) => {
                                  handleFileUpload(event);
                                  setResetPasswordUserUploadData((prev) => (
                                    { ...prev, is_valid: true }));
                                }}
                                style={{ display: 'none' }}
                              />
                              <Icon
                                name="close"
                                onClick={() => {
                                  setFieldValue('input_file_content', '');
                                  setResetPasswordUserUploadData('');
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        {resetPasswordUserUploadData.is_valid === false && (
                          <div className={styles.mutliUserResetPasswordError}>
                            <Icon name="warning circle" color="red" />
                            <p className={styles.FileErrorMessage}>{t('error_in_file')}</p>
                          </div>
                        )}
                        <div>
                          {`${t('template')}: `}
                          <a href={sampleTemplateFile} download="bulkUploadResetPasswordTemplate.csv">
                            {t('download_template')}
                          </a>
                        </div>
                      </Form.Field>
                    </>
                  )}
                  {resetPasswordUserUploadData.is_valid === false
                    && resetPasswordUserUploadData.error
                    ? (
                      <Button
                        icon="file excel"
                        fontas="alpha"
                        type="button"
                        content={t('download_file')}
                        onClick={downloadErrorsHandler}
                        className={styles.createResetButton}
                      />
                    ) : (
                      <Button
                        fontas="alpha"
                        disabled={((!isParticipant) && ((user_name_ref.current !== values.user_name) || (user_role_ref.current !== 'participant'))) && !values.input_file_content}
                        type={resetPasswordUserUploadData.is_valid === false ? 'button' : 'submit'}
                        content={t('save')}
                        className={styles.createResetButton}
                      />
                    )}
                </Form>
                <HRLModal
                  className={styles.messageModalOpen}
                  size="tiny"
                  open={isMessageModalOpen}
                  setOpen={() => setIsMessageModalOpen(false)}
                >
                  {(passwordForSingleUser.is_valid) ? (
                    <>
                      <Icon color="green" name="check circle outline" size="huge" />
                      <p>{t(passwordForSingleUser.error)}</p>
                    </>
                  ) : (
                    <>
                      <Icon color="red" name="times circle outline" size="huge" />
                      <p>{t(passwordForSingleUser.error)}</p>
                    </>
                  )}
                  <Button onClick={() => handleClose()}>
                    {t('close')}
                  </Button>
                </HRLModal>
                {isResetPasswordMultipleUserData.is_valid && (
                  <HRLModal
                    className={styles.messageModalOpen}
                    size="tiny"
                    open={isSuccesMessageModalOpen}
                    setOpen={() => setIsSuccesMessageModalOpen(false)}
                  >
                    <Icon color="green" name="check circle outline" size="huge" />
                    <p>{t(isResetPasswordMultipleUserData.error)}</p>
                    <Button
                      onClick={() => handleMultipleModalClose()}
                    >
                      {t('close')}
                    </Button>
                  </HRLModal>
                )}
              </>
            );
          }}
        </HRLFormik>
      </HRLModal>
    </div>
  );
};

export default withTranslation('recruiter')(ResetPasswordModal);
