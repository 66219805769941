import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import {
  Button, Dropdown, Grid,
} from 'semantic-ui-react';
import UserInfo from '@models/UserInfo';
import writeXlsxFile from 'write-excel-file';
import useComponentDidMount from '@utils/useComponentDidMount';
import { Picky } from 'react-picky';
import InstanceList from './InstanceList';
import styles from './less/CreateInstance.module.less';

function InstanceCreationPage(props) {
  const { t } = props;
  const roleName = UserInfo.getRole();
  const segmentType = roleName === 'program_manager';
  const [instanceList, setInstanceList] = useState([]);
  const [state, setState] = useState({
    teamList: [],
    platformList: [],
    vmCourseId: {},
    vmCourseList: [],
    vmTeamId: '',
    vmTeamList: [],
    showVmList: false,
    vmTeamIdList: [],
  });
  const [activeState, setActiveState] = useState('available_virtual_machine');

  const {
    vmCourseId,
    vmCourseList,
    vmTeamId,
    vmTeamList,
    showVmList,
    vmTeamIdList,
  } = state;

  const options = [
    {
      key: 'terminate',
      text: 'Terminate',
      value: 'terminate',
    },
    {
      key: 'activate',
      text: 'Activate',
      value: 'activate',
    },
    {
      key: 'refresh',
      text: 'Refresh State',
      value: 'refresh_state',
    },
    {
      key: 'stop',
      text: 'Stop',
      value: 'stop',
    },
  ];

  const navOptions = [
    { key: 1, value: 'available_virtual_machine', text: t('Available Virtual Machine') },
    { key: 2, value: 'terminated_virtual_machine', text: t('Terminated Virtual Machine') },
    { key: 3, value: 'manage_platform', text: t('Manage Platform') },
    { key: 4, value: 'user_allocation', text: t('User Allocation') },
  ];

  const obtainVmPlatformTypeList = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            platformList: data.vm_platform_type_list.map(
              (ele) => ({ key: ele.id, value: ele.id, text: ele.name }),
            ),
          }));
        }
      },
      resturls.obtainVmPlatformTypeList,
      {},
      'GET',
    );
  };

  const obtainVmCourses = () => {
    const url = segmentType
      ? resturls.obtainVmCourses
      : resturls.obtainVmAssessmentList;
    const obtainListType = segmentType
      ? 'vm_course_list'
      : 'vm_assessment_list';
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState((prev) => ({
            ...prev,
            vmCourseList: data[obtainListType].map(
              (ele) => ({ key: ele.id, value: ele.id, text: ele.name }),
            ),
          }));
        }
      },
      url,
      {},
      'GET',
    );
  };

  const obtainCourseTeamsForVm = (id) => {
    const url = segmentType
      ? resturls.obtainCourseTeamsForVm
      : resturls.obtainAssessmentTeamsForVm;
    if (id) {
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage, data } = respdata;
          if (estatus && emessage) {
            setState((prev) => ({
              ...prev,
              vmTeamList: data.team_list.map((opt) => ({
                key: opt.id, value: opt.id, text: opt.name,
              })),
            }));
          }
        },
        `${url}?${segmentType ? `course_id=${id}` : `hrl_session_id=${id}`}`,
        {},
        'GET',
      );
    }
  };

  const obtainAssignedUserInstanceList = (action, id) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setInstanceList(data.instance_list);
          setState((prev) => ({
            ...prev,
            showVmList: true,
            vmTeamId: id,
          }));
        }
      },
      `${resturls.obtainAssignedUserInstanceList}?team_id=${id && id ? id : vmTeamId}&action=${action}`,
      {},
      'GET',
    );
  };

  useComponentDidMount(() => {
    obtainVmCourses();
    obtainVmPlatformTypeList();
  });

  const handleVmDetailsExport = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { team_wise_user_instance_dict } = respdata;
        if (team_wise_user_instance_dict) {
          const allsheets = [];

          Object.keys(team_wise_user_instance_dict).forEach((team) => {
            const sheet = [];
            const excelHeader = [
              { value: 'Username', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Email', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'VDI Instance ID', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Instance ID', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Instance Name', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Instance Type', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Image Name', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Min', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Hours:Min', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Hours', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'USD/HR', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Cost', fontWeight: 'bold', backgroundColor: '#FFFF00' },
              { value: 'Duration', fontWeight: 'bold', backgroundColor: '#FFFF00' },
            ];
            sheet.push([...excelHeader]);
            team_wise_user_instance_dict[team].forEach((user) => {
              const userData = [];
              userData.push(
                { type: String, value: user.username },
                { type: String, value: user.email },
                { type: String, value: user.vdi_instance_id },
                { type: String, value: user.instance_id },
                { type: String, value: user.instance_name },
                { type: String, value: user.instance_type },
                { type: String, value: user.image_name },
                { type: Number, value: Number(user.min) },
                { type: String, value: user['hours:min'] },
                { type: String, value: user.hours },
                { type: Number, value: user['usd/hr'] || 0 },
                { type: Number, value: user.cost || 0 },
                { type: String, value: user.duration },
              );
              sheet.push(userData);
            });
            allsheets.push(sheet);
          });
          const sheetNames = Object.keys(team_wise_user_instance_dict);
          writeXlsxFile(allsheets, {
            sheets: sheetNames,
            fileName: 'VM_Details_MultiTeam.xlsx',
          });
        }
      },
      resturls.obtainBatchWiseDetailsForVm,
      { team_list: vmTeamIdList.map((ele) => (ele.value)) },
      'POST',
    );
  };

  const validOptions = state.vmTeamList.filter((opt) => opt && opt.value && opt.text);

  const handleInputChange = (data) => {
    setState((prevState) => ({
      ...prevState,
      vmTeamIdList: data,
    }));
  };

  return (
    <div className={styles.virtualMachine}>
      <div className={styles.virtualHeader}>
        <div fontas="alpha header">
          {t('vm_management')}
          <div className={styles.horizontalBar} />
        </div>
      </div>
      <div className={styles.vmteamandcourse}>
        {vmCourseList.length > 0 && (
          <>
            <div className={styles.selectedOptions}>
              <span fontas="alpha">{t(segmentType ? 'select_course' : 'select_assessmant')}</span>
              <Dropdown
                search
                width={16}
                selection
                options={vmCourseList.length > 0 ? vmCourseList : []}
                value={vmCourseId}
                onChange={(e, { value }) => {
                  setState((prevState) => ({ ...prevState, vmCourseId: value }));
                  obtainCourseTeamsForVm(value);
                }}
              />
            </div>
            <div className={styles.selectedOptions}>
              <span fontas="alpha">{t('select_team')}</span>
              <Dropdown
                width={16}
                search
                disabled={!vmTeamList.length > 0}
                selection
                options={vmTeamList.length > 0 ? vmTeamList : []}
                value={vmTeamId}
                onChange={(e, { value }) => {
                  setState((prevState) => ({ ...prevState, vmTeamId: prevState.vmTeamId, value }));
                  obtainAssignedUserInstanceList('', value);
                }}
              />
            </div>
          </>
        )}
      </div>
      {showVmList && (
        <>
          <Grid>
            <Grid.Row className={styles.showInstancePanel}>
              <Grid.Column width={8} className={styles.vmLeftHeader}>
                <div className={styles.VMSettingNav} fontas="alpha">
                  <div>
                    {React.Children.toArray(navOptions.map((ele) => (
                      <div>
                        <span
                          onClick={
                            () => {
                              setActiveState(ele.value);
                              obtainAssignedUserInstanceList(ele.value, vmTeamId);
                            }
                          }
                          className={activeState === ele.value ? styles.activeHeader : null}
                          role="button"
                          tabIndex="-1"
                          onKeyDown={null}
                        >
                          {t(ele.text)}
                        </span>
                        <div
                          className={
                            activeState === ele.value ? styles.activeHeaderBottom : null
                          }
                        />
                      </div>
                    )))}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={styles.vmRightHeader}>
                <Button disabled={!vmTeamIdList?.length > 0} onClick={handleVmDetailsExport}>{t('download_report')}</Button>
                <div className={styles.vmXlReportBtn}>
                  <Picky
                    labelKey="text"
                    valueKey="value"
                    multiple
                    includeFilter
                    includeSelectAll
                    options={validOptions}
                    value={vmTeamIdList?.length > 0 ? vmTeamIdList : []}
                    onChange={(data) => handleInputChange(data)}
                    placeholder="---Select team---"
                    search
                    fluid
                    clearable
                    required
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <InstanceList
            instanceDetailsList={instanceList}
            obtainAssignedUserInstanceList={obtainAssignedUserInstanceList}
            options={options}
            vmTeamId={vmTeamId}
            activeState={activeState}
          />
        </>
      )}
    </div>
  );
}

export default withTranslation('recruiter')(InstanceCreationPage);
