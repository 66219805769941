import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Styles from './HRLyticsElements.module.less';

function HRLyticsElements(props) {
  const { t } = props;
  return (
    <Container style={{ marginTop: '60px', marginBottom: '60px' }}>
      <h1 style={{ textAlign: 'center' }}>HRLytics Style Elements Test</h1>
      <div className={Styles.test}>
        <h2>{t('new color from corelytics')}</h2>
      </div>

      <h3>Large</h3>
      <Button size="large" content="Default" />
      <Button size="large" content="Primary" primary />
      <Button size="large" content="Secondary" secondary />
      <Button size="large" basic primary content="Basic" />
      <Button size="large" basic secondary content="Basic" />
      <br />
      <h3>Medium</h3>
      <Button content="Default" />
      <Button content="Primary" primary />
      <Button content="Secondary" secondary />
      <Button basic primary content="Basic" />
      <Button basic secondary content="Basic" />
      <br />
      <h3>Small</h3>
      <Button size="small" content="Default" />
      <Button size="small" content="Primary" primary />
      <Button size="small" content="Secondary" secondary />
      <Button size="small" basic primary content="Basic" />
      <Button size="small" basic secondary content="Basic" />
      <br />
      <h3>Button with icon</h3>
      <Button icon="stop" size="small" content="stop" />
      <Button icon="stop" size="small" content="stop" primary />
      <hr />

      {/* font size  */}
      <br />
      <div fontas="alpha header">alpha header 30</div>
      <br />
      <div fontas="beta header">beta header 24</div>
      <br />
      <div fontas="gamma header">gamma header 20</div>
      <br />
      <div fontas="alpha">alpha 20</div>
      <br />
      <div fontas="beta">beta 18</div>
      <br />
      <div fontas="gamma">gamma 16</div>
      <br />
    </Container>
  );
}

export default withTranslation('common')(HRLyticsElements);
