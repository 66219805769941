import React, {
  useEffect, useMemo, useReducer, useRef, useState, useCallback,
} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Divider, Label, Popup,
  Icon, Image, Loader,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import Scrollbars from 'react-custom-scrollbars';
import useComponentWillUnmount from '@utils/useComponentWillUnmount';
import useComponentDidMount from '@utils/useComponentDidMount';
import NoNotiFicationImg from '@images/icons/no_notification.webp';
import NotificationListContainer from './NotificationListContainer';
import { getDestinationPath } from './NotificationDestinationURL';
import NotificationSSE from './NotificationSSE';
import styles from './notification.module.less';

const NotificationPanel = ({ t }) => {
  const history = useHistory();

  const notificationReducer = (state, action) => {
    const { type, data } = action;
    switch (type) {
      case 'TO_SHOW':
        return {
          ...state,
          toShow: data,
        };
      case 'COUNT_UPDATE':
        return {
          ...state,
          unReadCount: data,
        };
      case 'OLD_NOTIFICATION_LIST':
        return {
          ...state,
          notificationList: [...state.notificationList, ...data] || [],
        };
      case 'NEW_NOTIFICATION_LIST':
        return {
          ...state,
          unReadCount: state.unReadCount + data.length,
          notificationList: [...data, ...state.notificationList] || [],
        };
      case 'NOTIFICATION_LIST':
        return {
          ...state,
          notificationList: [...data] || [],
        };
      case 'UPDATE_ALL':
        return {
          ...state,
          ...data,
        };
      default:
        throw new Error();
    }
  };

  const [notificationInitObj, dispatch] = useReducer(notificationReducer, {
    toShow: false,
    notificationList: [],
    unReadCount: 0,
  });

  const [getNotificationLoading, setNotificationLoading] = useState(false);

  const notificationCloseRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const mesgReference = useRef({
    init_total_messages: 0,
    new_message_through_sse: 0,
  });
  const newMesgsIdList = useRef([]);
  const iconRef = useRef(null);
  const scrollLimit = useRef({ upper: 1, lower: 10 });

  const { init_total_messages } = mesgReference.current;

  const { toShow, notificationList = [], unReadCount } = notificationInitObj;

  const updateMessageStatus = (type, notification_id_list) => {
    if (type === 'READ') {
      dispatch(({ type: 'COUNT_UPDATE', data: unReadCount - notification_id_list.length }));
    }
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { is_updated } } = respdata;
        if (estatus && emessage && is_updated) {
          const tempNotificationList = notificationList;
          const msgStatusObj = {
            UNREAD: 0,
            READ: 1,
            VIEWED: 2,
          };
          tempNotificationList.forEach((obj) => {
            if (notification_id_list.includes(obj.id)) {
              Object.assign(obj, { is_read: msgStatusObj[type] });
            }
          });
          dispatch(({ type: 'NOTIFICATION_LIST', data: tempNotificationList }));
          if (type === 'VIEWED') {
            const togetObj = notificationList.find(({ id }) => notification_id_list[0] === id);
            if (togetObj) {
              dispatch(({ type: 'TO_SHOW', data: false }));
              const { url = '', state = {} } = getDestinationPath(togetObj);
              history.push(url, { ...state });
            }
          }
        }
      }, resturls.updateNotificationStatus,
      { type, notification_id_list }, 'POST',
    );
  };

  const updateReadMesgStatus = () => {
    const unreadMesgIdList = notificationList?.filter(({ is_read }) => is_read === 0);
    newMesgsIdList.current = [...newMesgsIdList.current, ...unreadMesgIdList.map(({ id }) => id)];
    if (unreadMesgIdList.length) updateMessageStatus('READ', unreadMesgIdList.map(({ id }) => id));
  };

  const getNotifications = (type) => {
    const { upper = 1, lower = 10 } = scrollLimit.current;
    const { new_message_through_sse: count } = mesgReference.current;
    const [updated_upper, updated_lower] = [upper + count, lower + count];
    setNotificationLoading(true);
    GlobalService.generalSelect(
      (respdata) => {
        const {
          estatus, emessage, data:
          { notification_list, unread_count, total_count },
        } = respdata;
        if (estatus && emessage) {
          let updateData = null;
          if (type === 'all') {
            updateData = {
              notificationList: notification_list,
              unReadCount: unread_count,
            };
            mesgReference.current.init_total_messages = total_count;
          } else {
            updateData = notification_list;
          }
          setTimeout(() => {
            setNotificationLoading(false);
            dispatch(({ type: type === 'all' ? 'UPDATE_ALL' : 'OLD_NOTIFICATION_LIST', data: updateData }));
          }, 1500);
        }
      }, `${resturls.initialUserNotification}?upper_limit=${updated_upper}&lower_limit=${updated_lower}`,
      {}, 'GET',
    );
  };

  const handleClickOutside = useCallback((event) => {
    if ((iconRef.current && notificationCloseRef.current)
      && !notificationCloseRef.current.contains(event.target)
      && !iconRef.current.contains(event.target)) {
      dispatch({ type: 'TO_SHOW', data: false });
    }
  }, [toShow]);

  useComponentDidMount(() => {
    getNotifications('all');
    document.addEventListener('mousedown', handleClickOutside);
  });

  useComponentWillUnmount(() => {
    document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (toShow && notificationList.length) updateReadMesgStatus();
    else newMesgsIdList.current = [];
  }, [toShow, notificationList.length]);

  const handleScroll = ({ top }) => {
    if (top === 1
      && notificationCloseRef.current
      && (scrollLimit.current.lower + 10) < init_total_messages
      && !getNotificationLoading) {
      const { upper, lower } = scrollLimit.current;
      scrollLimit.current = { upper: upper + 10, lower: lower + 10 };
      getNotifications();
    }
  };

  const getContent = useMemo(() => (
    <Scrollbars
      autoHide
      onScrollFrame={handleScroll}
      style={{ margin: 0, height: '40em', width: '30em' }}
      universal
      ref={scrollbarsRef}
    >
      <div className={styles.notificationContentPanel} ref={notificationCloseRef}>
        {notificationList.length ? (
          <>
            <div className={styles.stickyHeader}>
              <h3>{t('notifications')}</h3>
              <Divider />
            </div>
            <NotificationListContainer
              notificationList={notificationList}
              dispatch={dispatch}
              newMesgsIdList={newMesgsIdList}
              updateMessageStatus={updateMessageStatus}
            />
            <Loader active={getNotificationLoading} inline="centered" size="small" />
          </>
        ) : (
          <Image src={NoNotiFicationImg} />
        )}
      </div>
    </Scrollbars>
  ), [notificationList, getNotificationLoading, toShow]);

  return (
    <>
      <NotificationSSE mesgReference={mesgReference} dispatch={dispatch} />
      <Popup
        trigger={(
          <div
            className={styles.notificationIconWrapper}
            ref={iconRef}
          >
            <Icon
              name={toShow ? 'bell' : 'bell outline'}
              onClick={() => dispatch({ type: 'TO_SHOW', data: !toShow })}
              size="large"
            />
            {unReadCount > 0 && (
              <Label
                color="red"
                circular
                size="mini"
                className={styles.countLabel}
              >
                {unReadCount}
              </Label>
            )}
          </div>
        )}
        content={getContent}
        position="bottom right"
        open={toShow}
        basic
        onOpen={() => {
          if (scrollbarsRef.current) {
            scrollbarsRef.current.scrollTop(0);
          }
        }}
        className={styles.notificationPopup}
      />
    </>
  );
};

export default withTranslation('common')(NotificationPanel);
