import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useComponentDidMount, ToastMessage } from '@hrlytics/corelytics';
import { Divider, Dropdown, Grid } from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import YesOrNoQuestionType from './questionType/YesOrNoQuestionType';
import styles from './less/questionnaireShowPage.module.less';
import RadioButtonQuestionType from './questionType/RadioButtonQuestionType';

const AddQuestionnaireQuestion = (props) => {
  const {
    t, questionnaireId, editQuestionValue, obtainQuestionnaireList,
    updateSelectedTab, isAttemptedQuestion,
  } = props;

  const [state, setState] = useState({
    questionType: [],
    selectedTypeValue: '',
  });
  const [toast, setToast] = useState((<></>));
  const [choiceTableData, setChoiceTableData] = useState([]);

  const { questionType, selectedTypeValue } = state;

  const selectedTypeObj = questionType?.find((e) => e.value === selectedTypeValue) || {};

  const componentObj = {
    Range: null,
    'Rate(scale 1..5)': null,
    'Check Box': null,
    Date: null,
    'Yes/No': YesOrNoQuestionType,
    SubQuestion: null,
    'Radio Buttons': RadioButtonQuestionType,
    'Dropdown Box': null,
    'Essay Box': null,
    'Text Box': null,
    Numeric: null,
    Label: null,
  };

  const obtainQuestionnaireQuestionTypes = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { questionnaire_question_type_list } } = respData;
      if (estatus && emessage) {
        setState((prev) => ({
          ...prev,
          questionType: questionnaire_question_type_list?.map((ele, i) => ({
            key: i, text: ele.name, value: ele.id, short_name: ele.short_name,
          })),
        }));
      }
    }, resturls.obtainQuestionnaireQuestionTypes, {}, 'GET');
  };

  const createAndEditQuestionnaireQuestion = (values) => {
    if (
      (
        selectedTypeObj.text || editQuestionValue?.type_name
      ) === 'Radio Buttons' && choiceTableData.length === 0) {
      setToast(
        <ToastMessage
          open
          toastType="HRL"
          options={{
            type: 'warning',
            title: t('please_choose_more_than_one_choice'),
            time: 2000,
          }}
        />,
      );
    } else {
      GlobalService.generalSelect((respData) => {
        const { estatus, emessage, data } = respData;
        if (estatus && emessage) {
          setToast(
            <ToastMessage
              open
              toastType="HRL"
              options={{
                type: 'success',
                title: t(`question_${data.status}`),
                time: 2000,
              }}
            />,
          );
          setTimeout(() => {
            updateSelectedTab('question_list');
            obtainQuestionnaireList();
          }, 2000);
        }
      }, resturls.createAndEditQuestionnaireQuestion, {
        questionnaire_question_obj: values,
        type: selectedTypeObj.short_name || editQuestionValue?.type,
        questionnaire_u_id: questionnaireId || '',
        choice_list: choiceTableData,
        questionnaire_question_id: editQuestionValue?.id,
      }, 'POST');
    }
  };

  useComponentDidMount(() => {
    obtainQuestionnaireQuestionTypes();
  });

  const Component = (
    componentObj[selectedTypeObj.text] || componentObj[editQuestionValue?.type_name]
  ) || null;

  return (
    <div className={styles.addQuestionShowPage}>
      {toast}
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <span fontas="alpha">
              {t('type')}
            </span>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={12}>
            <Dropdown
              selection
              disabled={editQuestionValue !== null}
              options={questionType}
              placeholder={t('select_type')}
              value={editQuestionValue?.type_id}
              onChange={(e, { value }) => {
                setState((prev) => ({
                  ...prev,
                  selectedTypeValue: value,
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {Component && (
        <>
          <h1>
            {t('create_or_edit_question')}
          </h1>
          <Divider />
          <Component
            createAndEditQuestionnaireQuestion={createAndEditQuestionnaireQuestion}
            editQuestionValue={editQuestionValue}
            choiceTableData={choiceTableData}
            setChoiceTableData={setChoiceTableData}
            isAttemptedQuestion={isAttemptedQuestion}
          />
        </>
      )}
    </div>
  );
};
export default withTranslation('recruiter')(AddQuestionnaireQuestion);
