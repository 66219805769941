import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Table,
  Icon,
  // Label,
} from 'semantic-ui-react';
import moment from 'moment';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import DatePicker from 'react-datepicker';
import { TableLoader } from '@hrlytics/corelytics'; 
import styles from './UserWiseCourseScreen.module.less';

const UserWiseDeepTrack = (props) => {
  const {
    t, sessionsandTeamtimingDetail, isLoading, setState,
    hrlsessionid, teamId,
  } = props;

  const [updatedSessionDataValue, setUpdatedSessionDataValue] = useState({});

  const enableEditMode = (hrl_session_id) => {
    setState((prevState) => ({
      ...prevState,
      hrlsessionid: hrl_session_id,
    }));
  };

  const updateSessionsandTeamtimingDetailForAdmin = (hrl_session_id, i, idx) => {
    const updatedData = {
      ...updatedSessionDataValue,
      module_list: updatedSessionDataValue?.module_list?.map((module, j) => {
        if (j === idx) {
          return {
            ...module,
            session_list: module.session_list.map((session, k) => {
              if (k === i) {
                return {
                  end_time: session.end_time,
                  start_time: session.start_time,
                  is_visible: session.is_visible,
                };
              }
              return session;
            }),
          };
        }
        return module;
      }),
    };

    const sessionData = updatedData.module_list[idx]?.session_list[i];

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage && data) {
        enableEditMode();
      }
    }, resturls.updateSessionsandTeamtimingDetailForAdmin, {
      update_obj: sessionData,
      hrl_session_id,
      team_id: teamId,
    }, 'POST');
  };

  const onChangeHandler = (i, value, key, idx) => {
    const updatedList = [...sessionsandTeamtimingDetail.module_list];
    updatedList[idx].session_list = updatedList[idx].session_list.map((session, j) => {
      if (j === i) {
        return {
          ...session,
          [key]: value,
        };
      }
      return session;
    });

    setUpdatedSessionDataValue({
      ...sessionsandTeamtimingDetail,
      module_list: updatedList,
    });
  };

  // const compareDates = (session) => {
  //   console.log(session, 'session');
  //   const startDate = new Date(session.start_time).getTime();
  //   const endDate = new Date(session.end_time).getTime();
  //   console.log(startDate, endDate, 'startDate,endDate');

  //   if (startDate < endDate) return 1;
  //   if (startDate > endDate) return -1;
  //   return 0;
  // };
  // console.log(compareDates, 'compareDates')
  return (
    <>
      <div className={styles.UserWiseDeepTrackTableDiv}>
        <Table singleLine celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('s_no')}</Table.HeaderCell>
              <Table.HeaderCell>{t('module')}</Table.HeaderCell>
              <Table.HeaderCell>{t('session')}</Table.HeaderCell>
              <Table.HeaderCell>{t('team_details')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {isLoading ? <TableLoader is_table_header={false} /> : (
            <Table.Body>
              {sessionsandTeamtimingDetail?.module_list?.length > 0 ? (
                <>
                  {sessionsandTeamtimingDetail?.module_list?.map((module, idx) => (
                    module.session_list?.map((session_list, i) => (
                      <Table.Row key={session_list.id}>
                        {i === 0 && (
                          <>
                            <Table.Cell rowSpan={module.session_list.length}>
                              {idx + 1}
                            </Table.Cell>
                            <Table.Cell rowSpan={module.session_list.length}>
                              {module.modulename}
                            </Table.Cell>
                          </>
                        )}
                        <Table.Cell>
                          <span>{session_list.hrl_session_name}</span>
                        </Table.Cell>
                        <Table.Cell>
                          <Table singleLine celled unstackable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>{t('team')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('start_time')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('end_time')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('visibile')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('action')}</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              <Table.Cell><span>{session_list.team_name}</span></Table.Cell>
                              <Table.Cell>
                                {session_list.hrl_session_id === hrlsessionid ? (
                                  <div className={styles.field}>
                                    <DatePicker
                                      fontas="beta"
                                      showTimeSelect
                                      timeIntervals={15}
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      selected={new Date(session_list.start_time)}
                                      onChange={(date) => onChangeHandler(i, date, 'start_time', idx)}
                                    />
                                  </div>
                                ) : (
                                  <span>{moment(session_list.start_time).format('DD-MM-YYYY, h:mm A')}</span>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {session_list.hrl_session_id === hrlsessionid ? (
                                  <div className={styles.field}>
                                    <DatePicker
                                      fontas="beta"
                                      showTimeSelect
                                      timeIntervals={15}
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      selected={new Date(session_list.end_time)}
                                      onChange={(date) => onChangeHandler(i, date, 'end_time', idx)}
                                    />
                                  </div>
                                ) : (
                                  <span>{moment(session_list.end_time).format('DD-MM-YYYY, h:mm A')}</span>
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {session_list.hrl_session_id === hrlsessionid ? (
                                  <Icon
                                    size="large"
                                    name={session_list.is_visible ? 'eye' : 'hide'}
                                    onClick={() => onChangeHandler(i, !session_list.is_visible, 'is_visible', idx)}
                                  />
                                ) : (
                                  <>
                                    {session_list.is_visible ? (
                                      <Icon size="large" name="eye" />
                                    ) : (
                                      <Icon size="large" name="hide" />
                                    )}
                                  </>

                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {session_list.hrl_session_id === hrlsessionid ? (
                                  <Icon
                                    size="large"
                                    name="save"
                                    onClick={() => updateSessionsandTeamtimingDetailForAdmin(
                                      session_list.hrl_session_id, i, idx,
                                    )}
                                  />
                                ) : (
                                  <Icon size="large" name="pencil alternate" onClick={() => enableEditMode(session_list.hrl_session_id)} />
                                )}
                              </Table.Cell>
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ))}
                </>
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} fontas="alpha" textAlign="center">
                    {t('no_data_found')}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          )}
        </Table>
      </div>
    </>

  );
};

export default withTranslation('admin')(UserWiseDeepTrack);
