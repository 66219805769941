import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Table } from 'semantic-ui-react';
import { restbaseurl } from '@utils/constants';
import UserInfo from '@models/UserInfo';
import { resturls } from '@utils/apiurls';
import styles from './supportManager.module.less';

const MentorDayWiseReport = (props) => {
  const {
    t, mentorDailyWiseReportData, submitObj,
    initialValues,
  } = props;
  const orgId = UserInfo.getOrgId();

  return (
    <div className={styles.mentorDayWiseReport}>
      <div>
        <div>{t('mentor_daywise_report')}</div>
        <a
          href={`${restbaseurl}${resturls.exportCsvAsDayWiseMentorReport}?start_date=${submitObj.start_date}&end_date=${submitObj.end_date}&org_id=${orgId}&to_user_id_list=${JSON.stringify(initialValues?.mentor)}`}
          download
          target="_blank"
          rel="noreferrer"
        >
          <Button>{t('download_report')}</Button>
        </a>
      </div>
      <div>
        <Table celled singleLine unstackable className={styles.mentorTableWrapper}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('s_no')}</Table.HeaderCell>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('username')}</Table.HeaderCell>
              <Table.HeaderCell>{t('email')}</Table.HeaderCell>
              {mentorDailyWiseReportData[0]?.date_wise_report_list
                && Object.keys(mentorDailyWiseReportData[0]?.date_wise_report_list).map((date) => (
                  <Table.HeaderCell>{date}</Table.HeaderCell>
                ))}
              <Table.HeaderCell>{t('total_solved_count')}</Table.HeaderCell>
              <Table.HeaderCell>{t('avg_solved_count')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {mentorDailyWiseReportData?.map((event, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  {event.name}
                </Table.Cell>
                <Table.Cell>{event.username}</Table.Cell>
                <Table.Cell>{event.email}</Table.Cell>
                {Object.values(event.date_wise_report_list).map((value) => (
                  <Table.Cell>{value}</Table.Cell>
                ))}
                <Table.Cell>{event.total_solved_count}</Table.Cell>
                <Table.Cell>{event.avg_solved_count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
export default withTranslation('programManager')(MentorDayWiseReport);
