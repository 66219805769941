import { enQl } from '@hrlytics/quizlytics';
import { enCode } from '@hrlytics/codelytics';
import { enLearn } from '@hrlytics/learnlytics';
import { enCore } from '@hrlytics/corelytics';
import Common from './Common';
import Login from './Login';
import Home from './Home';
import Recruiter from './Recruiter';
import OrgAdmin from './OrgAdmin';
import Participant from './Participant';
import ProgramManager from './ProgramManager';
import Admin from './Admin';
import DiscussionForum from './DiscussionForum';
import Mentor from './Mentor';
import UserProfile from './UserProfile';

const en = {
  common: Common,
  login: Login,
  home: Home,
  user: UserProfile,
  recruiter: Recruiter,
  orgAdmin: OrgAdmin,
  participant: Participant,
  programManager: ProgramManager,
  mentor: Mentor,
  admin: Admin,
  discussionForum: DiscussionForum,
  ...enQl,
  ...enCode,
  ...enLearn,
  ...enCore,
};

export default en;
