const Recruiter = {
  duration: 'Duration',
  level: 'Level',
  weightage: 'Weightage',
  grade: 'Grade',
  pre_packed_assessment: 'Pre-Packed Assessment',
  section: 'Section',
  selected_count: 'Selected count',
  question_type: 'Question Type',
  used_time: 'Used Time',
  within_org_unit: 'Within Org.Unit',
  other_org_unit: 'Other Org.Unit',
  time_in_mins: 'Time (in mins)',
  more_section: 'more sections',
  view_less: 'View Less',
  view_questions: 'View Questions',
  assessments: 'Assessments',
  assessment: 'Assessment',
  search_assessment_using_keywords: 'Search assessment using keywords',
  active: 'Active',
  completed: 'Completed',
  upcoming: 'Upcoming',
  createAssessment: 'Create Assessment',
  skills: 'Skills',
  designation: 'Designation',
  link: 'Link',
  code: 'Code',
  invited: 'Invited',
  attempted: 'Attempted',
  aboveCutoff: 'Above Cutoff',
  report: 'Report',
  inviteCandidate: 'Invite Candidate',
  addCandidate: 'Add Candidate',
  uploadCandidate: 'Upload Candidate',
  select_category: 'Select Category',
  select_skill: 'Select Skill',
  name: 'Name',
  startDate: 'Start Date',
  skill: 'Skill',
  endDate: 'End Date',
  staffing_requirement: 'Staffing Requirement',
  experience: 'Experience',
  prePackedAssessment: 'Pre-Packed Assessment',
  reUseAssessment: 'Re-Use an Assessment',
  customAssessment: 'Custom Assessment',
  create: 'Create',
  required: 'Required',
  radioError: 'Please select any Type',
  partial_use: 'Partial Use',
  use_assessment: 'Use Assessment',
  question_name: 'Question Name',
  type: 'Type',
  concept: 'Concept',
  time: 'Time',
  complexity: 'Complexity',
  add_question: 'Add Questions',
  add_section: 'Add Section',
  remove: 'Remove',
  question: 'Question',
  hrl_problem: 'HRL Problem',
  my_problem: 'My Problem',
  search_by_skills: 'Search by Skills',
  filters: 'Filters',
  clear_all: 'Clear All',
  usage: 'Usage',
  recently_used: 'Recently Used',
  least_used: 'Least Used',
  most_used: 'Most Used',
  mins: 'Mins',
  discard: 'Discard',
  save: 'Save',
  total_questions: 'Total Questions',
  added_questions: 'Added Questions',
  simple: 'Simple',
  medium: 'Medium',
  complex: 'Complex',
  add_this_question: 'Add this Question',
  remove_this_question: 'Remove this Question',
  last_used_date: 'Last Used Date',
  times_used: 'Times Used',
  complexity_level: 'Complexity Level',
  search_by_type: 'Search by Type',
  dashboard: 'Dashboard',
  interactions: 'Interactions',
  min_exp: 'Minimum Experience',
  max_exp: 'Maximum Experience',
  in_years: 'in years',
  must_a_number: 'Must be a number',
  endDate_later_than_startDate: 'End Date must be later than Start Date',
  enter_positive_value: 'It must be a positive value',
  more_than_min: 'Max. Experience must be more than Min. experience',
  more_than_min_salary: 'Max. Salary must be more than Min. Salary',
  filter_by_duration: 'Filter By Duration',
  filter_by_levels: 'Filter By Levels',
  assessment_settings: 'Assessment Settings',
  edit: 'Edit',
  secret_code: 'Secret Code',
  assessment_name: 'Assessment Name',
  hours: 'Hours',
  minutes: 'Minutes',
  role: 'Role',
  max_mins: 'Minutes must be less than 60',
  notification_settings: 'Notification Settings',
  slot: 'Slot',
  status_update_mail: 'Email ID of Users to send mail notification',
  remind_expiry: 'Remind  to Candidate on Test Expiry',
  mail_time_interval: 'Time Interval of Mail Notifications',
  choose_time_interval: 'Choose Time Interval',
  enter_valid_mail_id: 'Enter a valid E-mail Id',
  manual_questions: 'Manual Questions',
  view: 'View',
  delete: 'Delete',
  view_pool: 'View Pool',
  download_pdf: 'Download PDF',
  user: 'User',
  get_attempts: 'Get Attempts',
  s_no: 'S. No.',
  username: 'Username',
  attempt_start: 'Attempt Start',
  attempt_end: 'Attempt End',
  score: 'Score',
  no_attempts_found: 'No Attempts Found',
  enter_in_percentage: 'Enter in %',
  score_settings: 'Score Settings',
  total_score: 'Total Score',
  show_question_score: 'Show Question Score',
  pass_score: 'Pass Score(%)',
  show_final_score: 'Show Final Score',
  section_name: 'Section Name',
  section_weightage: 'Section Weightage(%)',
  section_passscore: 'Section Pass Score(%)',
  penalty: 'Penalty(%)',
  total_weightage_should_be_100: 'Total Weightage should be 100',
  enter_valid_core: 'Enter Valid Score',
  overall_score: 'Overall Score',
  settings: 'Settings',
  bluePrint: 'Blueprint',
  slots: 'Slots',
  'reports/insights': 'Reports / Insights',
  attempt: 'Attempt',
  assessmentSettings: 'Assessment Settings',
  candidateExperienceSettings: 'Candidate Experience Settings',
  scoreSettings: 'Score Settings',
  componentsettings: 'Component Settings',
  notificationSettings: 'Notification Settings',
  candidate_form_example: 'Ex: Full form',
  registration_form: 'Registration Form',
  preview: 'Preview',
  secure_window: 'Secure Window',
  fullScreenViolationCount: 'Full Screen Violation Count',
  full_screen_mode: 'Full Screen Mode',
  custom_instructions: 'Custom Instructions',
  practice_test: 'Practice Test',
  content_for_disclaimer: 'Content for Disclaimer.',
  content_for_instructions: 'Content for Instructions',
  candidate_disclaimer: 'Candidate Disclaimer',
  camera_proctoring: 'Camera Proctoring',
  screen_proctoring_interval_in_sec: 'Screen Proctoring Interval (In Sec)',
  screen_proctoring: 'Screen Proctoring',
  camera_proctoring_interval_in_sec: 'Camera Proctoring Interval (In Sec)',
  customized_form: 'Customized Form',
  personalDetails: 'Personal Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  currentAddress: 'Current Address',
  city: 'City',
  state: 'State',
  pincode: 'Pincode',
  adhaarNumber: 'Adhaar Number',
  educationDetails: 'Education Details',
  school: 'School',
  '10thPercentage': '10th Percentage',
  yearOfCompletion: 'Year of Completion',
  '12thPercentage': '12th Percentage',
  diploma: 'Diploma',
  diplomaPercentage: 'Diploma Percentage',
  underGraduateDegree: 'Under Graduate Degree',
  qualification: 'Qualification',
  branch: 'Branch',
  percentage: 'Percentage',
  collegeName: 'College Name',
  postGraduateDegree: 'Post Graduate Degree',
  integratedCourses: 'Integrated Courses',
  workExperience: 'Work Experience',
  companyName: 'Company Name',
  clientName: 'Client Name',
  general: 'General',
  aboutMe: 'About Me',
  interests: 'Interests',
  languages: 'Languages',
  technicalSkills: 'Technical Skills',
  hobby: 'Hobby',
  resume: 'Resume',
  readyToRelocate: 'Ready To Relocate',
  freeText: 'Free Text',
  fieldName: 'Field Name',
  fieldType: 'Field Type',
  formTitle: 'Form Title',
  select_all: 'Select All',
  add_free_text: 'Add Free Text',
  please_give_email: 'Please give emails..',
  download_resume: 'Download Resume',
  back: 'Back',
  emails: 'Emails',
  no_prepackedassessments_found: 'No PrePacked Assessments Found',
  mobile_number: 'Mobile Number',
  action: 'Action',
  unassign: 'UnAssign',
  start_time: 'Start Time',
  end_time: 'End Time',
  users: 'Users',
  assign: 'Assign',
  reg_link: 'Reg Link',
  reg_code: 'Reg Code',
  make_default: 'Make Default',
  assign_user: 'Assign User',
  updated_sucessfully: 'Updated Sucessfully!!',
  update: 'Update',
  new_slot: 'New Slot',
  edited_successfully: 'Edited Sucessfully',
  created_successfully: 'Created Sucessfully',
  edit_slot: 'Edit Slot',
  create_new_slot: 'Create New Slot',
  add: 'Add',
  error: 'Error',
  no_resume_found: 'No resume Found',
  no_users_found: 'No Users Found',
  recipient_email: 'Recipient E-mail',
  mail_report: 'Mail Report',
  user_report: 'User Report',
  proctor_report: 'Proctor Report',
  concept_node_report: 'Concept Node Report',
  proficiency_profile_report: 'Proficiency Profile Report',
  demo_drive: 'Demo Drive',
  proctoring_score: 'Proctoring Score',
  download: 'Download',
  code_copied: 'Code copied to clipboard',
  link_copied: 'Link copied to clipboard',
  finish_setup: 'Finish Setup',
  fixed_questions: 'Fixed Questions',
  problems: 'Problems',
  total_problems: 'Total Problems',
  problem_name: 'Problem Name',
  problem_type: 'Problem Type',
  update_stack: 'Update Stack',
  status: 'Status',
  add_delete_language: 'Add/Delete Language',
  do_not_remove_instruction: '[Please, do not remove problems once you have submissions for them.]',
  view_problem: 'View Problem',
  adjust_weightage: 'Adjust Weightage',
  submission_status: 'Submission Status',
  problem: 'Problem',
  solved: 'Solved',
  sub_no: 'Submission No.',
  enter_sub_number: 'Enter submission number...',
  language: 'Language',
  received_time: 'Received Time',
  testcase: 'Testcase',
  accepted: 'Accepted',
  wrongAnswer: 'Wrong Answer',
  compileTimeError: 'Compile Time Error',
  pagination: 'Pagination',
  update_rankings: 'Update Rankings',
  compute_rankings: 'Compute Rankings',
  points: 'Points',
  user_statistics: 'User Statistics',
  result: 'Result',
  total: 'Total',
  'ip/sub_status': 'IP/ Sub. Status',
  code_length_count: 'Code Length Count',
  more_details: 'More Details',
  autoSave: 'Auto Save',
  executed: 'Executed',
  validate: 'Validate',
  beforeSub: 'Before Sub',
  submission: 'Submission',
  random_questions: 'Random Questions',
  pool_name: 'Pool Name',
  total_count: 'Total Count',
  inProgress: 'In Progress',
  notAttempted: 'Not Attempted',
  sno: 'S No.',
  attNo: 'Att. No.',
  startTime: 'Start Time',
  endTime: 'End Time',
  graceTime: 'Grace Time',
  remainingTime: 'Remaining Time',
  attemptStatus: 'Attempt Status',
  extended_time: 'Extended Time',
  finish_attempts: 'Finish Attempts',
  delete_attempts: 'Delete Attempts',
  continue_attempts: 'Continue Attempts',
  select_training_slot: 'Select Training Slot',
  'are_you_sure!': 'Are you sure !',
  you_want_to_delete_the_selected_attempts: 'You want to delete the selected attempts',
  optional: 'Optional',
  scores: 'Scores',
  reports: 'Reports',
  insights: 'Insights',
  view_scores: 'View Scores',
  selected: 'Selected',
  shortlisted: 'Shortlisted',
  waitlisted: 'Waitlisted',
  not_selected: 'Not Selected',
  score_card: 'Score Card',
  shortlist: 'Shortlist',
  create_filter: 'Create Filter',
  c: 'C',
  select_assessment: 'Select Assessment',
  download_report: 'Download Report',
  email_placeholder: 'abc@mail.com',
  send_mail: 'Send Mail',
  mail: 'Mail',
  threshold: 'Threshold',
  select_condition: 'Select Condition',
  condition: 'Condition',
  custom_filter: 'Custom Filter',
  component: 'Component',
  select_component: 'Select Component',
  select_the_checkboxes_to_shortlist_the_candidates: 'Select the checkboxes to shortlist the candidates',
  standard_deviation: 'Standard Deviation',
  average: 'Average',
  add_random_questions: 'Add Random Questions',
  close: 'Close',
  questions_required: 'Questions Required',
  random_type: 'Random Type',
  uniform: 'Uniform',
  un_uniform: 'Un-Uniform',
  no_fixed_questions_available: 'No Fixed Questions available',
  no_random_questions_available: 'No Random Questions available',
  should_be_lesser_than_total_count: 'Should be lesser than total count',
  view_sequence: 'View Sequence',
  greater_than_min: 'Value must be greater than min value',
  no_data_found: 'No Data Found',
  required_field: 'Required Field',
  actual_score_out_of: 'Actual Score out of',
  actual_pass_score_out_of: 'Actual Pass Score out of',
  create_section: 'Create Section',
  quiz: 'Quiz',
  codeAnalysis: 'Code Analysis',
  programming: 'Programming',
  project: 'Project',
  quries: 'Quries',
  existing_sections: 'Existing Sections',
  new_section: 'New Section',
  equal_split: 'Equal Split',
  section_name_is_required: 'Section Name is Required',
  weightage_error: 'Weightage Error',
  sum_of_weightages_is_not_equal_to_100: 'Sum of Weightages is not Equal to 100',
  assessment_analytics: 'Assessment Analytics',
  candidatesInvited: 'Candidates Invited',
  inviteDelivered: 'Invite Delivered',
  linkClicked: 'Link Clicked',
  get_reports: 'Get Reports',
  section_wise_score_distribution: 'Section Wise Score Distribution',
  section_wise_assessment_strength: 'Section Wise Assessment Strength',
  overall_score_distribution: 'Overall Score Distribution',
  overall_assessment_strength: ' Overall Assessment Strength',
  candidates_feedback: 'Candidates Feedback',
  sad: 'Sad',
  neutral: 'Neutral',
  happy: 'Happy',
  overall: 'Overall',
  of_the_candidates_are: 'of the Candidates are',
  with_the_assessment: 'with the Assessment',
  slides: 'Slides',
  select_drive: 'Select a Drive',
  view_assessment: 'View Assessment',
  re_use_round: 'Re Use you_want_to_delete_the_selected_attemptsa Round',
  team: 'Team',
  bulk_upload: 'Bulk Upload',
  questions: 'Questions',
  upload: 'Upload',
  select_folder: 'Select Folder',
  search: 'Search',
  preview_questions: 'Preview Questions',
  row: 'Row',
  has_no_errors: 'has no errors',
  has: 'has',
  errors: 'errors',
  categories: 'Categories',
  drag_and_drop_your_file_here: 'Drag and Drop your file here',
  or: 'or',
  click_the_box: 'Click the Box',
  click_the_upload_button: 'Click the Upload Button',
  release_to_upload: 'Release to upload',
  uploaded_file: 'Uploaded File',
  congratulations_no_validation_errors: 'Congratulations! No Validation Errors',
  insert_questions: 'Insert Questions',
  add_questions: 'Add Questions',
  category: 'Category',
  open_folder: 'Open Folder',
  invalid_file_upload: 'Invalid File Upload',
  questions_inserted: 'Questions Inserted',
  template: 'Template',
  download_template: 'Download Template',
  validation_errors_found: 'Validation Errors Found',
  reupload: 'Re upload',
  online: 'Online',
  offline: 'Offline',
  gd: 'GD',
  create_interview: 'Create Interview',
  blocking_the_calenders: 'Blocking the Calenders',
  playBackRequired: 'Play Back required',
  sendAutoMailers: 'Send Auto Mailers',
  pairCoding: 'Pair Coding',
  liveStreaming: 'Live Streaming',
  meetings: 'Meetings',
  attempts: 'Attempts',
  reqMismatch: 'Req Mismatch',
  select_the_section: 'Select the Section',
  cancel: 'Cancel',
  session_name: 'Session Name',
  no_of_teams_allowed: 'No. of teams allowed',
  components: 'Components',
  teams: 'Team Name',
  title: 'Title',
  interview: 'Interview',
  feedback_report: 'Feedback Report',
  view_report: 'View Report',
  yes: 'Yes',
  no: 'No',
  actions: 'Actions',
  dashboard_name: 'Dashboard Name',
  random_un_uniform_questions: 'Random Ununiform Questions',
  random_uniform_questions: 'Random Uniform Questions',
  create_workflow: 'Create Workflow',
  search_org_unit: 'Search Org. Unit',
  my_org_units: 'My Org. Units',
  apply_filters: 'Apply Filters',
  clear_filters: 'Clear Filters',
  new_component: 'New Component',
  existing_components: 'Existing Components',
  component_name: 'Component Name',
  add_component: 'Add Component',
  create_component: 'Create Component',
  rearrange_components: 'Rearrange Components',
  delete_component_content: 'Are you sure to delete this component?',
  enter_new_option: 'Enter New Option',
  correct_option: 'Set as Correct Option',
  multiple_choice_question: 'Multiple Choice Question',
  short_answer: 'Short Answer',
  long_answer: 'Long Answer',
  match: 'Match',
  already_active: 'Already Active',
  rh_contact_number: 'RH Contact Number',
  question_folder_name: 'Question Folder Name',
  create_quiz_question: 'Create Quiz Question',
  question_file_name: 'Question File Name',
  description: 'Description',
  shuffle_options: 'Shuffle Options',
  option: 'Option',
  random_question: 'Random Question',
  fixed_question: 'Fixed Question',
  view_added_qst: 'View Added Qst',
  save_section: 'Save Section',
  undo_changes: 'Undo Changes',
  save_changes: 'Save Changes',
  edit_components: 'Edit Components',
  update_components: 'Update Components',
  create_folder: 'Create Folder',
  edit_folder: 'Edit Folder',
  archive_folder: 'Archive Folder',
  swap_folder: 'Swap Folder',
  create_file: 'Create File',
  edit_file: 'Edit File',
  swap_file: 'Swap File',
  archive_file: 'Archive File',
  add_description: 'Add Description',
  java: 'Java',
  no_files_found: 'No Files Found',
  archive: 'Archive',
  archive_modal_content_folder: 'Are you sure to move all the folders and files in this Main folder to Archive?',
  archive_modal_content_file: 'Are you sure to move this file to Archive?',
  existing_folder: 'Existing Folder',
  new_folder: 'New Folder',
  new_folder_1: 'New Folder 1',
  basic_java: 'Basic Java',
  create_atleast_2_options: 'Create Atleast 2 Options',
  grade_should_be_greater_than_0: 'Grade Should Be Greater Than 0',
  time_to_solve_is_mandatory: 'Time To Solve Is Mandatory',
  time_to_solve_should_be_greater_than_0: 'Time To Solve Should Be Greater Than 0',
  question_added_successfully: 'Question Added Successfully',
  max_file_size_is: 'Max file size is',
  unable_to_upload_file: 'Unable to upload file',
  no_file_warning: 'Currently no file available in this folder. Please create a file in this folder to add questions.',
  add_component_instruction: 'Please choose any component type to proceed further..',
  please_select_a_file_to_load_questions: 'Please select a file to load questions',
  please_add_some_questions_to_save_changes: 'Please add some questions to save changes',
  are_you_sure_you_want_to_delete_this: 'Are you sure you want to delete this',
  delete_file: 'Delete File',
  invalid_email: 'Invalid Email',
  invite_candidate: 'Invite Candidate',
  send_sms: 'Send SMS',
  send_email: 'Send Email',
  invite_multiple_candidates: 'Invite Multiple Candidates',
  upload_file: 'Upload File(.xls, xlsx)',
  select_file: 'Select File',
  no_file_choosen: 'No File Choosen',
  remove_question: 'Remove Question',
  add_new_question_paper: 'Add New Question Paper',
  pool: 'Pool',
  filter: 'Filter',
  schedule_to_teams: 'Schedule To Teams',
  create_team: 'Create Team',
  create_new_team: 'Create New Team',
  schedule_team: 'Schedule Team',
  randomize_a_qp_for_all_candidates: 'Randomize a QP for all Candidates',
  randomize_qp_to_random_candidates: 'Randomize QP to random Candidates',
  attempts_allowed: 'Attempts Allowed',
  if_no_selection_is_made_then_question_paper_1_will_be_the_default_for_all_sections: 'If no selection is made then Question Paper 1 will be the default for all sections',
  note: 'Note',
  select_team: 'Select Team',
  add_team_name: 'Add Team Name',
  add_user: 'Add User',
  team_name: 'Team Name',
  team_members: 'Team Members',
  edit_team: 'Edit Team',
  participant: 'Participant',
  interviewer: 'Interviewer',
  recruiter: 'Recruiter',
  phone: 'Phone',
  access: 'Access',
  remove_user: 'Remove User',
  contact_number: 'Contact Number',
  e_mail_id: 'E-mail ID',
  invalid_mobile_number: 'Invalid Mobile Number',
  download_error_excel_file: 'Download Error Excel File',
  insert_users: 'Insert Users',
  add_user_bulk_upload: 'Add User Bulk Upload',
  remove_user_bulk_upload: 'Remove User Bulk Upload',
  team_name_already_exists: 'Team Name Already Exists',
  entered_user_is_not_available_in_the_team: 'Entered user is not available in the team',
  add_bulk_user: 'Add Bulk User',
  remove_bulk_user: 'Remove Bulk User',
  search_user: 'Search User',
  has_been_removed_successfully: 'has been removed successfully!',
  remove_users: 'Remove Users',
  schedule: 'Schedule',
  random_uniform_question: 'Random Uniform Question',
  random_un_uniform_question: 'Random Un-Uniform Question',
  please_select_team_to_load_attempts: 'Please select team to load attempts',
  cant_able_to_add_question: "Can't able to add question",
  qst: 'Qst',
  time_cannot_be_extended_for_these_attempts: 'Time cannot be extended for these attempts',
  attempt_status_cannot_be_updated_as_in_progress_for_these_attempts: 'Attempt status cannot be updated as "In Progress" for these attempts',
  user_id: 'User ID',
  attempt_no: 'Attempt No.',
  extend_time: 'Extend Time',
  grace_time: 'Grace Time',
  min: 'min',
  reason: 'Reason',
  saved_successfully: 'Saved Successfully',
  updated_successfully: 'Updated Successfully!!',
  sheduled_successfully: 'Sheduled Successfully',
  duration_mins: 'Duration(mins)',
  controlled_attempt: 'Controlled Attempt',
  carry_forward_timer: 'Carry Forward Timer',
  section_status: 'Section Status',
  attempt_status: 'Attempt Status',
  active_section: 'Active Section',
  previousGraceTime: 'Previous Grace Time',
  yet_no_attempts_found: 'Yet no attempts found',
  refresh: 'Refresh',
  grace_time_should_not_be: 'Grace time should not be',
  negative_grace_time_will_decrease_the_duration_of_the_attempt: 'Negative grace time will decrease the duration of the attempt',
  not_attempted: 'Not Attempted',
  in_progress: 'In Progress',
  do_you_want_to_proceed_for_the_rest_of_the_users: 'Do you want to proceed for the rest of the users',
  you_want_to_update_attempt_status_as_in_progress_for_the_selected_attempts: 'You want to update attempt status as "In Progress" for the selected attempts',
  you_want_to_update_attempt_status_as_completed_for_the_selected_attempts: 'You want to update attempt status as "Completed" for the selected attempts',
  enable_penalty: 'Enable Penalty',
  manual: 'Manual',
  random_uniform: 'Random Uniform',
  random_un_uniform: 'Random Un-Uniform',
  no_questions_here: 'There are no questions in this section',
  attempt_number: 'Attempt Number',
  attempt_start_time: 'Attempt Start Time',
  attempt_end_time: 'Attempt End Time',
  show_save_button: 'Show Save Button',
  completedTime: 'Completed Time',
  is_required_atleast_once: 'is required atleast once',
  create_atleast: 'Create atleast',
  options: 'options',
  delete_option: 'Delete Option',
  'grade_should_be_+ve': 'Grade should be +ve',
  value_should_be_in_1_to_10: 'Value should be in 1 to 10',
  fillInDescriptionWarningMessage: 'Blanks ("____") should be mentioned as ("##...##")',
  evaluation_mode: 'Evaluation Mode',
  quiz_question_category: 'Question Category',
  complexity_category: 'Complexity Category',
  penality: 'Penalty',
  details: 'Details',
  more: 'More',
  hrl_repository: 'HRL Repository',
  my_repository: 'My Repository',
  problem_statement: 'Problem Statement',
  test_case: 'Test Case',
  advanced_settings: 'Advanced Settings',
  constraints: 'Constraints',
  miscellaneous: 'Miscellaneous',
  new_pool: 'New Pool',
  probelem_constraints: 'Problem Constraints',
  problem_help: 'Problem Help',
  reference_code: 'Reference Code',
  problem_pool: 'Problem Pool',
  testcase_purpose: 'Testcase Purpose',
  ncomplexity: 'n (Complexity)',
  input: 'Input',
  output: 'Output',
  console: 'Console',
  hint: 'Hint',
  cmdline: 'Cmd. Line Args',
  evaluation_type: 'Evaluation Type',
  creator: 'Creator',
  main_filename: ' Main Filename',
  check_rule: 'CA Check Rule Group',
  save_testcase: 'Save Testcase',
  get_additional_constraints: 'Get Additional Constraints',
  problem_purpose: 'Problem Purpose',
  files: 'Files',
  flow_chat_view_required: 'Flow Chat View Required',
  show_flowchart_editor: 'Show Flowchart Editor',
  eneble_testcase_weightage: 'Enable Testcase Weightage',
  compute_complexity: 'Compute Complexity',
  auto_eval: 'Auto Eval',
  approved: 'Approved',
  allow_file_upload: 'Allow File Upload',
  expected_complexity: 'Expected Complexity',
  prompt_intensive: 'Prompt Intensive',
  allowed_similarty: 'Allowed Similarty',
  average_solving_time: 'Average Solving Time',
  cleanup_schema_tables: 'Cleanup Schema Tables',
  upload_project: 'Upload Project',
  strict_compoare: 'Strict Compoare',
  enable_group_attempt: 'Enable Group Attempt',
  plagirism_cutoff: 'Plagirism Cutoff',
  sample_problem: 'Sample Problem',
  vm_based_project: 'Vm Based Project',
  console_testcase_weightage: 'Console Testcase Weightage',
  junit_testcase_weightage: 'Junit Testcase Weightage',
  problem_label: 'Problem Label',
  template_zip_file: 'Template Zip File',
  save_code: 'Save Code',
  current_template_code: 'Current Template Code',
  next: 'Next',
  choose_problem_type: 'Choose Problem Type',
  skip_constraints: 'Skip Constraints',
  previous: 'Previous',
  cmd_lines_args: 'Cmd. Line Args.',
  test_cases: 'Test Cases',
  add_template: 'Add Template',
  existing_testcases: 'Existing Testcases',
  visible: 'Visible',
  eval_type: 'Eval Type',
  get: 'Get',
  command_line: 'Command Line',
  purpose: 'Purpose',
  save_test_case: 'Save Test Case',
  visible_to_participant: 'Visible to Participant',
  choose_file: 'Choose File',
  or_c: 'OR',
  select_one: 'Select One',
  more_option: 'More Option',
  trail_solution: 'Trail/Soluion',
  dependency: 'Dependency',
  concept_trees: 'Concept Trees',
  subsystem_test_cases: 'Subsystem Test Cases',
  file_test_case: 'File Test Case',
  editorial: 'Editorial',
  problems_pools_contests: 'Problems Pools & Contests',
  s_no_: 'S.No.',
  sample: 'Sample',
  assign_groups: 'Assign Groups',
  upload_files: 'Upload File',
  please_select_a_file: 'Please Select A File',
  please_select_anyone: 'Please Select Anyone',
  problem_pools: 'Problem Pools',
  edit_test_case: 'Edit Test Case',
  un_supported_file_format: 'UnSupported File Format',
  save_next: 'Save & Next',
  complexity_name: 'Complexity Name',
  complexity_assignment: 'Complexity Assignment',
  visibility: 'Visibility Settings',
  hrl_session: 'Hrl session',
  auto_save_of_code: 'Auto save of code',
  allowed_submission_count: 'Allowed submission count',
  disable_copy_paste: 'Disable copy paste',
  show_template_code: 'Show template code',
  solve_in_order: 'Solve in order',
  show_detailed_test_case_in_report: 'Show detailed test case in report',
  show_all_test_cases_for_participants: 'Show all test cases for participants',
  avoid_sample_testcase_for_scoring: 'Avoid sample testcase for scoring',
  show_editor: 'Show editor',
  evaluation_question_count: 'Evaluation question count',
  show_user_file: 'Show user file',
  user_file_upload_restriction_count: 'User file upload restriction count',
  show_submission_stack_pane: 'Show submission stack pane',
  show_ranking_pane: 'Show ranking pane',
  show_submission_tab: 'Show submission tab',
  show_validate_button: 'Show validate button',
  show_steps: 'Show steps',
  show_submit_button: 'Show submit button',
  show_finish: 'Show finish',
  validate_file: 'Please Choose File',
  problem_constraints: 'Problem Constraints',
  reuse_this_assessment: 'Re-Use this Assessment',
  select_an_assessment: 'Select an Assessment',
  subject: 'Subject',
  viewed: 'Viewed',
  edit_questions: 'Edit Questions',
  cannot_edit_question_paper: 'You cannot edit the Question Paper with User Attempts',
  staffingrequirements: 'Staffing Requirements',
  min_count: 'Min Count',
  max_count: 'Max Count',
  get_keywords: 'Get Keywords',
  function: 'Function',
  DBMS_query_problem: 'DBMS Query Problem',
  add_update: 'Add/Update',
  create_node: 'Create Node',
  test_data: 'Test Data',
  upload_testdata: 'Upload Testdata',
  current_test_data_sets: 'Current Test-Data Sets',
  test_data_name: 'Test-Data Name',
  script: 'Script',
  solving_time: 'Solving Time',
  impacted_tables: 'Impacted Tables',
  test_data_query: 'Test Data Query',
  correct_query: 'Correct Query',
  query_language: 'Query Language',
  drop_statements: 'Drop Statements',
  base_problem_id: 'Base Problem Id',
  existing_sub_problems: 'Existing Sub Problems',
  new_file: 'New File',
  file_name: 'File Name',
  new_problem_pool: 'New Problem Pool',
  parent: 'Parent',
  new_schema: 'New Schema',
  new_schema_name: 'New Schema Name',
  get_statistics: 'Get Statistics',
  select_question_paper: 'Select Question Paper',
  select_language: 'Select Language',
  question_paper_error: 'is the base paper. The complexity, question count, score should match',
  question_bank: 'Question Bank',
  bulk_upload_questions: 'Bulk Upload Questions',
  folder_name: 'Folder Name',
  description_is_a_mandatory_field: 'Description is a Mandatory Field',
  folder_name_is_a_mandatory_field: 'Folder Name is a Mandatory Field',
  file_name_is_a_mandatory_field: 'File Name is a Mandatory Field',
  keyword: 'Keyword',
  dont_care: 'Dont care',
  large_file_size: 'Large File Size',
  save_func_testcase: 'Save Func.Testcase',
  savefunction: 'Save Function',
  schema_name_is_required: 'Schema Name is required',
  file_name_is_required: 'File Name is required',
  name_is_required: 'Name is required',
  reference_code_is_required: 'Reference Code is required',
  base_problem_id_is_required: 'Base Problem id is required',
  query_language_is_required: 'Query Language is required',
  description_is_required: 'Description is required',
  solving_time_is_required: 'Solving Time is required',
  complexity_category_id_is_required: 'Complexity is required',
  question_bank_id_is_required: 'File Name is required',
  impacted_tables_is_required: 'Impacted Tables is required',
  test_data_query_is_required: 'Test Data Query is required',
  correct_query_is_required: 'Correct Query is required',
  new_schema_name_is_required: 'New Schema Name is required',
  dbms_query_problems: 'Dbms Query Problems',
  schema_name: 'Schema Name',
  answer: 'Answer',
  description_is_mandatory: 'Description is Mandatory',
  options_are_mandatory: 'Options are Mandatory',
  grade_is_mandatory: 'Grade is Mandatory',
  set_correct_option: 'You are required to select the correct answer for the Question',
  quiz_question_type: 'Quiz Question Type',
  no_test_data_sets_found: 'No Test-Data Sets found',
  sub_problem: 'Sub Problem',
  non_file_test_case: 'Non File Test Case',
  tag_concept_node: 'Tag Concept Node',
  functions: 'Functions',
  add_weightage: 'Add Weightage',
  concept_tree: 'Concept Tree',
  go_to_concept: 'Go To Concept',
  concept_weightage: 'Concept Weightage',
  current_concept: 'Current Concept',
  knowledge: 'Knowledge',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete',
  value_must_be_a_number: 'Value must be a number',
  value_must_be_greater_than_or_equal_to_0: 'Value must be greater than or equal to 0',
  base_problem_id_should_be_greater_than_0: 'Base Problem id should be greater than 0',
  value_is_required: 'Value is required',
  please_select_a_concept_tree: 'Please select a concept tree',
  please_select_a_concept: 'Please select a concept',
  weightage_must_be_a_number: 'Weightage must be a number',
  weightage_must_be_greater_than_or_equal_to_0: 'Weightage must be greater than or equal to 0',
  weightage_must_be_less_than_or_equal_to_100: 'Weightage must be less than or equal to 100',
  please_enter_the_concept_weightage: 'Please enter the concept weightage',
  select_concept_tree: 'Select Concept Tree',
  select_concept: 'Select Concept',
  email_userid: 'Email & User ID',
  securewindow: 'Breach Count',
  re_evaluate_score: 'Re-evaluate Score',
  re_evaluate_score_updated: 'Re Evaluate Updated',
  sms_instruction: 'Note : Please do not remove the content inside $()',
  compose_sms_content: 'Compose SMS Content',
  compose_sms_content_placeholder: 'Please take your assessment in https://app.talentrankk.in HRlytics Team.',
  recruiter_email: 'Recruiter Email',
  recruiter_head: 'Recruiter Head',
  download_errors: 'Download Errors',
  email_errors: 'Email Errors',
  mobile_number_error: 'Mobile Number Error',
  name_error: 'Name Error',
  export: 'Export',
  attempted_funnel: 'Attempted - ',
  above_cutoff: 'Above Cutoff - ',
  report_name: 'Report Name',
  assessment_score: 'Assessment Score',
  assessment_pass_percentage: 'Assessment Pass Percentage',
  assessment_pass_score: 'Assessment Pass Score',
  range: 'Range',
  count: 'Count',
  load_questions: 'Load Questions',
  destination_folder: 'Destination Folder',
  no_testcase: 'This question has no testcase',
  choose_flow: 'Choose Flow',
  in_description: 'in the description',
  program_manager: 'Program Manager',
  registered: 'Registered - ',
  treshold: 'Threshold - ',
  tested: 'Tested - ',
  confirm_delete: 'Confirm Delete',
  are_you_sure: 'Are you sure you want to delete this question paper?',
  enter_question_paper_name: 'Enter Question Paper Name',
  create_question_paper: 'Create New Question Paper',
  question_paper_name_is_already_exists: 'question paper name is already exists',
  atleast_one_question_paper_should_present: 'Atleast One Question Paper Should Present',
  pass_code: 'Passcode',
  upload_csv_file: 'Upload csv File',
  re_enter_password: 'Re-enter Password',
  user_name: 'Username',
  reset_password: 'Reset Password',
  password_must_be_same: 'Password must be same',
  error_in_file: 'Error In File',
  download_file: 'Download File',
  reset_password_error_list: 'Reset Password Error List',
  recompute_score: 'Recompute Score',
  re_compute_updated: 'Re Compute Updated',
  edit_section_weightage: 'Edit Section Weightage',
  edit_weightage: 'Edit Weightage',
  show_answer: 'Show Answer',
  cutoff: 'Cutoff %',
  more_than_0: 'More than 0',
  weightage_total_must_be_100: 'Weightage total must be 100',
  cutoff_must_be_100_or_below: 'CutOff must be 100 or below',
  weightage_updated: 'Weightage Updated',
  show_timer: 'Show Timer',
  code_auto_save_interval: 'Code Auto Save Interval',
  manual_intervention: 'Manual Intervention',
  adaptive_mode: 'Adaptive Mode',
  rankings_score_settings: 'Rankings/Score Settings',
  create_quiz: 'Create Quiz',
  invite_multiple_candidates_sample_template: 'Invite Multiple Candidates Sample Template',
  fixed_problems: 'Fixed Problems',
  add_delete_anguage: 'Add/delete Language',
  ok: 'Ok',
  sample_query: 'Sample Query',
  trigger: 'Trigger',
  trigger_data_query: 'Trigger Data Query',
  procedure_call: 'Procedure Call',
  procedure: 'Procedure',
  negative_scoring: 'Negative Scoring',
  evaluation_configuration: 'Evaluation Configuration',
  security_protocols: 'Security Protocols',
  grading_metrics: 'Grading Metrics',
  alert_configuration: 'Alert Configuration',
  component_configuration: 'Component Configuration',
  weightage_is_required: 'Weightage is required',
  _is_required: 'is required',
  template_is_required: 'Template is required',
  questions_error_list: 'Question Errors',
  test_case_error_list: 'TestCase Errors',
  db_type_error_list: 'Db Type Errors',
  insert_question: 'Insert Question',
  question_id: 'Question Id',
  errors_file: 'File Error !!!',
  preview_upload: 'Preview Bulk Upload',
  no_file_errors: 'No File Errors',
  download_link: 'Download Link',
  question_text: 'Question Text',
  configurations: 'Configurations',
  analytical_blueprint: 'Analytical Blueprint',
  scheduling_hub: 'Scheduling Hub',
  evaluation_summaries: 'Evaluation Summaries',
  analytics: 'Analytics',
  component_questions: 'Component Questions',
  my_profile: 'My Profile',
  dashboard_info: 'Dashboard Info',
  total_submission: 'Total Submission',
  accepted_submission: 'Accepted Submission',
  total_submissions: 'Total Submissions',
  Session_level: 'Session Level',
  proctoring_management: 'Proctoring Management',
  from: 'From',
  to: 'To',
  get_assessments: 'Get Assessments List',
  select_teams: 'Select Teams',
  genuinity_score: 'Genuinity Score',
  view_timeline: 'View Timeline',
  view_all: 'View All',
  compute_genuinity_index: 'Compute Genuinity Index',
  delete_proctoring_attempts: 'Delete Proctoring Attempts',
  suspicious: 'Suspicious',
  all: 'All',
  load_more: 'Load More',
  face_score: 'Face Score',
  start: 'Start',
  profiling_images: 'Profiling Images',
  suspicious_activity_found: 'Suspicious activity found',
  no_suspicious_activity_found: 'No Suspicious activity found',
  observation: 'Observation',
  create_lesson_component: 'Create Lesson Component',
  course_management: 'Course Management',
  component_type: 'Component Type',
  please_select_component_type: 'Please Select Component Type',
  question_count: 'Question Count',
  cant_select_more_than_10_attempts: "Can't Select more than 10 Attempts",
  "can't_be_removed": "can't be removed",
  file_is_required: 'File is required',
  verify_id: 'Verify Id',
  please_choose_any_component_type: 'Please choose Any Component type',
  course: 'Course',
  new_course: 'New Course',
  organization: 'Organization',
  course_count: 'Course Count',
  new_track: 'New Track',
  track: 'Track',
  new_user: 'New User',
  register_number: 'Register Number',
  new_role: 'New Role',
  priority: 'Priority',
  role_list: 'Role List',
  user_batch: 'User Batch List',
  new_batch: 'New Batch',
  number: 'Number',
  name_of_the_organization: 'Name of The Organization',
  org_admin_name: 'Org Admin Name',
  org_admin_email_id: 'Org Admin Email Id',
  skillytics: 'Skillytics',
  reclytics: 'Reclytics',
  new_organization: 'New Organization',
  user_batch_list: 'User Batch List',
  change_password: 'Change Password',
  track_user: 'Track User',
  view_more: 'View More',
  more_options: 'More Options',
  org_name: 'Org Name',
  logo_of_the_organization: 'Logo of The Organization',
  org_dmin_mail_id: 'Org Admin Email Id',
  create_org: 'Create Organization',
  id: 'Id',
  password_as_username: 'Password as Username',
  same_password: 'Same Password',
  'sub_no.': 'Sub. No.',
  get_submissions: 'Get Submissions',
  question_papers: 'Question Papers',
  select_questions: 'Select Questions',
  select_users: 'Select Users',
  select_problems: 'Select Problems',
  select_status: 'Select Status',
  select_languages: 'Select Languages',
  create_dashboard: 'Create Dashboard',
  prev: 'Prev',
  Verify_the_year_of_completion_value: 'Verify the year of completion value',
  tag_atleast_one_team_to_course: 'Tag atleast one Team to Course',
  hrl_session_lesson_session: 'Hrl Session - lesson session',
  quiz_details: 'Quiz Details',
  password_update_has_been_successful: 'Password Update has been successful',
  refresh_list: 'Refresh List',
  ranking_mode: 'Ranking Mode',
  select_ranking_mode: 'Select Ranking Mode',
  ranking_score_settings: 'Ranking Score Settings',
  ranking_mode_id: 'Ranking Mode',
  enable_sse: 'Enable SSE',
  contests: 'Contests',
  showAcceptedSubmission: 'Show Accepted Submission',
  earliestSubmission: 'Earliest Submission',
  latestSubmission: 'Latest Submission',
  no_testcase_created: 'No Test Cases Created',
  function_test_creation: 'Function- Test Creation',
  quiz_reports: 'Quiz Reports',
  question_reports: 'Question Reports',
  manual_grading: 'Manual Grading',
  import_export_blueprint: 'Import/Export Blueprint',
  rearrange: 'Rearrange',
  question_description: 'Question Description',
  question_category: 'Question Category',
  re_evaluate: 'ReEvaluate',
  total_no_of_question: 'Total no. of Question',
  number_of_questions_assigned: 'Number of questions assigned',
  show_explore: 'Show Explore',
  question_paper_list: 'Question paper',
  question_paper_is_not_evaluate: 'Question paper is not evaluate',
  dbms_settings: 'DBMS - Settings',
  add_language: 'Add Language',
  quiz_settings: 'Quiz Settings',
  auto_save_interval: 'Auto save interval',
  component_must_have_atleast_one_language: 'Component must have atleast one language',
  this_session_is_already_attempted: 'This session is already attempted.You cannot delete the component',
  deleted_successfully: 'Deleted Successfully',
  this_session_is_already_attempt: 'This session is already attempted.You cannot Edit the component',
  un_archive: 'UnArchive',
  un_archive_file: 'UnArchiveFile',
  un_archive_folder: 'UnArchiveFolder',
  un_archive_modal_content_folder: 'Are you sure to move all the folders and files in this Archive folder to Main folder?',
  un_archive_modal_content_file: 'Are you sure to move this file to Main folder?',
  expected_output: 'Expected Output',
  test_script: 'Test Script',
  are_you_sure_: 'Are you sure you want to delete?',
  extend_time_bulk_upload: 'Extend Time Bulkupload',
  continue_attempts_bulk_upload: 'Continue Attempts Bulkupload',
  view_insights: 'View Insights',
  must_be_have_anyone_language: 'Must Be Have Anyone Language',
  yrs: 'Yrs',
  question_removed_successfully: 'Question Removed Successfully',
  choice_based_modal_content: 'Enter the number of questions participant can answer',
  filter_by_status: 'Filter by Status',
  change_status: 'Change Status',
  // are_you_want_to_change_status: 'Are you sure, you want to change the Status!',
  untag_user: 'Untag User',
  are_you_want_to_untag: 'Are you sure, you want to Remove the Status!',
  are_you_want_to_Select_status: 'Aru you sure, want to Select the Candidate!',
  'are_you_want_to_Not Select_status': 'Aru you sure, want to Not Select the Candidate!',
  are_you_want_to_Waitlist_status: 'Aru you sure, want to Waitlist the Candidate!',
  record: 'Record',
  sub_problem_details: 'Sub Problem Details',
  solution_hint: 'Solution Hint',
  problem_tag_set: 'Problem Tag Set',
  links: 'Links',
  titbit: 'Titbit',
  concept_node: 'Concept Node',
  update_sub_question: 'Update Sub Question',
  team_management: 'Team Management',
  extend_time_for_selected_user: 'Extend time for selected user',
  extend_time_for_bulk_user: 'Extend time for bulk user',
  continue_attempt_for_selected_user: 'Continue attempt for selected user',
  continue_attempt_for_bulk_user: 'Continue attempt for bulk user',
  framework_language: 'Framework Language',
  save_test_suite: 'Save TestSuite',
  class_name: 'Class Name',
  console_output: 'Console Output',
  test_suite_purpose: 'TestSuite Purpose',
  test_app_component: 'Test App Component (100.0)',
  function_name: 'Function Name',
  testcase_weightage_total_mustbe_100: 'Testcase Weightage total must be 100',
  org_admin_name_is_required: 'Organization Admin Name Is Required',
  questionbank: 'Question Bank',
  coursemanagement: 'Course Management',
  trackmanagement: 'Track Management',
  widgetmanagement: 'Widget Management',
  organizationmanagement: 'Organization  Management',
  licensemanagement: ' License Management',
  rolescapabilities: 'Roles And capabilities',
  usermanagement: 'User Management',
  select_folder_is_required: 'Select folder is required',
  question_bank_name_is_required: 'Question bank name is required',
  time_to_solve: 'Time to Solve(Mins.)',
  delete_the_duestion: 'Are you sure! Do you want to Delete the Sub Question ?',
  junit_testcase: 'JUnit Testcases',
  add_test_suite: 'Add Test Suite',
  className: 'Class Name',
  frameworkLanguage: 'Framework Language',
  testSuite: 'Test Suite',
  you_must_have_atleast_one_language: 'You Must Have Atleast One Language',
  capabilityManagement: 'Capability Management',
  name_should_only_contain_letters: 'Names can only contain alphabets, space, dots, must not start with space or dot and no two dots or spaces consecutively',
  please_enter_the_valid_email: 'Please enter the valid email Eg:xxx@gmail.com',
  user_added_successfully: 'User added successfully',
  solution: 'Solution',
  file_name_already_exists: 'File Name Already Exists',
  duration_is_required: 'Duration is required',
  attempts_allowed_required: 'Attempts Allowed must be greater than or equal to 1',
  quiz_category_list: 'Quiz Category List',
  assign_teams: 'Assign Teams',
  team_pool_assignment: 'Team Pool Assignment',
  org_unit_name: 'Org Unit Name',
  role_name: 'Role Name',
  edit_access: 'Edit Access',
  are_you_sure_want_to_delete: 'Are you Sure, Want to Delete!',
  already_created: 'Already Created',
  do_you_want_to_edit_questions: 'Do you want to edit questions? If you edit the questions, you have to finish setup once again!!',
  current_script_file: 'Current Script File',
  add_script_file: 'Add Script File',
  script_file: 'Script File',
  scriptFile_added_successfully: 'ScriptFile added successfully',
  scriptFile_deleted_successfully: 'ScriptFile deleted successfully',
  programming_settings: 'Programming Settings',
  get_users_for_quick_submission: 'Get Users For Quick Submission',
  get_users_for_integration_submission: 'Get Users For Integration Submission',
  pass_score_must_be_100_or_below: 'Pass Score must be 100 or below',
  choose_video: 'Choose Video',
  instruction_video: 'Instruction Video',
  create_question_category: 'Create Question Category',
  folder: 'Folder',
  file: 'File',
  create_questionbank: 'Create QuestionBank',
  questionbank_list: 'Question Bank List',
  create_category: 'Create Category',
  concept_node_tagging: 'Concept Node Tagging',
  problem_file: 'Problem File',
  choice_question_count: 'Choice Question Count',
  sub_question_name: 'Sub Question Name',
  edit_statics: 'Edit Statistics',
  ip_sub_status: 'IP/Sub. Status',
  org_tree_structure: 'Org Tree Structure',
  organisation_name: 'Organisation Name',
  search_file_folder: 'Search File or Folder',
  search_organisation: ' Search Organisation',
  search_folder: 'Search Folder',
  batch: 'Batch',
  search_file: 'Search File',
  submission_stack: 'Submission Stack',
  import_file: 'Import File',
  select_problem_pool: 'Select Problem Pool',
  question_should_be_tagged_atleast_one_problem_pool: 'Question should be tagged atleast one problem pool',
  add_question_paper: 'Add Question Paper',
  are_you_sure_to: 'Are you sure to delete this Question?',
  component_question_count: 'Component Question Count',
  choice_question_count_should_be_greater_than_0: 'Choice question count should be greater than 0',
  choice_question_count_should_be_less_than_or_equal_to: 'Choice question count should be less than or equal to',
  questions_in_this_component_are_not_in_same_complexity: 'Questions in this component are not in same complexity',
  please_select_the_file: 'Please select the file',
  there_is_no_questions: 'There is no questions',
  multiple_monitor_detection: 'Multiple Monitor Detection',
  deafault_team_is_not_active: 'Deafault team is not active',
  total_team_members: 'Total Attempted Users',
  cleared_users_count: 'Cleared Users Count',
  cleared_percentage: 'Cleared Percentage',
  logo_right: 'Logo Right',
  logo_left: 'Logo Left',
  testing_problem: 'Testing Problem',
  proceed: 'Proceed',
  current_problems_cart: 'Current Problems Cart',
  get_problems: 'Get Problems',
  get_contest: 'Get Contest',
  problem_count: 'Problem Count',
  testCase_threshold: 'TestCase Threshold',
  generate: 'Generate',
  talent_show: 'Talent Show',
  add_remove_fill: 'Add / Remove Fill',
  similarity_threshold: 'Similarity Threshold',
  done: 'Done',
  allowed_submission_count_validate_message: 'Allowed Submission Count should be greater than or equal to -1 and not equal to 0',
  infinity: 'Infinity',
  allowed_submission_count_warning_message: '-1 indicates Infinity Allowed Submission Count',
  create_code_analysis_component: 'Create Code Analysis Component',
  amphi_session: 'Amphi Session',
  show_screen_in_result: 'Show Screen in Result',
  show_details_in_result: 'Show Details in Result',
  shuffle_question: 'Shuffle Question',
  evaluate_on_save: 'Evaluate on Save',
  no_users_available: 'No Users Available',
  user_ai_report: 'User AI Report',
  query_type: 'Query Type',
  courses: 'Courses',
  lines_per_block: 'Lines Per Block',
  submission_id: 'Submission Id',
  manual_gen: 'Manual Gen',
  submissions: 'Submissions',
  contest: 'Contest',
  problem_is_present_in_following_pools: 'Problem is present in following pools :',
  problem_details: 'Problem Details',
  submission_number: 'Submission Number',
  view_details: 'View Details',
  unattempted_users: 'Unattempted Users',
  download_excel: 'Download Excel',
  initial_key: 'Initial Key',
  view_and_generate_initial_key: 'View and Generate Initial Key',
  click_generate_button_to_generate_initial_key:'Click Generate button to generate the initial key',
  download_initial_key: 'Download Initial Key',
  random_initial_key: 'Random Initial Key',
  intermediate_key: 'Intermediate Key',
  generate_keys: 'Generate Keys',
  generate_key: 'Generate Key',
  generate_code: 'Generate Code',
  kindly_select_your_team_before_performing_further_actions: 'Kindly, select (your) team before performing further actions.',
  select_username: 'Select Username',
  look_up: 'Look Up',
  existing_codes: 'Existing Codes',
  type_3_characters_to_search_users: 'Type 3 characters to search users',
  these_secret_codes_are_valid_only_10_mins: 'These secret codes are valid only 10 mins',
  min_1question_required: 'Min. one question required',
  u_id: 'U Id',
  org_unit: 'Org. Unit',
  ordered_answer: 'Ordered Answer',
  new_skill: 'New Skill',
  user_already_exist: 'User already exist',
  has_warning: 'has warning',
  dq: 'Dq',
  non_dq: 'Non Dq',
  dq_random_uniform_question: 'DQ Random Uniform Questions',
  dq_random_un_uniform_question: 'DQ Random Ununiform Questions',
  question_paper: 'Question Paper No',
  dq_random_un_uniform: 'DQ Random UnUniform',
  dq_random_uniform: 'DQ Random Uniform',
  get_components: 'Get Components',
  pool_assignment: 'Pool Assignment',
  min_2_option_grade_must_be_greater_than_0: 'Min 2 option grade must be greater than 0',
  min_1_option_must: 'Min 1 option must',
  evalscript: 'Eval. Script',
  question_cannot_be_deleted: 'Question cannot be Deleted',
  'add/update': 'Add/Update',
  test_case_list: 'TestCase List',
  query: 'Query',
  random: 'Random',
  logo: 'Logo',
  skill_id_list: 'Skill List',
  user_status: 'User Status',
  catalog_name: 'Catalog Name',
  cost: 'Cost(unit)',
  tax: 'Tax(%)',
  tariff: 'Tariff',
  new_catalog: 'New Catalog',
  choose_org: '--Choose organization--',
  create_catalog: 'Create Catalog',
  edit_catalog: 'Edit Catalog',
  teams_: 'Teams',
  start_date: 'Start Date',
  end_date: 'End Date',
  enable_seb_mode: 'Enable SEB Mode',
  submit_partial_submission: 'Submit Partial Submission',
  partial_submissions: 'Partial Submissions',
  manual_save_count: 'Manual Save Count',
  total_save_count: 'Total Save Count',
  submit: 'Submit',
  no_attempts_present: 'No Attempts Present',
  allowed_ip_config: 'Allowed Ip Config',
  ip_config: 'Ip Config',
  type_min_3_letter: 'Type Min 3 Letters',
  get_score: 'Get Score',
  max_two_decimals: 'Max Two Decimals',
  end_date_mustbe_grater_then_start_date: 'End Date mustbe Greater than Start Date',
  common_for_all: 'Common For All',
  xls: 'XLS',
  project_management: 'Project Management',
  story_creation: 'Story Creation',
  add_story: 'Add Story',
  existing_stories: 'Existing Stories',
  story_name: 'Story Name',
  story_type: 'Story Type',
  edit_stories: 'Edit Stories',
  update_stories: 'Update Stories',
  new_story: 'New Story',
  this_component_name_already_exist: 'This component name already exist',
  this_story_name_already_exist: 'This story name already exist',
  create_story: 'Create Story',
  delete_story_content: 'Are you sure to delete this story?',
  rearrange_stories: 'Rearrange Stories',
  editor_statistics: 'Editor Statistics',
  subscription_type: 'Subscription Type',
  fillup: 'Fillup',
  undo: 'Undo',
  edit_chages_saved: 'Edit Chages Saved',
  allowed_ips: 'Allowed ips',
  coupen_code: 'Coupen Code',
  key: 'Key',
  kindly_note: 'Kindly note the code is valid only till date and time',
  weightage_option: 'Weightage Option',
  no_questions_available_in_the_chosen_file: 'No Questions available in the chosen file',
  please_enter_a_value: 'Please enter a solution',
  story: 'Story',
  task_allocation: 'Task Allocation',
  assigned_tasks: 'Assigned Tasks',
  task_name: 'Task Name',
  total_tasks: 'Total Tasks',
  task_type: 'Task Type',
  task_assigned_successfully: 'Task Assigned Successfully',
  cant_able_to_assign_task: "Can't able to assign task",
  task_unassigned_successfully: 'Task Unassigned Successfully',
  unassign_task: 'Unassign Task',
  assign_this_task: 'Assign this task',
  are_you_sure_you_want_to_delete_this_option: 'Are you sure you want to delete this option?',
  slide_name: 'Slide Name',
  is_active: 'Is Active',
  clone_dashboard: 'Clone Dashboard',
  time_frame: 'Time Frame',
  time_to_solve_required: 'Time to solve Required Field',
  new: 'New',
  assessment_strength: 'Assessment Strength',
  blueprint: 'Blueprint',
  code_analysis_settings: 'CodeAnalysis-Settings',
  no_widgets_found: 'No Widgets Found',
  clone_slide: 'Clone Slide',
  unique_name_error: 'Enter a unique dashboard name',
  select_atleast_one_component: 'Select atleast one component',
  edit_dashboard: 'Edit Dashboard',
  show_slides: 'Show Slides',
  preview_dashboard: 'Preview Slide',
  delete_dashboard: 'Delete Dashboard',
  copy_dashboard_link: 'Copy Dashboard Link',
  add_slide: 'Add Slide',
  rearrange_slides: 'Re-Arrange Slides',
  preview_all_slides: 'Preview All Slides',
  copy_slides_link: 'Copy Slides Link',
  edit_slide: 'Edit Slide',
  delete_slide: 'Delete Slide',
  option_is_required: 'Option is Required',
  first: 'First',
  options_should_represent_the_correct_answers: 'options should represent the correct answers',
  dad_weightage_option_err_message: "Correct answer's grade should be greater than 0 & Wrong answer's grade should be 0",
  show_scores_in_result: 'Show Scores Result',
  old_password: 'Old Password',
  sessions: 'Sessions',
  option_weightage_sum_error: 'Sum of option weightage should be equal to 100',
  less_than_100: 'Less than or equal to 100',
  catalog_validity: 'Catalog Validity(In days)',
  user_validity: 'User Validity(In days)',
  carry_forward: 'Carry Forward',
  track_percentage: 'Track(%)',
  course_percentage: 'Course(%)',
  enter_value: 'Enter value',
  segment_type: 'Segment Type',
  coupon_code: 'Coupon code',
  coupon_type_name: 'Coupon type name',
  license_cancellation_threshold: 'License Cancellation Threshold(%)',
  name_required_field: 'Name is Required Field',
  tax_required: 'Tax is Required Field',
  cost_required: 'Cost is Required Field',
  user_validity_required: 'User Validity is Required Field',
  catalog_validity_required: 'Catalog Validity is Required Field',
  subscription_type_required: 'Subscription Type is Required Field',
  add_sub_match: 'Add Sub Match',
  quiz_question_sub_match: 'Quiz Question Sub Match',
  create_atleast_2_quiz_question_sub_match: 'Create Atleast 2 Quiz Question Sub Match',
  file_name_contains_spaces: 'File name contains spaces',
  top_10_users: 'Top 10 Users',
  session_level_data: 'Session Level Data',
  total_enrollment: 'Total Enrollment',
  total_attempt: 'Total Attempt',
  ongoing_attempt: 'Ongoing Attempt',
  finished_attempt: 'Finished Attempt',
  slide_info: 'Slide Info',
  overall_top_10_users: 'Overall Top 10 Users',
  performance_percentage: 'Perf (%)',
  part_percentage: 'Part (%)',
  function_template_list: 'Function Template List',
  show_function_template: 'Show Function Template',
  sure_to_delete: 'Are you sure you want to delete?',
  edit_function_template: 'Edit Function Template',
  compile_program: 'Compile Program',
  edit_template: 'Edit Template',
  create_template: 'Create Template',
  keyword_list: 'Keyword List',
  test_script_creation: 'Test Script Creation',
  save_test_script: 'Save Test Script',
  is_visible: 'Is Visible',
  edit_test_script: 'Edit Test Script',
  program: 'Program',
  template_name_must_be_unique: 'Template name must be unique',
  select_either_program_or_zip_file: 'Select either program or zip file',
  clone_component: 'Clone Component',
  weightage_score: 'Weightage Score',
  are_you_sure_to_delete: 'Are you sure to Delete?',
  total_users: 'Total Users',
  not_attempted_users: 'Not Attempted Users',
  in_progress_attempts: 'In Progress Attempts',
  completed_attempts: 'Completed Attempts',
  preview_candidates: 'PREVIEW CANDIDATES',
  first_name: 'First Name',
  last_name: 'Last Name',
  default_template: 'Default Template',
  designation_id_list: 'Designation List',
  skills_and_designation: 'Skill And Designation',
  shuffle_questions: 'Shuffle Questions',
  any: 'Any',
  are_deleted: 'are deleted',
  is_deleted: 'is deleted',
  deleted_ids_copied: "Deleted ID's copied",
  download_sample_excel: 'Download Sample Excel',
  drag_and_drop_your_excel_file_here: 'Drag and Drop your excel file here',
  uploaded_excel_file: 'Uploaded excel file',
  now_click_the_upload_btn: 'Now click the upload button',
  upload_allowed_ips: 'Upload Allowed ips',
  invalid_excel: 'Invalid Excel',
  invalid_excel_file_uploaded: 'Invalid excel file uploaded',
  replace_ips: 'Replace ips',
  append_ips: 'Append ips',
  accessable_ips_bulk_upload_valid_mess: 'Your excel is free of errors. Please click the button below to upload the list of accessible IP addresses',
  variable_validation_error: 'Text inside the curly braces cannot be changed',
  random_qb: 'Random QB',
  generate_coupon: 'Generate Coupon',
  attempts_in_progress: 'Attempt(s) In Progress',
  finish_all_attempts: 'Finish All Attempts',
  duration_instruction: 'Duration for Component should not be zero',
  components_duration: 'Components Duration',
  score_card_v1: 'Score Card version 1',
  score_card_v2: 'Score Card version 2',
  unique_id: 'Unique Id',
  no_of_questions_attempted: 'No. of Questions Attempted',
  no_of_questions_correct: 'No. of Questions Correct',
  no_of_questions_with_penality: 'No. of Questions With Penality',
  no_of_questions_wrong: 'No. of Questions Wrong',
  cam_proctoring_disabled: 'Cam Proctoring Disabled for this Attempt',
  screen_proctoring_disabled: 'Screen Proctoring Disabled for this Attempt',
  accepted_submissions: 'Accepted Submissions',
  reset_password_for_participant: 'Reset Password For Participants',
  verify: 'Verify',
  the_entered_username_is_not_a_participant: 'The Entered Username does not exist or he / she is not a participant',
  errors_in_excel: 'Errors',
  enter_video_url: 'Enter Video URL',
  invalid_url: 'Invalid URL',
  not_a_participant: 'The Entered Username Is Not A Participant',
  user_does_not_exist: 'This User Does Not Exist In This Org. Unit',
  no_beginning_and_ending_spaces: 'Spaces are not allowed at the beginning and ending of passwords',
  multi_select_instruction: 'Please Choose any one Complexity in Filters menu to add multiple questions at a time',
  dq_mixed_warning: 'You can\'t add DQ questions along with other type questions',
  source: 'Source',
  score_card_versions: 'Score Card Versions',
  enter_email_for_report: 'Enter Emails to Send Report',
  email_already_exist: 'Email is already added in the list',
  email_sended_succesfully: 'Mail Initiated',
  remove_questions: 'Remove Questions',
  duplicate_component_name: 'The entered component name has already been taken',
  invalid_submission_number: 'Invalid Submission Number',
  schedule_new_team: 'Schedule / Create Team',
  not_in_participant_role: 'User not in Participant Role',
  cannot_unarchive: 'Cannot unarchive subfolders or files within an archived parent folder',
  resume_found: 'Resume Found',
  remark: 'Remark',
  unarchive: 'Unarchive',
  unarchive_successful: 'Unarchive Successful',
  delete_permanently: 'Delete Permanently',
  overall_dashboard: 'Overall Dashboard',
  scoreboard: 'Scoreboard',
  assessment_specification_dashboard: 'Assessment Specification Dashboard',
  participation: 'Participation',
  student_participation: 'Student Participation In Assessments',
  statistics: 'Statistics',
  perf_statistics: 'Performance Statistics',
  improvement_consistency: 'Improvement / Consistency Chart',
  student: 'Student',
  students: 'Students',
  assessments_taken: 'Assessments Taken',
  z_score: 'Z - Score',
  sl_no: 'Sl No.',
  rank_for_dashboard: 'Rank',
  e_mail: 'E- Mail',
  pseudo_code: 'Pseudo Code',
  is_corporate: 'Is Corporate',
  search_by_email: 'Search by Email',
  assessment_participation: 'Assessment Participation',
  assessments_completed: 'Assessments Completed',
  covered_domains: 'Covered Domains',
  passed_out_year: 'Passed out year',
  search_question: 'Search Question',
  passed_out_year_must_a_number: 'Passed out year must a number',
  passed_out_year_must_have_four_digits: 'Passed out year must have four digits',
  covered_skills: 'Covered Skills',
  average_score: 'Average Score',
  competitive_mode: 'Competitive Mode',
  select_number: 'Select Number',
  relational_schema: 'Consider the following relational schema',
  value_should_be_in_1_to_900: 'Value should be in 1 to 900',
  changing_the_status: '[Changing the status to "Accepted" would delete all the future submissions made by the user for this problem]',
  update_status: 'Update Status',
  reevalution_submission: 'Reevalution Submission',
  submitted_query: 'Submitted Query',
  expected_query: 'Expected Query',
  dataset: 'Dataset',
  expected: 'Expected',
  obtained: 'Obtained',
  differences: 'Differences',
  observations: 'Observations',
  users_score_list: 'Users Score List',
  cant_update_without_changes: 'Can\'t update without any changes',
  view_submission: 'View Submission',
  problems_and_pseudoCode_must: 'Problems and pseudoCode must',
  hiring: 'Hiring',
  personalised: 'Personalised',
  upload_job_description: 'Upload Job Description',
  browse: 'Browse',
  or_drag_a_file_here: 'or drag a file here',
  file_type_error_for_pdf_doc_docx: 'Please upload a PDF, DOC, or DOCX file.',
  location: 'Location',
  job_title: 'Job Title',
  min_salary: 'Min Salary',
  max_salary: 'Max Salary',
  skill_list: 'Skill List',
  good_to_go_skills: 'good_to Go Skills',
  generate_patterns: 'Generate Patterns',
  min_experience: 'Minimum Experience',
  max_experience: 'Maximum Experience',
  no_concept_nodes_found: 'No Concept Nodes Found',
  profiling_not_yet_done: 'Profiling not yet done',
  offline_coding_tag: 'This question does not suit for offline coding.',
  experience_required: 'Experience Required (Years)',
  skills_required: 'Skills Required',
  other_skills_required: 'Other Skills Required',
  salary_range: 'Salary Range',
  pattern_type: 'Pattern Type',
  notes: 'Notes',
  generate_assessments: 'Generate Assessments',
  generated_patterns: 'Generated Patterns',
  are_you_sure_you_want_to_delete_the_pattern: 'Are you sure you want to delete the Pattern ?',
  newly_generated_patterns: 'Newly Generated Patterns',
  no_of_questions: 'Number of Questions',
  focus_area: 'Focus Area',
  time_mins: 'Time(Minutes)',
  add_skill: 'Add Skill',
  random_un_uniform_personalised: 'Random Un Uniform Personalised',
  questionnaire: 'Questionnaire',
  addquestion: 'Add Question',
  question_list: 'Question List',
  filter_question: 'Filter Question',
  score_for_yes: 'Score For Yes',
  score_for_no: 'Score For No',
  save_question: 'Save Question',
  new_choice: 'New Choice',
  content: 'Content',
  comment: 'Comment',
  numbering_enabled: 'Numbering Enabled',
  create_or_edit_question: 'Create or Edit Question',
  please_choose_more_than_one_choice: 'Please Choose Morethan One Choice',
  question_is_saved: 'Question Saved. Check Questions List Tab',
  question_is_updated: 'Question Updated. Check Questions List Tab',
  other_field: 'Other Field',
  other_field_label: 'Other Field Label',
  alignment: 'Alignment',
  score_for_other_field: 'Score For Other Field',
  save_choice: 'Save Choice',
  select_type: 'Select Type',
  create_qestionnaire: 'Create Questionnaire',
  show_qestionnaire: 'Show Questionnaire',
  questionnaire_list: 'Questionnaire List',
  create_questionnaire: 'Create Questionnaire',
  search_questionnaire: 'Search Questionnaire',
  import_questionnaire: 'Import Questionnaire',
  delete_question: 'Delete Question',
  attempted_ques: 'Attempted Question.Unable to Delete',
  good_to_have_skills: 'Good to have Skills',
  must_have_skills: 'Must have Skills',
  property: 'Property',
  order: 'Order',
  record_count: 'Record Count',
  value: 'Value',
  dob: 'Date of Birth',
  deactivate: 'De-Activate',
  de_select_all: 'De-Select All',
  show_user: 'Show User',
  aadhaar_number: 'Aadhaar Number',
  age: 'Age',
  enrollment_details: 'Enrollment Details',
  badges: 'Badges',
  ug_institution: 'UG Institution',
  degree: 'Degree',
  department: 'Department',
  x_percentage: 'X Percentage',
  xii_percentage: 'XII Percentage',
  ug_percentage: 'UG Percentage',
  diploma_institution: 'Diploma Institution',
  diploma_percentage: 'Diploma Percentage',
  x_institution: 'X Institution',
  xii_institution: 'XII Institution',
  no_badges_yet: 'You have not unlocked any badges yet',
  pg_institution: 'PG Institution',
  pg_percentage: 'PG Percentage',
  mobile_verification: 'Mobile Verification',
  user_list: 'User List',
  insOrOrg: 'Institution/Organization',
  create_user: 'Create User',
  choose_segment_type: 'Choose Segment Type',
  choose_ins_org: 'Choose Ins/Org before choosing batch',
  choose_segment_before_ins: 'Choose Seg. Type before choosing Ins/Org',
  re_enter_your_password_again: 'Enter your password again',
  enter_password: 'Enter your password',
  enter_email: 'Enter Email',
  enter_name: 'Enter Name',
  enter_username: 'Enter username',
  re_enter_your_password: 'Re-Enter Password',
  no_data_available: 'No Data Available',
  stream: 'Stream',
  both: 'Both',
  passwords_must_match: 'Passwords Must Match',
  email_already_exists: 'This Email Already Exists',
  user_created_successfully: 'User Created Successfully',
  institution: 'Institution',
  ug: 'UG',
  pg: 'PG',
  school_details: 'SCHOOL',
  tenth: 'X',
  twelth: 'XII',
  accessible_segments: 'Accessible Segments',
  reclytics_caps: 'RECLYTICS',
  skilllytics_caps: 'SKILLLYTICS',
  teams_caps: 'TEAMS',
  streams: 'STREAMS',
  batches: 'BATCHES',
  tracks: 'TRACKS',
  staffing_requirement_name: 'Staffing Requirement Name',
  add_workflow_details: 'Add Workflow Details',
  show_in_thive: 'Show In T-Hive',
  job_description: 'Job Description',
  shortlisted_candidates: 'ShortListed Candidates',
  deadline: 'Deadline',
  no_of_positions: 'No. Of. Positions',
  client_name: 'Client Name',
  about_client: 'About Client',
  client_logo: 'Client Logo',
  add_logo: 'Add Logo',
  end_date_must_be_later_than_start_date: 'End Date Must Be greater than Start Date',
  max_exp_must_be_greater_than_min_exp: 'Max. Exp. Must be greater than Min. Exp.',
  close_requirement: 'Close Requirement',
  edit_workflow: 'Edit Workflow',
  transfer_team: 'Transfer Team',
  add_team: 'Add Team',
  reclytics_team: 'Reclytics Team',
  user_segment_amendment: 'User Segment Amendment',
  existing_rec_team: 'Existing Reclytics Team',
  new_rec_team: 'New Reclytics Team',
  with_attempt: 'With Attempt',
  without_attempt: 'Without Attempt',
  base_org: 'Base Organization',
  accessible_org: 'Accessible Organization',
  accessible_segment: 'Accessible Segment',
  not_tagged: 'Not Tagged',
  transfer: 'Transfer',
  exp_and_skill: 'Experience And Skills',
  company_name: 'Company Name',
  employment_type: 'Employment Type',
  current_status: 'Current Status',
  presently_working_here: 'Presently Working Here',
  emp_details: 'EMPLOYMENT DETAILS',
  skills_caps: 'SKILLS',
  cert_details: 'CERTIFICATION DETAILS',
  image: 'Image',
  issued_by: 'Issued By',
  completion_date: 'Completion Date',
  check_assessments: 'Check Assessments',
  assessments_with_attempt: 'Assessments With Attempts',
  assessments_without_attempt: 'Assessments Without Attempts',
  user_transferred_successfully: 'User Transferred Successfully',
  actions_caps: 'ACTIONS',
  no_assessments_found: 'No Assessments Found',
  insert: 'Insert',
  questionnaire_template: 'Questionnaire Template',
  questionnaire_question_list_export: 'Questionnaire question list export',
  batch_report: 'Batch Report',
  search_stream: 'Search Stream',
  search_track: 'Search Track',
  search_batch: 'Search Batch',
  search_course: 'Search Course',
  unselect_all: 'Unselect All',
  all_batch_analysis: 'All Batch Analysis',
  no_of_users: 'Number of Users',
  no_of_courses: 'Number of Courses',
  avg_no_of_course_completed: 'Average number of courses completed',
  avg_participant_percentage: 'Average Participation Percentage',
  avg_performance_percentage: 'Average Performance Percentage',
  user_who_have_cleared_all_assesment: '% of users who have cleared all assessments',
  no_of_assesment_types: 'No. of assessments types',
  batch_name: 'Batch Name',
  deactivated_users: 'Deactivated Users',
  deactivated: 'Deactivated',
  click_to_activete_user: 'Click to Activate User',
  activate_user: 'Activate User',
  deactivate_admin_access: 'Contact admin to Deactivate User',
  activate_admin_access: 'Contact admin to Activate User',
  course_wise_report: 'Course Wise Report',
  yes_score: 'Yes score',
  no_score: 'No score',
  batch_caps: 'BATCH',
  add_batch: 'Add Batch',
  transfer_batch: 'Transfer Batch',
  existing_batches: 'Existing Batches',
  select_team_list: 'Select Team List',
  dbms_bulkupload: 'DBMS Bulk Upload',
  existing_tracks: 'Existing Tracks',
  existing_teams: 'Existing Teams',
  existing: 'EXISTING',
  new_caps: 'NEW',
  new_team: 'New Team',
  track_name: 'Track Name',
  from_team: 'From Team',
  to_team: 'To Team',
  rounds: 'Rounds',
  swap: 'Swap',
  create_assessment: 'Create Assessment',
  clone_assessment: 'Clone Assessment',
  clone_interview: 'Clone Interview',
  clone: 'Clone',
  educational_qualification: 'Educational Qualification',
  rearrange_successful: 'Rearranged Successfully',
  matches: 'Matches',
  invite: 'Invite',
  current_company: 'Current Company',
  drag_a_file_here: 'Drag a file here',
  ai_generate_feedback: 'AI Generate Feedback',
  enable_learning_mode: 'Enable Learning Mode',
  allow_microphone_permission: 'Allow Microphone Permission',
  allow_microphone: 'Allow Microphone',
  ai_gen_questions: 'AI Generated Questions',
  regen_ques: 'Re-Generate Question',
  view_answers: 'View Answers',
  export_summary: 'Export Summary',
  complexity_type: 'Complexity Type',
  sure_to_export: 'Are you sure you want to export ?',
  assessment_generating_instruction_1: 'Your assessment is currently being generated. This process typically takes about 5 minutes.',
  assessment_generating_instruction_2: 'Please wait patiently and do not refresh or navigate away from this page.',
  assessment_generating_instruction_3: 'Thank you for your patience!',
  edit_only_participants: 'Segment Details Can Only Be Edited For Participants.',
  no_save_occured: 'No Save Occured',
  compiled_save_count: 'Compiled Save Count',
  executed_save_count: 'Executed Save Count',
  validated_save_count: 'Validated Save Count',
  invite_to_talent_pulse: 'Invite to talent pulse',
  invite_to_round_1: 'Invite to round 1',
  assessment_link: 'Assessment Link',
  web_link: 'Web Link',
  user_not_added: 'User not added',
  no_teams_are_created: 'No teams are created for this work flow. Kindly create a team and try again.',
  assign_users: 'Assign Users',
  previous_round_slot: 'Previous Round Slot',
  include_assigned_candidates: 'Include assigned candidates',
  only_shortlisted_candidates: 'Only shortlisted candidates',
  only_waitlisted_candidates: 'Only waitlisted candidates',
  assign_all_users_list: 'Assign All Users List',
  genuinity_score_calculated_successfully: 'Genuinity Score Calculated Successfully',
  prompt_management: 'Prompt Management',
  add_prompt: 'Add Prompt',
  additional_info: 'Additional Info',
  prompt: 'Prompt',
  no_prompt_found: 'No Prompt Found',
  mandatory_field: 'Mandatory Field',
  face_recognition: 'Face Recognition',
  total_duration_must_equal: 'Total Duration Must Equal',
  concept_node_list: 'Concept Node List',
  create_concept: 'Create Concept',
  create_concept_node: 'Create Concept Node',
  concept_details: 'Concept Details',
  delete_caps: 'DELETE',
  rename_caps: 'RENAME',
  create_caps: 'CREATE',
  tagged_questions: 'Tagged Questions',
  edit_concept_node: 'Edit Concept Node',
  delete_concept_node: 'Delete Concept Node',
  no_questions_found: 'No Questions Found',
  good_to_have_skills_threshold: 'Good to have Skills Threshold',
  must_have_skills_threshold: 'Must have Skills Threshold',
  threshold_error: 'Threshold must be in between 0 and 100',
  create_nano_assessment: 'Create Nano Assessment',
  create_custom_assessment: 'Create Custom Assessment',
  create_personalised_assessment: 'Create Personalised Assessment',
  algorithmic_complexity: 'Algorithmic Complexity',
  choose_algorithmic_complexity: 'Choose Algorithmic Complexity',
  algorithmic_complexity_enable: 'Enable Algorithmic Complexity',
  no_testcase_or_complexity: 'This Question has no Testcase or it has no Algorithmic Complexity',
  random_qn_disabled_warning: 'Some questions in this questionbank doesn\'t have Algorithmic Complexity',
  job_type: 'Job Type',
  insights_from_resume: 'Insights Derived From Resume',
  institution_name: 'Institution Name',
  degree_type: 'Degree Type',
  mode_of_score: 'Mode of Score',
  assessment_manager: 'Assessment Manager',
  preskillassessment: 'Pre-Skill',
  postskillassessment: 'Post-Skill',
  skillvalidationassessment: 'Skill Validation',
  check_ai_evaluation: 'Check AI Evaluation',
  feedback_count: 'Feedback Count',
  questionnaire_questions: 'Questionnaire Questions',
  document_no: 'Document Number',
  mapping: 'Mapping',
  get_count: 'Get Count',
  document_feedback_mapping: 'Document Feedback Mapping',
  feedback_count_must_be_greater_than_0: 'Feedback Count must be greater than 0',
  count_must_be_greater_than_0: 'Count must be greater than 0',
  max_100: 'Minimum should be 100',
  mail_template: 'Mail Template',
  job_code: 'Job Code',
  about_company: 'About Company',
  manual_creation: 'Manual Creation',
  job_name: 'Job Name',
  no_of_position_error: 'No of Position must be a Positive Integer',
  add_location: 'Add Location',
  deadline_error: 'Deadline must be later than current date',
  min_max_error: 'Maximum value must be greater then or equal to minimum value',
  finish: 'Finish',
  automatic_creation: 'Automatic Creation',
  notice_period: 'Notice Period',
  image_logo_error: 'Please Choose .png or .jpeg File',
  experience_required_in_years: 'Experience Required (Years)',
  select_notice_period: 'Select Notice Period',
  location_error: 'Add atleast 1 Job Location',
  remote_location: 'Remote Location',
  fresher: 'Fresher',
  candidate_id: 'Candidate Id',
  user_already_exists: 'User Already Exists',
  Interview: 'Create Interview',
  Nano: 'Create Nano Assessment',
  Customised: 'Create Custom Assessment',
  Personalised: 'Create Personalised Assessment',
  assessment_type: 'Assessment Type',
  create_virtual_machines: 'Create Virtual Machines',
  required_machine: 'Required Machine',
  platform: 'Platform',
  subnet: 'Subnet',
  time_slot: 'Time Slot',
  need_extra_nic: 'Need Extra NIC',
  need_extra_valume: 'Need Extra Value',
  user_team: 'User Team List',
  clear: 'Clear',
  status_list: 'Status',
  weightage_must_be_greater_than_0: 'Weightage must be greater than 0',
  weightage_must_be_greater_than_or_equal_to_100: 'Weightage must be greater than or equal to 100',
  delete_choice: 'Delete Choice',
  user_unauthorised: 'User Unauthorised',
  select_image: 'Select Image',
  mandate_upload_resume: 'Mandate Upload Resume',
  entity: 'Entity',
  uml_entities_list: 'UML Entities List',
  attribute_list: 'Attribute List',
  relation_list: 'Relation List',
  uml_question_type: 'UML Question Type',
  recreate: 'Recreate',
  send: 'Send',
  modifying_prompts: 'Modifying prompts',
  er_diagram_entities: 'ER DIAGRAM ENTITIES',
  attributes_for_building: 'ATTRIBUTES FOR BUILDING',
  attributes_for_building_type: 'ATTRIBUTES FOR BUILDING TYPE',
  attributes_for_slab: 'ATTRIBUTES FOR SLAB',
  attributes_for_electricity_reading: 'ATTRIBUTES FOR ELECTRICITY_READING',
  er_diagram_relations: 'ER DIAGRAM RELATIONS',
  er_diagram: 'ER DIAGRAM',
  present: 'Present',
  add_entities: 'Add Entities',
  add_attributes: 'Add Attributes',
  cardinality: 'Cardinality',
  from_and_to_are_different: 'Please make sure "from" and "to" are different values.',
  from_Relations_is_required: 'From Relations is Required',
  to_relations_is_required: 'To Relations is Required',
  relation_is_required: 'Relations is Required',
  select_from: 'Select from',
  select_to: 'Select to',
  select_relation: 'Select relation',
  above_relation_is_already_added: 'Above relation is already added',
  image_added_sucessfully: 'Image added sucessfully',
  image_failed_to_add: 'Image failed to add',
  preview_image: 'Preview Image',
  mode_of_evaluation: 'Mode Of Evaluation',
  registration_success_message: 'Registration Success Message',
  ai_resume_extraction: 'AI Resume Extraction',
  baseproblemid: 'Base Problem Id',
  audio_proctoring: 'Audio Proctoring',
  microphoneInActive: 'Microphone is inactive',
  video: 'Video',
  audio: 'Audio',
  total_no_of_questions: 'Total No. Of. Questions',
  prompt_template: 'Prompt Template',
  keywords: 'Keywords',
  is_default: 'Is Default',
  no_keywords: 'No Keywords Present',
  keyword_value: 'Keyword-Value',
  show_and_finalize_prompt: 'View and Finalize Prompt',
  preview_finalized_prompt: 'Preview Finalized Prompt',
  update_finalized_prompt: 'Update Finalized Prompt',
  finalized_prompt: 'Finalized Prompt',
  dq_type: 'DQ Question Type',
  descriptive_question_type: 'Descriptive Question Type',
  no_of_attempts_for_media_type: 'No of attempts for media type',
  threshold_must_be_greater_than_0: 'Threshold must be greater than 0',
  threshold_is_mandatory: 'Threshold is mandatory',
  media_type: 'Media Type',
  media_type_allowed_duration: 'Media type allowed duration',
  text: 'Text',
  evaluate_later: 'Evaluate Later',
  evaluate_later_condition: 'Evaluate later must be turned off when show answer is enabled',
  evaluate_now: 'Evaluate Now',
  activities_list: 'Activities List',
  actor: 'Actor',
  time_interval: 'Time Interval',
  reminder: 'Reminder',
  add_configuration: 'Add Configuration',
  there_is_no_team_available_for_scheduling: 'There is no team available for scheduling.',
  evaluation_status: 'Evaluation Status',
  evaluating: 'Evaluating',
  no_errors: 'No Errors',
  errors_found: 'Errors Found',
  existing_questions: 'Existing Questions',
  to_be_generated: 'To be generated',
  viva_optional: 'Viva Question (Optional or Max. 5)',
  ai_generated_questions: 'AI Generated Questions',
  regenerate_question: 'Regenerate Question',
  generating: 'Generating',
  testcase_results: 'Testcase Results',
  obtained_result: 'Obtained Result',
  expected_result: 'Expected Result',
  concept_name: 'Concept Name',
  assign_weightage: 'Assign Weightage',
  minimum_0: 'Minimum 0',
  maximim_100: 'Maximum 100',
  select_atleast_one_language: 'Select atleast one language',
  weightage_must_sum_100: 'Weightage Sum must be 100',
  reevaluate: 'Re-Evaluate',
  template_for_media: 'Template for media',
  complexity_category_name: 'Complexity Category Name',
  question_score: 'Question Score',
  attempt_score: 'Attempt Score',
  pass_percentage: 'Pass Percentage',
  get_session: 'Get Session',
  concept_node_statement: 'Refer the base problem for viewing the tagged concept.',
  highlighter: '-------------------------------------',
  cannot_add_pool: 'Can\'t Add Pool',
  cannot_add_pool_description: 'You can\'t add another pool because there is only one pool left.',
  cannot_remove_pool: 'Can\'t remove Pool',
  cannot_remove_pool_description: 'You cannot remove this pool because there is only one tagged pool left.',
  existing_pools: 'Existing Pools',
  assigned_pools: 'Assigned Pools',
  assigned_pool: 'Assigned Pool',
  total_pools: 'Total Pools',
  total_pool: 'Total Pool',
  pool_list: 'Pool List',
  no_pools_available: 'No pools available',
  no_tagged_pools_available: 'No Tagged pools available',
  fill_up_count: 'Fillup Count',
  remainder: 'Remainder',
  are_you_sure_remainder: 'Mail alert will be configured immediately if the "Enable Email Reminder" is turned on. Are you sure?',
  given_input: 'GIVEN INPUT:',
  expected_output_: 'EXPECTED OUTPUT:',
  generated_prog_list_empty: 'InVisible test case is less then the problem creation count',
  uploaded_successfully: 'Uploaded Successfully!',
  mappings_to_rounds_or_interview: 'Mappings to Rounds or Interview',
  notifications: 'Notifications',
  workflow: 'Workflow',
  application_link_metrics: 'Application Link Metrics',
  round: 'Round',
  market_place_candidates: 'Market Place Candidates',
  candidates_attended: 'Candidates Attended',
  assessment_attempts: 'Assessment Attempts',
  assessment_passed: 'Assessment Passed',
  triggered_links: 'Triggered Links',
  opened_links: 'Opened Links',
  entries_per_page: 'Entries per Page',
  enable_automation: 'Enable Automation',
  add_preferred_location: 'Add Preferred Location',
  preferred_location: 'Preferred Location',
  users_without_resume: 'Users Without Resume',
  workflow_interval: 'Workflow Interval (in Days)',
  finish_setup_true: 'Setup Finished',
  finish_setup_false: 'Setup Not Finished',
  setup_successfully_finished: 'Setup Successfully Finished',
  delete_round_confirmation: 'Are you sure to delete this round?',
  scores_and_reports: 'Scores and Reports',
  select_round: 'Select Round',
  round_no: 'Round no.',
  duration_in_minutes: 'Duration (in Mins.)',
  greater_than_1: 'Must be greater than 1',
  edit_assessment: 'Edit Assessment',
  trigger_mail: 'Trigger Mail',
  already_mail_sent_error: 'Can\'t invite already invited user',
  invite_status: 'Invite Status',
  already_invited: 'Already Invited',
  not_yet_invited: 'Not yet Invited',
  filter_not_invited_users: 'Filter not yet invited users',
  must_have_skills_error: 'Add atleast 1 Skill',
  time_taken: 'Time Taken (min)',
  confirm_password: 'Confirm Password',
  create_vm: 'Create Virtual Machine',
  select_instance_type: 'Select Instance Type',
  select_protocol: 'Select Protocol',
  os_version: 'Os Version',
  protocol: 'Protocol',
  port: 'Port',
  instance_type: 'Instance Type',
  image_id: 'Image Name',
  architecture: 'Architecture',
  version: 'Version',
  select_course: 'Select Course',
  vm_management: 'Virtual Machine Management',
  is_vm_assessment: 'VM Assessment',
  add_credit: 'Add Credit',
  select_assessmant: 'Select Assessment',
  task_board: 'Task Board',
  overview: 'Overview',
  project_completion: 'Project Completion',
  inprogress: 'In-Progress',
  completed_tasks: 'Completed Tasks',
  select_sprint: 'Select sprint',
  sprint: 'Sprint',
  task_overview: 'Task Overview',
  view_team_progress: 'View Team Progress',
  task_title: 'Task Title',
  sprint_name: 'Sprint Name',
  assigned_on: 'Assigned on',
  epic_name: 'Epic Name',
  burn_up_chart: 'Burn Up Chart',
  burn_down_chart: 'Burn Down Chart',
  velocity_chart: 'Velocity Chart',
  epic_chart: 'Epic Chart',
  sprint_report: 'Sprint Report',
  cumulative_flow_diagram: 'Cumulative Flow Diagram',
  control_chart: 'Control Chart',
  version_report: 'Version Report',
  no_tasks_found: 'No Tasks Found',
  team_member: 'Team Member',
  select_team_member: 'Select Team Member',
  todo: 'To-Do',
  preview_question: 'Preview Question',
  take_attempt: 'Take Attempt',
  no_projects_here: 'No Projects Here',
  task_starts_on: 'Task starts on',
  deadline_over: 'Your deadline for the task is over',
  sprint_completion: 'Sprint Completion',
  my_task: 'My Task',
  team_progress: 'Team Progress',
  generate_questions: 'Generate Questions',
  choose_quiz_question_type: 'Choose quiz question type',
  choose_complexity: 'Choose complexity',
  no_of_options: 'No. of options',
  field_required_and_only_numbers: 'Field is required and must contain only numbers',
  topic: 'Topic',
  choose_skill: 'Choose Skill',
  choose_concept_node: 'Choose Concept Node',
  regenerate: 'Re-Generate',
};

export default Recruiter;
