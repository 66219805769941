import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { HRLFormik, useComponentDidMount, FullPageLoader } from '@hrlytics/corelytics';
import writeXlsxFile from 'write-excel-file';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import OperationHeader from '@globalComps/header/OperationHeader';
import {
  Button, ButtonContent, Dropdown, Form, Icon,
} from 'semantic-ui-react';
import styles from './services.module.less';

const UserBatchReport = (props) => {
  const { t } = props;

  const [batchReportList, setBatchReportList] = useState({
    streamOptions: [],
    trackOptions: [],
    batchOptions: [],
    overallReportData: [],
    selectedConfigIds: [],
    isFullPageLoading: false,
  });
  const [selectAll, setSelectAll] = useState({
    stream: false,
    track: false,
    batch: false,
  });

  const [selectionOrder, setSelectionOrder] = useState([]);

  const {
    streamOptions, trackOptions, batchOptions,
    overallReportData, selectedConfigIds, isFullPageLoading,
  } = batchReportList;

  const removeDuplicates = (options) => {
    const seenKeys = new Set();
    return options.filter((option) => {
      const duplicate = seenKeys.has(option.key);
      seenKeys.add(option.key);
      return !duplicate;
    });
  };

  const getInstitutionDetails = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { batch_detail_list } } = respData;
      if (estatus && emessage) {
        setBatchReportList((prev) => ({
          ...prev,
          streamOptions: removeDuplicates(batch_detail_list.map((e) => ({
            key: e.stream_id,
            value: e.stream_id,
            text: e.stream_name,
            track_config_id: e.id,
          }))),
          trackOptions: removeDuplicates(batch_detail_list.map((e) => ({
            key: e.track_id,
            value: e.track_id,
            text: e.track_name,
            track_config_id: e.id,
          }))),
          batchOptions: removeDuplicates(batch_detail_list.map((e) => ({
            key: e.batch_id,
            value: e.batch_id,
            text: e.batch_name,
            track_config_id: e.id,
          }))),
          overallReportData: batch_detail_list,
        }));
      }
    }, resturls.getInstitutionDetails, {}, 'GET');
  };

  const obtainBatchReport = () => {
    setBatchReportList((prev) => ({
      ...prev,
      isFullPageLoading: true,
    }));
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { batch_list } } = respdata;
        if (estatus && emessage) {
          setBatchReportList((prev) => ({
            ...prev,
            isFullPageLoading: false,
          }));
          const commonHeaderStyles = {
            backgroundColor: '#133a1b',
            align: 'center',
            fontWeight: 'bold',
            borderColor: '#000000',
            fontSize: 10,
          };

          const excelSheets = batch_list.map((e, i) => e.name || `Batch${i + 1}`);
          const excelHeader1 = [
            { value: 'S. No.', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Name', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Username', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Participation Percentage', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Certification', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Performance Percentage', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Course Completion Count', rowSpan: 2, ...commonHeaderStyles },
            { value: 'Time Spent', rowSpan: 2, ...commonHeaderStyles },
          ];

          const excelHeader2 = [];
          const courseHeaders = [];

          excelHeader1.forEach(() => {
            excelHeader2.push(null);
          });

          batch_list.forEach((batch) => {
            batch.user_details.forEach((user) => {
              user.course_details_list.forEach((course) => {
                if (!courseHeaders.includes(course.name)) {
                  courseHeaders.push(course.name);
                  excelHeader1.push({ value: course.name, span: 3, ...commonHeaderStyles },
                    null, null);
                  excelHeader2.push({ value: 'Score Participation', ...commonHeaderStyles });
                  excelHeader2.push({ value: 'Score Performance', ...commonHeaderStyles });
                  excelHeader2.push({ value: 'Certification', ...commonHeaderStyles });
                }
              });
            });
          });

          const getBgColor = (val) => {
            let clrcode = '';
            if (val >= 0 && val <= 25) clrcode = '#FF0000';
            else if (val >= 25 && val <= 50) clrcode = '#FFA500';
            else if (val >= 50 && val <= 75) clrcode = '#90EE90';
            else if (val >= 76) clrcode = '#008000';
            return clrcode;
          };

          const sheetData = [];

          batch_list.forEach((batch) => {
            const excelBody = [];
            batch.user_details.forEach((user, ind) => {
              const userData = [
                { value: ind + 1, align: 'center' },
                { value: user.name },
                { value: user.username },
                { value: user.participation_percentage, backgroundColor: getBgColor(user.participation_percentage), borderColor: '#000000' },
                { value: user.Certification, backgroundColor: getBgColor(user.Certification), borderColor: '#000000' },
                { value: user.performance_percentage, backgroundColor: getBgColor(user.performance_percentage), borderColor: '#000000' },
                { value: user.course_completion_count },
                { value: user.time_spent },
              ];

              courseHeaders.forEach((courseName) => {
                const course = user.course_details_list.find((e) => e.name === courseName);
                if (course) {
                  const scoreParticipation = course.score_dict.score_participation;
                  const scorePerformance = course.score_dict.score_performance;
                  const { certification } = course.score_dict;

                  userData.push({ value: scoreParticipation, backgroundColor: getBgColor(scoreParticipation), borderColor: '#000000' });
                  userData.push({ value: scorePerformance, backgroundColor: getBgColor(scorePerformance), borderColor: '#000000' });
                  userData.push({ value: certification, backgroundColor: getBgColor(certification), borderColor: '#000000' });
                } else {
                  userData.push({ value: '' });
                  userData.push({ value: '' });
                  userData.push({ value: '' });
                }
              });
              excelBody.push(userData);
            });
            sheetData.push([
              excelHeader1,
              excelHeader2,
              ...excelBody,
            ]);
          });

          const columnWidths = [
            {}, { width: 20 }, { width: 35 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 },
          ];

          courseHeaders.forEach(() => {
            columnWidths.push({ width: 20 });
            columnWidths.push({ width: 20 });
            columnWidths.push({ width: 20 });
          });

          const columns = new Array(batch_list.length).fill(columnWidths);

          writeXlsxFile(
            sheetData,
            {
              columns,
              fileName: 'userBatchReport.xlsx',
              sheets: excelSheets,
            },
          );
        }
      },
      resturls.obtainBatchReport,
      { track_config_id_list: selectedConfigIds },
      'POST',
    );
  };

  useComponentDidMount(getInstitutionDetails);

  const updateAssociatedFields = (name, selectedItems = [], setFieldValue) => {
    const associatedFields = {
      stream: new Set(),
      track: new Set(),
      batch: new Set(),
    };

    selectedItems.forEach((itemId) => {
      overallReportData.forEach((item) => {
        if (item[`${name}_id`] === itemId) {
          associatedFields.stream.add(item.stream_id);
          associatedFields.track.add(item.track_id);
          associatedFields.batch.add(item.batch_id);
        }
      });
    });

    Object.entries(associatedFields).forEach(([fieldName, associatedValues]) => {
      if (fieldName !== name) {
        setFieldValue(fieldName, Array.from(associatedValues));
      }
    });
  };

  const handleSelection = (name, value, values, setFieldValue) => {
    if (!selectionOrder.includes(name)) {
      setSelectionOrder([...selectionOrder, name]);
    }
    const selectedStreams = name === 'stream' ? value : values.stream;
    const selectedTracks = name === 'track' ? value : values.track;
    const selectedBatches = name === 'batch' ? value : values.batch;

    const selectedItems = {
      stream: name === 'stream' ? value : values.stream,
      track: name === 'track' ? value : values.track,
      batch: name === 'batch' ? value : values.batch,
    };

    updateAssociatedFields(name, selectedItems[name], setFieldValue);

    const allSelectedConfigIds = new Set();

    selectedStreams.forEach((streamId) => {
      const stream = streamOptions.find((option) => option.value === streamId);
      if (stream) allSelectedConfigIds.add(stream.track_config_id);
    });

    selectedTracks.forEach((trackId) => {
      const track = trackOptions.find((option) => option.value === trackId);
      if (track) allSelectedConfigIds.add(track.track_config_id);
    });

    selectedBatches.forEach((batchId) => {
      const batch = batchOptions.find((option) => option.value === batchId);
      if (batch) allSelectedConfigIds.add(batch.track_config_id);
    });

    setBatchReportList((prev) => ({
      ...prev,
      selectedConfigIds: Array.from(allSelectedConfigIds),
    }));
  };

  const handleSelectAll = (name, values, setFieldValue) => {
    let allValues = [];
    if (name === 'stream') {
      allValues = streamOptions.map((option) => option.value);
    } else if (name === 'track') {
      allValues = trackOptions.map((option) => option.value);
    } else if (name === 'batch') {
      allValues = batchOptions.map((option) => option.value);
    }

    setFieldValue(name, allValues);
    handleSelection(name, allValues, values, setFieldValue);
    setSelectAll((prev) => ({ ...prev, [name]: !selectAll[name] }));
  };

  const handleUnselectAll = (field, setFieldValue) => {
    setFieldValue('stream', []);
    setFieldValue('track', []);
    setFieldValue('batch', []);
  };

  return (
    <div>
      {isFullPageLoading && <FullPageLoader />}
      <OperationHeader />
      <div className={styles.userBatchReport}>
        <div fontas="beta header">{t('batch_report')}</div>
        <div>
          <HRLFormik
            initialValues={{
              stream: [],
              track: [],
              batch: [],
            }}
          // validationSchema={schema}
          // onSubmit={submitQuestionnaire}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleSelect,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Field
                    required
                    control={Dropdown}
                    selection
                    width={4}
                    search
                    options={
                      streamOptions.length === values.stream.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...streamOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...streamOptions]
                    }
                    multiple
                    label={t('stream')}
                    placeholder={t('search_stream')}
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('stream', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('stream', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('stream', d.value, values, setFieldValue);
                      }
                    }}
                    name="stream"
                    value={values.stream}
                    onBlur={handleBlur}
                    error={touched.stream && errors.stream}
                  />
                  <Form.Field
                    required
                    control={Dropdown}
                    selection
                    width={4}
                    label={t('track')}
                    placeholder={t('search_track')}
                    search
                    options={
                      trackOptions.length === values.track.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...trackOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...trackOptions]
                    }
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('track', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('track', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('track', d.value, values, setFieldValue);
                      }
                    }}
                    multiple
                    name="track"
                    value={values.track}
                    onBlur={handleBlur}
                    error={touched.track && errors.track}
                  />
                  <Form.Field
                    required
                    control={Dropdown}
                    selection
                    width={4}
                    options={
                      batchOptions.length === values.batch.length
                        ? [{ key: 'unselect_all', value: 'unselect_all', text: t('unselect_all') }, ...batchOptions]
                        : [{ key: 'all', value: 'all', text: t('select_all') }, ...batchOptions]
                    }
                    onChange={(e, d) => {
                      if (d.value.includes('all')) {
                        handleSelectAll('batch', values, setFieldValue);
                      } else if (d.value.includes('unselect_all')) {
                        handleUnselectAll('batch', setFieldValue);
                      } else {
                        handleSelect(e, d);
                        handleSelection('batch', d.value, values, setFieldValue);
                      }
                    }}
                    label={t('batch')}
                    placeholder={t('search_batch')}
                    search
                    multiple
                    name="batch"
                    value={values.batch}
                    onBlur={handleBlur}
                    error={touched.batch && errors.batch}
                  />
                </Form.Group>
                {
                  (
                    values.stream.length > 0 && values.track.length > 0 && values.batch.length > 0
                  ) && (
                    <div className={styles.downloadBatchReportBtn}>
                      {/* <Button basic onClick={obtainBatchReport} type="button">
                        <Icon name="download" />
                        {t('download')}
                      </Button> */}
                      <Button onClick={obtainBatchReport} type="button" animated>
                        <ButtonContent visible>{t('download')}</ButtonContent>
                        <ButtonContent hidden>
                          <Icon name="download" />
                        </ButtonContent>
                      </Button>
                    </div>
                  )
                }
              </Form>
            )}
          </HRLFormik>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('recruiter')(UserBatchReport);
