import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Grid, Icon, Input, Table, TextArea,
} from 'semantic-ui-react';
import { restbaseurl } from '@utils/constants';
import { HRLModal } from '@hrlytics/corelytics';
import HtmlParser from 'react-html-parser';
import { resturls } from '@utils/apiurls';
import CKEditor from 'ckeditor4-react';
import styles from './less/questionType.module.less';

const RadioButtonNewChoiceOption = (props) => {
  const {
    t, saveChoicesForRadioButton, setComment, comment,
    choice_list, content, setScore, score, setContent,
    setEditedQuestionId, setChoiceTableData,
  } = props;

  const [deleteQuestionModalOpen, setdeleteQuestionModalOpen] = useState(false);
  const [deletedChoiceIndex, setDeletedChoiceIndex] = useState('');

  const editChoice = (e) => {
    setComment(e.comment);
    setScore(e.score);
    setContent(e.content);
    setEditedQuestionId(e.id);
  };

  const deleteChoice = () => {
    setdeleteQuestionModalOpen(true);
    const newChoiceList = choice_list.filter((_, i) => i !== deletedChoiceIndex);
    setChoiceTableData(newChoiceList);
    setdeleteQuestionModalOpen(false);
  };

  return (
    <div className={styles.radioButtonNewChoice}>
      <div>
        {
          choice_list?.length > 0 && (
            <div className={styles.radioButtonNewChoiceTable}>
              <Table celled fixed singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('content')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('score')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('comment')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('edit')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('edit')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {
                  choice_list?.map((e, index) => (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{HtmlParser(e.content)}</Table.Cell>
                        <Table.Cell>{e.score}</Table.Cell>
                        <Table.Cell>{e.comment}</Table.Cell>
                        <Table.Cell>
                          <Icon
                            name="edit"
                            onClick={() => editChoice(e)}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Icon
                            name="trash"
                            onClick={() => {
                              setdeleteQuestionModalOpen(true);
                              setDeletedChoiceIndex(index);
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  ))
                }
              </Table>
            </div>
          )
        }
        <h1>{t('new_choice')}</h1>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <span fontas="alpha">
                {t('content')}
              </span>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              <CKEditor
                id="content"
                config={{
                  filebrowserUploadUrl: `${restbaseurl}${resturls.uploadAndGenerateLinkForQuizImage}`,
                  extraPlugins: ['font', 'uploadimage'],
                  removePlugins: ['TextTransformation', 'about'],
                  uploadUrl: `${restbaseurl}${resturls.uploadAndGenerateLinkForQuizImage}`,
                  versionCheck: false,
                }}
                name="content"
                data={content}
                onChange={(event) => {
                  const data = event.editor.getData();
                  setContent(data);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <span fontas="alpha">
                {t('comment')}
              </span>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              <TextArea
                id="comment"
                fontas="alpha"
                name="comment"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.rangeChoiceInput}>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <span fontas="alpha">
                {t('score')}
              </span>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              <Input
                min={0}
                max={100}
                onChange={(e, { value }) => setScore(value)}
                step={1}
                type="range"
                value={score}
              />
              <span fontas="alpha">{score}</span>
            </Grid.Column>
          </Grid.Row>
          <div>
            <Button onClick={() => saveChoicesForRadioButton()} type="button">{t('save_choice')}</Button>
          </div>
        </Grid>
      </div>
      {
        deleteQuestionModalOpen && (
          <HRLModal
            size="tiny"
            open
            setOpen={() => setdeleteQuestionModalOpen(false)}
            modalHeaderContent={t('delete_choice')}
            headerPosition="center"
            contentClassName={styles.deleteQuestionModal}
          >
            <p fontas="alpha">{t('are_you_sure_')}</p>
            <div>
              <Button onClick={deleteChoice}>{t('yes')}</Button>
              <Button onClick={() => setdeleteQuestionModalOpen(false)}>
                {t('no')}
              </Button>
            </div>
          </HRLModal>
        )
      }
    </div>
  );
};
export default withTranslation('recruiter')(RadioButtonNewChoiceOption);
