import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import UserInfo from '@models/UserInfo';
import styles from './ReclyticsHeader.module.less';

function ReclyticsHeader(props) {
  const {
    t, Showicon = true, assessmentName, compoentTypeName, componentName,
    pageRedirect,
  } = props;

  const history = useHistory();

  const orgPathName = UserInfo.getOrgUnitName();
  const rollName = UserInfo.getRole();

  const getPathName = history.location.pathname.toLowerCase();

  return (
    <div className={styles.ReclyticsHeader}>
      <span>
        {Showicon && (
          <Icon
            name="arrow alternate circle left outline"
            size="big"
            onClick={pageRedirect}
          />
        )}
        {
          getPathName.includes('createquestion') ? (
            <span fontas={window.innerWidth > 560 ? 'alpha header' : 'beta header'}>
              {`${t('create')} ${componentName} ${t('question')}`}
            </span>
          ) : (
            <span fontas={window.innerWidth > 560 ? 'alpha header' : 'beta header'}>
              {`${assessmentName ? `${assessmentName}` : ''}${(assessmentName && (compoentTypeName || componentName)) ? '/' : ''}${compoentTypeName || componentName ? `${compoentTypeName || componentName} ${t('component')}` : ''}`}
            </span>
          )
        }

      </span>
      {
        (rollName !== 'admin' && rollName !== 'program_manager') && (
          <div>
            <Image size="mini" src="/images/org_table.svg" alt="" />
            <span fontas={window.innerWidth > 560 ? 'alpha header' : 'beta header'}>{orgPathName}</span>
            {/* <span fontas="alpha header">{` ${currentOrg}`}</span> */}
          </div>
        )
      }
    </div>
  );
}

export default withTranslation('common')(ReclyticsHeader);
