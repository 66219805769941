const Participant = {
  sort_by: 'Sort By',
  latest_first: 'Latest First',
  oldest_first: 'Oldest First',
  filter_by_company: 'Filter by company',
  start_date: 'Start Date',
  end_date: 'End Date',
  start: 'Start',
  designation: 'Designation',
  technology: 'Technology',
  competition_and_challenges: 'Competition & Challenges',
  view_details: 'View Details',
  no_events_scheduled: 'No events scheduled',
  We_will_keep_you_updated: 'We’ll keep you updated!',
  answer_status: 'Answer Status',
  attempted: 'Attempted',
  not_attempted: 'Not Attempted',
  in_progress: 'In Progress',
  viewed: 'Viewed',
  flag_review: 'Flagged for review',
  review: 'Review',
  next: 'Next',
  no_assessments_scheduled_for_the_day: 'No Assessments scheduled for the day',
  completed: 'Completed',
  question: 'Question',
  next_section: 'Next Section',
  yet_to_start: 'Yet to start',
  retake: 'Retake',
  resume: 'Resume',
  review_and_finish: 'Review and Finish',
  confirm_finish_content: 'Are you sure to finish this Assessment?',
  section: 'Section',
  reviewandfinish: 'Review & Finish',
  section_control_content: 'section_control_content',
  cant_go_back: 'Are you sure to go to next section? You can\'t go back to the section',
  carried_forward: ' will be carried forward to the next section',
  question_name: 'Question Name',
  status: 'Status',
  grace_time_content: 'Do you want to Take up grace time?',
  you_have_grace_time: 'You have grace time in the following components',
  changed_to: 'Changed to ',
  mins: 'minute(s) and',
  secs: 'seconds',
  session_ended: 'Session Ended!',
  session_ended_due_to_hard_end_time_exceed: 'Session Ended due to hard end time exceed !',
  overall_completion: 'Overall Completion',
  fullScreenHead: 'Full Screen Mode',
  fullScreenBody: 'This challenge needs the browser to be in a full screen mode. Press OK to continue.',
  warning: 'Warning',
  fullScreenWarningBody: 'Assessment will end if you exit fullscreen mode !!',
  no: 'No',
  yes: 'Yes',
  violation: 'Violation',
  fullScreenViolationBody: 'Assessment is ended due to full screen mode violation !!',
  secureWindowBody: 'Secure window violation will lead to attempt termination !!',
  secureWindowViolationBody: 'Assessment is ended due to secure window mode violation !!',
  multipleMonitorViolationBody: 'Assessment is ended due to multiple monitor detected !!',
  ok: 'OK',
  save: 'Save',
  go_to_home: 'Go to Home',
  attempt_summary: 'Attempt summary',
  finish: 'Finish',
  are_you_sure: 'Are you sure',
  finish_confirm_content: `Finishing the test will prevent further edits, and complete your current session, 
  Do you want to continue?`,
  continue: 'Continue',
  exit: 'Exit',
  congratulations: 'Congratulations',
  attemptCongratulationsMessage: 'You have completed this Assessment.',
  home: 'Home',
  duration: 'Duration',
  attemptSummaryErrMessage: "You can't navigate to that section..!",
  reclytics: 'Reclytics',
  skilllytics: 'Skillytics',
  wrong: 'Wrong',
  profiling: 'Profiling',
  multiFaceDetected: 'Multi Face Detected',
  faceDetected: 'Face Detected',
  idDetected: 'ID Detected',
  idNotDetected: 'ID Not Detected',
  noFaceDetected: 'No Face Detected',
  browserError: 'Browser not Supported',
  cameraError: "Couldn't access Camera",
  serverError: 'Error Connecting to server',
  capture: 'capture',
  proctoringLabel: 'Proctoring',
  proctoringHeader: 'Share the following media device to proceed',
  cameraBTNLabel: 'Share Camera',
  screenShareBTNLabel: 'Share Screen',
  retryBtn: 'Retry',
  cameraStopped: 'Camera had stopped',
  cameraInActive: 'Camera is not active',
  screenShareWarning: "You need to choose 'Entire screen' to take the assessment",
  screenShareStopped: 'Screen share had stopped',
  screenShareInActive: 'Screen share is not active',
  screenShareError: "Couldn't share the Screen",
  faceNotDetected: 'Face not detected',
  // learning
  welcome: 'Welcome!',
  everyday: 'Everyday is an opportunity to learn something new..',
  my_learning_path: 'My Learning Path',
  on_going: 'Ongoing',
  complete: 'Completed',
  all: 'All',
  java_basics_foundation_course: 'Java Basics - Foundation Course',
  continue_learning: 'Continue Learning',
  view_all: 'View All',
  my_schedule: 'My Schedule',
  to_do: 'To Do',
  no_activities_today: 'No activities today',
  continue_course: 'Continue Course',
  recomended_courses: 'Recomended Courses',
  recomended_track: 'Recomended Track',
  learn_something_new: 'Everyday is an opportunity to learn something new..',
  module: 'Module',
  about: 'About',
  modules: 'Modules',
  learning_progress: 'Learning Progress',
  notes: 'Notes',
  enroll_now: 'Enroll Now',
  filter: 'Filter',
  upcoming: 'Upcoming',
  previous_Sessions: 'Previous Sessions',
  in_this_session_we_will_learn_about: 'In this session, we will learn about',
  // videos: ' . Videos',
  schedule: 'Schedule',
  s_no: 'S.no.',
  session_name: 'Session Name',
  start_time: 'Start Time',
  end_time: 'End Time',
  attempts: 'Attempts',
  visibility: 'Visibility',
  active_sesstion: 'Active Sesstion',
  no_data_modules: 'no data modules',
  back: 'Back',
  learning_Progress: 'Learning Progress',
  save_note: 'Save Note',
  cancel: 'Cancel',
  saved_successfully: 'Saved successfully...!',
  sections: 'Sections',
  questions: 'Questions',
  minutes: 'Minutes',
  disclaimer_checkbox_label: 'Please select this to continue the assessment!',
  enter_the_initial_key: 'Enter the initial key',
  todaysSession: "Today's Session",
  upcomingSession: 'Upcoming Session',
  completedSession: 'Completed Sessions',
  previousSession: 'Previous Sessions',
  today: 'Today',
  live_interaction: 'Live Interaction',
  filterBy: 'Filter By',
  sortBy: 'Sort By',
  noSession: 'No Sessions Available',
  attending: 'Attending',
  hours: 'hrs',
  minute: 'mins',
  videos_watched: 'No. of videos watched',
  no_events: 'No Events',
  details: 'Details',
  // create_a_new_note: 'create a new note',
  // you_must_attend: 'You must attend a session before proceeding',
  submit_check_point: 'Submit Check Point',
  submit_confirm_content: `Submitting the check point will prevent further modification on given answers,
  Do you want to continue ?`,
  // check_point_successfully: 'You have completed the check point successfully.',
  // interesting_topics_ahead: 'Interesting topics Ahead',
  // congrats: 'Congrats!',
  // check_point_not_met: 'Criteria not met, Recommended to take up the topic again.',
  // not_met: 'Not met!',
  // try_again: 'Try again',
  all_notes: 'All Notes',
  file_text: 'File text',
  dot_circle: 'Dot Circle',
  alternate_outline: 'List Alternate outline',
  sign_out: 'Sign-out',
  search: 'Search...',
  ldp_track: 'LDP Track',
  code_image: 'Code Image',
  completion: 'Completion',
  hello: 'Hello!',
  welcome_back: 'Welcome Back...',
  course_objectives: 'Course Objectives',
  no_of_videos: 'No of videos',
  mcqs: 'MCQs',
  program_exercise: 'Program Exercise',
  modules_name: 'Modules Name',
  videos_name: 'Videos Name',
  my_doubts: 'My Doubts',
  discussion_forum: 'Discussion Forum',
  assessment_report: 'Assessment Report',
  correct: 'Correct',
  pick_a_date_month: 'Pick a Date / Month',
  week: 'Week',
  assignment: 'Assignment',
  project: 'Project',
  perform: 'Perform',
  learning_progression: 'Learning Progression',
  mylearning_progression: 'My Learning Progression',
  course_progress_report: 'Course Progress Report',
  knowledge_problem: 'Knowledge Problem',
  skill_problem: 'Skill Problem',
  no_sessions: 'No Sessions',
  view_more: 'View More',
  no_assignment: 'You have no Assignment activity at the moment!',
  hours_spent: 'Hours spent',
  new_doubt: 'New Doubt',
  close_the_doubt: 'Do you want to close the doubt ?',
  doubtStatus: 'Doubt Status',
  doubts: 'Doubts',
  course: 'Course',
  skill: 'Skill',
  submit: 'Submit',
  issueType: 'Issue Type',
  chooseIssue: 'Choose Issue Type',
  raiseQuery: 'Please raise your query / doubt here...',
  selectCourse: 'Select course',
  selectSkill: 'Select skill',
  selectIssue: 'Select Issue type',
  progress: 'Progress',
  log_out: 'Log out',
  reOpen: 'Re-opened',
  doubtClosed: 'This Doubt has been Closed.',
  play: 'Play',
  yetToStart: 'Yet to Start',
  join: 'Join',
  skills: 'Skills',
  browser_not_supported: 'Browser not supported',
  please_use_google_chrome_with_version_101_or_higher: 'please use Google Chrome with version 101 or higher',
  allowWindowManagement: 'Allow Window Management',
  allow_window_management_for_better_experience: 'Allow window management for better experience',
  instructions: 'Instructions',
  windowManagementPermissionDeniedMessage: "You have declined permission to manage windows across all of your displays. To continue with the test, please enable window management access in your browser settings under 'Privacy and security' section and refresh the page.",
  refresh: 'Refresh',
  feedback: 'Feedback',
  no_questions: 'No Feedback Questions Available',
  provide_feedback: 'Provide Your Feedback',
  feedback_text: 'Please give your valuable feedback about your learning experience in our platform.',
  feedback_suggestion: 'Your suggestions will help us to improve our quality of service.',
  learning_materials: 'Learning Materials',
  enter_your_intermediate_key: 'Enter your intermediate key',
  intermediate_key_is_mandatory: 'Intermediate key is mandatory',
  invalid_intermediate_key: 'Invalid intermediate key',
  contact_your_coordinator_for_intermediate_key: 'Contact your coordinator for intermediate key',
  intermediate_key_must_be_a_number: 'Intermediate key must be a number',
  finish_attempt: 'Finish Attempt',
  important_note: 'Important Note',
  to_complete_module: 'to complete module',
  edit: 'Edit',
  learning_statistics: 'Learning Statistics',
  report: 'Report',
  share_camera: 'Share Camera',
  share_screen: 'Share Screen',
  mobile_proctoring_warning: "You can't attend this Assessment in this device",
  no_doubts_yet: 'No Doubts yet',
  apply: 'Apply',
  rank: 'Rank',
  team: 'Team',
  global: 'Global',
  ranking: 'Ranking',
  top: 'Top',
  yourCompletion: 'Your Completion',
  rivalStatus: 'Rival Status',
  loading: 'Loading',
  pts: 'PTS',
  show_ranking: 'Show Ranking',
  team_rankings: 'Team Rankings',
  global_rankings: 'Global Rankings',
  search_by_name: 'Search by name',
  solved: 'Solved',
  points: 'Points',
  name: 'Name',
  competitiveRankMess1: 'The competitor that solved most problems is ranked first',
  competitiveRankMess2: 'Competitors that solved the same number of problems are ranked by the least total time(Points)',
  competitiveRankMess3: 'The Points(total time) is the sum of the time consumed for each problem. The time consumed for a problem is the time elapsed while you are active in that problem screen plus 20 minutes for each rejected submission.',
  congrats: 'Congrats !',
  acceptedSubmissionTitle: 'Your Submission is Accepted',
  acceptedSubmissionText: 'Burst the balloon and let your competitors know it!',
  type_3_characters_to_search: 'Type 3 characters to search',
  close: 'Close',
  your_skills: 'Your Skills',
  rate_your_skills: 'Rate Your Skills',
  personalized_assessment_content_1: 'To take this assessment, the following skills are required.Please rate yourself on these skills.',
  simplify_the_question: 'Simplify the question',
  provide_a_learning_resource: 'Provide a learning resource',
  hint_selection_content: 'Would you prefer a simpler question on the same concept, or would you like a learning resource to better understand the concept and continue with the same question?',
  send_response: 'Send Response',
  waiting_for_you_to_speak: 'Waiting for you to speak',
  scope_of_getting_skills: 'These ratings would help us determine the right questions for you',
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  expert: 'Expert',
  reupload_response: 'Reupload Response',
  choose_the_correct_option_and_send_response: 'Choose the correct option and send response',
  upload_pdf: 'Upload PDF',
  aiGeneratingInstruction: 'AI is generating your next question. Please wait.',
  unauthorized_access: 'UnAuthorized Access',
  no_access: 'You do not have access to view this page',
  score: 'Score',
  question_status: 'Question Status',
  question_choose_content: 'Please give your Response to proceed',
  finish_interview: 'Finish Interview',
  skip_question: 'Skip Question',
  next_question: 'Next Question',
  open_code_editor: 'Open Code Editor',
  cant_access_mic_refresh_page: 'Can\'t Access Microphone. Please Refresh the page and Allow Microphone Permission',
  cant_access_mic: 'Can\'t Access Microphone',
  allow_microphone_permission: 'Allow Microphone Permission',
  allow_microphone: 'Allow Microphone',
  finish_content: 'Thankyou!! for taking the assessment',
  view_profile: 'View Profile',
  over_all_progress: 'Over-all Progress',
  tracks_completed: 'Tracks Completed',
  tracks_in_progress: 'Tracks In-Progress',
  tracks_enrolled: 'Tracks Enrolled',
  recent_activity: 'Recent Activity',
  total_hours_spent: 'Total Hours Spent',
  learning_track: 'Learning Track',
  capstone_project: 'Capstone Project',
  external_course: 'External Course',
  longest_learning_streak: 'Longest Learning Streak',
  hrs: 'hrs',
  Mins: 'mins',
  no_tracks_currently_enrolled: 'No tracks currently enrolled',
  courses: 'Courses',
  overall_track_completion: 'Overall Track Completion',
  track_progress: 'Track Progress',
  no_course_here: 'No Courses here',
  no_tracks_here: 'No Tracks here',
  overall_course_progress: 'Overall Course Progress',
  batch_rank: 'Batch Rank',
  skill_problems: 'Skill Problems',
  knowledge_problems: 'Knowledge Problems',
  overall_progress: 'Overall Progress',
  learning_resource: 'Learning Resource',
  MCQs: 'MCQs',
  knowledge_hub: 'Knowledge Hub',
  discover_zone: 'Discover Zone',
  blueprint: 'Blueprint',
  achive: 'Achive',
  estimated_completion_time: 'Estimated Completion Time',
  my_learning_progression: 'My Learning Progression',
  overall_progression: 'Overall Progression',
  work_pattern_chart: 'Work Pattern Chart',
  no_modules_in_this_course: 'No Modules in this course',
  no_session_types_are_available_for_this_module: 'No session types are available for this module',
  topic: 'Topic',
  team_rank: 'Team Rank',
  reports: 'Reports',
  progress_report: 'Progress Report',
  coaching_report: 'Coaching Report',
  go_to_viva_question: 'Go to Viva Question',
  audioError: "Couldn't access Microphone",
  share_mic: 'Share Microphone',
  microphoneInActive: 'Microphone is not active',
  increase_volume: 'Increase your Microphone Volume and try again',
  camera_or_audio_stopped: 'Camera/Audio had stopped',
  please_complete_following_module: 'Please Complete Following Module',
  please_complete_following_modules: 'Please Complete Following Modules',
  group_modules: 'Group Modules',
  last_accessed: 'Last Accessed ',
  go_to_course: 'Go To Course',
  complete_below_module: 'Kindly ensure to complete the below modules before starting this module',
  problems: 'Problems',
  my_shopping_cart: 'My Shopping Cart',
  apply_coupon_code: 'Apply Coupon Code',
  checkout: 'Checkout',
  subtotal: 'Subtotal',
  total: 'Total',
  tax: 'Tax',
  discount: 'Discount',
  no_items_in_cart: 'There is no items in the Cart',
  remove: 'Remove',
  tax_hint: '(Inclusive of all taxes)',
  download_certificate: 'Download Certificate',
  view_certificate: 'View Certificate',
  submit_and_get_back_to_tasks: 'Submit and get back to tasks',
};

export default Participant;
