import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  HRLFormik, HRLCkEditor, useComponentDidMount, ToastMessage,
} from '@hrlytics/corelytics';
import {
  Button, Checkbox, Dropdown, Form, Grid, Input,
} from 'semantic-ui-react';
// import { resturls } from '@utils/apiurls';
// import GlobalService from '@services/GlobalService';
// import styles from './less/questionType.module.less';
import RadioButtonNewChoiceOption from './RadioButtonNewChoiceOption';

const RadioButtonQuestionType = (props) => {
  const {
    t, createAndEditQuestionnaireQuestion, editQuestionValue, choiceTableData,
    setChoiceTableData, isSubmitting, isAttemptedQuestion,
  } = props;
  const [toast, setToast] = useState(<></>);

  const {
    name = '',
    description = '',
    required = false,
    filter = false,
    questionnaire_question_type_obj: {
      choice_list = [],
      other_field = false,
      other_field_label = '',
      other_field_score = 0,
      alignment = false,
      weightage = 0,
    } = {},
  } = editQuestionValue || {};

  const [comment, setComment] = useState('');
  const [content, setContent] = useState('');
  const [score, setScore] = useState(0);
  const [editedQuestionId, setEditedQuestionId] = useState(null);

  const gradeOptions = [];
  for (let i = 1; i <= 100; i += 1) {
    gradeOptions.push({ key: i, text: i.toString(), value: i });
  }

  const saveChoicesForRadioButton = () => {
    if (comment !== '' && content !== '') {
      if (editedQuestionId !== null) {
        const editingQuestionIndex = choiceTableData.findIndex(
          (choice) => choice.id === editedQuestionId,
        );

        if (editingQuestionIndex !== -1) {
          const updatedList = [...choiceTableData];
          updatedList[editingQuestionIndex] = {
            ...updatedList[editingQuestionIndex],
            content,
            comment,
            score,
            id: new Date().getTime(),
          };

          setChoiceTableData(updatedList);
          setComment('');
          setContent('');
          setScore(0);
          setEditedQuestionId(null);
        }
      } else {
        const newChoice = {
          content,
          comment,
          score,
          id: new Date().getTime(),
        };
        setChoiceTableData([...choiceTableData, newChoice]);
        setComment('');
        setContent('');
        setScore(0);
      }
    } else {
      setToast(
        <ToastMessage
          open
          toastType="HRL"
          options={{
            type: 'success',
            title: t('rearrange_successful'),
            time: 5000,
          }}
        />,
      );
    }
  };

  useComponentDidMount(() => {
    setChoiceTableData(choice_list);
  });

  return (
    <>
      <HRLFormik
        initialValues={{
          name: name || '',
          description: description || '',
          required: required || false,
          filter_question: filter || false,
          other_field: other_field || false,
          other_field_label: other_field_label || '',
          alignment: alignment || false,
          weightage: weightage || 1,
          score_for_other_field: other_field_score || 0,
        }}
        onSubmit={(values) => createAndEditQuestionnaireQuestion(values)}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSelect,
          handleSelectiveBlur,
          handleRadioChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('name')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    required
                    fontas="beta"
                    onChange={handleChange}
                    control={Input}
                    name="name"
                    width={4}
                    value={values.name}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('description')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <HRLCkEditor
                    name="description"
                    required={false}
                    value={values.description}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={touched.description && errors.description}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('required')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    control={Checkbox}
                    name="required"
                    value={values.required}
                    checked={values.required}
                    onChange={handleRadioChange}
                    onBlur={handleBlur}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('filter_question')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    control={Checkbox}
                    name="filter_question"
                    value={values.filter_question}
                    checked={values.filter_question}
                    onChange={handleRadioChange}
                    onBlur={handleBlur}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('alignment')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    name="alignment"
                    options={[
                      { key: 1, text: 'Vertical', value: true },
                      { key: 2, text: 'Horizontal', value: false },
                    ]}
                    value={values.alignment}
                    onChange={handleSelect}
                    width={4}
                    onBlur={handleSelectiveBlur}
                    control={Dropdown}
                    required
                    selection
                    error={touched.alignment && errors.alignment}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <span fontas="alpha">
                    {t('weightage')}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                  <Form.Field
                    name="weightage"
                    options={gradeOptions}
                    value={values.weightage}
                    onChange={handleSelect}
                    width={4}
                    onBlur={handleSelectiveBlur}
                    control={Dropdown}
                    required
                    selection
                    error={touched.weightage && errors.weightage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {toast}
            <RadioButtonNewChoiceOption
              saveChoicesForRadioButton={saveChoicesForRadioButton}
              comment={comment}
              setComment={setComment}
              choice_list={choiceTableData}
              content={content}
              score={score}
              setScore={setScore}
              setContent={setContent}
              editedChoiseList={choice_list}
              setEditedQuestionId={setEditedQuestionId}
              setChoiceTableData={setChoiceTableData}
              isAttemptedQuestion={isAttemptedQuestion}
            />
            <div>
              <Button onClick={() => handleSubmit(values)} disabled={isSubmitting} type="button">{t('save_question')}</Button>
            </div>
          </Form>
        )}
      </HRLFormik>
    </>
  );
};
export default withTranslation('recruiter')(RadioButtonQuestionType);
