const DiscussionForum = {
  discussionForum: 'Discussion Forum',
  createNewGroup: 'Create New Group',
  groupInfoClick: 'Click here for group info',
  createDiscussion: 'Create Discussion',
  cancel: 'Cancel',
  create: 'Create',
  batchName: 'Batch Name',
  groupNamePlaceholder: 'Group Name',
  createdByYou: 'Created By You',
  createdBy: 'Created By',
  participants: 'Participants',
  youCreatedGroup: 'You created group',
  activeUsers: 'Active Users',
  startDiscussion: 'Start a Discussion',
  reply: 'Reply',
  delete: 'Delete',
  hideGroupInfoClick: 'Hide group info',
  addMembers: 'Add Members',
  add: 'Add',
  noDiscussionsFound: 'No Discussions Found',
  noresultsfound: 'No results found',
  remove: 'Remove',
  close: 'Close',
  recover: 'Recover',
  delete_message_content: ' This message was deleted by',
  you_deleted_this_message: 'You deleted this message',
  admin: 'Admin',
  info: 'Info',
  this_group_has_been_disabled: 'This Group has been Disabled',
  disable: 'Disable',
  enable: 'Enable',
  forward: 'Forward',
  forwardMesgTo: 'Forward Message To',
  sendDirectMessage: 'Send a direct message',
  chatInfoClick: 'Click here for chat info',
  hideChatInfoClick: 'Hide chat info',
  groupsInCommon: 'Groups in common',
};
export default DiscussionForum;
