import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import HRLEvent from '@globalComps/HRLEvent/HRLEvent';
import OperationHeader from '@globalComps/header/OperationHeader';
import SupportManagerDashboard from './SupportManagerDashboard';

const SupportManagerLandingPage = () => {
  const [active_tab, setActiveTab] = useState('dashboard');

  let ActiveComponent = <></>;

  const compObj = {
    dashboard: SupportManagerDashboard,
  };

  ActiveComponent = compObj[active_tab];

  return (
    <>
      <HRLEvent />
      <OperationHeader />
      {ActiveComponent && (
        <ActiveComponent
          setActiveTab={setActiveTab}
          active_tab={active_tab}
        />
      )}
    </>
  );
};
export default withTranslation('programManager')(SupportManagerLandingPage);
