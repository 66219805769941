import React from 'react';
import { withTranslation } from 'react-i18next';
import { HRLModal } from '@hrlytics/corelytics';
import moment from 'moment';
import { Grid, Modal, Table } from 'semantic-ui-react';
import styles from './supportManager.module.less';

const ConversationMessageModal = (props) => {
  const { t, conversationMessageList, setIsConversationShow } = props;

  const {
    msg_list = [], question_u_id, final_submission_percentage,
    question_status, received_time, component_attempt_id, submission_id,
    language_id,
  } = conversationMessageList;

  const showQuestion = () => {
    window.open(`/showprogrammingsubmission/${question_u_id}/${submission_id}/${component_attempt_id}/${language_id}`);
  };
  return (
    <>
      <HRLModal
        size="tiny"
        open
        setOpen={() => setIsConversationShow(false)}
        modalHeaderContent={t('conversation')}
        headerPosition="center"
      >
        <Modal.Content scrolling>
          <Grid.Column>
            <div className={styles.activeMsgWrapper}>
              {msg_list?.map((msg) => (
                <>
                  <div className={styles.dateDiv}>{moment(msg.received_time).format('MMM D, YYYY')}</div>
                  <>
                    <div
                      className={(msg.posted_user_name === 'Participant')
                        ? styles.participantMsgDiv : styles.supportManagerMsgDiv}
                    >
                      <div
                        className={(msg.posted_user_name === 'Participant')
                          ? styles.participantMsg : styles.supportManagerMsg}
                      >
                        {msg.message}
                        <div className={styles.timeDiv}>
                          {moment(msg.received_time).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </>
                </>
              ))}
            </div>
          </Grid.Column>
          <div className={styles.latestSubmissionTable}>
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('id')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('recieved_time')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('testcase_pass_percentage')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell onClick={() => showQuestion()}>{question_u_id}</Table.Cell>
                  <Table.Cell>{question_status}</Table.Cell>
                  <Table.Cell>{moment(received_time).format('DD-MM-YYYY, h:mm A')}</Table.Cell>
                  <Table.Cell>{final_submission_percentage}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </HRLModal>
    </>
  );
};
export default withTranslation('programManager')(ConversationMessageModal);
