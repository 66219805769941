import React from 'react';
import { withTranslation } from 'react-i18next';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import HtmlParser from 'react-html-parser';
import writeXlsxFile from 'write-excel-file';
import { useComponentDidMount, HRLModal } from '@hrlytics/corelytics';
import {
  Button, Icon, Popup, Table,
} from 'semantic-ui-react';
import styles from './less/questionnaireShowPage.module.less';

const QuestionList = (props) => {
  const {
    t,
    questionnaireId, setState, questionListValues,
    editQuestionList, deleteQuestionData, deleteQuestionModal,
    setEditQuestionValue, isAttemptedQuestion,
  } = props;

  const deleteQuestionList = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage && data) {
        setState((prevState) => ({
          questionListValues: prevState.questionListValues.filter(
            (question) => question.id !== deleteQuestionData.id,
          ),
        }));
      }
    }, `${resturls.deleteQuestionnaireQuestion}?question_id=${deleteQuestionData.id}&type=${deleteQuestionData.type}&questionnaire_id=${questionnaireId}`, {}, 'GET');
  };

  const downloadErrorsHandler = () => {
    const additionalHeaders = questionListValues.reduce((acc, ele) => {
      const choiceHeaders = ele.questionnaire_question_type_obj?.choice_list?.flatMap(() => [
        { value: '', fontWeight: 'bold' },
        { value: '', fontWeight: 'bold' },
      ]) || [];
      return acc.concat(choiceHeaders);
    }, []);

    const completeExcelHeader = [...[], ...additionalHeaders];

    const excelBody = questionListValues.map((ele) => {
      const baseRow = [
        { type: String, value: ele.type },
        { type: Number, value: ele.required ? 1 : 0 },
        { type: String, value: ele.name },
        { type: String, value: ele.description },
        { type: Number, value: ele.questionnaire_question_type_obj.weightage ? 1 : 0 },
      ];

      let val = [];
      if (ele.type === 'radio') {
        val = [
          { type: String, value: ele.questionnaire_question_type_obj.alignment ? 'horizontal' : 'vertical' },
        ];
      } else if (ele.type === 'yesno') {
        val = [
          { type: Number, value: ele.questionnaire_question_type_obj.yes_score },
          { type: Number, value: ele.questionnaire_question_type_obj.no_score },
        ];
      }

      const choiceData = Array.isArray(ele.questionnaire_question_type_obj?.choice_list)
        ? ele.questionnaire_question_type_obj?.choice_list.flatMap((choice) => [
          { type: String, value: choice.content },
          { type: Number, value: choice.score },
        ])
        : [];

      return [...baseRow, ...val, ...choiceData];
    });

    const columnWidths = completeExcelHeader.map(() => ({ width: 25 }));

    writeXlsxFile(
      [[], ...excelBody],
      {
        columns: columnWidths,
        fileName: `${t('questionnaire_question_list_export')}.xlsx`,
      },
    );
  };

  useComponentDidMount(() => {
    setEditQuestionValue(null);
  });

  return (
    <>
      <div className={styles.questionList}>
        <div className={styles.Actions}><Button onClick={downloadErrorsHandler}>{t('download')}</Button></div>
        <Table celled fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('no')}</Table.HeaderCell>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
              <Table.HeaderCell>{t('type')}</Table.HeaderCell>
              <Table.HeaderCell>{t('filter')}</Table.HeaderCell>
              <Table.HeaderCell>{t('edit')}</Table.HeaderCell>
              <Table.HeaderCell>{t('delete')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {questionListValues.length > 0 ? (
              <>
                {
                  questionListValues?.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>
                        {i + 1}
                      </Table.Cell>
                      <Table.Cell>
                        {e.name}
                      </Table.Cell>
                      <Table.Cell>
                        {HtmlParser(e.description)}
                      </Table.Cell>
                      <Table.Cell>
                        {e.type_name}
                      </Table.Cell>
                      <Table.Cell>
                        {e.filter ? 'On' : 'Off'}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon
                          name="edit"
                          onClick={() => editQuestionList(e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {
                          isAttemptedQuestion ? (
                            <Popup
                              content={t('attempted_ques')}
                              position="top center"
                              trigger={(
                                <Icon
                                  disabled={isAttemptedQuestion}
                                  name="trash"
                                  onClick={() => setState((prev) => ({
                                    ...prev,
                                    deleteQuestionModal: true,
                                    deleteQuestionData: e,
                                  }))}
                                />
                              )}
                            />
                          ) : (
                            <Icon
                              disabled={isAttemptedQuestion}
                              name="trash"
                              onClick={() => setState((prev) => ({
                                ...prev,
                                deleteQuestionModal: true,
                                deleteQuestionData: e,
                              }))}
                            />
                          )
                        }
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </>
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} fontas="alpha" textAlign="center">
                  {t('no_data_found')}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {
          deleteQuestionModal && (
            <HRLModal
              size="tiny"
              open
              setOpen={() => setState((prev) => ({
                ...prev,
                deleteQuestionModal: false,
              }))}
              modalHeaderContent={t('delete_question')}
              headerPosition="center"
              contentClassName={styles.deleteQuestionModal}
            >
              <p fontas="alpha">{t('are_you_sure_')}</p>
              <div>
                <Button onClick={deleteQuestionList}>{t('yes')}</Button>
                <Button onClick={() => setState((prev) => ({
                  ...prev,
                  deleteQuestionModal: false,
                }))}
                >
                  {t('no')}
                </Button>
              </div>
            </HRLModal>
          )
        }
      </div>
    </>
  );
};
export default withTranslation('recruiter')(QuestionList);
