/* eslint-disable import/no-extraneous-dependencies */
import React, { lazy, Suspense } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Switch, Route } from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/semantic-ui.css';
import 'react-calendar/dist/Calendar.css';
import '@less/icon/icon.less';
import {
  Error500, Error404, DefaultLoader, Error403,
  SomethingWentWrong, Unauthorized, useAbortPrevPageApiRequests,
} from '@hrlytics/corelytics';
import HRLyticsElements from '@screens/HRLyticsElements';
import RoleBasedRoute from '@utils/RoleBasedRoute';
import ResetPasswordModal from '@globalComps/ResetPasswordModal';
import ErrorBoundary from '@globalComps/errormessage/ErrorBoundary';
import HRLEvent from '@globalComps/HRLEvent/HRLEvent';
import ArchiveResults from '@globalComps/ArchiveResults/ArchiveResults';
import { updateUserInfo, PrivateRoute } from '@utils/index';
import 'react-picky/dist/picky.css';
import './assets/less/common.less';
import './assets/less/toggle.less';
import './App.css';
import useConstructor from '@utils/useConstructor';
import {
  CreateLesson,
  ShowLessonDetails, LessonComponent, LessonComponentSummary,
} from '@hrlytics/learnlytics';
import { EditorStatistics } from '@hrlytics/codelytics';
import LicenseDeactive from '@screens/licenseConsumption/LicenseDeactive';
import OverallQuestionnaireTable from '@screens/orgManagement/questionnaire/OverallQuestionnaireTable';
import CreateQuestionnaire from '@screens/orgManagement/questionnaire/CreateQuestionnaire';
import QuestionnaireShowPage from '@screens/orgManagement/questionnaire/questionnaireShowPage/QuestionnaireShowPage';
import SerivesUserManagement from '@screens/programManager/servicesUserManagement/SerivesUserManagement';
import DeactivatedUsers from '@screens/admin/services/DeactivatedUsers';
import VirtualMachineLandingPage from '@screens/virtualMachine/VirtualMachineLandingPage';
import UserBatchReport from '@screens/admin/services/UserBatchReport';
import BatchAnalysis from '@screens/admin/services/BatchAnalysis';
import CourseWiseReport from '@screens/admin/services/CourseWiseReport';
import SupportManagerLandingPage from '@screens/supportManager/SupportManagerLandingPage';
// import MeetingLayout from '@screens/virtualMeeting/MeetingLayout';

const LoginScreen = lazy(() => import('@screens/login/LoginScreen'));
const AdminUserLoginScreen = lazy(() => import('@screens/login/AdminUserLoginScreen'));
const ForgetPassword = lazy(() => import('@screens/login/ForgetPassword'));
const ResetPassword = lazy(() => import('@screens/login/ResetPassword'));
const UpdateGraphDetail = lazy(() => import('@screens/login/UpdateGraphDetail'));
const OperationLayout = lazy(() => import('@screens/programManager/OperationLayout'));
const OrgUnitHomePage = lazy(() => import('@screens/orgunits/OrgUnitHomePage'));

// attempt
const AttemptLayout = lazy(() => import(/* webpackChunkName: 'attemptLayout' */ '@screens/participant/attempt/AttemptLayout'));
const Profiling = lazy(() => import(/* webpackChunkName: 'profiling' */ '@screens/participant/Profiling'));
const AttemptFinish = lazy(() => import(/* webpackChunkName: 'attemptFinish' */ '@screens/participant/attempt/AttemptFinish'));
const InterviewAttempt = lazy(() => import(/* webpackChunkName: 'interviewAttempt' */ '@screens/participant/interview/reducer/InterviewAttempt'));

// participant
const LiveIntraction = lazy(() => import('@screens/participant/liveIntraction/LiveIntractionLayout'));
const MyDoubtsScreen = lazy(() => import('@screens/participant/myDoubts/MyDoubtsScreen'));
const ParticipantLandingPage = lazy(() => import('@screens/participant/ParticipantLandingPage'));
const DFChatScreen = lazy(() => import('@screens/discussionForum/DFChatScreen'));

// personalised flow
const GeneratedPatternsForSession = lazy(() => import('@screens/assessment/personalizedAssessment/generatedPatterns/GeneratedPatternsForSession'));
const GeneratedSkills = lazy(() => import('@screens/assessment/personalizedAssessment/generatedPatterns/generatedSkills/GeneratedSkills'));
const AIGeneratedQuestions = lazy(() => import('@screens/assessment/personalizedAssessment/generatedPatterns/AIGeneratedQuestions'));
const GenerateProcessLandingPage = lazy(() => import('@screens/assessment/personalizedAssessment/generateProcess/GenerateProcessLandingPage'));

// recruiter
const DashBoardLandingPage = lazy(() => import('@screens/dashboard/DashBoardLandingPage'));

// admin
const AdminLandingPage = lazy(() => import('@screens/admin/AdminLandingPage'));
const LiveUsersScreen = lazy(() => import('@screens/admin/LiveUsers/LiveUsersScreen'));
const UserWiseCourseScreen = lazy(() => import('@screens/admin/UserWiseCourse/UserWiseCourseScreen'));

// orgadmin
const OrgAdminLandingPage = lazy(() => import('@screens/orgManagement/OrgAdminLandingPage'));
const ShowCourse = lazy(() => import('@screens/programManager/course/showCourse/ShowCourse'));

// program manager
const CourseInfoLayout = lazy(() => import('@screens/programManager/course/courseInfo/CourseInfoLayout'));
const HeatGridAnalyticsTable = lazy(() => import('@screens/programManager/course/show/HeatGridAnalyticsTable'));
const CourseShowInfoLayout = lazy(() => import('@screens/programManager/course/show/CourseShowInfoLayout'));
const CourseShowDetails = lazy(() => import('@screens/programManager/course/show/CourseShowDetails'));
const ModuleViewAllCard = lazy(() => import('@screens/programManager/course/show/module/moduleView/ModuleViewAllCard'));
const CreateNewModule = lazy(() => import('@screens/programManager/course/show/module/CreateNewModule'));
const SessionListScreen = lazy(() => import('@screens/programManager/session/SessionListScreen'));
const StreamInfoLayout = lazy(() => import('@screens/programManager/stream/StreamInfoLayout'));
const BatchInfolayout = lazy(() => import('@screens/programManager/batch/batchInfo/BatchInfolayout'));
const CreateSession = lazy(() => import('@screens/programManager/session/create/CreateSession'));
const CreateBatchLayout = lazy(() => import('@screens/programManager/batch/createbatch/CreateBatchLayout'));
const TrackScreen = lazy(() => import('@screens/programManager/track/TrackScreen'));
const TrackShowScreen = lazy(() => import('@screens/programManager/track/show/TrackShowScreen'));
const CreateTrackLayout = lazy(() => import('@screens/programManager/track/create/CreateTrackLayout'));
const CourseScreen = lazy(() => import('@screens/programManager/course/CourseScreen'));
const CreateCourseScreen = lazy(() => import('@screens/programManager/course/create/CreateCourseScreen'));
const ShowSessionSettings = lazy(() => import('@screens/programManager/session/show/ShowSessionSettings'));
const ProjectManagement = lazy(() => import('@screens/programManager/project/show/ProjectManagement'));
const CreateProject = lazy(() => import('@screens/programManager/project/create/CreateProject'));
const ShowEpic = lazy(() => import('@screens/programManager/project/epic/ShowEpic'));
const RegistrationLayout = lazy(() => import('@screens/registration/RegistrationLayout'));
// const ViewReport = lazy(() => import('@screens/assessment/
// assessmentSettings/reportsInsights/report/ViewReport'));
const TeamManagementLandingPage = lazy(() => import('@screens/teamManagement/TeamManagementLandingPage'));
const BatchManagementLandingPage = lazy(() => import('@screens/batchManagement/BatchManagementLandingPage'));
const LicenseConsumptionLandingPage = lazy(() => import('@screens/licenseConsumption/LicenseConsumptionLandingPage'));
const QuestionBankLandingPage = lazy(() => import('@screens/assessment/problemHandler/QuestionBankLandingPage'));
const AssessmentProblems = lazy(() => import('@screens/assessment/AssessmentProblems'));
const AssessmentHomePage = lazy(() => import('@screens/assessment/AssessmentHomePage'));
const AssessmentSettingPanel = lazy(() => import('@screens/assessment/assessmentSettings/AssessmentSettingPanel'));
const CreateDashBoardPage = lazy(() => import('@screens/assessment/assessmentSettings/dashboard/CreateDashBoardPage'));
const ComponentSettingsLandingPage = lazy(() => import('@screens/assessment/assessmentSettings/settings/ComponentSettingsLandingPage'));
const ShowQuestionLandingPage = lazy(() => import('@screens/assessment/ShowQuestionLandingPage'));
const LinkQuestionLandingPage = lazy(() => import('@screens/assessment/LinkQuestionLandingPage'));
const EditQuestionLandingPage = lazy(() => import('@screens/assessment/EditQuestionLandingPage'));
const CreateQuestionLandingPage = lazy(() => import('@screens/assessment/CreateQuestionLandingPage'));
const SubmissionLandingPage = lazy(() => import('@screens/assessment/assessmentSettings/BluePrint/SubmissionStack/SubmissionLandingPage'));
const CodeAnalysisSubmissionLandingPage = lazy(() => import('@screens/assessment/assessmentSettings/BluePrint/CodeAnalysis/CodeAnalysisSubmissionLandingPage'));
const ShowDbmsSubQuestionDetails = lazy(() => import('@screens/assessment/assessmentSettings/BluePrint/DbmsSubDetails/ShowDbmsSubQuestionDetails'));
const AddComponent = lazy(() => import('@screens/assessment/addComponent/AddComponent'));
const MentorHomePage = lazy(() => import('@screens/mentor/MentorHomePage'));
const MentorDoubtScreen = lazy(() => import('@screens/mentor/MentorDoubtScreen'));
const CreateDashBoardLandingPage = lazy(() => import('@screens/programManager/course/dashboard/CreateDashBoardLandingPage'));
const ShowCreatedCodeAnalysisProblem = lazy(() => import('@screens/assessment/ShowCreatedCodeAnalysisProblem'));
// const LearningProgressionForTrack =
// lazy(() => import('@screens/participant/learningProgression/LearningProgressionForTrack'));
const LearningProgressDashboard = lazy(() => import('@screens/participant/skillytics/learningProgress/LearningProgressDashboard'));
const AssessmentTypesScreen = lazy(() => import('@screens/programManager/AssessmentType/AssessmentTypesScreen'));
const ReUseAssessment = lazy(() => import('@screens/assessment/ReUseAssessment'));
const PreviewWidgetsForDashboard = lazy(() => import('@screens/assessment/assessmentSettings/dashboard/PreviewWidgetsForDashboard'));
const PreviewAllWidgets = lazy(() => import('@screens/assessment/assessmentSettings/dashboard/PreviewAllWidgets'));

const LanguageTemplate = lazy(() => import('@screens/admin/services/userManageMent/LanguageTemplate'));
const ShowFunctionTemplate = lazy(() => import('@screens/admin/services/userManageMent/ShowFunctionTemplate'));
const CreateFunctionTemplate = lazy(() => import('@screens/admin/services/userManageMent/CreateFunctionTemplate'));
const LanguageTemplateAdmin = lazy(() => import('@screens/admin/services/userManageMent/LanguageTemplateAdmin'));
const PreviewAndEditForm = lazy(() => import('@screens/assessment/assessmentSettings/PreviewAndEditForm'));
const DeletedCourse = lazy(() => import('@screens/programManager/course/deletedCourse/DeletedCourse'));
const CheckpointStatusLandingPage = lazy(() => import('@screens/assessment/assessmentSettings/settings/checkpointStatus/CheckpointStatusLandingPage'));
const SearchUser = lazy(() => import('@screens/admin/services/userManageMent/SearchUser'));
const ShowUsers = lazy(() => import('@screens/admin/services/userManageMent/ShowUsers'));
const AddNewUser = lazy(() => import('@screens/admin/services/userManageMent/AddNewUser'));
const ShowUserList = lazy(() => import('@screens/admin/services/userManageMent/ShowUserList'));
const QuestionnaireBulkUpload = lazy(() => import('@screens/orgManagement/questionnaire/BulkUpload/QuestionnaireBulkUpload'));
const DbmsBulkUpload = lazy(() => import('@screens/assessment/problemHandler/dbmsbulkupload/DbmsBulkUpload'));
const TalentPulseSettings = lazy(() => import('@screens/assessment/staffingRequirement/createWorkflow/TalentPulseSettings'));
const UserProfile = lazy(() => import('@screens/commonComponents/profile/UserProfile'));
const NanoAssessmentLayout = lazy(() => import('@screens/participant/attempt/NanoAssessmentLayout'));
const CapstoneDashboardLandingPage = lazy(() => import('@screens/participant/skillytics/trackDashboard/capstone/capstoneDashboard/CapstoneDashboardLandingPage'));

function App() {
  useConstructor(() => {
    updateUserInfo();
  });

  useAbortPrevPageApiRequests();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <HRLEvent />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/hl" component={HRLyticsElements} />
          <PrivateRoute exact path="/resetpassword" component={ResetPasswordModal} />
          <PrivateRoute exact path="/updateGraphDetail" component={UpdateGraphDetail} />
          <Route path="/login" component={LoginScreen} />
          <Route exact path="/adminuserlogin" component={AdminUserLoginScreen} />
          <Route exact path="/500" component={Error500} />
          <Route exact path="/somethingwentwrong" component={SomethingWentWrong} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route exact path="/403" component={Error403} />
          <Route exact path="/forgetpassword" component={ForgetPassword} />
          <Route exact path="/resetpassword/:validate_code" component={ResetPassword} />
          <PrivateRoute path="/admin" component={AdminLandingPage} />
          <PrivateRoute path="/VirtualMachine" component={VirtualMachineLandingPage} />
          <PrivateRoute path="/programmanager" component={OperationLayout} />
          <PrivateRoute path="/project" component={ProjectManagement} />
          <PrivateRoute path="/createproject" component={CreateProject} />
          <PrivateRoute path="/editproject/:projectid" component={CreateProject} />
          <PrivateRoute path="/epic/:projectid" component={ShowEpic} />
          <PrivateRoute path="/storycreation/:epicid" component={AddComponent} />
          <PrivateRoute path="/teammanagement" component={TeamManagementLandingPage} />
          <PrivateRoute path="/batchmanagement" component={BatchManagementLandingPage} />
          <PrivateRoute path="/licenseconsumption" component={LicenseConsumptionLandingPage} />
          <PrivateRoute path="/orgadmin" component={OrgAdminLandingPage} />
          <PrivateRoute path="/participant" component={ParticipantLandingPage} />
          <PrivateRoute path="/orgunits" component={OrgUnitHomePage} />
          <PrivateRoute path="/liveusers" component={LiveUsersScreen} />
          <PrivateRoute path="/userwisecourse" component={UserWiseCourseScreen} />
          <PrivateRoute path="/liveinteraction/:courseId" component={LiveIntraction} />
          {/* <PrivateRoute path="/learningprogress/:courseId"
          component={LearningProgressLayout} /> */}
          <PrivateRoute path="/learningprogress/:courseId" component={LearningProgressDashboard} />
          <PrivateRoute path="/mydoubts/:courseId" component={MyDoubtsScreen} />

          <PrivateRoute path="/track/:trackid" component={TrackShowScreen} />
          <PrivateRoute path="/track" component={TrackScreen} />
          <PrivateRoute path="/usermanagement" component={SerivesUserManagement} />
          <PrivateRoute path="/createtrack" component={CreateTrackLayout} />
          <PrivateRoute path="/edittrack/:trackid" component={CreateTrackLayout} />
          <PrivateRoute path="/deactivatedusers" component={DeactivatedUsers} />
          <PrivateRoute path="/supportmanager" component={SupportManagerLandingPage} />

          <PrivateRoute path="/course/:courseid" component={CourseShowInfoLayout} />
          <PrivateRoute path="/reportanalytics/orderbyusername" component={HeatGridAnalyticsTable} />
          <PrivateRoute path="/courseshow/:courseid" component={CourseShowDetails} />
          <PrivateRoute path="/course" component={CourseScreen} />
          <PrivateRoute path="/createcourse" component={CreateCourseScreen} />
          <PrivateRoute path="/editcourse/:courseid" component={CreateCourseScreen} />

          <PrivateRoute path="/module/:moduleid" component={ModuleViewAllCard} />
          <PrivateRoute path="/createmodule" component={CreateNewModule} />
          <PrivateRoute path="/showmodule/:moduleid" component={SessionListScreen} />
          <PrivateRoute path="/editmodule/:moduleid" component={CreateNewModule} />

          <PrivateRoute path="/createhrlsession/:moduleid" component={CreateSession} />
          <PrivateRoute path="/edithrlsession/:learningsessionid" component={CreateSession} />
          <PrivateRoute path="/hrlsession/:learningsessionid" component={ShowSessionSettings} />

          <PrivateRoute path="/createlesson/:componentid/:hrlsessionid" component={CreateLesson} />
          <PrivateRoute path="/showlesson/:lessoncomponentid/:hrlsessionid" component={LessonComponent} />
          <PrivateRoute path="/lessoncomponentsummary/:lessoncomponentid/:hrlsessionid" component={LessonComponentSummary} />

          <PrivateRoute path="/courseinfo/:batchid/:courseid" component={CourseInfoLayout} />
          <PrivateRoute path="/stream/:stream_id" component={StreamInfoLayout} />
          <PrivateRoute path="/batch/:stream_id/:batch_id" component={BatchInfolayout} />
          <PrivateRoute path="/userbatchreport" component={UserBatchReport} />
          <PrivateRoute path="/batchanalysis" component={BatchAnalysis} />
          <PrivateRoute path="/coursewisereport" component={CourseWiseReport} />

          <PrivateRoute exact path="/talentpulsesettings/:workflow_id" component={TalentPulseSettings} />
          <PrivateRoute exact path="/talentpulse" component={() => <AssessmentHomePage type="talentpulse" />} />

          <PrivateRoute path="/reuseassessment" component={ReUseAssessment} />
          <PrivateRoute exact path="/assessmenttypes" component={AssessmentTypesScreen} />
          <PrivateRoute path="/addcomponent/:hrlsessionid" component={AddComponent} />
          <PrivateRoute path="/archievedproblems/:componentTypeId/:questionbankId" component={QuestionBankLandingPage} />
          <PrivateRoute path="/archiveresults" component={ArchiveResults} />
          <PrivateRoute path="/createbatch" component={CreateBatchLayout} />
          <PrivateRoute path="/componenttype" component={AssessmentProblems} />

          <PrivateRoute path="/:componentTypeName/questionbank/:questionbankid" component={QuestionBankLandingPage} showHeader />
          <PrivateRoute path="/questionbank/:componentTypeId" component={(props) => <QuestionBankLandingPage {...props} showHeader />} />
          <PrivateRoute path="/addProblem/:componentTypeId" component={(props) => <QuestionBankLandingPage {...props} showHeader />} />
          <PrivateRoute path="/showquestion/:componentTypeId/:problemid/:umlQuestionTypeName" component={(props) => <ShowQuestionLandingPage {...props} />} />
          <PrivateRoute path="/showquestion/:componentTypeId/:problemid" component={(props) => <ShowQuestionLandingPage {...props} />} />
          <PrivateRoute path="/linkquestion/:questionbankid/:problemid" component={(props) => <LinkQuestionLandingPage {...props} />} />
          <PrivateRoute path="/editquestion/:componentTypeId/:problemid/:questionbankid" component={(props) => <EditQuestionLandingPage {...props} />} />
          <PrivateRoute path="/createquestion/:componentTypeId/:questionbankFileId" component={(props) => <CreateQuestionLandingPage {...props} adminQb />} />
          <PrivateRoute path="/createquestion/:componentTypeId" component={(props) => <CreateQuestionLandingPage {...props} />} />
          <PrivateRoute path="/showquestion/:componentTypeId" component={(props) => <ShowCreatedCodeAnalysisProblem {...props} />} />
          <PrivateRoute path="/showprogrammingsubmission/:problemid/:submissionid/:componentattemptid/:languageid" component={SubmissionLandingPage} />
          <PrivateRoute path="/showcodeanalysissubmission/:questionid/:codeanalysisquestionid/:attemptid" component={CodeAnalysisSubmissionLandingPage} />
          <PrivateRoute path="/recruiter/assessment/dbmsbulkupload/:componentTypeId" component={(props) => <DbmsBulkUpload {...props} />} />
          <PrivateRoute path="/showDbmsSubQuestionDetails" component={ShowDbmsSubQuestionDetails} />
          <PrivateRoute path="/programmingcomp/showstatistics/:submissionid/:activitytrackid" component={EditorStatistics} />

          <PrivateRoute exact path="/createanalyticsdashboard/:assessmentId/:dashboardId" component={CreateDashBoardPage} />
          <PrivateRoute exact path="/createcoursedashboard/:courseId/:dashboardId" component={CreateDashBoardLandingPage} />

          {/* personalised assessment flow */}
          {/* creation */}
          <PrivateRoute exact path="/generatedquestions/:assessmentId" component={AIGeneratedQuestions} />
          <PrivateRoute exact path="/personalisedassessment" component={() => <AssessmentHomePage type="personalised" />} />
          <PrivateRoute exact path="/generatedpatterns/:assessmentId" component={GeneratedPatternsForSession} />
          <PrivateRoute exact path="/generatedskills/:assessmentId" component={GeneratedSkills} />
          <PrivateRoute exact path="/generateprocess/:assessmentId" component={GenerateProcessLandingPage} />
          {/* attempt */}
          <PrivateRoute path="/interview/:sessionId/:teamId" component={InterviewAttempt} />
          <PrivateRoute path="/interviewfinish" component={() => <AttemptFinish type="interview" />} />

          <PrivateRoute exact path="/assessment" component={() => <AssessmentHomePage type="hiring" />} />
          <PrivateRoute exact path="/assessment/:assessmentId" component={AssessmentSettingPanel} />
          <PrivateRoute exact path="/dashboard" component={DashBoardLandingPage} />
          <PrivateRoute exact path="/checkpointstatus/:componentTypeId/:checkpointId/:userId" component={CheckpointStatusLandingPage} />
          <PrivateRoute exact path="/component/:assessmentId/:componentId/:componentTypeId" component={ComponentSettingsLandingPage} />
          <PrivateRoute exact path="/component/:componentid/:hrlsessionid" component={ShowLessonDetails} />
          <PrivateRoute path="/discussionforum/:courseId" component={DFChatScreen} />
          <PrivateRoute path="/showCourse/:courseId" component={ShowCourse} />
          <PrivateRoute exact path="/" component={RoleBasedRoute} />
          <PrivateRoute path="/mentor" component={MentorHomePage} />
          <PrivateRoute path="/mentordashboard" component={MentorDoubtScreen} />
          <PrivateRoute path="/profile" component={UserProfile} />
          <PrivateRoute path="/deactivatelicense" component={LicenseDeactive} />
          <PrivateRoute path="/deletedcourse" component={DeletedCourse} />
          <PrivateRoute path="/questionnaire/list" component={OverallQuestionnaireTable} />
          <PrivateRoute path="/questionnaire/create" component={CreateQuestionnaire} />
          <PrivateRoute path="/questionnaire/bulkupload/:questionnaireId" component={QuestionnaireBulkUpload} />
          <PrivateRoute path="/questionnaire/show/:questionnaireId" component={QuestionnaireShowPage} />

          {/* Registration */}
          <Route exact path="/a/nt/:assessmentId/:userId" component={NanoAssessmentLayout} />
          <Route exact path="/a/rt/:assessmentId/:team_timing_id" component={RegistrationLayout} />
          <Route exact path="/a/rt/:assessmentId" component={RegistrationLayout} />

          {/* Attempt */}
          <PrivateRoute path="/attempt/finish" component={AttemptFinish} />
          {/* <PrivateRoute path="/attempt/:sessionId/:teamId/:storyId/:taskId" component={AttemptLayout} /> */}
          <PrivateRoute path="/attempt/:sessionId/:teamId" component={AttemptLayout} />
          <PrivateRoute path="/attempt/:sessionId" component={AttemptLayout} />
          <PrivateRoute path="/profiling/:hrlSessionId" component={Profiling} />

          {/* Learning Progression */}
          <PrivateRoute path="/trackprogress/:u_id" component={LearningProgressDashboard} />

          {/* DashboardFlow Preview */}
          <PrivateRoute path="/slidepreview/:dashboard_id" component={PreviewWidgetsForDashboard} />
          <PrivateRoute path="/allslidespreivew/:all_slides_dashboard_id" component={PreviewAllWidgets} />

          {/* Admin flow code editor */}
          <Route path="/functiontemplatelist" component={LanguageTemplate} />
          <Route path="/showfunctiontemplate/:template_id" component={ShowFunctionTemplate} />
          <Route path="/createtemplate" component={CreateFunctionTemplate} />
          <Route path="/edittemplate/:template_id" component={CreateFunctionTemplate} />
          <Route path="/keywordtemplatelist" component={LanguageTemplateAdmin} />
          <Route path="/formpreview/:formId" component={PreviewAndEditForm} />

          {/* <Route path="/mailmaster/maillog" component={MailLog} /> */}

          {/* search user flow in user management */}
          <PrivateRoute path="/searchuser" component={SearchUser} />
          <PrivateRoute path="/showusers/:profileid" component={ShowUsers} />
          <PrivateRoute path="/addnewuser" component={AddNewUser} />
          <PrivateRoute path="/showuserlist" component={ShowUserList} />

          {/* assessment manager */}

          <PrivateRoute path="/preskillassessment" component={() => <AssessmentHomePage type="preskillassessment" />} />
          <PrivateRoute path="/postskillassessment" component={() => <AssessmentHomePage type="postskillassessment" />} />
          <PrivateRoute path="/skillvalidationassessment" component={() => <AssessmentHomePage type="skillvalidationassessment" />} />

          {/* <PrivateRoute path="/meeting" component={MeetingLayout} /> */}
          <PrivateRoute path="/capstonedashboard/:team_id/:project_id" component={CapstoneDashboardLandingPage} />

          <Route path="*" component={Error404} />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
