import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Icon, Pagination, Table,
} from 'semantic-ui-react';
import styles from './supportManager.module.less';

const DoubtDetailsTable = (props) => {
  const {
    t, doubtDetails, obtainMessagesForDoubtThreadFunct, setData, obtainDoubtThreadDetailsFunct,
    totalCount = 0, doubtsPerPage,
  } = props;

  const { doubt_thread_list } = doubtDetails;

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const getButtonContent = (icon) => (
    <>
      {icon === 'prev' && t(icon)}
      <Icon name={`chevron ${icon === 'next' ? 'right' : 'left'}`} size="large" />
      {icon === 'next' && t(icon)}
    </>
  );

  const pageChangeHandler = (e, { activePage }) => {
    obtainDoubtThreadDetailsFunct(activePage);
  };

  const sortDataForUserList = () => {
    const { key, direction } = sortConfig;
    const sortedData = [...doubt_thread_list].sort((a, b) => {
      const valueA = a[key] || '';
      const valueB = b[key] || '';
      if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
      if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setData((prev) => (
      { ...prev, doubtDetails: { ...prev.doubtDetails, doubt_thread_list: sortedData } }
    ));
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig) sortDataForUserList();
  }, [sortConfig]);

  return (
    <div className={styles.doubtDetailsTable}>
      <div>{t('doubt_details')}</div>
      <div>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t('doubtId')}
                <Icon
                  name={`sort ${sortConfig?.key === 'u_id' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('u_id')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('participant')}
                <Icon
                  name={`sort ${sortConfig?.key === 'username' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('username')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('mentor')}
                <Icon
                  name={`sort ${sortConfig?.key === 'mentor_name' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('mentor_name')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('course')}
                <Icon
                  name={`sort ${sortConfig?.key === 'course_name' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('course_name')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('skill')}
                <Icon
                  name={`sort ${sortConfig?.key === 'skill_name' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('skill_name')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('created_time')}
                <Icon
                  name={`sort ${sortConfig?.key === 'created_time' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('created_time')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('status')}
                <Icon
                  name={`sort ${sortConfig?.key === 'doubt_status_name' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('doubt_status_name')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('resolved_last_respond_time')}
                <Icon
                  name={`sort ${sortConfig?.key === 'last_message_time' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('last_message_time')}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('sla_time')}
                <Icon
                  name={`sort ${sortConfig?.key === 'last_updated_sla_time' && (sortConfig?.direction === 'ascending' ? 'sort content ascending' : 'sort content descending')}`}
                  onClick={() => sortData('last_updated_sla_time')}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {doubtDetails?.doubt_thread_list?.map((event) => (
              <Table.Row>
                <Table.Cell
                  onClick={() => obtainMessagesForDoubtThreadFunct(event.id)}
                >
                  {event.u_id}
                </Table.Cell>
                <Table.Cell>{event.username}</Table.Cell>
                <Table.Cell>{event.mentor_name !== null ? event.mentor_name : '-'}</Table.Cell>
                <Table.Cell>{event.course_name ? event.course_name : '-'}</Table.Cell>
                <Table.Cell>{event.skill_name ? event.skill_name : '-'}</Table.Cell>
                <Table.Cell>{moment(event.created_time).format('DD-MM-YYYY, h:mm A')}</Table.Cell>
                <Table.Cell>{event.doubt_status_name}</Table.Cell>
                <Table.Cell>{moment(event.last_message_time).format('DD-MM-YYYY, h:mm A')}</Table.Cell>
                <Table.Cell>{event.last_updated_sla_time}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {totalCount > doubtsPerPage && (
          <div className={styles.pagination}>
            <Pagination
              defaultActivePage={1}
              totalPages={Math.ceil(totalCount / doubtsPerPage)}
              nextItem={{ 'aria-label': 'Next item', content: getButtonContent('next') }}
              prevItem={{ 'aria-label': 'Previous item', content: getButtonContent('prev') }}
              firstItem={null}
              lastItem={null}
              secondary
              onPageChange={pageChangeHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default withTranslation('programManager')(DoubtDetailsTable);
