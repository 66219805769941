import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';
import DefaultHeader from '@globalComps/header/DefaultHeader';
import { useHistory } from 'react-router-dom';
import styles from './questionnaire.module.less';

const QuestionnaireHeader = (props) => {
  const { t, headerText, questionnaireId } = props;
  const history = useHistory();

  let questionnaireOptions = [];

  if (history.location.pathname.includes('list')) {
    questionnaireOptions = [
      { value: 'create_questionnaire', onClick: () => history.push('/questionnaire/create') },
      { value: 'search_questionnaire' },
    ];
  } else if (history.location.pathname.includes('create')) {
    questionnaireOptions = [
      { value: 'questionnaire_list', onClick: () => history.push('/questionnaire/list') },
      { value: 'search_questionnaire' },
    ];
  } else {
    questionnaireOptions = [
      { value: 'create_questionnaire', onClick: () => history.push('/questionnaire/create') },
      { value: 'questionnaire_list', onClick: () => history.push('/questionnaire/list') },
      { value: 'bulk_upload', onClick: () => history.push(`/questionnaire/bulkupload/${questionnaireId}`) },
    ];
  }

  return (
    <div>
      <DefaultHeader />
      <div className={styles.questionnaireHeader}>
        <div>
          <Icon size="big" name="arrow alternate circle left outline" onClick={() => history.goBack()} />
          <span fontas="alpha">{headerText}</span>
        </div>
        <div>
          <Dropdown text={t('more_options')} fontas="alpha">
            <Dropdown.Menu>
              {questionnaireOptions.map((e) => (
                <Dropdown.Item key={e.value} text={t(e.value)} {...e} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('recruiter')(QuestionnaireHeader);
