export const protocol = process.env.REACT_APP_API_PROTOCOL;
export const domain = process.env.REACT_APP_API_DOMAIN;
export const port = process.env.REACT_APP_API_PORT;
export const reactport = process.env.REACT_APP_REACT_PORT;

export const reactcontext = process.env.REACT_APP_REACT_CONTEXT;
export const apicontext = process.env.REACT_APP_APICONTEXT;
export const cookiedomain = process.env.REACT_APP_COOKIE_DOMAIN;

export const restbaseurl = process.env.REACT_APP_RESTBASEURL;
export const cdnurl = process.env.REACT_APP_API_CDNURL;
export const payumoneyurl = process.env.REACT_APP_PAYUMONEYURL;
export const reacturl = process.env.REACT_APP_REACTURL;
export const fileManagerurl = process.env.REACT_APP_FILEMANGERURL;
export const fileManagerUploadsurl = process.env.REACT_APP_FILEMANGERUPLOADSURL;
export const googleClientid = process.env.REACT_APP_GOOGLECLIENTID;
export const facebookClientid = process.env.REACT_APP_FACEBOOKCLIENTID;

// export const contexPath = process.env.REACT_APP_CONTEXPATH;
export const timerRefreshInterval = 120000;

export const execprotocol = process.env.REACT_APP_EXEC_PROTOCOL;
export const execdomain = process.env.REACT_APP_EXEC_DOMAIN;
export const execport = process.env.REACT_APP_EXEC_PORT;
// export const execurl = process.env.REACT_APP_EXECBASEURL;
