import React, { useState } from 'react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import { ToastMessage } from '@hrlytics/corelytics';
import UserInfo from '@models/UserInfo';
import { updateCookieBasedOnRole } from '@utils/index';

export default function OpHeaderInitOptions() {
  const history = useHistory();
  const roleName = UserInfo.getRole();

  const [componentTypeList, setComponentTypeList] = useState([]);
  const [adminOptions, setAdminOptions] = useState([]);
  const [toast, setToast] = useState((<></>));

  const getTextForRole = (rolename) => {
    let txt = '';
    const rolesplit = rolename.split('_');
    if (rolesplit.length > 1) {
      rolesplit.forEach((name) => {
        txt += name.charAt(0);
      });
      txt = txt.toUpperCase();
    } else {
      txt = rolename.charAt(0).toUpperCase() + rolename.slice(1);
    }
    return `${txt} View`;
  };

  const obtainComponentTypesForArchivedProblems = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setComponentTypeList(
            data.component_type_list.map((e) => (
              {
                key: e.id,
                text: e.name,
                value: e.id,
              }
            )),
          );
        }
      }, resturls.obtainComponentTypesForArchivedProblems, {}, 'GET', 'cdn',
    );
  };

  const obtainArchivedProblemsForOrgUnit = (id, name) => {
    GlobalService.generalSelect(
      (respdata) => {
        const {
          estatus, emessage, data, data: { status },
        } = respdata;
        if (estatus && emessage) {
          if (status !== 'There is no archived problem avilable') {
            ls.set('selectedComponentType', name);
            history.push(`/archievedproblems/${id}/${data.question_bank_id}`);
            window.location.reload();
          } else {
            setToast(
              <ToastMessage
                open
                options={{
                  type: 'error',
                  title: status,
                }}
              />,
            );
          }
        }
      }, `${resturls.obtainArchivedProblemsForOrgUnit}?component_type_id=${id}`, {}, 'GET',
    );
  };

  const getSwitchoptions = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage, data: { role_list } } = respdata;
        if (estatus && emessage) {
          let roleswitchtab = [];
          roleswitchtab = role_list.map((role) => (
            {
              role: role.name,
              onClickFn: () => updateCookieBasedOnRole(role.name, role.segment_type_id,
                UserInfo.getOrgUnitId()),
              label: getTextForRole(role.name),
            }
          ));
          roleswitchtab = roleswitchtab.filter(({ role }) => role !== roleName);
          setAdminOptions(roleswitchtab);
        }
      }, resturls.obtainSegmentAndRolesForAdmin,
      {}, 'GET', 'cdn',
    );
  };

  const handleDeletedCourses = () => {
    history.push('/deletedcourse');
  };

  const archivedProblems = () => {
    // setDropdownShow(true);
    if (!componentTypeList.length > 0) {
      obtainComponentTypesForArchivedProblems();
    }
  };
  return {
    callBacks: {
      obtainArchivedProblemsForOrgUnit,
      getSwitchoptions,
      handleDeletedCourses,
      archivedProblems,
    },

    options: {
      componentTypeList,
      adminOptions,
    },

    toastMsg: {
      toast,
    },

  };
}
