import React from 'react';
import { withTranslation } from 'react-i18next';
import { HRLModal, useComponentDidMount } from '@hrlytics/corelytics';
import { Card, Image } from 'semantic-ui-react';
import Reclytics from '@images/roles/Reclytics.png';
import Skilllytics from '@images/roles/Skilllytics.png';
import styles from './orgunit.module.less';

function RoleBasedRouteModal(props) {
  const {
    roleList = [], setRoleBasedModal, setRoleList,
    t, updateCookieBasedOnRole, org_unit_id,
  } = props;

  const imgObj = {
    Reclytics,
    Skilllytics,
  };

  // This func is written to display Reclytics in the beginning of the card

  const reArrangeRoleSegments = () => {
    const temp = [...roleList];
    const indexOfReclytics = temp.findIndex((ele) => ele.role_segment_name === 'Reclytics');
    if (indexOfReclytics !== -1) {
      const [removeItem] = temp.splice(indexOfReclytics, 1);
      temp.unshift(removeItem);
      setRoleList(temp);
    }
  };

  useComponentDidMount(reArrangeRoleSegments);

  return (
    <HRLModal
      open
      size="tiny"
      setOpen={() => setRoleBasedModal(false)}
    >
      <h2>{t('choose_flow')}</h2>
      <Card.Group itemsPerRow="2">
        {
          roleList.map(({ role, role_segment_name, role_segment_type_id }) => (
            <Card
              onClick={() => updateCookieBasedOnRole(role, role_segment_type_id, org_unit_id)}
              key={role}
              className={styles.roleCard}
            >
              <div>
                <Image src={imgObj[role_segment_name]} size="small" centered />
                <h3>{t(role)}</h3>
              </div>
            </Card>
          ))
        }
      </Card.Group>
    </HRLModal>
  );
}

export default withTranslation('recruiter')(RoleBasedRouteModal);
