import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import { useHistory } from 'react-router-dom';
import HtmlParser from 'react-html-parser';
import { useComponentDidMount } from '@hrlytics/corelytics';
import QuestionnaireHeader from './QuestionnaireHeader';
import styles from './questionnaire.module.less';

const OverallQuestionnaireTable = (props) => {
  const { t } = props;
  const history = useHistory();

  const [state, setState] = useState({
    initialValues: [],
  });

  const { initialValues } = state;

  const obtainQuestionnaire = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { questionnaire_list } } = respData;
      if (estatus && emessage) {
        setState((prev) => ({
          ...prev,
          initialValues: questionnaire_list,
        }));
      }
    }, resturls.obtainQuestionnaire, {}, 'GET');
  };

  const showIndividualQuestionnaire = (e) => {
    history.push(`/questionnaire/show/${e.u_id}`);
  };

  useComponentDidMount(() => {
    obtainQuestionnaire();
  });

  return (
    <div>
      <QuestionnaireHeader headerText={t('questionnaire_list')} />
      <div className={styles.questionnaireListTable}>
        <Table celled fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
              <Table.HeaderCell>{t('grade')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {initialValues.length > 0 ? (
              <>
                {
                  initialValues?.map((e) => (
                    <Table.Row>
                      <Table.Cell onClick={() => showIndividualQuestionnaire(e)}>
                        {e.name}
                      </Table.Cell>
                      <Table.Cell>
                        {HtmlParser(e.description)}
                      </Table.Cell>
                      <Table.Cell>
                        {e.grade}
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </>
            ) : (
              <Table.Row>
                <Table.Cell colSpan={3} fontas="alpha" textAlign="center">
                  {t('no_data_found')}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
export default withTranslation('recruiter')(OverallQuestionnaireTable);
