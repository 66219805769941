import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import GlobalService from '@services/GlobalService';
import { useComponentDidMount } from '@hrlytics/corelytics';
import { resturls } from '@utils/apiurls';
import { useLocation } from 'react-router-dom';
import QuestionnaireHeader from '../QuestionnaireHeader';
import EditAndShowQuestionnaire from './EditAndShowQuestionnaire';
import AddQuestionnaireQuestion from './AddQuestionnaireQuestion';
import QuestionList from './QuestionList';
import PreviewQuestion from './PreviewQuestion';
import styles from './less/questionnaireShowPage.module.less';

const QuestionnaireShowPage = (props) => {
  const location = useLocation();
  const { state: values } = location;
  const { t, match: { params: { questionnaireId } } } = props;
  const tabOptions = ['questionnaire', 'addquestion', 'question_list', 'preview'];
  const [selectedTab, updateSelectedTab] = useState(values?.header || 'questionnaire');
  const [editQuestionValue, setEditQuestionValue] = useState(null);
  const [state, setState] = useState({
    questionListValues: [],
    deleteQuestionModal: false,
    deleteQuestionData: null,
    isAttemptedQuestion: false,
  });

  const {
    questionListValues, deleteQuestionModal, deleteQuestionData,
    isAttemptedQuestion,
  } = state;

  const compObj = {
    questionnaire: EditAndShowQuestionnaire,
    addquestion: AddQuestionnaireQuestion,
    question_list: QuestionList,
    preview: PreviewQuestion,
  };

  const obtainQuestionnaireList = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { questionnaire_question_list, attempted } } = respData;
      if (estatus && emessage) {
        setState((prev) => ({
          ...prev,
          questionListValues: questionnaire_question_list,
          isAttemptedQuestion: attempted,
        }));
      }
    }, `${resturls.obtainQuestionnaireQuestionsList}?questionnaire_u_id=${questionnaireId}`, {}, 'GET');
  };

  const editQuestionList = (e) => {
    updateSelectedTab('addquestion');
    setEditQuestionValue(e);
  };

  useComponentDidMount(() => {
    obtainQuestionnaireList();
  });

  return (
    <>
      <QuestionnaireHeader headerText={t('show_qestionnaire')} questionnaireId={questionnaireId} />
      <div className={styles.showQuestionnarieTabOptions}>
        {tabOptions.map((tab) => (
          <span
            key={tab}
            fontas="beta header"
            id={selectedTab === tab ? 'selected' : ''}
            onClickCapture={() => updateSelectedTab(tab)}
          >
            {t(tab)}
          </span>
        ))}
      </div>
      {
        Object.keys(compObj).map((tabName) => {
          const QuestionnaireComp = compObj[tabName];
          return (
            <div className={tabName === selectedTab ? styles.visibleComp : styles.hiddenComp}>
              <QuestionnaireComp
                questionnaireId={questionnaireId}
                editQuestionList={editQuestionList}
                editQuestionValue={editQuestionValue}
                setState={setState}
                deleteQuestionData={deleteQuestionData}
                questionListValues={questionListValues}
                deleteQuestionModal={deleteQuestionModal}
                obtainQuestionnaireList={obtainQuestionnaireList}
                updateSelectedTab={updateSelectedTab}
                setEditQuestionValue={setEditQuestionValue}
                isAttemptedQuestion={isAttemptedQuestion}
              />
            </div>
          );
        })
      }
    </>
  );
};
export default withTranslation('recruiter')(QuestionnaireShowPage);
