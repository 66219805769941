import React from 'react';
import ls from 'local-storage';
import OperationHeader from './OperationHeader';
import DefaultHeader from './DefaultHeader';

function CommonHeader(props) {
  const segmentType = ls.get('segmentType');

  switch (segmentType) {
    case 1:
      return <OperationHeader {...props} />;
    default:
      return <DefaultHeader {...props} />;
  }
}

export default CommonHeader;
