import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import HtmlParser from 'react-html-parser';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import UserInfo from '@models/UserInfo';
import styles from './HRLEvent.module.less';

function HRLEvent() {
  const [state, setState] = useState({
    isLoading: true,
    eventData: [],
  });
  const { isLoading, eventData } = state;
  const history = useHistory();

  const handleCloseAlert = () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    ls.set('isEventClosed', 'hide');
  };

  useEffect(() => {
    if (UserInfo.isAuth() && ls.get('isEventClosed') !== 'hide') {
      GlobalService.generalSelect((respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          setState({
            isLoading: false,
            eventData: data,
          });
        }
      }, resturls.obtainHrlEventForUser, {}, 'GET');
    }
  }, [history]);

  return (
    <>
      {(!isLoading && eventData.length > 0 && ls.get('isEventClosed') !== 'hide') && (
        <div className={isLoading ? `${styles.HRLEvent}` : `${styles.HRLEvent} ${styles.activeAlert}`}>
          <ul>
            {eventData.map((event) => <li key={event.id}>{HtmlParser(event.modal_content)}</li>)}
          </ul>
          <Icon onClick={handleCloseAlert} name="x" />
        </div>
      )}
    </>
  );
}

export default HRLEvent;
