import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { useComponentDidMount, HRLFormik } from '@hrlytics/corelytics';
import {
  Button, Checkbox, Input, Table,
} from 'semantic-ui-react';
import styles from './less/questionnaireShowPage.module.less';

const PreviewQuestion = (props) => {
  const { t, questionListValues, setEditQuestionValue } = props;
  const [selectedOption, setSelectedOption] = useState({});

  const handleRadioChange = (rowId, value) => {
    setSelectedOption({
      ...selectedOption,
      [rowId]: value,
    });
  };

  useComponentDidMount(() => {
    // setEditQuestionValue(null);
  });

  const isOptionChecked = (rowId, value) => selectedOption[rowId] === value;

  // const renderRadioButtons = (rowId, questionnaire_question_type_obj) => {
  //   const { choice_list } = questionnaire_question_type_obj;
  //   return (
  //     <>
  //       {choice_list.map((choice, i) => (
  //         <div>
  //           <Checkbox
  //             radio
  //             value={choice.content}
  //             name={`radioGroup_${rowId}`}
  //             label={HTMLReactParser(choice.content)}
  //             checked={isOptionChecked(rowId, `${choice.content}${i}`)}
  //             onChange={() => handleRadioChange(rowId, `${choice.content}${i}`)}
  //           />
  //         </div>
  //       ))}
  //     </>
  //   );
  // };

  // const renderYesNo = (rowId) => (
  //   <>
  //     <Checkbox
  //       radio
  //       value="yes"
  //       name={`radioGroup_${rowId}`}
  //       label="Yes"
  //       checked={isOptionChecked(rowId, 'yes')}
  //       onChange={() => handleRadioChange(rowId, 'yes')}
  //     />
  //     <Checkbox
  //       radio
  //       value="no"
  //       name={`radioGroup_${rowId}`}
  //       label="No"
  //       checked={isOptionChecked(rowId, 'no')}
  //       onChange={() => handleRadioChange(rowId, 'no')}
  //     />
  //   </>
  // );
  return (
    <div className={styles.previewQuestion}>
      {/* <Table celled>
        <Table.Body>
          {questionListValues.map((type) => (
            <Table.Row key={type.id}>
              <Table.Cell>{type.name}</Table.Cell>
              <Table.Cell
                className={
                  type.questionnaire_question_type_obj?.alignment === false
                  && styles.previewQuestionOptionsAlign
                }
              >
                {type.type === 'radio' && (
                  renderRadioButtons(type.id, type.questionnaire_question_type_obj)
                )}
                {type.type === 'yesno' && (
                  renderYesNo(type.id, type)
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table> */}
      {questionListValues?.map((type, index) => (
        <div className={styles.mcqItem} key={type.id}>
          <div className={styles.questionText} fontas="alpha">
            <strong>{index + 1}.</strong> {HTMLReactParser(type.description)}
          </div>
          <div
            className={
              type.questionnaire_question_type_obj?.alignment === false
              && styles.previewQuestionOptionsAlign
            }>
            {type.type === 'radio' && (
              <>
                {type.questionnaire_question_type_obj?.choice_list?.map((choice, i) => (
                  <div key={i + 1}>
                    <Checkbox
                      radio
                      value={choice.content}
                      name={`radioGroup_${type.id}`}
                      label={HTMLReactParser(choice.content)}
                      checked={isOptionChecked(type.id, `${choice.content}${i}`)}
                      onChange={() => handleRadioChange(type.id, `${choice.content}${i}`)}
                    />
                  </div>
                ))}
              </>
            )}
            {type.type === 'yesno' && (
              <>
                <Checkbox
                  radio
                  value="yes"
                  name={`radioGroup_${type.id}`}
                  label="Yes"
                  checked={isOptionChecked(type.id, 'yes')}
                  onChange={() => handleRadioChange(type.id, 'yes')}
                />
                <Checkbox
                  radio
                  value="no"
                  name={`radioGroup_${type.id}`}
                  label="No"
                  checked={isOptionChecked(type.id, 'no')}
                  onChange={() => handleRadioChange(type.id, 'no')}
                />
              </>
            )}
          </div>
        </div>
      ))}
      <Button type="button">{t('submit')}</Button>
    </div>
  );
};
export default withTranslation('recruiter')(PreviewQuestion);
