const OrgAdmin = {
  myTeams: 'My Teams',
  search_org_unit: 'Search Org. Unit',
  org_admin_home_toast_content: 'Please pick a Team to navigate further!',
  teamManagement: 'Team Management',
  assign_member: 'Assign Member',
  grant_access: 'Grant Access',
  role: 'Role',
  name: 'Name',
  mobile_number: 'Mobile Number',
  experience: 'Years of Experience',
  email_id: 'E-mail ID',
  current_designation: 'Current Designation',
  band: 'Grade / Band',
  orgUnit: 'Org. Unit',
  orgUnitMember: 'Org. Unit Member',
  grantRevokeAccess: 'Grant / Revoke Access',
  unit_name: 'Unit Name',
  parent_organization: 'Parent Organization',
  rh_name: 'RH Name',
  rh_email: 'RH Email',
  rh_contact_number: 'RH Contact Number',
  add_logo: 'Add Logo',
  invalid_mobile_number: 'Invalid Mobile Number',
  invalid_email: 'Invalid Email',
  required: 'is a Required Field',
  select_unit_member_role: 'Select Unit Member Role',
  create_org_unit: 'Create Org Unit',
  organization_name: 'Organization Name',
  create: 'Create',
  orgManagement: 'Org Management',
  name_already_exists: 'Name Already Exists',
  org_unit_list: 'Organization Units List',
  existing_user: 'Existing User',
  non_existing_user: 'Non Existing User',
  upload_logo: 'Upload Logo',
  created_successfully: 'Created Successfully',
  added_successfully: 'Added Successfully',
  server_error_kindly_refresh: 'Server error: kindly refresh the page',
  recruiter_head: 'Recruiter Head',
  interviewer: 'Interviewer',
  recruiter: 'Recruiter',
  source_manager: 'Source Manager',
  sourcer: 'Sourcer',
  vendor_manager: 'Vendor Manager',
  more_option: 'More Option',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete?  ',
  no: 'No',
  yes: 'Yes',
  subject: 'Subject',
  mail_content: 'Mail Content',
  date_created: 'Date Created',
  last_updated: 'Last Updated',
  action: 'Action',
  name_is_required: 'Name is required',
  subject_is_required: 'Subject is required',
  mailer_purpose_is_required: 'Mailer Purpose is required',
  mail_content_is_required: 'Mail Content is required',
  from_address: 'From Address',
  to_address: 'To Address',
  cc_address: 'CC Address',
  bcc_address: 'BCC Address',
  reply_to_address: 'Reply To Address',
  mailer_purpose: 'Mailer Purpose',
  org_units: 'Org Units',
  save: 'Save',
  mailer_template: 'Mailer Template',
  no_templates: 'No Templates Available',
  mail_log: 'Mail Log',
  no_data_found: 'No Data Found',
  s_no: 'S. No.',
  purpose: 'Purpose',
  sent_date: 'Sent Date',
  delivered: 'Delivered',
  view_mail_log: 'View Mail Log',
  is_read: 'Is Read',
  next: 'Next',
  prev: 'Previous',
  services: 'Services',
  skill_management: 'Skill Management',
  designation_management: 'Designation Management',
  add_new_skill: 'Add New Skill',
  search_skill: 'Search Skill',
  active_status: 'Active Status',
  skill: 'Skill',
  version: 'Version',
  enabled: 'Enabled',
  disabled: 'Disabled',
  new_skill: 'New Skill',
  edit_skill: 'Edit Skill',
  skill_name: 'Skill Name',
  choose_file: 'Choose File',
  no_file_choosen: 'No File Choosen',
  version_is_required: 'Version is Required',
  are_you_sure_to_delete: 'Are you sure to Delete?',
  description_is_required: 'Description is Required',
  please_select_a_file: 'Please Select a File',
  add_new_designation: 'Add New Designation',
  search_designation: 'Search Designation',
  edit_designation: 'Edit Designation',
  designation_name: 'Designation Name',
  new_designation: 'New Designation',
  designation: 'Designation',
  description: 'Description',
  select_org_unit: 'Select Org Unit',
  access: 'Access',
  email: 'Email',
  feedback: 'Feedback',
  createFeedbackQuestion: 'Create Feedback Question',
  questions: 'Questions',
  type: 'Type',
  category: 'Category',
  options: 'Options',
  defaultQuestion: 'Default Question',
  createFeedback: 'Create Feedback',
  feedbackType: 'Feedback Type',
  institutuion: 'Institutuion',
  option: 'Option',
  default: 'Default',
  institution: 'Institution',
  questions_is_required: 'Questions is required',
  category_is_required: 'Category is required',
  feedbackType_is_required: 'FeedbackType is required',
  option_is_required: 'Option is required',
  completion_end_range_is_required: 'Completion End Range is required',
  completion_start_range_is_required: 'Completion Start Range is required',
  max_two_decimals: 'Max Two Decimals',
  enter_positive_value: 'It must be a Positive value',
  less_than_100: 'Less than or equal to 100',
  actions: 'Actions',
  editFeedback: 'Edit Feedback',
  submit: 'Submit',
  update: 'Update',
  add: 'Add',
  add_role: 'Add Role',
  subscription_details: 'Subscription Details',
  reclytics: 'Reclytics',
  skillytics: 'Skillytics',
  no_of_users: 'No. of users',
  subscribe_now: 'Subscribe Now',
  cart: 'Cart',
  your_subscriptions: 'Your Subscriptions',
  items_in_cart: 'items in cart',
  enter_code: 'Enter code',
  discount_code: 'Discount Code',
  apply: 'Apply',
  payment_options: 'Payment Options',
  full_payment: 'Full Payment',
  monthly_payment: 'Monthly Payment',
  partial_payment: 'Partial Payment',
  subtotal: 'Subtotal',
  items: 'Items',
  discount: 'Discount',
  tax: 'Tax',
  grand_total: 'Grand Total',
  continue_shopping: 'Continue Shopping',
  proceed_to_payment: 'Proceed To Payment',
  pay: 'Pay',
  payment: 'payment',
  remaining_balance: 'Remaining Balance',
  payable_now: 'Payable now',
  status: 'Status',
  thank_you_for_your_purchase: 'Thank you for your purchase! Your license will be added to your account shortly.',
  coupon_code_apply_successfully: 'Coupon code apply successfully',
  wrong_coupon_code: 'Wrong coupon code',
  view_skill: 'View Skill',
  logo: 'Logo',
  viewFeedback: 'View Feedback',
  completion_start_range: 'Completion Start Range',
  completion_end_range: 'Completion End Range',
  more_options: 'More Options',
  org_mail_list: 'Org. Mail List',
  mailer_template_list: 'Mailer Template List',
  edit_mailer_template: 'Edit Mailer Template',
  view_mailer_template: 'View Mailer Template',
  mail_master: 'Mail Master',
  search_mail_log: 'Search Mail Log',
  variable_validation_error: 'Text inside the curly braces cannot be changed',
  reject: 'Reject',
  approve: 'Approve',
  requested_skill: 'Requested Skill',
  request_new_skill: 'Request New Skill',
  tag: 'Tag',
  request: 'Request',
  remove: 'Remove',
  tag_designation: 'Tag Desig.',
  request_new_designation: 'Req. New Desig.',
  request_designation: 'Request Designation',
  assigned_roles: 'Assigned roles',
  assigned_role: 'Assigned role',
  program_manager: 'Program Manager',
  questionnaire_list: 'Questionnaire List',
  edit: 'Edit',
  delete: 'Delete',
};

export default OrgAdmin;
