import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Icon, Image, Dropdown, Popup,
} from 'semantic-ui-react';
import ls from 'local-storage';
import getRoleUrl from '@utils/RoleBasedURL';
import UserInfo from '@models/UserInfo';
import { useHistory, Link } from 'react-router-dom';
import useComponentDidMount from '@utils/useComponentDidMount';
import { mediaBreakpoint } from '@utils/GlobalVariables';
import Amphilogo from '@images/learning_services/amphiPhoto.svg';
import userlogo from '@images/learning_services/userlogo.svg';
import OpHeaderInitOptions from './OpHeaderInitOptions';
import OpHeaderData from './OpHeaderData';
// import NotificationPanel from '../notification/NotificationPanel';
import styles from './operationHeader.module.less';

const OperationHeader = (props) => {
  const {
    t, children = [],
    icons,
  } = props;

  const { commonDropdownOptions } = OpHeaderData();

  const { callBacks, toastMsg, options: { adminOptions } } = OpHeaderInitOptions();
  const { toast } = toastMsg;
  const {
    obtainArchivedProblemsForOrgUnit, getSwitchoptions,
  } = callBacks;

  const history = useHistory();
  const leftWrapper = children[0] ? children[0] : children;
  const rightWrapper = children[1];

  const isAdmin = ls.get('isAdmin');

  const reportsList = [
    { label: 'batch_report', onClickFn: () => history.push('/userbatchreport') },
    { label: 'all_batch_analysis', onClickFn: () => history.push('/batchanalysis') },
    { label: 'course_wise_report', onClickFn: () => history.push('/coursewisereport') },
  ];
  const [dropdownShow, setDropdownShow] = React.useState(false);
  const DropdownOptions = [
    { label: 'create_new_batch', onClickFn: () => { document.location.href = '/createbatch'; } },
    { label: 'assessment_types', onClickFn: () => { document.location.href = '/assessmenttypes'; } },
    { label: 'disable_enable_learners', onClickFn: null },
    { label: 'batch_transfer', onClickFn: null },
    {
      label: 'reports',
      onClickFn: null,
      reportsSubDropdown: true,
      onMouseEnter: () => setDropdownShow(true),
      onMouseLeave: () => setDropdownShow(false),
      options: reportsList,
    },
    { label: 'live_users', onClickFn: () => history.push('/liveusers') },
    // { label: 'epic_creation', onClickFn: null },
  ];

  useComponentDidMount(() => {
    if (isAdmin) getSwitchoptions();
  });

  return (
    <>
      {toast}
      {(window.innerWidth) > mediaBreakpoint.mobile ? (
        <div className={styles.operationHeader}>
          <div>
            <Link to="/">
              <Image
                src="/images/logo.svg"
                alt="logo"
              />
            </Link>
            <div className={styles.operationDropdown}>
              <Icon size="large" name="home" onClick={() => history.push(getRoleUrl())} />
              <Dropdown
                simple
                text={(<span fontas="beta header">{UserInfo.getName()}</span>)}
                className={styles.entireDropdown}
              >
                <Dropdown.Menu>
                  {[...adminOptions, ...commonDropdownOptions].map(({
                    icon, label, subdropdown, options = [], onClickFn,
                  }) => (
                    <Dropdown.Item onClick={onClickFn}>
                      {subdropdown ? (
                        <Dropdown
                          simple
                          direction="top"
                          text={(
                            <>
                              {icon && <Icon name={icon} />}
                              {t(label)}
                            </>
                          )}
                        >
                          <Dropdown.Menu className={options[0].key === 'user_tracking' ? styles.userManagementSub : styles.problemsAndCourses}>
                            {options.map(({
                              text, problemTypeSubDropdown, ...rest
                            }) => (
                              <Dropdown.Item
                                onClick={rest.onClickFn}
                              >
                                {problemTypeSubDropdown ? (
                                  <Dropdown
                                    pointing="right"
                                    simple
                                    text={text}
                                  >
                                    <Dropdown.Menu>
                                      {rest.options.map((type) => (
                                        <Dropdown.Item
                                          onClick={
                                            () => obtainArchivedProblemsForOrgUnit(type.value,
                                              type.text)
                                          }
                                        >
                                          {type.text}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : text}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <>
                          {icon && <Icon name={icon} />}
                          {t(label)}
                        </>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>

              </Dropdown>
            </div>
          </div>
          {leftWrapper.length === 0 ? (
            // <div className={styles.getOrgName}>
            //   <span>
            //     <Image src={Amphilogo} alt="Amphilogo" />
            //     <Popup
            //       content={(
            //         <div>
            //           <span>{UserInfo.getOrgUnitName()}</span>
            //         </div>
            //       )}
            //       trigger={(
            //         <div>
            //           <span fontas="beta header">{UserInfo.getOrgUnitName()}</span>
            //         </div>
            //       )}
            //       flowing
            //       position="top right"
            //     />
            //   </span>
            // </div>
            <></>
          ) : (
            <div>
              {leftWrapper}
              <div>
                <span>
                  <Image src={Amphilogo} alt="Amphilogo" />
                  <Popup
                    content={(
                      <div>
                        <span>{UserInfo.getOrgUnitName()}</span>
                      </div>
                    )}
                    trigger={(
                      <div>
                        <span fontas="beta header">{UserInfo.getOrgUnitName()}</span>
                      </div>
                    )}
                    flowing
                    position="top right"
                  />
                  {icons?.settingicon && (
                    <div className={styles.settingicon}>
                      <Dropdown
                        className={styles.dropdownSetting}
                        direction="left"
                        icon={<Icon size="large" name="setting" />}
                      >
                        <Dropdown.Menu direction="right">
                          {DropdownOptions.map((option) => (
                            <Dropdown.Item
                              key={option.label}
                              className={styles.dropdownItems}
                              fontas="beta"
                              onClick={option.onClickFn}
                              onMouseEnter={option.onMouseEnter}
                              onMouseLeave={option.onMouseLeave}
                            >
                              {t(option.label)}
                              {option.reportsSubDropdown && (
                                <Dropdown
                                  className={styles.subDropdownSetting}
                                  open={dropdownShow}
                                  pointing="right"
                                >
                                  <Dropdown.Menu
                                    className={styles.subDropdownItems}
                                  >
                                    {option.options?.map((subOption) => (
                                      <Dropdown.Item
                                        key={subOption.label}
                                        onClick={subOption.onClickFn}
                                      >
                                        {t(subOption.label)}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </span>
                {rightWrapper}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.mobileViewHeader}>
          <div>
            <Link to="/">
              <Image
                size="tiny"
                src="/images/logo.svg"
                alt="logo"
              />
            </Link>
            <div>
              <Image
                src={userlogo}
                alt="User Logo"
              />
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default withTranslation('programManager')(OperationHeader);
