import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

const DoubtCompletionChart = (props) => {
  const { DoubtCompletionChartData, totalCount } = props;

  const series = Object.keys(DoubtCompletionChartData).map((interval) => {
    const { total_doubts, solved_doubts } = DoubtCompletionChartData[interval];
    return [total_doubts, solved_doubts];
  });

  const categories = Object.keys(DoubtCompletionChartData) || {};

  const state = {
    series: [
      {
        name: 'Total Doubts',
        data: series.map((data) => data[0]),
      },
      {
        name: 'Resolved Doubts',
        data: series.map((data) => data[1]),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5],
      },
      title: {
        text: '',
        align: 'left',
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories,
      },
      yaxis: {
        min: 0,
        max: Math.round(totalCount),
        tickAmount: 5,
        labels: {
          formatter(val) {
            return Math.round(val);
          },
        },
        title: {
          text: 'Total Doubt Count',
        },
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    },
  };
  return (
    <div>
      <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
    </div>
  );
};
export default withTranslation('programManager')(DoubtCompletionChart);
