const Common = {
  back: 'Back',
  filter: 'Filter',
  myProfile: 'My Profile',
  changePassword: 'Change Password',
  helpDesk: 'Help Desk',
  plugins: 'Plugins',
  logout: 'Logout',
  username: 'Username',
  name: 'Name',
  batch: 'Batch',
  submit: 'Submit',
  button: 'Buttons',
  dashboard: 'Dashboard',
  assessment: 'Assessments',
  assessments: 'Assessments',
  staffingRequirements: 'Staffing requirements',
  interactions: 'Interactions',
  registration_form: 'Registration Form',
  add_personal_details: 'Add Personal Details',
  add_education_details: 'Add Education Details',
  add_experience_details: 'Add Experience Details',
  add_certificate_details: 'Add Certificate Details',
  add_skills: 'Add Skills',
  add_about: 'Add About',
  next: 'Next',
  previous: 'Previous',
  registration_form_instruction: '*In order to process your registration, we ask you to provide the following information. All fields marked with an asterisk(*) are required.',
  required: 'Required',
  invalid_email: 'Invalid Email Address',
  password_length_6: 'Password must be 6 characters long',
  password_schema_error: 'Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
  password_must_match: 'Password and Confirm Password Must Match',
  invalid: 'Invalid',
  positive_value: 'Must be a Positive Value',
  max_value: 'Maximim value is ',
  get_started_to_registration: 'Get started with Registration Process',
  enter_your_secret_code: 'Enter your secret code ',
  enter_your_email_address: 'Enter your Email Address ',
  password: 'Password',
  already_registered_email_error: 'This email is already registered. Please enter password.',
  forget_password_error: 'Forget Password ? Login with',
  otp: 'OTP',
  my_profile: 'My Profile',
  go_to_my_profile: 'Go to My Profile',
  team_management: 'Team Management',
  settings: 'Settings',
  archived_problems: 'Archived Problems',
  register: 'Register',
  add_more: 'Add More',
  kindly_fill_details_before_saving: 'Kindly Fill Details Before Saving',
  complete_the_last_experience_detail_to_add: 'Complete The Last Experience Detail To Add',
  save_the_last_certificate_before_adding: 'Save The Last Certificate Before Adding',
  invalid_secret_code: 'Invalid Secret Code',
  user_exists_but_not_a_participant: 'User Exists But Not A Participant',
  incorrect_password: 'Incorrect Password',
  view_report: 'View Report',
  view_chart: 'View Chart',
  personal_details: 'Personal Details',
  education_details: 'Education Details',
  work_experience: 'Work Experience',
  skills_and_certifications: 'Skills And Certifications',
  general: 'General',
  reset_password: 'Reset Password',
  live_users: 'Live Users',
  check_otp: 'Check Your Mobile For OTP',
  verify: 'Verify',
  get_otp: 'Get OTP',
  verified: 'Verified',
  enter_valid_otp: 'Enter Valid OTP',
  please_verify_mobile_no: 'Please Verify Mobile Number',
  Verify_the_year_of_completion_value: 'Verify the year of completion value',
  home: 'Home',
  courses: 'Courses',
  my_cart: 'My Cart',
  profile: 'Profile',
  contact_us: 'CONTACT US',
  p_no: '9345924939',
  mail: 'contact@hrlytics.in',
  visit_us: 'VISIT US',
  hrlytics_private_ltd: 'HRLytics Private Ltd',
  no_kuvempu_road: 'No. 354/B Kuvempu Road',
  mg_road_bangalore: 'Mg Road , Bangalore',
  pincode: '560048',
  save: 'Save',
  delete: 'Delete',
  staffing_requirements: 'Staffing Requirements',
  send_via_mail: 'Send Via Mail',
  send_via_phone: 'Send Via PhoneNumber',
  component: 'Component',
  create: 'Create',
  question: 'Question',
  user_tracking: 'User Tracking',
  user_management: 'User management',
  event_management: 'Event Management',
  ust_report: 'UST Report',
  institution_management: 'Institution Management',
  company: 'Company',
  college: 'College',
  school: 'School',
  about_me: 'AboutMe',
  employment_details: 'Employment Details',
  skills: 'Skills',
  certifications: 'Certifications',
  welcome: 'Welcome',
  educations: 'Educations',
  mobile_number: 'Mobile Number',
  date_of_birth: 'Date Of Birth',
  aadhar_number: 'Aadhar Number',
  profile_completion: 'Profile Completion',
  upload_resume: 'Upload Resume',
  contact: 'Contact',
  city: 'City',
  state: 'State',
  house_number: 'House Number',
  pincode_: 'Pincode',
  street: 'Street',
  follow_us: 'Follow Us',
  address_line_1: 'HRLytics Private Ltd',
  address_line_2: 'No. 362/7, Third Floor,16th Main,',
  address_line_3: '4th T Block East,Jayanagar,',
  address_line_4: 'Bangalore 560041',
  see_on_map: 'SEE ON MAP ',
  license_consumption: 'License Consumption',
  deactivate_license: 'Deactivate License',
  paste_address: 'Paste Address',
  configurations: 'Configurations',
  analytical_blueprint: 'Analytical Blueprint',
  scheduling_hub: 'Scheduling Hub',
  evaluation_summaries: 'Evaluation Summaries',
  analytics: 'Analytics',
  'reports/insights': 'Reports / Insights',
  forgot_password: 'Forgot Password',
  verify_to_change_password: 'Verify to Change Password',
  re_enter_password: 'Re-Enter Password',
  notifications: 'Notifications',
  view: 'View',
  change_password: 'Change Password',
  hiring: 'Hiring',
  personalised: 'Personalised',
  hiring_assessment: 'Hiring Assessment',
  personalised_assessment: 'Personalised Assessment',
  re_upload_resume: 'Re-Upload Resume',
  delete_resume: 'Delete Resume?',
  confirm_delete: 'Are you sure you want to remove your resume?',
  cancel: 'Cancel',
  unique_identification_number: 'Unique Identification Number(Govt. Id. Card)',
  services: 'Services',
  mail_log: 'Mail Log',
  search_user: 'Search User',
  user_list: 'User List',
  talentpulse: 'Talent Pulse',
  add_user: 'Add User',
  deactivated_users: 'Deactivated Users',
  access_denied_user_deactivated: 'Access Denied: Your Account Has Been De-Activated',
  name_cannot_be_edited: 'Name Cannot Be Edited',
  company_name: 'Company Name',
  employment_type: 'Employment Type',
  start_date: 'Start Date',
  end_date: 'End Date',
  designation: 'Designation',
  issued_by: 'Issued By',
  completion_date: 'Completion Date',
  update: 'Update',
  skill: 'Skill',
  error_in_loading_pdf: 'Error In Loading PDF',
  please_upload_your_resume: 'Please Upload Your Resume',
  pre_skill_assessment: 'Pre Skill Assessment',
  post_skill_assessment: 'Post Skill Assessment',
  skill_validation_assessment: 'Skill Validation Assessment',
  thive_user_management: 'THive Users',
};
export default Common;
