/* eslint-disable no-template-curly-in-string */

const NotificationUrl = {
  acceptSkillOrDesgination: '/admin/skilldesignation',

  // sample
  accept: '/admin/${course_id}/${id}',
};

const getDestinationPath = (
  { destination_url: destinationTo, destination_url_params: destinationparams, id },
) => {
  let url = NotificationUrl[destinationTo];

  if (url && url.includes('$')) {
    Object.keys(destinationparams).forEach((key) => {
      url = url.replace(new RegExp(`\\${key}`, 'g'), destinationparams[key]);
    });
  }

  return { url, state: { ...destinationparams, notification_id: id } };
};

export { getDestinationPath, NotificationUrl };
