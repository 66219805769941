/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route, Redirect, useHistory, useLocation,
} from 'react-router-dom';
import { coreUtils } from '@hrlytics/corelytics';
import UserInfo from '@models/UserInfo';

function PrivateRoute({ component: Component, cprops, ...rest }) {
  const valid = UserInfo.getRole();
  const hasUserUploadedResume = true;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const catalogId = queryParams.get('catalogId');
  const costingLevel = queryParams.get('costingLevel');

  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!valid) {
          return (
            <Redirect
              to={{
                pathname: catalogId ? `/login?catalogId=${catalogId}&costingLevel=${costingLevel}` : '/login',
                state: { from: props.location },
              }}
            />
          );
        }

        if (valid === 'participant' && !hasUserUploadedResume && history.location.pathname !== '/profile') {
          return (
            <Redirect
              to={{
                pathname: '/profile',
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...cprops} {...props} />;
      }}
    />
  );
}

const {
  parseJwt,
  updateC1,
  updateUserInfo,
  processLogout,
  clearCookies,
  updateFavIcon,
  updateCookieBasedOnRole,
} = coreUtils;

export {
  parseJwt, updateC1, updateUserInfo, PrivateRoute, processLogout,
  clearCookies, updateFavIcon, updateCookieBasedOnRole,
};
