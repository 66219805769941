import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
// import OperationHeader from '@globalComps/header/OperationHeader';
import UserWiseCourseScreen from '@screens/admin/UserWiseCourse/UserWiseCourseScreen';
import { Tab, TabPane } from 'semantic-ui-react';
import OperationHeader from '@globalComps/header/OperationHeader';
import DeactivatedUsers from '@screens/admin/services/DeactivatedUsers';
import styles from './ServicesUserManagement.module.less';
// import BulkUserDeactive from './BulkUserDeactive';

const SerivesUserManagement = (props) => {
  const { t } = props;

  const [activeTab, setActiveTab] = useState(0);

  const panes = [
    {
      menuItem: t('user_tracking'),
      render: () => (
        <TabPane attached={false}>
          <UserWiseCourseScreen isProgramManager />
        </TabPane>
      ),
    },
    {
      menuItem: t('user_list'),
      render: () => null,
    },
    // {
    //   menuItem: t('bulk_user_deactive'),
    //   render: () => (
    //     <TabPane attached={false}>
    //       <BulkUserDeactive />
    //     </TabPane>
    //   ),
    // },
    {
      menuItem: t('deactivated_users'),
      render: () => (
        <TabPane attached={false}>
          <DeactivatedUsers />
        </TabPane>
      ),
    },
  ];
  return (
    <>
      <OperationHeader />
      <div className={styles.mailMasterHeader}>
        <Tab
          menu={{ secondary: true }}
          panes={panes}
          activeIndex={activeTab}
          onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}
        />
      </div>
    </>
  );
};

export default withTranslation('programManager')(SerivesUserManagement);
