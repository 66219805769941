// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.createResetPasswordModal__1YK49 > div:last-child {\n  padding: 10px 60px !important;\n}\n.createResetPasswordModal__1YK49 .createResetButton__AtCwA {\n  margin: auto;\n  display: block ruby;\n}\n.ResetPasswordModal__20SY_ .usernameWithVerification__W-0qA {\n  display: flex;\n  justify-content: space-between;\n}\n.ResetPasswordModal__20SY_ .usernameWithVerification__W-0qA > div:last-child {\n  display: flex;\n  align-items: center;\n}\n.ResetPasswordModal__20SY_ > div[class=\"ui red pointing above basic label\"] {\n  margin-top: 0 !important;\n}\n.ResetPasswordModal__20SY_ > div:nth-child(5) > div:last-child {\n  padding: 10px 0 !important;\n}\n.ResetPasswordOr__19cA3 {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  padding: 8px;\n}\n.ResetPasswordModalForm__1waf7 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: 1px solid #C2CAC7 !important;\n  padding: 7px;\n  border-radius: 4px;\n}\n.ResetPasswordModalForm__1waf7 > span:first-child {\n  width: 62%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.messageModalOpen__2DVRE > div {\n  display: flex !important;\n  flex-direction: column !important;\n  align-items: center !important;\n}\n.ErrorBackground__3Fz6Q {\n  background-color: #FF14143B;\n}\n.FileErrorMessage__2P7rk {\n  color: #9A0A0A;\n}\n.mutliUserResetPasswordError__10J7Z {\n  display: flex;\n}\n", ""]);
// Exports
exports.locals = {
	"createResetPasswordModal": "createResetPasswordModal__1YK49",
	"createResetButton": "createResetButton__AtCwA",
	"ResetPasswordModal": "ResetPasswordModal__20SY_",
	"usernameWithVerification": "usernameWithVerification__W-0qA",
	"ResetPasswordOr": "ResetPasswordOr__19cA3",
	"ResetPasswordModalForm": "ResetPasswordModalForm__1waf7",
	"messageModalOpen": "messageModalOpen__2DVRE",
	"ErrorBackground": "ErrorBackground__3Fz6Q",
	"FileErrorMessage": "FileErrorMessage__2P7rk",
	"mutliUserResetPasswordError": "mutliUserResetPasswordError__10J7Z"
};
module.exports = exports;
