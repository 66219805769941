import { restbaseurl } from './constants';

export const resturls = {
  login: 'framelytics/userManagement/user/login',
  logout: 'framelytics/userManagement/user/logout',
  obtainAssessmentSessionForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentSessionForOrgUnit',
  createAssessmentSession: 'framelytics/assessmentSessionManagement/assessmentSession/createAssessmentSession',
  displayExistingComponents: '/framelytics/assessmentSessionManagement/assessmentSession/displayExistingComponents',
  createAndTagNewComponent: 'framelytics/assessmentSessionManagement/assessmentSession/createAndTagNewComponent',
  rearrangeComponents: 'framelytics/assessmentSessionManagement/assessmentSession/rearrangeComponents',
  deleteComponent: 'framelytics/assessmentSessionManagement/assessmentSession/deleteComponent',
  componentUpdate: 'framelytics/assessmentSessionManagement/assessmentSession/componentUpdateFinishSetup',
  obtainSkillsandDesignationsForOrg: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSkillsAndDesignationsForOrg',
  obtainQuestionBankTreeStructure: '/framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionBankTreeStructure',
  bulkUploadValidation: 'quizlytics/quizManagement/quizQuestion/validateBulkUpload',
  bulkUpload: 'quizlytics/quizManagement/quizQuestion/bulkUpload',
  obtainFirstLevelQuestionBanksForOrgUnit: '/framelytics/assessmentSessionManagement/assessmentSession/obtainFirstLevelQuestionBanksForOrgUnit',
  obtainFirstLevelQuestionBanksUnderTechnicalForOrg: 'framelytics/assessmentSessionManagement/assessmentSession/obtainFirstLevelQuestionBanksUnderTechnicalForOrg',
  obtainQuestionsForQuestionBank: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionsForQuestionBank',
  tagQuestionsToComponent: 'framelytics/assessmentSessionManagement/assessmentSession/tagQuestionsToComponent',
  obtainQuestionPapersQuestionCount: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionPapersQuestionCount',
  createNewQuestionPaper: 'framelytics/assessmentSessionManagement/assessmentSession/createNewQuestionPaper',
  obtainTaggedQuestionsForQuestionPaper: '/framelytics/assessmentSessionManagement/assessmentSession/obtainTaggedQuestionsForQuestionPaper',
  unTagQuestionsFromQuestionPaper: 'framelytics/assessmentSessionManagement/assessmentSession/unTagQuestionsFromQuestionPaper',
  obtainFormFields: 'framelytics/userManagement/user/obtainFormFields',
  createForm: 'framelytics/userManagement/user/createForm',
  removeUserCertificationDetail: 'framelytics/userManagement/user/removeUserCertificationDetail',
  retrieveComplexity: 'framelytics/baseappManagement/baseapp/retrieveComplexity',
  listOfOrganizationTreeStructure: '/framelytics/userManagement/user/listOfOrganizationTreeStructure',
  createOrganizationMember: 'framelytics/userManagement/user/createOrganizationMember',
  obtainOrganisationRole: 'framelytics/userManagement/user/obtainOrganisationRole',
  createOrganizationUnit: 'framelytics/userManagement/user/createOrganizationUnit',
  obtainOrgAndOrgUnitRolesForUser: 'framelytics/userManagement/user/obtainOrgAndOrgUnitRolesForUser',
  obtainAllOrgUnitForOrg: 'framelytics/userManagement/user/obtainAllOrgUnitForOrg',
  obtainAllOrgUnitForRh: 'framelytics/userManagement/user/obtainAllOrgUnitForRhTreeStructure',
  checkOrgName: 'framelytics/userManagement/user/checkOrgName',
  complexityCategory: 'framelytics/assessmentSessionManagement/complexityCategory/',
  quizQuestionType: 'quizlytics/quizManagement/quizQuestionType/',
  obtainAllFolders: '/framelytics/assessmentSessionManagement/assessmentSession/obtainAllFolders',
  teamViewHandler: 'framelytics/userManagement/user/obtainTeamsForTeamManagement',
  checkExistingTeamName: 'framelytics/userManagement/user/checkTeamName',
  obtainExistingUsers: 'framelytics/userManagement/user/obtainExistingUsers',
  validateUserBulkUploadForTeam: 'framelytics/userManagement/user/validateUserBulkUploadForTeam',
  updateTeamDetails: 'framelytics/userManagement/user/updateTeamDetails',
  validateRemoveUsersBulkupload: 'framelytics/userManagement/user/validateRemoveUsersBulkupload',
  bulkUploadUserForTeam: 'framelytics/userManagement/user/bulkUploadUserForTeam',
  removeUsers: 'framelytics/userManagement/user/removeUsers',
  obtainRolesForTeam: 'framelytics/userManagement/user/obtainRolesForTeam',
  assignSingleUser: 'framelytics/userManagement/user/assignSingleUser',
  obtainExistingUserDetails: 'framelytics/userManagement/user/obtainExistingUserDetails',
  obtainAssessmentsForParticipant: '/framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentsForParticipant',
  obtainScheduleTeamSettings: '/framelytics/assessmentSessionManagement/assessmentSession/obtainScheduleTeamSettings',
  saveScheduleTeamSettings: '/framelytics/assessmentSessionManagement/assessmentSession/saveScheduleTeamSettings',
  updateQuestionAttempt: 'quizlytics/quizManagement/quizQuestion/updateQuestionAttempt',
  obtainTeamTimingForHrlSession: '/framelytics/assessmentSessionManagement/assessmentSession/obtainTeamTimingForHrlSession',
  obtainComponentQuestionpaperStatus: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentQuestionpaperStatus',
  updateScheduleTeamSettings: 'framelytics/assessmentSessionManagement/assessmentSession/updateScheduleTeamSettings',
  obtainQuestionsCountByCategory: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionsCountByCategory',
  obtainQuestionsCountByCategoryForRandom: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionsCountByCategoryForRandom',
  obtainTeamsForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamsForAssessment',
  obtainTeamsForLearningSession: 'framelytics/learningSessionManagement/learningSession/obtainTeamsForLearningSession',
  displayUserAttempts: 'framelytics/baseappManagement/hrlSession/displayUserAttempts',
  continueAttemptsForUsers: 'framelytics/baseappManagement/hrlSession/continueAttemptsForUsers',
  finishAttemptsForUsers: 'framelytics/baseappManagement/hrlSession/finishAttemptsForUsers',
  deleteAttemptsForUsers: 'framelytics/baseappManagement/hrlSession/deleteAttemptsForUsers',
  extendTimeForAssessment: 'framelytics/baseappManagement/hrlSession/extendTimeForAssessment',
  obtainScoreSettings: 'framelytics/assessmentSessionManagement/assessmentSession/obtainScoreSettings',
  updateScoreSettings: 'framelytics/assessmentSessionManagement/assessmentSession/updateScoreSettings',
  obtainAssessmentSettings: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentSettings',
  updateSecretCode: 'framelytics/assessmentSessionManagement/assessmentSession/updateSecretCode',
  updateAssessmentSettings: 'framelytics/assessmentSessionManagement/assessmentSession/updateAssessmentSettings',
  obtainCandidateExperienceSettings: 'framelytics/assessmentSessionManagement/assessmentSession/obtainCandidateExperienceSettings',
  updateCandidateExperienceSettings: 'framelytics/assessmentSessionManagement/assessmentSession/updateCandidateExperienceSettings',
  uploadVideoInInstructionPage: 'framelytics/assessmentSessionManagement/assessmentSession/uploadVideoInInstructionPage',
  obtainAttemptStatusForUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAttemptStatusForUser',
  updateAttemptStatusForUser: 'framelytics/assessmentSessionManagement/assessmentSession/updateAttemptStatusForUser',
  obtainComponentTypeDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentTypeDetails',
  validateSecretCode: 'framelytics/assessmentSessionManagement/assessmentSession/validateSecretCode',
  validateEmailForAssesmentSession: 'framelytics/userManagement/user/validateEmailForAssesmentSession',
  obtainRegistrationForm: 'framelytics/userManagement/user/obtainRegistrationForm',
  obtainAllForms: 'framelytics/userManagement/user/obtainAllForms',
  obtainTeamTimingForAssessment: 'quizlytics/quizManagement/quizQuestion/obtainTeamTimingForAssessment',
  obtainUserListForTeamTiming: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserListForTeamTiming',
  obtainUserHrlSessionAttempt: 'quizlytics/quizManagement/quizQuestion/obtainQuizAttemptDetailsForTeam',
  obtainUserPersonalDetails: 'framelytics/userManagement/user/obtainUserPersonalDetails',
  saveUserPersonalDetails: 'framelytics/userManagement/user/saveUserPersonalDetails',
  obtainUserEducationDetails: 'framelytics/userManagement/user/obtainUserEducationDetails',
  saveUserEducationDetails: 'framelytics/userManagement/user/saveUserEducationDetails',
  obtainUserSkillsAndCertificationDetails: 'framelytics/userManagement/user/obtainUserSkillsAndCertificationDetails',
  obtainUserWorkExperienceDetails: 'framelytics/userManagement/user/obtainUserWorkExperienceDetails',
  saveUserWorkExperienceDetails: 'framelytics/userManagement/user/saveUserWorkExperienceDetails',
  saveUserAboutMeDetails: 'framelytics/userManagement/user/saveUserAboutMeDetails',
  obtainUserAboutMeDetails: 'framelytics/userManagement/user/obtainUserAboutMeDetails',
  obtainReportDetailsForTeamTiming: 'framelytics/assessmentSessionManagement/assessmentSession/obtainReportDetailsForTeamTiming',
  obtainTeamTimingforReport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamTimingforReport',
  saveUserTechnicalSkillDetails: 'framelytics/userManagement/user/saveUserTechnicalSkillDetails',
  saveUserCertificationDetails: 'framelytics/userManagement/user/saveUserCertificationDetails',
  saveUserRegistration: 'framelytics/userManagement/user/saveUserRegistration',
  validateUser: 'framelytics/userManagement/user/validateUser',
  updateTeamTiming: '/framelytics/assessmentSessionManagement/assessmentSession/updateTeamTiming',
  obtainUserListForTeam: '/framelytics/assessmentSessionManagement/assessmentSession/obtainUserListForTeam',
  createFolderAndFile: 'framelytics/assessmentSessionManagement/assessmentSession/createFolderAndFile',
  removeMemberInForumGroup: 'framelytics/learningSessionManagement/learningSession/removeMemberInForumGroup',
  importProgrammingQuestionBank: 'codelytics/codeManagement/codingQuestion/importProgrammingQuestionBank',
  updateFolderAndFile: 'framelytics/assessmentSessionManagement/assessmentSession/updateFolderAndFile',
  obtainUserReport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserReport',
  assignUserForTeamTiming: '/framelytics/assessmentSessionManagement/assessmentSession/assignUserForTeamTiming',
  obtainComponentsFromAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentsFromAssessment',
  updateCodingComponentdetails: 'codelytics/codeManagement/codingQuestion/updateCodingComponentdetails',
  checkIfEqualScoreForQp: '/framelytics/assessmentSessionManagement/assessmentSession/checkIfEqualScoreForQp',
  obtainOverallScoreDistribution: 'framelytics/assessmentSessionManagement/assessmentSession/obtainOverallScoreDistribution',
  obtainOverallAssessmentStrength: 'framelytics/assessmentSessionManagement/assessmentSession/obtainOverallAssessmentStrength',
  obtainSectionWiseScoreDistribution: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSectionWiseScoreDistribution',
  obtainSectionWiseAssesmentStrength: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSectionWiseAssesmentStrength',
  obtainScoresForReport: '/framelytics/assessmentSessionManagement/assessmentSession/obtainScoresForReport',
  cloneAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/cloneAssessment',
  saveEmailNotificationSettings: 'framelytics/assessmentSessionManagement/assessmentSession/saveEmailNotificationSettings',
  obtainEmailNotificationSettings: 'framelytics/assessmentSessionManagement/assessmentSession/obtainEmailNotificationSettings',
  checkQuestionPaperAttempted: 'framelytics/assessmentSessionManagement/assessmentSession/checkQuestionPaperAttempted',
  obtainSegmentAndRolesForAdmin: 'framelytics/adminManagement/admin/obtainSegmentAndRolesForAdmin',
  forwardMessage: 'framelytics/learningSessionManagement/learningSession/forwardMessage',
  obtainStatisticsForAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/obtainStatisticsForAttempt',
  uploadAndGenerateLinkForAssessmentImage: 'framelytics/assessmentSessionManagement/assessmentSession/uploadAndGenerateLinkForAssessmentImage',
  uploadAndGenerateLinkForModuleImage: 'framelytics/courseManagement/course/uploadAndGenerateLinkForModuleImage',
  uploadAndGenerateLinkForCourseImage: 'framelytics/courseManagement/course/uploadAndGenerateLinkForCourseImage',
  obtainParentConceptNode: 'framelytics/baseappManagement/baseapp/obtainParentConceptNode',
  obtainChildConceptNodes: 'framelytics/baseappManagement/baseapp/obtainChildConceptNodes',
  createWeightageForQuestionBankQuestions: 'quizlytics/quizManagement/quizQuestion/createWeightageForQuestionBankQuestions',
  obtainUserReportForComponent: '/framelytics/assessmentSessionManagement/assessmentSession/obtainUserReportForComponent',
  obtainFunnelDataForTeam: 'framelytics/assessmentSessionManagement/assessmentSession/obtainFunnelDataForTeam',
  reEvaluateQuestionScore: 'framelytics/assessmentSessionManagement/assessmentSession/reEvaluateQuestionScore',
  inviteSingleUserForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/inviteSingleUserForAssessment',
  inviteMultipleUsersForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/inviteMultipleUsersForAssessment',
  addInvitedUsersInTeamAndSendEmailAndSmsNotification: 'framelytics/assessmentSessionManagement/assessmentSession/addInvitedUsersInTeamAndSendEmailAndSmsNotification',
  obtainComponentQuestionPaperForTeam: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentQuestionPaperForTeam',
  obtainConceptNodeReport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainConceptNodeReport',
  obtainUserScoreReportUsingComponentWiseFilter: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserScoreReportUsingComponentWiseFilter',
  obtainBluePrintComponentWisePdf: 'framelytics/assessmentSessionManagement/assessmentSession/obtainBluePrintComponentWisePdf',
  obtainQuestionFromQuestionBankForExport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionFromQuestionBankForExport',
  obtainOrgUnitsForMailerTemplate: 'framelytics/userManagement/user/obtainOrgUnitsForMailerTemplate',
  saveMailerTemplateForOrganization: 'framelytics/userManagement/user/saveMailerTemplateForOrganization',
  obtainMailertemplatesForOrganization: 'framelytics/userManagement/user/obtainMailertemplatesForOrganization',
  updateMailerTemplateDetailsForOrganization: 'framelytics/userManagement/user/updateMailerTemplateDetailsForOrganization',
  deleteMailerTemplateFromOrganization: 'framelytics/userManagement/user/deleteMailerTemplateFromOrganization',
  swapQuestionBanks: 'framelytics/assessmentSessionManagement/assessmentSession/swapQuestionBanks',
  obtainBluePrintQuestionsForComponent: 'framelytics/assessmentSessionManagement/assessmentSession/obtainBluePrintQuestionsForComponent',
  obtainRoleListForUser: 'framelytics/userManagement/user/obtainRoleListForUser',
  obtainRoleSegmentList: 'framelytics/baseappManagement/baseapp/obtainRoleSegmentList',
  obtainLastActiveSegmentForParticipant: 'framelytics/baseappManagement/baseapp/obtainLastActiveSegmentForParticipant',
  updateLastActiveSegmentInParticipant: 'framelytics/baseappManagement/baseapp/updateLastActiveSegmentInParticipant',
  updateCookieBasedOnRole: 'framelytics/userManagement/user/updateCookieBasedOnRole',
  obtainLanguageForComponent: 'codelytics/codeManagement/codingQuestion/obtainLanguageForComponent',
  obtainTeamQPAssignmentForSession: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamQPAssignmentForSession',
  obtainBatchListForAddingUsers: 'framelytics/learningSessionManagement/learningSession/obtainBatchListForAddingUsers',

  // attempt urls
  obtainInstructionPageDetails: 'framelytics/baseappManagement/hrlSession/obtainInstructionPageDetails',
  obtainAllComponentAttemptsAndCurrentComponentQuestionAttempts: 'framelytics/baseappManagement/hrlSession/obtainAllComponentAttemptsAndCurrentComponentQuestionAttempts',
  updateComponentAndQuestionPointer: 'framelytics/baseappManagement/hrlSession/updateComponentAndQuestionPointer', // for SC
  reviewAndFinish: 'framelytics/baseappManagement/hrlSession/reviewAndFinish',
  graceTimeUpdateServerSentEvents: 'framelytics/baseappManagement/hrlSession/updateGraceTimeServerSentEvent', // for SC
  checkSecureWindowViolation: 'framelytics/baseappManagement/hrlSession/checkSecureWindowViolation',
  updateSecureWindowViolation: 'framelytics/baseappManagement/hrlSession/updateSecureWindowViolation',
  checkFullScreenViolation: 'framelytics/baseappManagement/hrlSession/checkFullScreenViolation',
  updateFullScreenViolation: 'framelytics/baseappManagement/hrlSession/updateFullScreenViolation',
  createOrObtainQuestionAttempt: 'framelytics/baseappManagement/hrlSession/createOrObtainQuestionAttempt',
  getRemainingTimeAndActiveQuestion: 'framelytics/baseappManagement/hrlSession/getRemainingTimeAndActiveQuestion',
  checkPendingComponents: 'framelytics/baseappManagement/hrlSession/checkPendingComponents',
  updateDurationForAttempt: 'framelytics/baseappManagement/baseapp/updateDurationForAttempt',
  updateMultipleMonitorDetectionCount: 'framelytics/assessmentSessionManagement/assessmentSession/updateMultipleMonitorDetectionCount',
  updateTimeTakenForQuestionAttempt: 'framelytics/baseappManagement/hrlSession/updateTimeTakenForQuestionAttempt',

  // proctoring urls
  obtainProctoringConfig: 'framelytics/baseappManagement/hrlSession/obtainProctoringConfig',
  saveFaces: 'user/saveFaces',
  saveHawkId: 'framelytics/userManagement/user/saveHawkId',
  checkProctoringForAttempt: 'framelytics/baseappManagement/hrlSession/checkProctoringForAttempt',
  logFNDWarning: 'framelytics/baseappManagement/hrlSession/logFNDWarning',
  attemptGenuine: 'proctoring/attemptGenuine',
  saveAttemptScreen: 'proctoring/saveAttemptScreen',
  saveAudioFileForAttempt: 'proctoring/saveAudioFileForAttempt',

  // RH - proctoring urls
  obtainAssessmentInRange: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentInRange',
  obtainUserAttemptsForProctoring: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserAttemptsForProctoring',
  obtainDomainName: 'framelytics/assessmentSessionManagement/assessmentSession/obtainDomainName',
  obtainCamAndScreenCount: 'proctoring/obtainCamAndScreenCount',
  attemptDetail: 'proctoring/attemptDetail',
  obtainScreenImages: 'proctoring/obtainScreenImages',
  obtainProfilingImages: 'user/obtainProfilingImages',
  obtainAttemptNumberForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAttemptNumberForAssessment',
  calculateGenuinityIndex: 'proctoring/calculateGenuinityIndex',
  deleteAttempt: 'proctoring/deleteAttempt',
  obtainProctoringReport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainProctoringReport',
  proctoringReportsBulkDownload: 'framelytics/assessmentSessionManagement/assessmentSession/proctoringReportsBulkDownload',
  retrieveAudiosForProctoring: 'proctoring/retrieveAudiosForProctoring',
  obtainAudioFile: 'proctoring/obtainAudioFile',

  // learning component///////////////////////////////////////////////////////////////////////////
  createOrUpdateCourse: 'framelytics/learningSessionManagement/learningSession/createOrUpdateCourse',
  obtainCourseList: 'framelytics/learningSessionManagement/learningSession/obtainCourseList',
  courseCategory: 'framelytics/learningSessionManagement/courseCategory/',
  obtainCourseDetails: 'framelytics/adminManagement/admin/obtainCourseDetails',
  obtainExistingCourseDetails: 'framelytics/learningSessionManagement/learningSession/obtainExistingCourseDetails',
  updateVisibility: 'framelytics/learningSessionManagement/learningSession/updateVisibility',
  createOrUpdateTrack: 'framelytics/learningSessionManagement/learningSession/createOrUpdateTrack',
  obtainTrackList: 'framelytics/learningSessionManagement/learningSession/obtainTrackList',
  obtainExistingTrack: 'framelytics/learningSessionManagement/learningSession/obtainExistingTrack',
  obtainCourseNameList: 'framelytics/learningSessionManagement/learningSession/obtainCourseNameList',
  obtainCourseNameListForOrgunitForTagging: 'framelytics/learningSessionManagement/learningSession/obtainCourseNameListForOrgunitForTagging',
  obtainTrackDetail: 'framelytics/learningSessionManagement/learningSession/obtainTrackDetail',
  tagCourseForTrack: 'framelytics/learningSessionManagement/learningSession/tagCourseForTrack',
  unTagCourseForTrack: 'framelytics/learningSessionManagement/learningSession/unTagCourseForTrack',
  obtainTaggedCoursesForTrack: 'framelytics/learningSessionManagement/learningSession/obtainTaggedCoursesForTrack',
  deleteTrack: 'framelytics/learningSessionManagement/learningSession/deleteTrack',
  obtainCourseDetail: 'framelytics/learningSessionManagement/learningSession/obtainCourseDetail',
  createOrUpdateModule: 'framelytics/learningSessionManagement/learningSession/createOrUpdateModule',
  obtainModuleGroupAndItsModuleList: 'framelytics/learningSessionManagement/learningSession/obtainModuleGroupAndItsModuleList',
  obtainSessionListForModuleList: 'framelytics/learningSessionManagement/learningSession/obtainSessionListForModuleList',
  swapSessionsForModules: 'framelytics/learningSessionManagement/learningSession/swapSessionsForModules',
  createOrUpdateModuleGroup: 'framelytics/learningSessionManagement/learningSession/createOrUpdateModuleGroup',
  deleteModuleGroup: 'framelytics/learningSessionManagement/learningSession/deleteModuleGroup',
  obtainModuleDetails: 'framelytics/learningSessionManagement/learningSession/obtainModuleDetails',
  modulesRearrange: 'framelytics/learningSessionManagement/learningSession/modulesRearrange',
  saveOrUpdateFileField: 'framelytics/learningSessionManagement/learningSession/saveOrUpdateFileField',
  saveOrUpdateFileFieldForTrack: 'framelytics/learningSessionManagement/learningSession/saveOrUpdateFileFieldForTrack',
  deleteModule: 'framelytics/learningSessionManagement/learningSession/deleteModule',
  taggedCoursesRearrange: 'framelytics/learningSessionManagement/learningSession/taggedCoursesRearrange',
  obtainOverallCourseDetail: 'framelytics/learningSessionManagement/learningSession/obtainOverallCourseDetail',
  createLearningSession: 'framelytics/learningSessionManagement/learningSession/createLearningSession',
  organizeSessionType: 'framelytics/learningSessionManagement/organizeSessionType/',
  obtainTrackNamesForCreatePage: 'framelytics/learningSessionManagement/learningSession/obtainTrackNamesForCreatePage',
  obtainAllTrackTypes: 'framelytics/learningSessionManagement/learningSession/obtainAllTrackTypes',
  createLessonComponent: 'framelytics/learningSessionManagement/learningSession/createLessonComponent',
  updateLessonComponent: 'framelytics/learningSessionManagement/learningSession/updateLessonComponent',
  obtainExistingSessionDetails: 'framelytics/learningSessionManagement/learningSession/obtainExistingSessionDetails',

  // modules and id call
  obtainModuleNamesForCourse: 'framelytics/learningSessionManagement/learningSession/obtainModuleNamesForCourse',
  sessionType: 'framelytics/learningSessionManagement/sessionType/',
  obtainLearningSessionsForModule: 'framelytics/learningSessionManagement/learningSession/obtainLearningSessionsForModule',
  obtainComponentType: 'framelytics/baseappManagement/baseapp/obtainComponentType',
  learningSessionRearrangeForModule: 'framelytics/learningSessionManagement/learningSession/learningSessionRearrangeForModule',

  // createBatch//////
  createLocation: 'framelytics/learningSessionManagement/learningSession/createLocation',
  createIntake: 'framelytics/learningSessionManagement/learningSession/createIntake',
  createStream: 'framelytics/learningSessionManagement/learningSession/createStream',
  createBatch: 'framelytics/learningSessionManagement/learningSession/createBatch',
  obtainStreamForOrgunit: 'framelytics/userManagement/user/obtainStreamForOrgunit',
  obtainBatchCreationDetailList: 'framelytics/learningSessionManagement/learningSession/obtainBatchCreationDetailList',
  createTrackConfigAndTagBatchForTrack: 'framelytics/learningSessionManagement/learningSession/createTrackConfigAndTagBatchForTrack',
  CreateNewHandler: 'framelytics/learningSessionManagement/learningSession/CreateNewHandler',
  assignTeamForTrackCourses: 'framelytics/learningSessionManagement/learningSession/assignTeamForTrackCourses',
  assignUserListForBatch: 'framelytics/learningSessionManagement/learningSession/assignUserListForBatch',
  obtainTeamsForOrgUnit: 'framelytics/learningSessionManagement/learningSession/obtainTeamsForOrgUnit',
  obtainBatchNameForBatchId: 'framelytics/learningSessionManagement/learningSession/obtainBatchNameForBatchId',
  obtainLocationAndIntakeForTrackConfig: 'framelytics/learningSessionManagement/learningSession/obtainLocationAndIntakeForTrackConfig',
  BulkUploadUserForTeamAndBatch: 'framelytics/userManagement/user/BulkUploadUserForTeamAndBatch',
  validateUserBulkUploadForTeamAndBatch: 'framelytics/userManagement/user/validateUserBulkUploadForTeamAndBatch',
  deleteQuestionPaperFromComponent: 'framelytics/assessmentSessionManagement/assessmentSession/deleteQuestionPaperFromComponent',
  updateQuestionPaperName: 'framelytics/assessmentSessionManagement/assessmentSession/updateQuestionPaperName',
  resetPasswordForSingleUser: 'framelytics/userManagement/user/resetPasswordForSingleUser',
  validateUserUploadForResetPassword: 'framelytics/userManagement/user/validateUserUploadForResetPassword',
  resetPasswordForMultipleUser: 'framelytics/userManagement/user/resetPasswordForMultipleUser',
  recomputeScoreForSession: 'framelytics/learningSessionManagement/learningSession/recomputeScoreForSession',
  obtainEditWeightageInBlueprint: 'framelytics/assessmentSessionManagement/assessmentSession/obtainEditWeightageInBlueprint',
  obtainScoreCard: 'framelytics/assessmentSessionManagement/assessmentSession/obtainScoreCard',
  searchMailerTemplatesForOrganization: 'framelytics/userManagement/user/searchMailerTemplatesForOrganization',
  obtainMailerPurposeForOrgUnit: 'framelytics/userManagement/user/obtainMailerPurposeForOrgUnit',
  obtainLanguagesForComponentSettings: 'codelytics/codeManagement/codingQuestion/obtainLanguagesForComponentSettings',
  rankingMode: '/codelytics/codeManagement/rankingMode/',
  updateDbmsQueryComponentDetails: 'codelytics/queryManagement/dbmsQueryQuestion/updateDbmsQueryComponentDetails',
  updateQuizComponentSetting: 'quizlytics/quizManagement/quizQuestion/updateQuizComponentSetting',
  obtainComponentQuestionForSession: 'framelytics/learningSessionManagement/learningSession/obtainComponentQuestionForSession',
  language: 'codelytics/codeManagement/language/',
  obtainLanguagesInBlueprint: 'codelytics/codeManagement/codingQuestion/obtainLanguagesInBlueprint',
  obtainQuestionBankForCodingQuestion: 'codelytics/codeManagement/codingQuestion/obtainQuestionBankForCodingQuestion',
  validateDbmsBulkUploadXl: 'codelytics/queryManagement/dbmsQueryQuestion/validateDbmsBulkUploadXl',
  addLanguageInBlueprint: 'codelytics/codeManagement/codingQuestion/addLanguageInBlueprint',
  deleteLanguageInBluePrint: 'codelytics/codeManagement/codingQuestion/deleteLanguageInBluePrint',
  updateLanguageVisibility: 'codelytics/codeManagement/codingQuestion/updateLanguageVisibility',
  insertDbmsBulkupload: 'codelytics/queryManagement/dbmsQueryQuestion/insertDbmsBulkupload',
  obtainQbForParent: 'codelytics/queryManagement/dbmsQueryQuestion/obtainQbForParent',
  obtainSchemaForFile: 'codelytics/queryManagement/dbmsQueryQuestion/obtainSchemaForFile',
  validateEmailAndGenerateValidateCode: 'framelytics/userManagement/user/validateEmailAndGenerateValidateCode',
  resetPassword: 'framelytics/userManagement/user/resetPassword',
  obtainWidgetsForAdmin: 'framelytics/adminManagement/admin/obtainWidgetsForAdmin',
  obtainCourseData: 'framelytics/adminManagement/admin/obtainCourseList',
  obtainSingleWidgetForAdmin: 'framelytics/adminManagement/admin/obtainSingleWidgetForAdmin',
  obtainLearningSessionsAndComponentsForModule: 'framelytics/learningSessionManagement/learningSession/obtainLearningSessionsAndComponentsForModule',
  teamFinishSetupForSession: 'framelytics/learningSessionManagement/learningSession/teamFinishSetupForSession',
  obtainDashBoardDetailsForBatch: 'framelytics/learningSessionManagement/learningSession/obtainDashBoardDetailsForBatch',
  obtainBatchListForStream: 'framelytics/learningSessionManagement/learningSession/obtainBatchListForStream',
  lookupUserNameForAdmin: 'framelytics/adminManagement/admin/lookupUserNameForAdmin',
  obtainUserTaggedCourseandTeamlist: 'framelytics/adminManagement/admin/obtainUserTaggedCourseandTeamlist',
  obtainSessionsandTeamtimingDetailForAdmin: 'framelytics/adminManagement/admin/obtainSessionsandTeamtimingDetailForAdmin',
  updateSessionsandTeamtimingDetailForAdmin: 'framelytics/adminManagement/admin/updateSessionsandTeamtimingDetailForAdmin',

  obtainTrackListAndCourseCountForBatch: 'framelytics/learningSessionManagement/learningSession/obtainTrackListAndCourseCountForBatch',
  obtainBatchListAndUserCountForStream: 'framelytics/learningSessionManagement/learningSession/obtainBatchListAndUserCountForStream',
  updateStream: 'framelytics/learningSessionManagement/learningSession/updateStream',
  obtainUserDetailsforTrackAndBatch: 'framelytics/learningSessionManagement/learningSession/obtainUserDetailsforTrackAndBatch',
  obtainCoursesForTrack: 'framelytics/learningSessionManagement/learningSession/obtainCoursesForTrack',
  obtainCourseListAndUserCountForTrack: 'framelytics/learningSessionManagement/learningSession/obtainCourseListAndUserCountForTrack',
  obtainBatchWiseParticipationAndPerformanceForStream: 'framelytics/learningSessionManagement/learningSession/obtainBatchWiseParticipationAndPerformanceForStream',
  obtainpercentageWiseParticipationAndPerformanceForCourse: 'framelytics/learningSessionManagement/learningSession/obtainpercentageWiseParticipationAndPerformanceForCourse',
  showEditCourseDetails: 'framelytics/learningSessionManagement/learningSession/showEditCourseDetails',
  updateCourseMentorList: 'framelytics/learningSessionManagement/learningSession/updateCourseMentorList',
  deleteLearningSession: 'framelytics/learningSessionManagement/learningSession/deleteLearningSession',
  goBackToSession: 'framelytics/learningSessionManagement/learningSession/goBackToSession',
  updateLearningSession: 'framelytics/learningSessionManagement/learningSession/updateLearningSession',
  obtainTeamListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainTeamListForCourse',
  obtainModulesAndItsSessionsListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainModulesAndItsSessionsListForCourse',
  scheduleSessionsForTeams: 'framelytics/learningSessionManagement/learningSession/scheduleSessionsForTeams',
  updateVisibleForSession: 'framelytics/learningSessionManagement/learningSession/updateVisibleForSession',
  obtainSessionsForAttempts: 'framelytics/learningSessionManagement/learningSession/obtainSessionsForAttempts',
  obtainTopTenPerformersForCourse: 'framelytics/learningSessionManagement/learningSession/obtainTopTenPerformersForCourse',
  obtainAveragePerformersForCourse: 'framelytics/learningSessionManagement/learningSession/obtainAveragePerformersForCourse',
  obtainParticipantSummaryCount: 'framelytics/learningSessionManagement/learningSession/obtainParticipantSummaryCount',
  obtainUsersNotVisitedInTwoDays: 'framelytics/learningSessionManagement/learningSession/obtainUsersNotVisitedInTwoDays',
  obtainUsersNotTakenAssessmentInTwoDays: 'framelytics/learningSessionManagement/learningSession/obtainUsersNotTakenAssessmentInTwoDays',
  lookUpUsernameForattempts: 'framelytics/learningSessionManagement/learningSession/lookUpUsernameForattempts',
  userListForTeamforSession: 'framelytics/learningSessionManagement/learningSession/userListForTeamforSession',
  displayUserAttemptsForSession: 'framelytics/learningSessionManagement/learningSession/displayUserAttemptsForSession',

  obtainCourseIdAndTeamIdFromSession: 'framelytics/learningSessionManagement/learningSession/obtainCourseIdAndTeamIdFromSession',
  obtainTeamForCourse: 'framelytics/userManagement/user/obtainTeamForCourse',
  obtainLearningSessionListForModule: 'framelytics/learningSessionManagement/learningSession/obtainLearningSessionListForModule',
  obtainComponentListForSession: 'framelytics/learningSessionManagement/learningSession/obtainComponentListForSession',
  obtainUserNameForTeam: 'framelytics/learningSessionManagement/learningSession/obtainUserNameForTeam',
  obtainProblemForComponent: 'framelytics/learningSessionManagement/learningSession/obtainProblemForComponent',
  obtainLanguageForActivityStack: 'framelytics/learningSessionManagement/learningSession/obtainLanguageForActivityStack',
  obtainActivityStackDetails: 'codelytics/codeManagement/codingQuestion/obtainActivityStackDetails',
  obtainLearnersDetails: 'framelytics/learningSessionManagement/learningSession/obtainLearnersDetails',
  obtainTeamWiseDashboardForCourseSatistics: 'framelytics/learningSessionManagement/learningSession/obtainTeamWiseDashboardForCourseSatistics',
  obtainTeamActivenessUserListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainTeamActivenessUserListForCourse',
  obtainEffectivenessPercentageTeamWiseForCourse: 'framelytics/learningSessionManagement/learningSession/obtainEffectivenessPercentageTeamWiseForCourse',
  obtainOverallTeamStatisticsForCourse: 'framelytics/learningSessionManagement/learningSession/obtainOverallTeamStatisticsForCourse',

  // dashboard
  saveDashboardInfo: 'framelytics/assessmentSessionManagement/assessmentSession/saveDashboardInfo',
  obtaindashboardInfo: 'framelytics/assessmentSessionManagement/assessmentSession/obtaindashboardInfo',
  deleteSingleDashboard: 'framelytics/assessmentSessionManagement/assessmentSession/deleteSingleDashboard',
  obtainComponentsAndWidgetsFordashboard: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentsAndWidgetsFordashboard',
  deleteForumGroup: 'framelytics/learningSessionManagement/learningSession/deleteForumGroup',
  disableOrEnableForumGroup: 'framelytics/learningSessionManagement/learningSession/disableOrEnableForumGroup',
  obtainTeamsForDashboardSlide: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamsForDashboardSlide',
  saveDashboardSlideDetails: 'framelytics/assessmentSessionManagement/assessmentSession/saveDashboardSlideDetails',
  obtainDashboardSlideDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainDashboardSlideDetails',
  deleteDashboardSlide: 'framelytics/assessmentSessionManagement/assessmentSession/deleteDashboardSlide',
  obtainComponentDetailsForWidget: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentDetailsForWidget',
  obtainAnalyticsSlideWidgets: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAnalyticsSlideWidgets',
  saveQuizWidgetsBasedOnComponentSelection: 'quizlytics/quizManagement/quizQuestion/saveQuizWidgetsBasedOnComponentSelection',
  deleteWidgetPosition: 'framelytics/assessmentSessionManagement/assessmentSession/deleteWidgetPosition',
  updateWidgetPosition: 'framelytics/assessmentSessionManagement/assessmentSession/updateWidgetPosition',
  // partcipant learn////
  obtainTrackListForUser: 'framelytics/learningSessionManagement/learningSession/obtainTrackListForUser',
  createUserTrackList: 'framelytics/learningSessionManagement/learningSession/createUserTrackList',
  obtainCourseListForUser: 'framelytics/learningSessionManagement/learningSession/obtainCourseListForUser',
  obtainRecommendedTrackListForUser: 'framelytics/learningSessionManagement/learningSession/obtainRecommendedTrackListForUser',
  obtainCourseDetailForUser: 'framelytics/learningSessionManagement/learningSession/obtainCourseDetailForUser',
  obtainTimeSpendForParticipant: 'framelytics/learningSessionManagement/learningSession/obtainTimeSpendForParticipant',
  createOrUpdateUserCourseLog: 'framelytics/learningSessionManagement/learningSession/createOrUpdateUserCourseLog',
  obtainModuleDetailsForUser: 'framelytics/learningSessionManagement/learningSession/obtainModuleDetailsForUser',
  obtainModuleListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainModuleListForCourse',
  obtainOrganizeSessionTypeListForModule: 'framelytics/learningSessionManagement/learningSession/obtainOrganizeSessionTypeListForModule',
  obtainModuleListForGroupModule: 'framelytics/learningSessionManagement/learningSession/obtainModuleListForGroupModule',
  updateTimeSpentForUserCourseLog: 'framelytics/learningSessionManagement/learningSession/updateTimeSpentForUserCourseLog',
  obtainHrlSessionListForOrganizeSessionType: 'framelytics/learningSessionManagement/learningSession/obtainHrlSessionListForOrganizeSessionType',
  updateNotesForCourse: 'learnlytics/lessonComponentManagement/lessonComponent/updateNotesForCourse',
  deleteNotesForCourse: 'learnlytics/lessonComponentManagement/lessonComponent/deleteNotesForCourse',
  obtainOverAllModuleForCourse: 'learnlytics/lessonComponentManagement/lessonComponent/obtainOverAllModuleForCourse',
  checkScoreForCheckPoint: 'learnlytics/lessonComponentManagement/lessonComponent/checkScoreForCheckPoint',
  obtainHrlSessionListForDropDown: 'framelytics/learningSessionManagement/learningSession/obtainHrlSessionListForDropDown',
  obtainNotesForLessonComponentResource: 'learnlytics/lessonComponentManagement/lessonComponent/obtainNotesForLessonComponentResource',
  updateLastViewedPositionForLessonComponentResourceAttempt: 'learnlytics/lessonComponentManagement/lessonComponent/updateLastViewedPositionForLessonComponentResourceAttempt',
  obtainOverAllNotesForModule: 'learnlytics/lessonComponentManagement/lessonComponent/obtainOverAllNotesForModule',
  createOrUpdateUserCourseStatus: 'framelytics/learningSessionManagement/learningSession/createOrUpdateUserCourseStatus',
  updateUserSolvedProblem: 'framelytics/learningSessionManagement/learningSession/updateUserSolvedProblem',
  obtainPercentageWiseUserListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainPercentageWiseUserListForCourse',
  obtainTeamUserList: 'framelytics/learningSessionManagement/learningSession/obtainTeamUserList',
  obtainQuadrantChartDetails: 'framelytics/learningSessionManagement/learningSession/obtainQuadrantChartDetails',
  obtainAlertTemplateForCourse: 'framelytics/learningSessionManagement/learningSession/obtainAlertTemplateForCourse',
  courseAttemptAlert: 'framelytics/learningSessionManagement/learningSession/courseAttemptAlert',
  obtainTrackAndBatchInactiveUser: 'framelytics/learningSessionManagement/learningSession/obtainTrackAndBatchInactiveUser',
  activeUsersForThreeMonths: 'framelytics/learningSessionManagement/learningSession/activeUsersForThreeMonths',
  obtainBatchWiseInactiveUsers: 'framelytics/learningSessionManagement/learningSession/obtainBatchWiseInactiveUsers',

  obtainTeamWiseStatisticsForCourse: 'framelytics/learningSessionManagement/learningSession/obtainTeamWiseStatisticsForCourse',
  obtainModuleListAndSessionListForteam: 'framelytics/learningSessionManagement/learningSession/obtainModuleListAndSessionListForteam',
  obtainComponentTypeWidgetManagement: 'framelytics/adminManagement/admin/obtainComponentTypesForAdminwidgetManagement',
  saveWidget: 'framelytics/adminManagement/admin/saveWidget',
  obtainTrackData: 'framelytics/adminManagement/admin/obtainTrackList',
  obtainUserData: 'framelytics/adminManagement/admin/obtainUserList',
  obtainRoleData: 'framelytics/adminManagement/admin/obtainRoleList',
  obtainBatchData: 'framelytics/adminManagement/admin/obtainBatchList',
  saveOrganization: 'framelytics/adminManagement/admin/saveOrganization',
  obtainOrganizationData: 'framelytics/adminManagement/admin/obtainOrganizationsForAdmin',
  obtainSingleOrganisation: 'framelytics/adminManagement/admin/obtainSingleOrganisation',
  obtainTeamsForAdmin: 'framelytics/adminManagement/admin/obtainTeamsForAdmin',
  resetPasswordForSingleUserInAdmin: 'framelytics/adminManagement/admin/resetPasswordForSingleUserInAdmin',
  validateUserUploadForResetPasswordInAdmin: 'framelytics/adminManagement/admin/validateUserUploadForResetPasswordInAdmin',
  resetPasswordForMultipleUserInAdmin: 'framelytics/adminManagement/admin/resetPasswordForMultipleUserInAdmin',
  getActiveUsers: 'framelytics/adminManagement/admin/getActiveUsers',
  obtainMailLogs: 'framelytics/userManagement/user/obtainMailLogs',
  obtainSingleLog: 'framelytics/userManagement/user/obtainSingleLog',
  sendOTPForRegisteration: 'framelytics/userManagement/user/sendOTPForRegisteration',
  verifyUserOTP: 'framelytics/userManagement/user/verifyUserOTP',
  obtainStatesForRegisterationForm: 'framelytics/userManagement/user/obtainStatesForRegisterationForm',
  obtainCollegesBasedOnState: 'framelytics/userManagement/user/obtainCollegesBasedOnState',
  obtainQualificationsbasedOnDegree: '/framelytics/userManagement/user/obtainQualificationsbasedOnDegree',
  obtainBranchesBasedOnQualification: '/framelytics/userManagement/user/obtainBranchesBasedOnQualification',
  obtainMeetingList: 'framelytics/learningSessionManagement/learningSession/obtainMeetingList',
  updateScheduledMeeting: 'framelytics/learningSessionManagement/learningSession/updateScheduledMeeting',
  obtainUserListForMeeting: 'framelytics/learningSessionManagement/learningSession/obtainUserListForMeeting',
  deleteMeetingFromMeetingList: 'framelytics/learningSessionManagement/learningSession/deleteMeetingFromMeetingList',
  usersAttendanceReport: 'framelytics/learningSessionManagement/learningSession/usersAttendanceReport',
  obtainMeetingListForParticipant: 'framelytics/learningSessionManagement/learningSession/obtainMeetingListForParticipant',
  updateUserAttendance: 'framelytics/learningSessionManagement/learningSession/updateUserAttendance',
  obtainMeetingPurposeList: '/framelytics/learningSessionManagement/meetingPurpose/',
  obtainMeetingTypeList: '/framelytics/learningSessionManagement/meetingType/',
  obtainInviteTypeList: '/framelytics/learningSessionManagement/inviteType/',
  handleMeetingFormSubmit: '/framelytics/learningSessionManagement/learningSession/createAndScheduleMeeting',
  obtainOrganizerForOrgUnit: '/framelytics/learningSessionManagement/learningSession/obtainOrganizerForOrgUnit',
  obtainMeetingDetails: '/framelytics/learningSessionManagement/learningSession/obtainMeetingDetails',
  archiveQuestionBank: 'framelytics/assessmentSessionManagement/assessmentSession/archiveQuestionBank',
  userSolvedProblemCountReportForCourse: '/framelytics/learningSessionManagement/learningSession/userSolvedProblemCountReportForCourse',
  sessionWiseScoreXl: 'framelytics/learningSessionManagement/learningSession/sessionWiseScoreXl',
  userCompletionXlData: 'framelytics/learningSessionManagement/learningSession/userCompletionXlData',
  downloadBatchAnalysisXLS: 'framelytics/learningSessionManagement/learningSession/downloadBatchAnalysisXLS',
  obtainConsolidatedReportForTrack: 'framelytics/learningSessionManagement/learningSession/obtainConsolidatedReportForTrack',
  performanceDetailsReportForCourseForTeam: 'framelytics/learningSessionManagement/learningSession/performanceDetailsReportForCourseForTeam',
  obtainAssignedQBForUsers: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssignedQBForUsers',
  obtainComponentTypesForArchivedProblems: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentTypesForArchivedProblems',
  obtainArchivedProblemsForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/obtainArchivedProblemsForOrgUnit',
  updateQuestionBankUnArchived: 'framelytics/assessmentSessionManagement/assessmentSession/updateQuestionBankUnArchived',
  obtainProgrammingSubmissionStatusList: 'codelytics/codeManagement/codingQuestion/obtainProgrammingSubmissionStatusList',
  obtainComponentDetailsForComponentSettings: 'framelytics/assessmentSessionManagement/assessmentSession/obtainComponentDetailsForComponentSettings',
  obtainOverAllPercentageForUser: '/framelytics/learningSessionManagement/learningSession/obtainOverAllPercentageForUser',
  obtainModuleSessionTypeListAndPercentage: '/framelytics/learningSessionManagement/learningSession/obtainModuleSessionTypeListAndPercentage',
  bulkuploadForContinueAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/bulkuploadForContinueAttempt',
  updateContinueAttemptForBulkuploadUsers: 'framelytics/assessmentSessionManagement/assessmentSession/updateContinueAttemptForBulkuploadUsers',
  bulkuploadForGraceTime: 'framelytics/assessmentSessionManagement/assessmentSession/bulkuploadForGraceTime',
  obtainDoubtThreadIssueType: 'framelytics/learningSessionManagement/DoubtThreadIssueType/',
  createDoubtThread: 'framelytics/learningSessionManagement/learningSession/createDoubtThread',
  obtainDoubtThreadsForUser: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadsForUser',
  obtainMessagesForDoubtThread: 'framelytics/learningSessionManagement/learningSession/obtainMessagesForDoubtThread',
  obtainComponentDetailsForUser: 'framelytics/learningSessionManagement/learningSession/obtainComponentDetailsForUser',
  obtainCourseNamesForUser: 'framelytics/learningSessionManagement/learningSession/obtainCourseNamesForUser',
  closeDoubtThread: 'framelytics/learningSessionManagement/learningSession/closeDoubtThread',
  updateChoiceQuestionCountBasedOnRankingMode: 'codelytics/codeManagement/codingQuestion/updateChoiceQuestionCountBasedOnRankingMode',
  interviewStatusViewSet: 'framelytics/baseappManagement/interviewStatusViewSet/',
  saveInterviewStatusForUsers: 'framelytics/assessmentSessionManagement/assessmentSession/saveInterviewStatusForUsers',
  untagInterviewStatusForUser: 'framelytics/assessmentSessionManagement/assessmentSession/untagInterviewStatusForUser',
  obtainAllOrganisation: 'framelytics/adminManagement/admin/obtainAllOrganisation',
  obtainOrgUnitForOrganisation: 'framelytics/adminManagement/admin/obtainOrgUnitForOrganisation',
  updateCookieForAdmin: 'framelytics/userManagement/user/updateCookieForAdmin',
  deleteOrganization: 'framelytics/adminManagement/admin/deleteOrganization',
  obtainSubmissionStatus: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSubmissionStatus',
  obtainQuestionNamesForComponent: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionNamesForComponent',
  obtainSubmissionStackForUsers: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSubmissionStackForUsers',
  obtainComponentsFromSession: 'framelytics/learningSessionManagement/learningSession/obtainComponentsFromSession',
  obtainHrlSessionAttemptDetails: 'framelytics/learningSessionManagement/learningSession/obtainHrlSessionAttemptDetails',
  recomputeScoreForscoreViewPage: 'framelytics/learningSessionManagement/learningSession/recomputeScoreForscoreViewPage',
  getUserScoresForSession: 'framelytics/learningSessionManagement/learningSession/getUserScoresForSession',
  lookUpUsernameForSession: 'framelytics/learningSessionManagement/learningSession/lookUpUsernameForSession',
  getUserScoreDistributionForSession: 'framelytics/learningSessionManagement/learningSession/getUserScoreDistributionForSession',
  sessionUnAttemptedUsersXl: 'framelytics/learningSessionManagement/learningSession/sessionUnAttemptedUsersXl',
  obtainDoubtThreadDashboardDetails: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadDashboardDetails',
  obtainSkillsForOrg: 'framelytics/learningSessionManagement/learningSession/obtainSkillsForOrg',
  obtainSkillListForModule: 'framelytics/learningSessionManagement/learningSession/obtainSkillListForModule',
  obtainTeamTimingListForSession: 'framelytics/learningSessionManagement/learningSession/obtainTeamTimingListForSession',
  ScheduleTeamsForSession: 'framelytics/learningSessionManagement/learningSession/ScheduleTeamsForSession',
  obtainMessagesForGroup: 'framelytics/learningSessionManagement/learningSession/obtainMessagesForGroup',
  obtainMembersForGroup: 'framelytics/learningSessionManagement/learningSession/obtainMembersForGroup',
  obtainForumGroupsForUser: 'framelytics/learningSessionManagement/learningSession/obtainForumGroupsForUser',
  obtainForumGroups: 'framelytics/learningSessionManagement/learningSession/obtainForumGroups',
  obtainBatchesForDF: 'framelytics/learningSessionManagement/learningSession/obtainBatchesForOrgUnit',
  obtainParticipantDF: 'framelytics/learningSessionManagement/learningSession/obtainUsersFromBatch',
  handleCreateDFGroup: 'framelytics/learningSessionManagement/learningSession/createForumGroup',
  downloadFileInForumGroup: 'framelytics/learningSessionManagement/learningSession/downloadFileInForumGroup',
  DFEventSourceMessages: 'framelytics/learningSessionManagement/learningSession/forumMessageServerSentEvent',
  DFDeleteEventSourceMessages: 'framelytics/learningSessionManagement/learningSession/forumDeleteServerSentEvent',
  DFGroupEventSourceMessages: 'framelytics/learningSessionManagement/learningSession/forumGroupServerSentEvent',
  updateReadMessages: 'framelytics/learningSessionManagement/learningSession/updateReadMessages',
  addMemberIntoForumGroup: 'framelytics/learningSessionManagement/learningSession/addMemberIntoForumGroup',
  obtainActiveTeamsForAdmin: 'framelytics/adminManagement/admin/obtainActiveTeamsForAdmin',
  verifyOTPForParticipant: 'framelytics/userManagement/user/verifyOTPForParticipant',
  resetPasswordForParticipant: 'framelytics/userManagement/user/resetPasswordForParticipant',
  obtainUserReportForlearningSessionComponents: 'framelytics/learningSessionManagement/learningSession/obtainUserReportForlearningSessionComponents',
  obtainControllers: 'framelytics/adminManagement/admin/obtainControllers',
  obtainActionsForController: 'framelytics/adminManagement/admin/obtainActionsForController',
  updateRoleSetForAction: 'framelytics/adminManagement/admin/updateRoleSetForAction',
  updateAndRemoveAccessForAllActions: 'framelytics/adminManagement/admin/updateAndRemoveAccessForAllActions',
  obtainMailerTemplatesForAdmin: 'framelytics/adminManagement/admin/obtainMailerTemplatesForAdmin',
  obtainQuestionCategoryList: 'framelytics/adminManagement/admin/obtainQuestionCategoryList',
  findUltimateParentId: 'framelytics/adminManagement/admin/findUltimateParentId',
  obtainAllRoles: 'framelytics/adminManagement/admin/obtainAllRoles',
  updateOrgUnitAssignmentForQb: 'framelytics/adminManagement/admin/updateOrgUnitAssignmentForQb',
  obtainOrgUnitAssingmentForQb: 'framelytics/adminManagement/admin/obtainOrgUnitAssingmentForQb',
  obtainOrgUnitForOrganisationForQb: 'framelytics/adminManagement/admin/obtainOrgUnitForOrganisationForQb',
  deleteOrgUnitAssignment: 'framelytics/adminManagement/admin/deleteOrgUnitAssignment',
  obtainGlobalPool: 'framelytics/adminManagement/admin/obtainGlobalPool',
  obtainAdminOrgUnit: 'framelytics/adminManagement/admin/obtainAdminOrgUnit',
  updateFinishSetupForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/updateFinishSetupForAssessment',
  obtainFinishSetupStatusForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainFinishSetupStatusForAssessment',
  obtainSkillsForOrganisation: 'framelytics/userManagement/user/obtainSkillsForOrganisation',
  obtainDesignationsForOrganisation: 'framelytics/userManagement/user/obtainDesignationsForOrganisation',
  // addSkillInOrganisation: 'framelytics/userManagement/user/addSkillInOrganisation',
  addNewSkill: 'framelytics/adminManagement/admin/addNewSkill',
  addNewDesignation: 'framelytics/adminManagement/admin/addNewDesignation',
  // addDesignationInOrganisation: 'framelytics/userManagement/user/addDesignationInOrganisation',
  updateOrDeleteSkill: 'framelytics/adminManagement/admin/updateOrDeleteSkill',
  updateOrDeleteDesignation: 'framelytics/adminManagement/admin/updateOrDeleteDesignation',
  computeScoreForTeam: 'framelytics/assessmentSessionManagement/assessmentSession/computeScoreForTeam',
  obtainSubmissionListForUsers: 'codelytics/codeManagement/codingQuestion/obtainSubmissionListForUsers',
  obtainQbTreeStructureforAdmin: 'framelytics/adminManagement/admin/obtainFolderTreeStructureForAdmin',
  createFolderAndFileForAdmin: 'framelytics/adminManagement/admin/createFolderAndFileForAdmin',
  updateOrganization: 'framelytics/adminManagement/admin/updateOrganization',
  obtainOrgUnitsForOrg: '/framelytics/userManagement/user/obtainOrgUnitsForOrg',
  obtainOrgUnitMembersBasedOnRole: '/framelytics/userManagement/user/obtainOrgUnitMembersBasedOnRole',
  updateRoleAccessForOrgUnitMember: '/framelytics/userManagement/user/updateRoleAccessForOrgUnitMember',
  obtainComponentAttemptId: 'codelytics/codeManagement/codingQuestion/obtainComponentAttemptId',
  updateNameForOrgUnitMember: '/framelytics/userManagement/user/updateNameForOrgUnitMember',
  cloneCourse: 'framelytics/learningSessionManagement/learningSession/cloneCourse',
  obtainEditorStatistics: 'codelytics/codeManagement/codingQuestion/obtainEditorStatistics',
  obtainModuleDetailsForCourse: 'framelytics/learningSessionManagement/learningSession/obtainModuleDetailsForCourse',
  updateModuleInBulkSettings: 'framelytics/learningSessionManagement/learningSession/updateModuleInBulkSettings',
  updateTeamTimingForCoursePlan: 'framelytics/learningSessionManagement/learningSession/updateTeamTimingForCoursePlan',
  obtainModulesAndItsSessionListForCoursePlan: 'framelytics/learningSessionManagement/learningSession/obtainModulesAndItsSessionListForCoursePlan',
  obtainModuleDetailsForCourseTeam: 'framelytics/learningSessionManagement/learningSession/obtainModuleDetailsForCourseTeam',
  updateModuleInTeamBulkSettings: 'framelytics/learningSessionManagement/learningSession/updateModuleInTeamBulkSettings',
  obtainSkillsForUser: 'framelytics/learningSessionManagement/learningSession/obtainSkillsForUser',
  obtianSessionFromCourse: 'framelytics/learningSessionManagement/learningSession/obtianSessionFromCourse',
  obtainHeatGridReport: 'framelytics/learningSessionManagement/learningSession/obtainHeatGridReport',
  obtainMarkReportsPdf: 'framelytics/learningSessionManagement/learningSession/obtainMarkReportsPdf',
  obtainMarkReportsXl: 'framelytics/learningSessionManagement/learningSession/obtainMarkReportsXl',
  transferUserToAnotherTeam: '/framelytics/userManagement/user/transferUserToAnotherTeam',
  obtainTeams: 'framelytics/userManagement/user/obtainTeamForCourse',
  searchUserTeams: 'framelytics/userManagement/user/searchUserTeams',
  obtainUserListForLearningSessionTeam: 'framelytics/learningSessionManagement/learningSession/obtainUserListForLearningSession',
  obtainUnassignUserListForteam: 'framelytics/learningSessionManagement/learningSession/obtainUnassignUserListForteam',
  assignSingleOrBulkUserToTeamForCourse: 'framelytics/learningSessionManagement/learningSession/assignSingleOrBulkUserToTeamForCourse',
  obtainDoubtThreadListForOrgUnit: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForOrgUnit',
  obtainMentorList: 'framelytics/learningSessionManagement/learningSession/obtainMentorList',
  updateDoubtThreadForOrg: 'framelytics/learningSessionManagement/learningSession/updateDoubtThreadForOrg',
  obtainOrgUnitForOrg: 'framelytics/learningSessionManagement/learningSession/obtainOrgUnitForOrg',
  obtainCourseListForOrg: 'framelytics/learningSessionManagement/learningSession/obtainCourseListForOrg',
  obtainSlaCompletionPercentage: 'framelytics/learningSessionManagement/learningSession/obtainSlaCompletionPercentage',
  unAssignMentorForDoubtThread: 'framelytics/learningSessionManagement/learningSession/unAssignMentorForDoubtThread',
  obtainDoubtThreadListForMentor: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForMentor',
  obtainDoubtThreadTicketRate: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadTicketRate',
  obtainDateWiseDoubtThreadStatistics: 'framelytics/learningSessionManagement/learningSession/obtainDateWiseDoubtThreadStatistics',
  obtainCourseListForOrgUnit: 'framelytics/learningSessionManagement/learningSession/obtainCourseListForOrgUnit',
  obtainDoubtThreadsCountForMentor: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadsCountForMentor',
  obtainDateWiseDoubtThreadCount: 'framelytics/learningSessionManagement/learningSession/obtainDateWiseDoubtThreadCount',
  obtainCourseListForSkill: 'framelytics/learningSessionManagement/learningSession/obtainCourseListForSkill',
  updateSlaCompletionForDoubtThread: 'framelytics/learningSessionManagement/learningSession/updateSlaCompletionForDoubtThread',
  updateDoubtThreadStatus: 'framelytics/learningSessionManagement/learningSession/updateDoubtThreadStatus',
  obtainDoubtThreadListForMentorWithFilter: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForMentorWithFilter',
  obtainDoubtThreadListForOrgWithFilter: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForOrgWithFilter',
  obtainDoubtThreadsCountForOrg: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadsCountForOrg',
  saveForumMessage: 'framelytics/learningSessionManagement/learningSession/saveForumMessage',
  deleteAndRecoverForumGroupMessage: 'framelytics/learningSessionManagement/learningSession/deleteAndRecoverForumGroupMessage',
  downloadSubmissionFile: 'codelytics/codeManagement/codingQuestion/downloadSubmissionFile',
  sendConceptNodeReport: 'framelytics/assessmentSessionManagement/assessmentSession/sendConceptNodeReport',
  conceptNodeReportsBulkDownload: 'framelytics/assessmentSessionManagement/assessmentSession/conceptNodeReportsBulkDownload',
  obtainUserReportsBulkDownload: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserReportsBulkDownload',
  lookupUsernameForCourse: 'framelytics/learningSessionManagement/learningSession/lookupUsernameForCourse',
  obtainOrganizations: 'framelytics/adminManagement/admin/obtainOrganizations',
  obtainOrgUnitsForAdmin: 'framelytics/adminManagement/admin/obtainOrgUnitsForAdmin',
  obtainOrgUnitMembersBasedOnRoleForAdmin: 'framelytics/adminManagement/admin/obtainOrgUnitMembersBasedOnRoleForAdmin',
  obtainUserStatisticsForCodingComponent: 'codelytics/codeManagement/codingQuestion/obtainUserStatisticsForCodingComponent',
  obtainServicesForAdmin: 'framelytics/adminManagement/admin/obtainServicesForAdmin',
  loginWithOTPForParticipant: 'framelytics/userManagement/user/loginWithOTPForParticipant',
  obtainAttemptedLanguagesForCodingQuestions: 'codelytics/codeManagement/codingQuestion/obtainAttemptedLanguagesForCodingQuestions',
  scodvestAnalyticsPdf: 'framelytics/learningSessionManagement/learningSession/scodvestAnalyticsPdf',
  scodvestAnalyticsXl: 'framelytics/learningSessionManagement/learningSession/scodvestAnalyticsXl',
  untagIndividualQuestion: '/framelytics/assessmentSessionManagement/assessmentSession/untagQBFromIndividualQuestion',
  obtainDetailedUserReportForCourseForSingleUser: 'framelytics/learningSessionManagement/learningSession/obtainDetailedUserReportForCourseForSingleUser',
  obtainMessagesForDoubtThreadSSE: 'framelytics/learningSessionManagement/learningSession/obtainMessagesForDoubtThreadSSE',
  obtainDoubtThreadListForMentorSSE: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForMentorSSE',
  obtainDoubtThreadListForOrgUnitSSE: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadListForOrgUnitSSE',
  obtainEvaluationMode: 'framelytics/baseappManagement/baseapp/obtainEvaluationMode',
  saveFeedbackQuestion: '/framelytics/adminManagement/admin/saveFeedbackQuestion',
  obtainfeedbackQuestionListForOrg: '/framelytics/adminManagement/admin/obtainfeedbackQuestionListForOrg',
  updateFeedbackQuestion: '/framelytics/adminManagement/admin/updateFeedbackQuestion',
  removeFeedbackQuestionFromOrg: '/framelytics/adminManagement/admin/removeFeedbackQuestionFromOrg',
  obtainExistingFeedbackQuestion: '/framelytics/adminManagement/admin/obtainExistingFeedbackQuestion',
  tagFeedbackQuestionsToCourse: '/quizlytics/quizManagement/quizAdminAction/tagFeedbackQuestionsToCourse',
  unTagFeedbackQuestionFromCourse: '/quizlytics/quizManagement/quizAdminAction/unTagFeedbackQuestionFromCourse',
  obtainFeedbackQuestionsForUser: '/quizlytics/quizManagement/quizAdminAction/obtainFeedbackQuestionsForUser',
  createFeedbackQuestionAttempts: '/quizlytics/quizManagement/quizAdminAction/createFeedbackQuestionAttempts',
  updateFeedbackQuestionAttempts: '/quizlytics/quizManagement/quizAdminAction/updateFeedbackQuestionAttempts',
  obtainOverallFeedbackReportForCourse: '/quizlytics/quizManagement/quizAdminAction/obtainOverallFeedbackReportForCourse',
  obtainIndividualFeedbackReportForCourse: '/quizlytics/quizManagement/quizAdminAction/obtainIndividualFeedbackReportForCourse',
  updateFeedbackThresholdOrVisibility: '/quizlytics/quizManagement/quizAdminAction/updateFeedbackThresholdOrVisibility',
  obtainUnAttemptedUsersXl: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUnAttemptedUsersXl',
  obtainAllUsersForIntermediatekeyGeneration: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAllUsersForIntermediatekeyGeneration',
  obtainTeamsForSelectedUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamsForSelectedUser',
  generateIntermediateKeyForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/generateIntermediateKeyForAssessment',
  obtainExistingKeyGeneratedUsersForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainExistingKeyGeneratedUsersForAssessment',
  validateIntermediateKey: 'framelytics/assessmentSessionManagement/assessmentSession/validateIntermediateKey',
  obtainEventTypes: 'framelytics/adminManagement/admin/obtainEventTypes',
  obtainConstraintTypes: 'framelytics/adminManagement/admin/obtainConstraintTypes',
  obtainHrlEvent: 'framelytics/adminManagement/admin/obtainHrlEvent',
  obtainHrlEventList: 'framelytics/adminManagement/admin/obtainHrlEventList',
  createHrlEvent: 'framelytics/adminManagement/admin/createHrlEvent',
  updateHrlEvent: 'framelytics/adminManagement/admin/updateHrlEvent',
  deleteHrlEvent: 'framelytics/adminManagement/admin/deleteHrlEvent',
  obtainHrlEventForUser: 'framelytics/userManagement/user/obtainHrlEventForUser',
  obtainBatchesForOrgUnit: 'framelytics/learningSessionManagement/learningSession/obtainBatchesForOrgUnit',
  obtainExistingUsersFromBatch: 'framelytics/learningSessionManagement/learningSession/obtainExistingUsersFromBatch',
  obtainMeetingListForSSE: 'framelytics/learningSessionManagement/learningSession/obtainMeetingListForSSE',
  // obtainSchoolsBasedOnState: 'framelytics/userManagement/user/obtainSchoolsBasedOnState',
  obtainCompaniesForRegisterationForm: 'framelytics/userManagement/user/obtainCompaniesForRegisterationForm',
  obtainSchoolsForAdmin: 'framelytics/adminManagement/admin/obtainSchoolsForAdmin',
  obtainCollegesForAdmin: 'framelytics/adminManagement/admin/obtainCollegesForAdmin',
  obtainCompaniesForAdmin: 'framelytics/adminManagement/admin/obtainCompaniesForAdmin',
  updateSchoolInfo: 'framelytics/adminManagement/admin/updateSchoolInfo',
  updateCollegeInfo: 'framelytics/adminManagement/admin/updateCollegeInfo',
  updateCompanyInfo: 'framelytics/adminManagement/admin/updateCompanyInfo',
  createSchool: 'framelytics/adminManagement/admin/createSchool',
  createCollege: 'framelytics/adminManagement/admin/createCollege',
  createCompany: 'framelytics/adminManagement/admin/createCompany',
  deleteSchool: 'framelytics/adminManagement/admin/deleteSchool',
  deleteCollege: 'framelytics/adminManagement/admin/deleteCollege',
  deleteCompany: 'framelytics/adminManagement/admin/deleteCompany',
  deleteMultipleSchool: 'framelytics/adminManagement/admin/deleteMultipleSchool',
  deleteMultipleCollege: 'framelytics/adminManagement/admin/deleteMultipleCollege',
  deleteMultipleCompany: 'framelytics/adminManagement/admin/deleteMultipleCompany',
  obtainUserDetailsForProfile: 'framelytics/userManagement/user/obtainUserDetailsForProfile',
  saveProfileImageForUser: 'framelytics/userManagement/user/saveProfileImageForUser',
  updateCookieForName: 'framelytics/userManagement/user/updateCookieForName',
  saveResumeForUser: 'framelytics/userManagement/user/saveResumeForUser',
  deleteWidget: 'framelytics/adminManagement/admin/deleteWidget',
  updateWidget: 'framelytics/adminManagement/admin/updateWidget',
  obtainStatesForProfile: 'framelytics/userManagement/user/obtainStatesForProfile',
  obtainSchoolsForProfile: 'framelytics/userManagement/user/obtainSchoolsForProfile',
  obtainCollegesForProfile: 'framelytics/userManagement/user/obtainCollegesForProfile',
  obtainCompaniesForProfile: 'framelytics/userManagement/user/obtainCompaniesForProfile',
  createTeamForCourse: 'framelytics/userManagement/user/createTeamForCourse',
  obtainRolesForAdmin: 'framelytics/adminManagement/admin/obtainRolesForAdmin',
  saveRoleForAdmin: 'framelytics/adminManagement/admin/saveRoleForAdmin',
  deleteRoleForAdmin: 'framelytics/adminManagement/admin/deleteRoleForAdmin',
  obtainAllSkills: 'framelytics/adminManagement/admin/obtainAllSkills',
  updateSchoolData: 'framelytics/userManagement/user/updateSchoolData',
  updateCollegeData: 'framelytics/userManagement/user/updateCollegeData',
  obtainQualificationsbasedOnDegreeForProfile: 'framelytics/userManagement/user/obtainQualificationsbasedOnDegreeForProfile',
  obtainBranchesBasedOnQualificationForProfile: 'framelytics/userManagement/user/obtainBranchesBasedOnQualificationForProfile',
  obtainSkillsForProfile: 'framelytics/userManagement/user/obtainSkillsForProfile',
  updateTechnicalSkillsForUser: 'framelytics/userManagement/user/updateTechnicalSkillsForUser',
  updateUserProfilePersonalDetails: 'framelytics/userManagement/user/updateUserProfilePersonalDetails',
  updateUserAboutMeDetails: 'framelytics/userManagement/user/updateUserAboutMeDetails',
  updateUserProfileCertificationDetails: 'framelytics/userManagement/user/updateUserProfileCertificationDetails',
  updateUserProfileEmploymentDetails: 'framelytics/userManagement/user/updateUserProfileEmploymentDetails',
  removeTechnicalSkillsForUser: 'framelytics/userManagement/user/removeTechnicalSkillsForUser',
  obtainTariffTailerForAdmin: 'framelytics/adminManagement/admin/obtainTariffTailerForAdmin',
  obtainSubscriptionType: 'framelytics/adminManagement/admin/obtainSubscriptionType',
  updatesubscriptionDetail: 'framelytics/adminManagement/admin/updateSubscriptionDetail',
  obtainSubmissionStackForComponentConfiguration: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSubmissionStackForComponentConfiguration',
  createTariffTailer: 'framelytics/adminManagement/admin/createTariffTailer',
  obtainUsersForTeam: 'framelytics/adminManagement/admin/obtainUsersForTeam',
  obtainTeamsForSessionOrUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamsForSessionOrUser',
  lookupUserName: 'framelytics/assessmentSessionManagement/assessmentSession/lookupUserName',
  obtainFeedbackQuestionsForCourse: 'quizlytics/quizManagement/quizAdminAction/obtainFeedbackQuestionsForCourse',
  obtainPartialSubmissionForUsers: 'codelytics/codeManagement/codingQuestion/obtainPartialSubmissionForUsers',
  updateProgramFileForSubmission: 'codelytics/codeManagement/codingQuestion/updateProgramFileForSubmission',
  checkStatusForSubmission: 'codelytics/codeManagement/codingQuestion/checkStatusForSubmission',
  obtainHeatGridReportPdfDetails: 'framelytics/learningSessionManagement/learningSession/obtainHeatGridReportPdfDetails',
  checkIpFilterForAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/checkIpFilterForAttempt',
  lookUpUsernameForComponentConfiguration: 'framelytics/assessmentSessionManagement/assessmentSession/lookUpUsernameForComponentConfiguration',
  obtainSubscriptionDetails: 'framelytics/userManagement/user/obtainSubscriptionDetails',
  generateCoupenCodeForCatalog: 'framelytics/adminManagement/admin/generateCoupenCodeForCatalog',
  obtainUsersForPrivateChat: 'framelytics/learningSessionManagement/learningSession/obtainUsersForPrivateChat',
  obtainSingleDashboardInfoCourse: 'framelytics/learningSessionManagement/learningSession/obtainSingleDashboardInfoCourse',
  obtainLearningSessionsForModuleDashboard: 'framelytics/learningSessionManagement/learningSession/obtainLearningSessionsForModuleDashboard',
  obtainTeamTimingListForDashboard: 'framelytics/learningSessionManagement/learningSession/obtainTeamTimingListForDashboard',
  saveDashboardSlideDetailsForCourse: 'framelytics/learningSessionManagement/learningSession/saveDashboardSlideDetailsForCourse',
  obtainDashboardSlideDetailsForCourse: 'framelytics/learningSessionManagement/learningSession/obtainDashboardSlideDetailsForCourse',
  obtainWidgetsForDashboard: 'framelytics/learningSessionManagement/learningSession/obtainWidgetsForDashboard',
  obtainAssessmentTypeCategoryList: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentTypeCategoryList',
  createAssessmentType: 'framelytics/learningSessionManagement/learningSession/createAssessmentType',
  obtainAssessmentTypeList: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentTypeList',
  updateAssessmentType: 'framelytics/learningSessionManagement/learningSession/updateAssessmentType',
  deleteAssessmentType: 'framelytics/learningSessionManagement/learningSession/deleteAssessmentType',
  obtainCourseListForAssessmentType: 'framelytics/learningSessionManagement/learningSession/obtainCourseListForAssessmentType',
  obtainSessionListForCourseInAssessmentType: 'framelytics/learningSessionManagement/learningSession/obtainSessionListForCourseInAssessmentType',
  obtainTeamForCourseAndBatch: 'framelytics/learningSessionManagement/learningSession/obtainTeamForCourseAndBatch',
  obtainTeamUserListForAssessmentType: 'framelytics/learningSessionManagement/learningSession/obtainTeamUserListForAssessmentType',
  createTeamForAssessmentType: 'framelytics/learningSessionManagement/learningSession/createTeamForAssessmentType',
  assignTeamTimmingForAssessmentType: 'framelytics/learningSessionManagement/learningSession/assignTeamTimmingForAssessmentType',
  obtainAssessmentTypeListForBatch: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentTypeListForBatch',
  deleteTeamTimmingForAssessmentType: 'framelytics/learningSessionManagement/learningSession/deleteTeamTimmingForAssessmentType',
  updateTeamTimmingForAssessmentType: 'framelytics/learningSessionManagement/learningSession/updateTeamTimmingForAssessmentType',
  obtainAssessmentLearnerInfo: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentLearnerInfo',
  obtainAssessmentsForParticipants: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentsForParticipant',
  saveDashboardInfoForCourse: 'framelytics/learningSessionManagement/learningSession/saveDashboardInfoForCourse',
  obtainDashboardListForCourse: 'framelytics/learningSessionManagement/learningSession/obtainDashboardListForCourse',
  obtainModulesForDashboard: 'framelytics/learningSessionManagement/learningSession/obtainModulesForDashboard',
  feedbackType: 'quizlytics/quizManagement/feedbackType/',
  feedbackCategory: 'quizlytics/quizManagement/feedbackCategory/',
  saveCodeAnalysisAttempt: 'codelytics/codeAnalysisManagement/codeAnalysisQuestion/saveCodeAnalysisAttempt',
  obtainUserTeamRankForCourse: 'framelytics/learningSessionManagement/learningSession/obtainUserTeamRankForCourse',
  obtainActivitySummary: 'framelytics/learningSessionManagement/learningSession/obtainActivitySummary',
  obtainWorkPatternChart: 'framelytics/learningSessionManagement/learningSession/obtainWorkPatternChart',
  obtainDetailedUserReportForCourseForMultipleUser: 'framelytics/learningSessionManagement/learningSession/obtainDetailedUserReportForCourseForMultipleUser',
  runDbDiffScript: 'framelytics/adminManagement/admin/runDbDiffScript',
  sendOTPForDbScriptUser: 'framelytics/adminManagement/admin/sendOTPForDbScriptUser',
  verifyOTPForDbScriptUser: 'framelytics/adminManagement/admin/verifyOTPForDbScriptUser',
  obtainAllProjectDetails: 'framelytics/learningSessionManagement/learningSession/obtainAllProjectDetails',
  saveProjectDetails: 'framelytics/learningSessionManagement/learningSession/saveProjectDetails',
  obtainSingleProjectDetails: 'framelytics/learningSessionManagement/learningSession/obtainSingleProjectDetails',
  saveEpicDetails: 'framelytics/learningSessionManagement/learningSession/saveEpicDetails',
  obtainAllEpicDetails: 'framelytics/learningSessionManagement/learningSession/obtainAllEpicDetails',
  updateLastViewedPositionAndQuestionPointerInAttempt: 'learnlytics/lessonComponentManagement/lessonComponent/updateLastViewedPositionAndQuestionPointerInAttempt',
  learningProgressionReportForTrack: 'framelytics/learningSessionManagement/learningSession/learningProgressionReportForTrack',
  obtainActivitySummaryForTrack: 'framelytics/learningSessionManagement/learningSession/obtainActivitySummaryForTrack',
  obtainWorkPatternChartForTrack: 'framelytics/learningSessionManagement/learningSession/obtainWorkPatternChartForTrack',
  obtainCourseDetailsFromTrack: 'framelytics/learningSessionManagement/learningSession/obtainCourseDetailsFromTrack',
  obtainModuleDetailsFromCourse: 'framelytics/learningSessionManagement/learningSession/obtainModuleDetailsFromCourse',
  obtainDateWiseIdleCourseDetails: 'framelytics/userManagement/user/obtainDateWiseIdleCourseDetails',
  obtainIdleUsersForParticularDay: 'framelytics/userManagement/user/obtainIdleUsersForParticularDay',
  obtainCourseLogDates: 'framelytics/userManagement/user/obtainCourseLogDates',
  obtainModuleWiseUsersDetails: 'framelytics/userManagement/user/obtainModuleWiseUsersDetails',
  obtainOverallProblemSolvedCountDetailsForParticularDay: 'framelytics/userManagement/user/obtainOverallProblemSolvedCountDetailsForParticularDay',
  searchMailLog: 'framelytics/userManagement/user/searchMailLog',
  obtainAllCoursesForAdmin: 'framelytics/adminManagement/admin/obtainAllCoursesForAdmin',
  obtainAssessmentsListForParticipant: 'framelytics/learningSessionManagement/learningSession/obtainAssessmentsListForParticipant',
  updateManualPublish: 'framelytics/learningSessionManagement/learningSession/updateManualPublish',
  obtainAssessmentSessionForReuse: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentSessionForReuse',
  obtainAssessmentSessionForProctoringManagement: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentSessionForProctoringManagement',
  obtainSlidedetailsForSingleDashboard: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSlidedetailsForSingleDashboard',
  cloneDashboard: 'framelytics/assessmentSessionManagement/assessmentSession/cloneDashboard',
  rearrangeSlideDetails: 'framelytics/assessmentSessionManagement/assessmentSession/rearrangeSlideDetails',
  obtainSlideDetailsForPreview: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSlideDetailsForPreview',
  obtainAllSlideDetailsForPreview: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAllSlideDetailsForPreview',
  cloneSlidesForDashboard: 'framelytics/assessmentSessionManagement/assessmentSession/cloneSlidesForDashboard',
  LearnerDetailsXlData: 'framelytics/learningSessionManagement/learningSession/LearnerDetailsXlData',
  obtainCoursesForClone: 'framelytics/learningSessionManagement/learningSession/obtainCoursesForClone',
  obtainModuleNameList: 'framelytics/learningSessionManagement/learningSession/obtainModuleNameList',
  obtainOverallLearnerDetails: 'framelytics/learningSessionManagement/learningSession/obtainOverallLearnerDetails',
  updateCodeAnalysisComponentDetails: 'codelytics/codeAnalysisManagement/codeAnalysisQuestion/updateCodeAnalysisComponentDetails',
  addNewUserDetailForProfile: 'framelytics/userManagement/user/addNewUserDetailForProfile',
  checkOldPasswordForUser: 'framelytics/userManagement/user/checkOldPasswordForUser',
  cloneModule: 'framelytics/learningSessionManagement/learningSession/cloneModule',
  cloneLearningSession: 'framelytics/learningSessionManagement/learningSession/cloneLearningSession',
  obtainModuleListFromGroupmodule: 'framelytics/learningSessionManagement/learningSession/obtainModuleListFromGroupmodule',

  obtainFunctionTemplates: 'codelytics/codeManagement/codingAdminAction/obtainFunctionTemplates',
  obtainFunctionalTemplateData: 'codelytics/codeManagement/codingAdminAction/obtainFunctionalTemplateData',
  deleteFunctionalTemplateData: 'codelytics/codeManagement/codingAdminAction/deleteFunctionalTemplateData',
  editFunctionalTemplateData: 'codelytics/codeManagement/codingAdminAction/editFunctionalTemplateData',
  saveFunctionalTemplateData: 'codelytics/codeManagement/codingAdminAction/saveFunctionalTemplateData',
  compileFunctionalTemplateCode: 'codelytics/codeManagement/codingAdminAction/compileFunctionalTemplateCode',
  obtainKeywords: 'codelytics/codeManagement/codingAdminAction/obtainKeywords',
  editKeywordData: 'codelytics/codeManagement/codingAdminAction/editKeywordData',
  saveKeywordData: 'codelytics/codeManagement/codingAdminAction/saveKeywordData',
  deleteKeywordData: 'codelytics/codeManagement/codingAdminAction/deleteKeywordData',
  obtainSessionsForComponentClone: 'framelytics/learningSessionManagement/learningSession/obtainSessionsForComponentClone',
  componentCloneForCourse: 'framelytics/learningSessionManagement/learningSession/componentCloneForCourse',
  deleteAssessmentSession: 'framelytics/assessmentSessionManagement/assessmentSession/deleteAssessmentSession',
  obtainMailerTemplateForAssesment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMailerTemplateForAssesment',
  checkFileExistOrNotInHrlRepository: 'framelytics/adminManagement/admin/checkFileExistOrNotInHrlRepository',
  addOrRemoveFileInQuestionBankFromHrlRepository: 'framelytics/adminManagement/admin/addOrRemoveFileInQuestionBankFromHrlRepository',
  deleteEmploymentDetail: 'framelytics/userManagement/user/deleteEmploymentDetail',
  deleteCertificationDetail: 'framelytics/userManagement/user/deleteCertificationDetail',
  obtainSmsTemplatesForAdmin: 'framelytics/adminManagement/admin/obtainSmsTemplatesForAdmin',
  createOrUpdateSmsTemplate: 'framelytics/userManagement/user/createOrUpdateSmsTemplate',
  smsTemplateDetailsForUpdate: 'framelytics/adminManagement/admin/smsTemplateDetailsForUpdate',
  deleteSmsTemplate: 'framelytics/adminManagement/admin/deleteSmsTemplate',
  licenseSubscription: 'framelytics/userManagement/user/subscribeLicense',
  obtainLicenseDetails: 'framelytics/userManagement/user/obtainLicenseDetails',
  obtainStreamBatchTrackListForLicenseManagement: 'framelytics/userManagement/user/obtainStreamBatchTrackListForLicenseManagement',
  checkCouponCode: 'framelytics/userManagement/user/checkCouponCode',
  obtainCouponType: 'framelytics/adminManagement/admin/obtainCouponType',
  createCouponForCatalog: 'framelytics/adminManagement/admin/createCouponForCatalog',
  obtainLicensedUserForOrgUnit: 'framelytics/userManagement/user/obtainLicensedUserForOrgUnit',
  obtainCoupon: 'framelytics/adminManagement/admin/obtainCoupon',
  deactivatedUserLicense: 'framelytics/userManagement/user/deactivatedUserLicense',
  enableUserLicense: 'framelytics/userManagement/user/enableUserLicense',
  obtainAllOrgUnits: 'framelytics/userManagement/user/obtainAllOrgUnits',
  obtainAllProjectDetailsForStream: 'framelytics/learningSessionManagement/learningSession/obtainAllProjectDetailsForStream',
  tagProjectsToStream: 'framelytics/learningSessionManagement/learningSession/tagProjectsToStream',
  obtainProjectDetailsForStream: 'framelytics/learningSessionManagement/learningSession/obtainProjectDetailsForStream',
  saveSprintDetails: 'framelytics/learningSessionManagement/learningSession/saveSprintDetails',
  obtainAllSprintDetails: 'framelytics/learningSessionManagement/learningSession/obtainAllSprintDetails',
  obtainUnTaggedEpicsforSprint: 'framelytics/learningSessionManagement/learningSession/obtainUnTaggedEpicsforSprint',
  tagEpicListToSprint: 'framelytics/learningSessionManagement/learningSession/tagEpicListToSprint',

  obtainMissingCapabilityList: 'framelytics/adminManagement/admin/obtainMissingCapabilityList',
  createCapabilityByAdmin: 'framelytics/adminManagement/admin/createCapabilityByAdmin',
  updateIgnoreCapability: 'framelytics/adminManagement/admin/updateIgnoreCapability',
  obtainIgnoredCapabilityList: 'framelytics/adminManagement/admin/obtainIgnoredCapabilityList',
  calculateEstimatedCompletionTimeForUser: 'framelytics/learningSessionManagement/learningSession/calculateEstimatedCompletionTimeForUser',
  obtainMailAndSmsTemplateForTeamTiming: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMailAndSmsTemplateForTeamTiming',
  trackCompletionUserXlData: 'framelytics/learningSessionManagement/learningSession/trackCompletionUserXlData',
  obtainAllDesignations: 'framelytics/adminManagement/admin/obtainAllDesignations',
  obtainSkillsFromAdmin: 'framelytics/userManagement/user/obtainSkillsFromAdmin',
  obtainDesignationsFromAdmin: 'framelytics/userManagement/user/obtainDesignationsFromAdmin',
  tagSkillsForOrganization: 'framelytics/userManagement/user/tagSkillsForOrganization',
  untagSkillsFromOrganization: 'framelytics/userManagement/user/untagSkillsFromOrganization',
  tagDesignationsForOrganization: 'framelytics/userManagement/user/tagDesignationsForOrganization',
  untagDesignationsFromOrganization: 'framelytics/userManagement/user/untagDesignationsFromOrganization',
  obtainUserNotifications: 'framelytics/userManagement/user/obtainUserNotifications',
  requestNewSkillForOrganization: 'framelytics/userManagement/user/requestNewSkillForOrganization',
  updateNotificationStatus: 'framelytics/userManagement/user/updateNotificationStatus ',
  initialUserNotification: 'framelytics/userManagement/user/initialUserNotification',
  obtainSkillDetails: 'framelytics/adminManagement/admin/obtainSkillDetails',
  obtainDesignationDetails: 'framelytics/adminManagement/admin/obtainDesignationDetails',
  obtainSkillsForAdmin: 'framelytics/adminManagement/admin/obtainSkillsForAdmin',
  obtainDesignationsForAdmin: 'framelytics/adminManagement/admin/obtainDesignationsForAdmin',
  removeUserNotification: 'framelytics/userManagement/user/removeUserNotification',
  requestNewDesignationForOrganization: 'framelytics/userManagement/user/requestNewDesignationForOrganization',
  createTeamMeeting: 'framelytics/learningSessionManagement/learningSession/createTeamMeeting',
  validateQuizQuestionBankXl: 'quizlytics/quizManagement/quizQuestion/validateQuizQuestionBankXl',
  importQuizQuestionBank: 'quizlytics/quizManagement/quizQuestion/importQuizQuestionBank',
  obtainUserReportsBulkMail: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserReportsBulkMail',
  conceptNodeReportsBulkMail: 'framelytics/assessmentSessionManagement/assessmentSession/conceptNodeReportsBulkMail',
  proctoringReportsBulkMail: 'framelytics/assessmentSessionManagement/assessmentSession/proctoringReportsBulkMail',
  checkNotificationEnable: 'framelytics/userManagement/user/checkNotificationEnable',
  saveAllowedIpsInCandidateExperienceSettings: 'framelytics/userManagement/user/saveAllowedIpsInCandidateExperienceSettings',
  validateAllowedIpsbulkUploadForAssessment: 'framelytics/userManagement/user/validateAllowedIpsbulkUploadForAssessment',
  checkAndObtainActiveAttempts: 'framelytics/assessmentSessionManagement/assessmentSession/checkAndObtainActiveAttempts',
  obtainScoreCardVersionTwo: 'framelytics/assessmentSessionManagement/assessmentSession/obtainScoreCardVersionTwo',
  checkUserRole: 'framelytics/userManagement/user/checkUserRole',
  adminUserLogin: 'framelytics/adminManagement/admin/adminUserLogin',
  updateReadForDoubtThreadMsg: 'framelytics/learningSessionManagement/learningSession/updateReadForDoubtThreadMsg',
  obtainDoubtThreadsForUserSSE: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadsForUserSSE',
  obtainSocialValues: 'framelytics/userManagement/user/obtainSocialValues',
  obtainProficiencyProfileReport: 'framelytics/assessmentSessionManagement/assessmentSession/obtainProficiencyProfileReport',
  userResumesDownload: 'framelytics/assessmentSessionManagement/assessmentSession/downloadUserResumes',
  sendUserResumesInMail: 'framelytics/assessmentSessionManagement/assessmentSession/sendUserResumesInMail',
  tagDeletedSubQuestionToQueryQuestion: 'codelytics/queryManagement/dbmsQueryQuestion/tagDeletedSubQuestionToQueryQuestion',
  obtainBranchesForDashboard: 'framelytics/userManagement/user/obtainBranchesForDashboard',
  obtainAssesmentListForDashboard: 'framelytics/userManagement/user/obtainAssesmentListForDashboard',
  obtainUserListForDashboard: 'framelytics/userManagement/user/obtainUserListForDashboard',
  obtainTeamsForUserForDashboard: 'framelytics/userManagement/user/obtainTeamsForOrgUnit',
  obtainStudentParticipationGraph: 'framelytics/userManagement/user/obtainStudentParticipationGraph',
  zScoreCalculationForUsers: 'framelytics/userManagement/user/zScoreCalculationForUsers',
  obtainAssessmentWiseAverageScore: 'framelytics/userManagement/user/obtainAssessmentWiseAverageScore',
  obtainAssessmentDataForConceptNodeGraph: 'framelytics/userManagement/user/obtainAssessmentDataForConceptNodeGraph',
  obtainQuadrantGraphForDashBoard: 'framelytics/userManagement/user/obtainQuadrantGraphForDashBoard',
  obtainComponentTypeForAdmin: 'framelytics/adminManagement/admin/obtainComponentTypeForAdmin',
  searchQuestionsBasedOnCondition: 'framelytics/adminManagement/admin/searchQuestionsBasedOnCondition',
  obtainCompetitiveDataForAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainCompetitiveDataForAssessment',
  userandTeamWiseQuestionAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/userandTeamWiseQuestionAttempt',
  obtainCompetitiveModePointsTableDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainCompetitiveModePointsTableDetails',
  userandTeamWiseQuestionAttemptForSingleUser: 'framelytics/assessmentSessionManagement/assessmentSession/userandTeamWiseQuestionAttemptForSingleUser',
  obtainQuerySubmissionDetails: 'codelytics/queryManagement/dbmsQueryQuestion/obtainQuerySubmissionDetails',
  updateDbmsQuerySubmission: 'codelytics/queryManagement/dbmsQueryQuestion/updateDbmsQuerySubmission',
  reevaluateQuerySubmission: 'codelytics/queryManagement/dbmsQueryQuestion/reevaluateQuerySubmission',
  obtainAttemptedQuestionDetailsForUsers: 'quizlytics/quizManagement/quizQuestion/obtainAttemptedQuestionDetailsForUsers',
  updateQuizQuestionAttemptScore: 'quizlytics/quizManagement/quizQuestion/updateQuizQuestionAttemptScore',
  updateManualScoreForCodingQuestion: 'codelytics/codeManagement/codingQuestion/updateManualScoreForCodingQuestion',
  downloadQuerySubmissionFile: 'codelytics/queryManagement/dbmsQueryQuestion/downloadQuerySubmissionFile',
  retrieveCourse: 'framelytics/learningSessionManagement/learningSession/retrieveCourse',
  deleteCourse: 'framelytics/learningSessionManagement/learningSession/deleteCourse',
  extractDataFromJobDescription: 'framelytics/assessmentSessionManagement/assessmentSession/extractDataFromJobDescription',
  createPersonalisedAssessmentSession: 'framelytics/assessmentSessionManagement/assessmentSession/createPersonalisedAssessmentSession',
  updateCookieForParticipant: 'framelytics/userManagement/user/updateCookieForParticipant',
  obtainQuizQuestionAttemptedStatusForCheckPoint: 'learnlytics/lessonComponentManagement/lessonComponent/obtainQuizQuestionAttemptedStatusForCheckPoint',
  obtainCodingQuestionSubmissionsForCheckPoint: 'learnlytics/lessonComponentManagement/lessonComponent/obtainCodingQuestionSubmissionsForCheckPoint',
  obtainPersonalisedAssessmentPatternTypes: 'framelytics/assessmentSessionManagement/assessmentSession/obtainPersonalisedAssessmentPatternTypes',
  generateAssessmentPatternsForJD: 'framelytics/assessmentSessionManagement/assessmentSession/generateAssessmentPatternsForJD',
  deleteAndRegenerateAssessmentPattern: 'framelytics/assessmentSessionManagement/assessmentSession/deleteAndRegenerateAssessmentPattern',
  saveSelectedAssessmentPattern: 'framelytics/assessmentSessionManagement/assessmentSession/saveSelectedAssessmentPattern',
  obtainSkillsForPersonalisedAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSkillsForPersonalisedAssessment',
  deleteUserResumeFromProfile: 'framelytics/userManagement/user/deleteUserResumeFromProfile',
  deleteAboutDetail: 'framelytics/userManagement/user/deleteAboutDetail',
  deleteSkillsForProfile: 'framelytics/userManagement/user/deleteSkillsForProfile',
  saveEducationDetailsForProfile: 'framelytics/userManagement/user/saveEducationDetailsForProfile',
  obtainEducationDetailsForProfile: 'framelytics/userManagement/user/obtainEducationDetailsForProfile',
  updateEducationDetailsForProfile: 'framelytics/userManagement/user/updateEducationDetailsForProfile',
  obtainProfileCompletionPercentage: 'framelytics/userManagement/user/obtainProfileCompletionPercentage',
  createAndEditQuestionnaire: 'framelytics/questionnaireManagement/questionnaireQuestion/createAndEditQuestionnaire',
  obtainQuestionnaire: 'framelytics/questionnaireManagement/questionnaireQuestion/obtainQuestionnaire',
  obtainQuestionnaireQuestionTypes: 'framelytics/questionnaireManagement/questionnaireQuestion/obtainQuestionnaireQuestionTypes',
  createAndEditQuestionnaireQuestion: 'framelytics/questionnaireManagement/questionnaireQuestion/createAndEditQuestionnaireQuestion',
  saveChoicesForRadioButton: 'framelytics/questionnaireManagement/questionnaireQuestion/saveChoicesForRadioButton',
  obtainQuestionnaireQuestionsList: 'framelytics/questionnaireManagement/questionnaireQuestion/obtainQuestionnaireQuestionsList',
  obtainQuestionnaireQuestionBasedOnType: 'framelytics/questionnaireManagement/questionnaireQuestion/obtainQuestionnaireQuestionBasedOnType',
  deleteQuestionnaireQuestion: 'framelytics/questionnaireManagement/questionnaireQuestion/deleteQuestionnaireQuestion',
  lookupUserNameForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/lookupUserNameForOrgUnit',
  obtainUserAssesmentDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserAssesmentDetails',
  obtainUserTaggedCourselist: 'framelytics/learningSessionManagement/learningSession/obtainUserTaggedCourselist',
  obtainMailLogForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMailLogForOrgUnit',
  obtainMailerTemplateForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMailerTemplateForOrgUnit',
  checkAssessmentConfig: 'framelytics/assessmentSessionManagement/assessmentSession/checkAssessmentConfig',
  saveSkillLevelForPersonalisedAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/saveSkillLevelForPersonalisedAttempt',
  searchUserForAdmin: 'framelytics/adminManagement/admin/searchUserForAdmin',
  obtainAllUsersForAdmin: 'framelytics/adminManagement/admin/obtainAllUsersForAdmin',
  createUserInAdmin: 'framelytics/adminManagement/admin/createUserInAdmin',
  obtainTeamsBasedOnOrgUnitForAdmin: 'framelytics/adminManagement/admin/obtainTeamsBasedOnOrgUnitForAdmin',
  obtainTeamsAndBatchDetailsForUser: 'framelytics/adminManagement/admin/obtainTeamsAndBatchDetailsForUser',
  updateUserDetailsByAdmin: 'framelytics/adminManagement/admin/updateUserDetailsByAdmin',
  obtainTeamsForTeamTransferInAdmin: 'framelytics/adminManagement/admin/obtainTeamsForTeamTransferInAdmin',
  updateTeamsInReclyticsForAdmin: 'framelytics/adminManagement/admin/updateTeamsInReclyticsForAdmin',
  obtainAssessmentsForTeamTransfer: 'framelytics/adminManagement/admin/obtainAssessmentsForTeamTransfer',
  insertQuestionnaireQuestion: 'framelytics/questionnaireManagement/questionnaireQuestion/insertQuestionnaireQuestion',
  questionnaireBulkUpload: 'framelytics/questionnaireManagement/questionnaireQuestion/questionnaireBulkUpload',
  deactivateUserFromCourse: 'framelytics/learningSessionManagement/learningSession/activeAndDeactiveUserFromCourse',
  obtainDeactivatedUsers: 'framelytics/adminManagement/admin/obtainDeactivatedUsers',
  activateAndDeactivateUser: 'framelytics/adminManagement/admin/activateAndDeactivateUser',
  createTeam: 'framelytics/userManagement/user/createTeam',
  deleteQuestionnaire: 'quizlytics/quizManagement/questionnaireQuestion/deleteQuestionnaire',
  obtainBatchesForAdd: 'framelytics/adminManagement/admin/obtainBatchesForAdd',
  obatinTracksAndTeamsForAddBatch: 'framelytics/adminManagement/admin/obatinTracksAndTeamsForAddBatch',
  addAndTransferBatchForAdmin: 'framelytics/adminManagement/admin/addAndTransferBatchForAdmin',
  obtainBatchesBasedOnOrgUnit: 'framelytics/adminManagement/admin/obtainBatchesBasedOnOrgUnit',
  obtainOrganisationBasedOnSegmentType: 'framelytics/adminManagement/admin/obtainOrganisationBasedOnSegmentType',
  createWorkFlow: 'framelytics/assessmentSessionManagement/assessmentSession/createWorkFlow',
  obtainWorkFlowDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainWorkFlowDetails',
  updateWorkFlowDetails: 'framelytics/assessmentSessionManagement/assessmentSession/updateWorkFlowDetails',
  uploadImageForWorkFlow: 'framelytics/assessmentSessionManagement/assessmentSession/uploadImageForWorkFlow',
  createWorkFlowAssessmentSession: 'framelytics/assessmentSessionManagement/assessmentSession/createWorkFlowAssessmentSession',
  obtainWorkFlowAssessmentSessions: 'framelytics/assessmentSessionManagement/assessmentSession/obtainWorkFlowAssessmentSessions',
  rearrangeWorkFlowAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/rearrangeWorkFlowAssessment',
  obtainMarketPlaceTypes: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMarketPlaceTypes',
  obtainUserDetailsBasedOnMarketPlaceType: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserDetailsBasedOnMarketPlaceType',
  obtainInterviewSkillsAndQuestions: 'framelytics/assessmentSessionManagement/assessmentSession/obtainInterviewSkillsAndQuestions',
  createComponentForInterviewFocussedPattern: 'framelytics/assessmentSessionManagement/assessmentSession/createComponentForInterviewFocussedPattern',
  generateAudioForQuestion: 'quizlytics/quizManagement/quizQuestion/generateAudioForQuestion',
  generateFeedbackForInterviewBasedAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/generateFeedbackForInterviewBasedAttempt',
  obtainQuizQuestionListFromQB: 'quizlytics/quizManagement/quizQuestion/obtainQuizQuestionListFromQB',
  obtainPersonalisedQbFromQuestionPaper: 'framelytics/assessmentSessionManagement/assessmentSession/obtainPersonalisedQbFromQuestionPaper',
  generateSingleQuizQuestionForAiGeneratedSection: 'quizlytics/quizManagement/quizQuestion/generateSingleQuizQuestionForAiGeneratedSection',
  finishSetupForInterviewBasedAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/finishSetupForInterviewBasedAssessment',
  getInstitutionDetails: 'framelytics/learningSessionManagement/learningSession/getInstitutionDetails',
  obtainInstitutionLearningCourseList: 'framelytics/learningSessionManagement/learningSession/obtainInstitutionLearningCourseList',
  obtainBatchReport: 'framelytics/learningSessionManagement/learningSession/obtainBatchReport',
  obtainAllBatchAnalysis: 'framelytics/learningSessionManagement/learningSession/obtainAllBatchAnalysis',
  obtainOverallCourseDetailsForBatchReport: 'framelytics/learningSessionManagement/learningSession/obtainOverallCourseDetailsForBatchReport',
  obtainDoubtThreadDashboardDetailsForSManager: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadDashboardDetailsForSManager',
  obtainMentorWisePerformanceForDoubtThread: 'framelytics/learningSessionManagement/learningSession/obtainMentorWisePerformanceForDoubtThread',
  obtainDoubtCompletionGraphDetails: 'framelytics/learningSessionManagement/learningSession/obtainDoubtCompletionGraphDetails',
  obtainDayWiseMentorReport: 'framelytics/learningSessionManagement/learningSession/obtainDayWiseMentorReport',
  obtainDoubtThreadDetails: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadDetails',
  obtainDoubtThreadStatus: 'framelytics/learningSessionManagement/learningSession/obtainDoubtThreadStatus',
  obtainSlaMetrixDetails: 'framelytics/learningSessionManagement/learningSession/obtainSlaMetrixDetails',
  obtainUsersForBatch: 'framelytics/learningSessionManagement/learningSession/obtainUsersForBatch',
  obtainSkillMetrixForSManager: 'framelytics/learningSessionManagement/learningSession/obtainSkillMetrixForSManager',
  obtainSlaCompletionPercentageForSmanager: 'framelytics/learningSessionManagement/learningSession/obtainSlaCompletionPercentageForSmanager',
  obtainSubmissionAndMessagesForDoubtThread: 'framelytics/learningSessionManagement/learningSession/obtainSubmissionAndMessagesForDoubtThread',
  exportCsvAsDayWiseMentorReport: 'framelytics/learningSessionManagement/learningSession/exportCsvAsDayWiseMentorReport',
  exportDoubtThreadDetailForSManager: 'framelytics/learningSessionManagement/learningSession/exportDoubtThreadDetailForSManager',
  obtainComponentTypeList: 'framelytics/baseappManagement/baseapp/obtainComponentTypeList',
  generateJsonFileForPersonalisedQb: 'framelytics/assessmentSessionManagement/assessmentSession/generateJsonFileForPersonalisedQb',
  createComponentForDesignInterviewPattern: 'framelytics/assessmentSessionManagement/assessmentSession/createComponentForDesignInterviewPattern',
  updateQuestionCountInAiGeneratedSection: 'framelytics/assessmentSessionManagement/assessmentSession/updateQuestionCountInAiGeneratedSection',
  saveGenuinityScoreForAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/saveGenuinityScoreForAttempt',
  createMediaTypeQuestionAndAttempt: 'framelytics/assessmentSessionManagement/assessmentSession/createMediaTypeQuestionAndAttempt',
  obtainSingleCodingQuestionAttemptDetails: 'codelytics/codeManagement/codingQuestion/obtainSingleCodingQuestionAttemptDetails',
  obtainQuestionListFromQB: 'framelytics/assessmentSessionManagement/assessmentSession/obtainQuestionListFromQB',
  exportProgrammingQuestionBank: 'codelytics/codeManagement/codingQuestion/exportProgrammingQuestionBank',
  exportDbmsQuestionBank: 'codelytics/queryManagement/dbmsQueryQuestion/exportDbmsQuestionBank',
  importDbmsQuestionBank: 'codelytics/queryManagement/dbmsQueryQuestion/importDbmsQuestionBank',
  obtainWorkflowForOrgUnit: 'framelytics/assessmentSessionManagement/assessmentSession/obtainWorkflowForOrgUnit',
  validateUsersForTalentPulseAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/validateUsersForTalentPulseAssessment',
  addUsersForTalentPulseAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/addUsersForTalentPulseAssessment',
  obtainTeamListForPreviousAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamListForPreviousAssessment',
  obtainUserListForPreviousAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/obtainUserListForPreviousAssessment',
  addUsersForNextAssessmentInTalentPulseAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/addUsersForNextAssessmentInTalentPulseAssessment',
  obtainTeamsForBatch: 'framelytics/learningSessionManagement/learningSession/obtainTeamsForBatch',
  obtainBatchTeamUsers: 'framelytics/learningSessionManagement/learningSession/obtainBatchTeamUsers',
  obtainSprintListForCreateSubTeams: 'framelytics/learningSessionManagement/learningSession/obtainSprintListForCreateSubTeams',
  createSubTeamsForProject: 'framelytics/learningSessionManagement/learningSession/createSubTeamsForProject',
  obtainAssignedTeamList: 'framelytics/learningSessionManagement/learningSession/obtainAssignedTeamList',
  createAutoSplitTeams: 'framelytics/learningSessionManagement/learningSession/createAutoSplitTeams',
  deleteSubTeam: 'framelytics/learningSessionManagement/learningSession/deleteSubTeam',
  obtainAIPrompts: 'framelytics/adminManagement/admin/obtainAIPrompts',
  saveOrUpdateAIPrompt: 'framelytics/adminManagement/admin/saveOrUpdateAIPrompt',
  saveConceptNode: 'framelytics/adminManagement/admin/saveConceptNode',
  obtainConceptNodeDetails: 'framelytics/adminManagement/admin/obtainConceptNodeDetails',
  updateConceptNodeDetails: 'framelytics/adminManagement/admin/updateConceptNodeDetails',
  obtainSingleConceptNodeDetail: 'framelytics/adminManagement/admin/obtainSingleConceptNodeDetail',
  deleteConceptNode: 'framelytics/adminManagement/admin/deleteConceptNode',
  obtainQuestionsBasedOnConceptNode: 'framelytics/adminManagement/admin/obtainQuestionsBasedOnConceptNode',
  obtainJobTypeList: 'framelytics/assessmentSessionManagement/assessmentSession/obtainJobTypeList',
  finishSetupForProfile: 'framelytics/userManagement/user/finishSetupForProfile',
  extractDataFromResumeForRegistrationForm: 'framelytics/userManagement/user/extractDataFromResumeForRegistrationForm',
  obtainUsersForProjectTeam: 'framelytics/learningSessionManagement/learningSession/obtainUsersForProjectTeam',
  obtainSprintForTeam: 'framelytics/learningSessionManagement/learningSession/obtainSprintForTeam',
  assignTaskForUser: 'framelytics/learningSessionManagement/learningSession/assignTaskForUser',
  autoSplitTaskForProjectTeam: 'framelytics/learningSessionManagement/learningSession/autoSplitTaskForProjectTeam',
  obtainProjectTeamsForTeam: 'framelytics/learningSessionManagement/learningSession/obtainProjectTeamsForTeam',
  updateFinishSetupForSprint: 'framelytics/learningSessionManagement/learningSession/updateFinishSetupForSprint',
  generateVivaQuestion: 'codelytics/codeManagement/codingQuestion/generateVivaQuestion',
  saveUserResponseForMediaTypeQuestion: 'framelytics/assessmentSessionManagement/assessmentSession/saveUserResponseForMediaTypeQuestion',
  checkQuestionAvailabiltyCountInQb: '/codelytics/codeManagement/codingQuestion/checkQuestionAvailabiltyCountInQb',
  obtainSkillAssessmentSessionType: '/framelytics/assessmentSessionManagement/assessmentSession/obtainSkillAssessmentSessionType',
  obtainThiveUserList: 'framelytics/adminManagement/admin/obtainThiveUserList',
  obtainMailerTemplateForThiveUser: 'framelytics/adminManagement/admin/obtainMailerTemplateForThiveUser',
  obtainSmsTemplateForThiveUser: 'framelytics/adminManagement/admin/obtainSmsTemplateForThiveUser',
  sendEmailAndSmsToThiveUser: 'framelytics/adminManagement/admin/sendEmailAndSmsToThiveUser',
  obtainSkillListFromPersonalizedSession: '/codelytics/codeManagement/codingQuestion/obtainSkillListFromPersonalizedSession',
  obtainNanoAssessmentDetailsForUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainNanoAssessmentDetailsForUser',
  obtainShareHubTypeList: 'framelytics/assessmentSessionManagement/assessmentSession/obtainShareHubTypeList',
  obtainMailerTemplateBasedOnShareHubType: 'framelytics/assessmentSessionManagement/assessmentSession/obtainMailerTemplateBasedOnShareHubType',
  extractWorkFlowDataFromJobDescription: 'framelytics/assessmentSessionManagement/assessmentSession/extractWorkFlowDataFromJobDescription',
  createDesignation: 'framelytics/assessmentSessionManagement/assessmentSession/createDesignation',
  deleteResumeData: 'framelytics/userManagement/user/deleteResumeData',
  checkResumeRequirement: 'framelytics/userManagement/user/checkResumeRequirement',
  obtainAssessmentSessionTypeList: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentSessionTypeList',
  obtainTeamListForVm: 'codelytics/vmManagement/vmMachine/obtainTeamListForVm',
  obtainUserListFormTeamForVm: 'codelytics/vmManagement/vmMachine/obtainUserListFormTeamForVm',
  obtainVmPlatformTypeList: 'codelytics/vmManagement/vmMachine/obtainVmPlatformTypeList',
  obtainVmSubnetList: 'codelytics/vmManagement/vmMachine/obtainVmSubnetList',
  createVmMachines: 'codelytics/vmManagement/vmMachine/createVmMachines',
  obtainAssignedUserInstanceList: 'codelytics/vmManagement/vmMachine/obtainAssignedUserInstanceList',
  updateInstanceAction: 'codelytics/vmManagement/vmMachine/updateInstanceAction',
  lanchInstance: 'codelytics/vmManagement/vmMachine/lanchInstance',
  deleteSkill: 'framelytics/adminManagement/admin/deleteSkill',
  deleteDesignation: 'framelytics/adminManagement/admin/deleteDesignation',
  obtainTrackStatusAndTimeSpentByParticipant: 'framelytics/learningSessionManagement/learningSession/obtainTrackStatusAndTimeSpentByParticipant',
  obtainRecentActivityOfParticipant: 'framelytics/learningSessionManagement/learningSession/obtainRecentActivityOfParticipant',
  obtainTrackDetailForParticipant: 'framelytics/learningSessionManagement/learningSession/obtainTrackDetailForParticipant',
  obtainInstanceDetails: 'codelytics/vmManagement/vmMachine/obtainInstanceDetails',
  obtainTrackComplitionPercentageForParticipant: 'framelytics/learningSessionManagement/learningSession/obtainTrackComplitionPercentageForParticipant',
  obtainConceptbasedOnSkill: 'framelytics/adminManagement/admin/obtainConceptbasedOnSkill',
  tagConceptsInSkill: 'framelytics/adminManagement/admin/tagConceptsInSkill',
  obtainTaggedConceptBasedOnSkill: 'framelytics/adminManagement/admin/obtainTaggedConceptBasedOnSkill',
  deleteConceptInSkill: 'framelytics/adminManagement/admin/deleteConceptInSkill',
  obtainSkillsAndConceptsForPersonalisedSession: 'framelytics/assessmentSessionManagement/assessmentSession/obtainSkillsAndConceptsForPersonalisedSession',
  saveSkillAndConceptAssignmentForAIDrivenPattern: 'framelytics/assessmentSessionManagement/assessmentSession/saveSkillAndConceptAssignmentForAIDrivenPattern',
  obtainCourseCompletionDetails: '/framelytics/learningSessionManagement/learningSession/obtainCourseCompletionDetails',
  obtainOverallAssessmentReportDetails: 'framelytics/learningSessionManagement/learningSession/obtainOverallAssessmentReportDetails',
  obtainProgressReportDetails: 'framelytics/learningSessionManagement/learningSession/obtainProgressReportDetails',
  generateQuizQuestionForSelectedAssessmentPattern: 'framelytics/assessmentSessionManagement/assessmentSession/generateQuizQuestionForSelectedAssessmentPattern',
  obtainModeOfEvaluation: 'framelytics/assessmentSessionManagement/assessmentSession/obtainModeOfEvaluation',
  saveSkillAndConceptAssignmentForPersonalisedSession: 'framelytics/assessmentSessionManagement/assessmentSession/saveSkillAndConceptAssignmentForPersonalisedSession',
  createWeightageAssignmentForSelectedAssessmentPattern: 'framelytics/assessmentSessionManagement/assessmentSession/createWeightageAssignmentForSelectedAssessmentPattern',
  obtainBaseConceptsForSkill: 'framelytics/adminManagement/admin/obtainBaseConceptsForSkill',
  tagBaseConceptsToSkill: 'framelytics/adminManagement/admin/tagBaseConceptsToSkill',
  saveOrUpdateOrDeletePromptTemplate: 'quizlytics/quizManagement/quizQuestion/saveOrUpdateOrDeletePromptTemplate',
  obtainPromptTemplate: 'quizlytics/quizManagement/quizQuestion/obtainPromptTemplate',
  tagPromptKeywordInPromptTemplate: 'quizlytics/quizManagement/quizQuestion/tagPromptKeywordInPromptTemplate',
  deletepromptKeyword: 'quizlytics/quizManagement/quizQuestion/deletepromptKeyword',
  evaluateMediaAttempts: 'quizlytics/quizManagement/quizQuestion/evaluateMediaAttempts',
  updateTimeToSolveDurationInModule: 'framelytics/learningSessionManagement/learningSession/updateTimeToSolveDurationInModule',
  obtainModulesForPrerequisite: 'framelytics/learningSessionManagement/learningSession/obtainModulesForPrerequisite',
  checkPrerequisitesForModule: 'framelytics/learningSessionManagement/learningSession/checkPrerequisitesForModule',
  checkPrerequisitesForGroupModule: 'framelytics/learningSessionManagement/learningSession/checkPrerequisitesForGroupModule',
  obtainModuleGroupsForPrerequisiteGroupModules: 'framelytics/learningSessionManagement/learningSession/obtainModuleGroupsForPrerequisiteGroupModules',

  // code analysis bulk upload

  codeAnalysisbulkUploadValidation: 'codelytics/codeAnalysisManagement/codeAnalysisQuestion/validateBulkUpload',
  codeAnalysisBulkUpload: 'codelytics/codeAnalysisManagement/codeAnalysisQuestion/bulkUpload',
  obtainAllFiles: '/framelytics/assessmentSessionManagement/assessmentSession/obtainAllFiles',

  obtainTeamsForAlertConfig: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTeamsForAlertConfig',
  updateEmailNotificationSettings: 'framelytics/assessmentSessionManagement/assessmentSession/updateEmailNotificationSettings',
  insertUserToThive: 'framelytics/assessmentSessionManagement/assessmentSession/insertUserToThive',
  updateMeetingRecording: 'framelytics/learningSessionManagement/learningSession/updateMeetingRecording',
  updateMediaQuestionStatus: 'quizlytics/quizManagement/quizQuestion/updateMediaQuestionStatus',
  obtainToBeCreatedQuestionCountForSkillConcept: '/framelytics/assessmentSessionManagement/assessmentSession/obtainToBeCreatedQuestionCountForSkillConcept',
  obtainSavedSkillAssignmentList: '/framelytics/assessmentSessionManagement/assessmentSession/obtainSavedSkillAssignmentList',
  saveOrObtainPersonalisedSkillConceptQB: 'framelytics/assessmentSessionManagement/assessmentSession/saveOrObtainPersonalisedSkillConceptQB',
  generateQuizQuestionForSkillConcept: 'quizlytics/quizManagement/quizQuestion/generateQuizQuestionForSkillConcept',
  obtainPersonalisedProgrammingQuestionList: 'codelytics/codeManagement/codingQuestion/obtainPersonalisedProgrammingQuestionList',
  generateCodingQuestionDescriptionForSkillConcept: 'codelytics/codeManagement/codingQuestion/generateCodingQuestionDescriptionForSkillConcept',
  createComponentForAIDrivenPattern: 'framelytics/assessmentSessionManagement/assessmentSession/createComponentForAIDrivenPattern',
  obtainTabForPersonalisedSessionCreation: 'framelytics/assessmentSessionManagement/assessmentSession/obtainTabForPersonalisedSessionCreation',
  generateProblemTestcaseForAiGeneratedCodingQuestion: 'codelytics/codeManagement/codingQuestion/generateProblemTestcaseForAiGeneratedCodingQuestion',
  regenerateFailedTestcaseForCodingQuestion: 'codelytics/codeManagement/codingQuestion/regenerateFailedTestcaseForCodingQuestion',
  regenerateCodingQuestionDescriptionForSkillConcept: 'codelytics/codeManagement/codingQuestion/regenerateCodingQuestionDescriptionForSkillConcept',
  generateSampleProgramForForAiGeneratedCodingQuestion: 'codelytics/codeManagement/codingQuestion/generateSampleProgramForForAiGeneratedCodingQuestion',
  triggerValidateApiForGenaretedCodingQst: 'codelytics/codeManagement/codingQuestion/triggerValidateApiForGenaretedCodingQst',
  validateCodingQuestionBank: 'codelytics/codeManagement/codingQuestion/validateCodingQuestionBank',
  generateLanguageDetailsForCodingQuestion: 'codelytics/codeManagement/codingQuestion/generateLanguageDetailsForCodingQuestion',
  updateWeightageAndLanguageForComponent: 'framelytics/assessmentSessionManagement/assessmentSession/updateWeightageAndLanguageForComponent',
  obtainPersonalisedComponentList: 'framelytics/assessmentSessionManagement/assessmentSession/obtainPersonalisedComponentList',
  reevaluateForMediaAttempts: 'quizlytics/quizManagement/quizQuestion/reevaluateForMediaAttempts',
  enableOrDisableEmailReminder: 'framelytics/assessmentSessionManagement/assessmentSession/enableOrDisableEmailReminder',
  obtainInitialKeyForTeam: 'framelytics/assessmentSessionManagement/assessmentSession/obtainInitialKeyForTeam',
  generateInitialKeyForSingleUser: 'framelytics/assessmentSessionManagement/assessmentSession/generateInitialKeyForSingleUser',
  generateInitialKey: 'framelytics/assessmentSessionManagement/assessmentSession/generateInitialKey',
  validateInitialKey: 'framelytics/assessmentSessionManagement/assessmentSession/validateInitialKey',
  downloadInitialKeyDetails: 'framelytics/assessmentSessionManagement/assessmentSession/downloadInitialKeyDetails',
  obtainXlDataForThiveUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainXlDataForThiveUser',
  obtainAssessmentMetricesForWorkFlow: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentMetricesForWorkFlow',
  updateFinishSetupForWorkFlow: 'framelytics/assessmentSessionManagement/assessmentSession/updateFinishSetupForWorkFlow',
  deleteWorkFlowAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/deleteWorkFlowAssessment',
  obtainAssessmentRoundDetails: 'framelytics/assessmentSessionManagement/assessmentSession/obtainAssessmentRoundDetails',
  obtainLanguageListForAdmin: 'codelytics/codeManagement/codingQuestion/obtainLanguageListForAdmin',
  saveOrUpdateLanguageForAdmin: 'codelytics/codeManagement/codingQuestion/saveOrUpdateLanguageForAdmin',
  updateDurationForWorkFlow: 'framelytics/assessmentSessionManagement/assessmentSession/updateDurationForWorkFlow',
  sendMailForSelectedUsers: 'framelytics/assessmentSessionManagement/assessmentSession/sendMailForSelectedUsers',
  obtainImageListForVdiShowPage: 'codelytics/vmManagement/vmMachine/obtainImageListForVdiShowPage',
  obtainVmCourses: 'codelytics/vmManagement/vmMachine/obtainVmCourses',
  obtainCourseTeamsForVm: 'codelytics/vmManagement/vmMachine/obtainCourseTeamsForVm',
  obtainVmAssessmentList: 'codelytics/vmManagement/vmMachine/obtainVmAssessmentList',
  obtainAssessmentTeamsForVm: 'codelytics/vmManagement/vmMachine/obtainAssessmentTeamsForVm',
  addCreditToUser: 'codelytics/vmManagement/vmMachine/addCreditToUser',
  obtainBatchWiseDetailsForVm: 'codelytics/vmManagement/vmMachine/obtainBatchWiseDetailsForVm',
  obtainOnlineCatalogListForAdmin: 'framelytics/catalogManagement/admin/obtainOnlineCatalogListForAdmin',
  obtainOnlineContextListForAdmin: 'framelytics/catalogManagement/admin/obtainOnlineContextListForAdmin',
  obtainProductListForAdmin: 'framelytics/catalogManagement/admin/obtainProductListForAdmin',
  obtainOnlineServicesForAdmin: 'framelytics/catalogManagement/admin/obtainOnlineServicesForAdmin',
  obtainMeetingChatDetails: 'framelytics/learningSessionManagement/learningSession/obtainMeetingChatDetails',
  updateOnlineCart: 'framelytics/userManagement/user/updateOnlineCart',
  obtainOnlineCartDetails: 'framelytics/userManagement/user/obtainOnlineCartDetails',
  createOrUpdateLitTechnology: 'framelytics/adminManagement/admin/createOrUpdateLitTechnology',
  obtainLitTechnologiesForAdmin: 'framelytics/adminManagement/admin/obtainLitTechnologiesForAdmin',
  createOrUpdateLitSkillInLitTechnology: 'framelytics/adminManagement/admin/createOrUpdateLitSkillInLitTechnology',
  obtainLitSkillForLitTechnology: 'framelytics/adminManagement/admin/obtainLitSkillForLitTechnology',
  createOrUpdateLitCertificationInLitSkill: 'framelytics/adminManagement/admin/createOrUpdateLitCertificationInLitSkill',
  obtainLitCertificationsForLitSkill: 'framelytics/adminManagement/admin/obtainLitCertificationsForLitSkill',
  rearrangelitSkillListInLitTechnology: 'framelytics/adminManagement/admin/rearrangelitSkillListInLitTechnology',
  rearrangelitCertificationListInLitSkill: 'framelytics/adminManagement/admin/rearrangelitCertificationListInLitSkill',
  deleteLitTechnologies: 'framelytics/adminManagement/admin/deleteLitTechnologies',
  deleteLitSkillsFromLitTechnology: 'framelytics/adminManagement/admin/deleteLitSkillsFromLitTechnology',
  deleteLitCertificationsFromLitSkill: 'framelytics/adminManagement/admin/deleteLitCertificationsFromLitSkill',
  obtainLitCertificateForUser: 'framelytics/assessmentSessionManagement/assessmentSession/obtainLitCertificateForUser',
  validateLitAssessment: 'framelytics/assessmentSessionManagement/assessmentSession/validateLitAssessment',
  obtainInstitutionsList: 'framelytics/catalogManagement/admin/obtainInstitutionsList',
  obtainProductListForCatalog: 'framelytics/catalogManagement/admin/obtainProductListForCatalog',
  createOnlineContextForCatalog: 'framelytics/catalogManagement/admin/createOnlineContextForCatalog',
  obtainOnlineCatalogCategoriesFromOnlineService: 'framelytics/catalogManagement/admin/obtainOnlineCatalogCategoriesFromOnlineService',
  saveOrUpdateFileFieldForOnlineCatalog: 'framelytics/catalogManagement/admin/saveOrUpdateFileFieldForOnlineCatalog',
  deleteOnlineCatalog: '/framelytics/catalogManagement/admin/deleteOnlineCatalog',
  obtainOnlineCatalogForOnlineCatalogCategory: '/framelytics/catalogManagement/admin/obtainOnlineCatalogForOnlineCatalogCategory',
  createOrUpdateOnlineCatalogCategory: 'framelytics/catalogManagement/admin/createOrUpdateOnlineCatalogCategory',
  createOrUpdateOnlineCatalog: 'framelytics/catalogManagement/admin/createOrUpdateOnlineCatalog',
  obtainOnlineContextTypes: 'framelytics/catalogManagement/admin/obtainOnlineContextTypes',
  obtainOnlineContextForOnlineContextType: 'framelytics/catalogManagement/admin/obtainOnlineContextForOnlineContextType',
  removeCartEntryFromOnlineCart: 'framelytics/userManagement/user/removeCartEntryFromOnlineCart',
  obtainCapStoneListForUser: 'framelytics/learningSessionManagement/learningSession/obtainCapStoneListForUser',
  obtainSprintForProject: 'framelytics/learningSessionManagement/learningSession/obtainSprintForProject',
  obtainTasksForSprint: 'framelytics/learningSessionManagement/learningSession/obtainTasksForSprint',
  obtainProjectCompletionDetails: 'framelytics/learningSessionManagement/learningSession/obtainProjectCompletionDetails',
  obtainSprintDetailsForDashboard: 'framelytics/learningSessionManagement/learningSession/obtainSprintDetailsForDashboard',
  obtainTeamsForSubmissionStack: 'framelytics/learningSessionManagement/learningSession/obtainTeamsForSubmissionStack',
  obtainSprintsAndEpicsForSubmissionStack: 'framelytics/learningSessionManagement/learningSession/obtainSprintsAndEpicsForSubmissionStack',
  obtainUsersForTeamInSubmissionStack: 'framelytics/learningSessionManagement/learningSession/obtainUsersForTeamInSubmissionStack',
  obtainSubmissionsForUserInCapStone: 'framelytics/learningSessionManagement/learningSession/obtainSubmissionsForUserInCapStone',
  obtainUnverfiedQuestionsforGeneration: 'framelytics/adminManagement/admin/obtainUnverfiedQuestionsforGeneration',
  generateQuestionUsingAI: 'framelytics/adminManagement/admin/generateQuestionUsingAI',
  retrieveGradeBasedOnQB: 'framelytics/adminManagement/admin/retrieveGradeBasedOnQB',
  updateStatusForAIQuestions: 'framelytics/adminManagement/admin/updateStatusForAIQuestions',
  obtainConceptsForSkill: 'framelytics/adminManagement/admin/obtainConceptsForSkill',
  updateGraphDetail: 'framelytics/baseappManagement/baseapp/EvaluateXlReport',
  downloadXlReport: 'framelytics/baseappManagement/baseapp/downloadXlReport',
  obtainOrgForAdmin: 'framelytics/adminManagement/admin/obtainOrgForAdmin',
  obtainOrgunitsForOrg: 'framelytics/adminManagement/admin/obtainOrgunitsForOrg',
  obtainAssessmentNamesForOrgUnit: 'framelytics/adminManagement/admin/obtainAssessmentNamesForOrgUnit',
  obtainTimeBasedAssessmentReport: 'framelytics/adminManagement/admin/obtainTimeBasedAssessmentReport',
  obtainTimezoneList: 'framelytics/adminManagement/admin/obtainTimezoneList',
};

export const successurl = `${restbaseurl}student/success`;
export const failureurl = `${restbaseurl}student/failure`;

export const dummyurl = 'http://dummy.restapiexample.com/api/v1/employees';
