import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './supportManager.module.less';

const SLAMatrix = (props) => {
  const { t, SLAMatrixData } = props;
  return (
    <div className={styles.slaMatrix}>
      <div>
        {t('sla_matrix')}
      </div>
      <div>
        {
          Object.keys(SLAMatrixData).map(
            (e) => {
              const [start, end] = e.split(' - ');
              const startTime = start;
              const endTime = end;
              let rangeLabel;
              if (endTime === 'inf') {
                rangeLabel = `More than ${startTime / 60}H`;
              } else if (Number(startTime) === Number(0)) {
                rangeLabel = `Within ${endTime} mins`;
              } else {
                rangeLabel = endTime > 60 ? `${startTime / 60}H to ${endTime / 60}H` : `${e} mins`;
              }

              const maxPercentage = Math.max(...Object.values(SLAMatrixData).map(
                (data) => data.resolved_percentage,
              ));
              const isMaxPercentage = maxPercentage !== 0
                && SLAMatrixData[e].resolved_percentage === maxPercentage;

              return (
                <div className={isMaxPercentage ? styles.highestPercentage : ''}>
                  <div>{rangeLabel}</div>
                  <div>
                    Resolved:
                    {SLAMatrixData[e].resolved_count}
                  </div>
                  <div>
                    {SLAMatrixData[e].resolved_percentage}
                    %
                  </div>
                </div>
              );
            },
          )
        }
      </div>
    </div>
  );
};

export default withTranslation('programManager')(SLAMatrix);
