const Home = {
  scheduled_events: 'Scheduled Events',
  completed: 'Completed',
  not_taken: 'Not Taken',
  competition_challenges: 'Competition & Challenges',
  filter_by_tech: 'Filter by Technology',
  sort_by: 'Sort By',
  attempted: 'Attempted',
  not_attempted: 'Not Attempted',
  latest_first: 'Latest First',
  oldest_first: 'Oldest First',
  round: 'Round',
  date: 'Date',
  time: 'Time',
  start: 'Start',
  profile_completion: 'Profile Completion ',
  view_profile: 'View Profile',
  public_url: 'Public URL',
  video_brief: 'Video Brief',
  no_attempt: 'No Attempt',
  live_contest: 'Live Contest',
  upcoming_contest: 'Upcoming Contest',
  completed_contest: 'Completed Contest',
  no_live: 'No Live Contests for this technology',
  no_upcoming: 'No Upcoming Contests for this technology',
  no_completed: 'No Completed Contests for this technology',
  view_details: 'View Details',
  view_challenges: 'View Challenges',
  tech: 'Technology',
};
export default Home;
