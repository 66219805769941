import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useComponentDidMount, FullPageLoader } from '@hrlytics/corelytics';
import { Table } from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import OperationHeader from '@globalComps/header/OperationHeader';
import styles from './services.module.less';

const BatchAnalysis = (props) => {
  const { t } = props;

  const [allBatchAnalysis, setAllBatchAnalysis] = useState({
    batchAnalysisList: [],
    isFullPageLoading: false,
  });

  const { batchAnalysisList, isFullPageLoading } = allBatchAnalysis;

  const obtainAllBatchAnalysis = () => {
    setAllBatchAnalysis((prev) => ({
      ...prev,
      isFullPageLoading: true,
    }));
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data: { batch_list } } = respdata;
      if (estatus && emessage) {
        setAllBatchAnalysis((prev) => ({
          ...prev,
          batchAnalysisList: batch_list,
          isFullPageLoading: false,
        }));
      }
    }, resturls.obtainAllBatchAnalysis, {}, 'GET');
  };

  useComponentDidMount(obtainAllBatchAnalysis);

  return (
    <>
      <div>
        <OperationHeader />
        {isFullPageLoading && <FullPageLoader />}
        <div className={styles.batchAnalysis}>
          <div fontas="beta header">{t('all_batch_analysis')}</div>
          <div className={styles.batchAnalysisTable}>
            <Table basic="very" unstackable striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('sno')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('batch_name')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('no_of_users')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('no_of_courses')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('avg_no_of_course_completed')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('avg_participant_percentage')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('avg_performance_percentage')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('user_who_have_cleared_all_assesment')}</div></Table.HeaderCell>
                  <Table.HeaderCell className={styles.batchAnalysistableHeader}><div>{t('no_of_assesment_types')}</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(batchAnalysisList.map((ele, i) => (
                  <Table.Row>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>
                      {ele.name}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.user_count}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.course_count}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.avg_number_of_courses_completed}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.participation}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.performance}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.cleared_all_assessments}
                    </Table.Cell>
                    <Table.Cell>
                      {ele.assessment_type_count}
                    </Table.Cell>
                  </Table.Row>
                )))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation('recruiter')(BatchAnalysis);
