import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Dropdown, Button, ButtonGroup, ButtonOr, Icon, Popup,
} from 'semantic-ui-react';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import DefaultHeader from '@globalComps/header/DefaultHeader';
import OrgTreeStructure from '@screens/commonComponents/OrgTreeStructure';
import UserInfo from '@models/UserInfo';
import styles from './UserWiseCourseScreen.module.less';
import UserWiseCourseList from './UserWiseCourseList';
import UserWiseAssessmentList from './UserWiseAssessmentList';

const wait = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

const UserWiseCourseScreen = (props) => {
  const { t, isProgramManager } = props;
  const orgUnitId = UserInfo.getOrgUnitId();

  const [state, setState] = useState({
    courseUserListOption: [],
    searchQueryValue: '',
    selectedUserId: '',
    courseList: [],
    sessionsandTeamtimingDetail: [],
    courseId: '',
    teamId: '',
    isLoading: false,
    sessionsList: [],
    hrlsessionid: '',
    activeListName: '',
    orgAccordActiveIndices: [],
    orgAccordOpenList: [],
    listViewCompKey: '',
    isDeactivateUser: false,
    isAdminActive: false,
  });

  const {
    courseUserListOption, courseList, courseId, sessionsList, hrlsessionid,
    searchQueryValue, selectedUserId, sessionsandTeamtimingDetail,
    isLoading, teamId, activeListName, orgAccordActiveIndices, orgAccordOpenList,
    listViewCompKey, isDeactivateUser, isAdminActive,
  } = state;

  const currentRole = UserInfo.getRole();

  const setOrgAccordActiveIndices = (activeIndices) => {
    setState((prevState) => ({
      ...prevState,
      orgAccordActiveIndices: activeIndices,
    }));
  };

  const setOrgAccordOpenList = (accordOpenList) => {
    setState((prevState) => ({
      ...prevState,
      orgAccordOpenList: accordOpenList,
    }));
  };

  const lookupUserNameForAdmin = async (searchQuery) => {
    await wait(500);
    const params = currentRole === 'recruiter_head' || currentRole === 'program_manager'
      ? `&org_unit_id=${orgUnitId}`
      : '';

    const url = currentRole === 'recruiter_head' || currentRole === 'program_manager'
      ? resturls.lookupUserNameForOrgUnit
      : resturls.lookupUserNameForAdmin;

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage) {
        const { user_list } = data;
        setState((prev) => ({
          ...prev,
          courseUserListOption: user_list?.map((e) => ({
            key: `${e.name}-${e.email}`,
            value: e.id,
            text: `${e.name} - ${e.email}`,
            active: e.active,
            adminActive: e.admin_active,
          })),
        }));
      }
    }, `${url}?search=${searchQuery || '  '}${params}`, {}, 'GET');
  };

  const obtainUserTaggedCourseandTeamlist = () => {
    let url = ''
    if (currentRole === 'recruiter_head') {
      url = resturls.obtainUserAssesmentDetails
    } else if (currentRole === 'program_manager') {
      url = resturls.obtainUserTaggedCourselist
    } else {
      url = resturls.obtainUserTaggedCourseandTeamlist
    }
    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage) {
        let listName = '';
        const accordActiveIndices = [];
        if (data.assessment_list && data.course_list) {
          listName = (activeListName !== '' && activeListName !== 'orgunit') ? activeListName : 'assessment';
        } else if (data.org_unit_list) {
          listName = 'orgunit';

          const traverseOrgIdsRecursively = (orgList) => {
            orgList.forEach(({ id, org_unit_list }) => {
              accordActiveIndices.push(id);
              if (org_unit_list.length > 0) traverseOrgIdsRecursively(org_unit_list);
            });
          };

          traverseOrgIdsRecursively(data.org_unit_list);
        } else if (data.assessment_list && data.role === 'Participant') {
          listName = 'assessment';
        } else if (data.course_list && data.role === 'Participant') {
          listName = 'course';
        }

        setState((prev) => ({
          ...prev,
          courseList: data,
          activeListName: listName,
          orgAccordActiveIndices: accordActiveIndices,
          listViewCompKey: data.user_U_id,
        }));
      }
    }, `${url}?user_id=${selectedUserId}`, {}, 'GET');
  };

  const handleSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length >= 3
      || searchQueryValue.length > searchQuery.length) {
      setState((prev) => ({
        ...prev,
        searchQueryValue: searchQuery,
      }));
      lookupUserNameForAdmin(searchQuery);
    }
  };

  const setActiveListName = (listName) => {
    setState((prevState) => ({
      ...prevState,
      activeListName: listName,
    }));
  };

  const copyText = (event, value) => {
    const body = document.getElementsByTagName('body')[0];
    const tempInput = document.createElement('INPUT');
    body.appendChild(tempInput);
    tempInput.setAttribute('value', value);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    body.removeChild(tempInput);
    event.stopPropagation();
    toast({
      type: 'success',
      description: t('email_copied'),
      time: 2000,
    });
  };

  const copyEmailContent = (event) => {
    const emailId = courseUserListOption.find(({ value }) => value === selectedUserId).key.split('-')[1];
    copyText(event, emailId);
  };

  const listViewComponentObj = {
    course: UserWiseCourseList,
    assessment: UserWiseAssessmentList,
    orgunit: OrgTreeStructure,
  };

  const ListViewComp = listViewComponentObj[activeListName];

  const renderButtons = () => {
    if (currentRole === 'recruiter_head') {
      return (
        <Button
          className={activeListName === 'assessment' ? styles.activeBtn : ''}
          onClick={() => setActiveListName('assessment')}
        >
          {t('reclytics')}
        </Button>
      );
    } else if (currentRole === 'program_manager') {
      return (
        <Button
          positive
          className={activeListName === 'course' ? styles.activeBtn : ''}
          onClick={() => setActiveListName('course')}
        >
          {t('skillytics')}
        </Button>
      );
    } else {
      return (
        <>
          <Button
            className={activeListName === 'assessment' ? styles.activeBtn : ''}
            onClick={() => setActiveListName('assessment')}
          >
            {t('reclytics')}
          </Button>
          <ButtonOr text={t('or')} />
          <Button
            positive
            className={activeListName === 'course' ? styles.activeBtn : ''}
            onClick={() => setActiveListName('course')}
          >
            {t('skillytics')}
          </Button>
        </>
      );
    }
  };

  const deactivateUserFromCourseFunct = (value) => {
    let url = '';
    if (currentRole === 'recruiter_head' || currentRole === 'program_manager') {
      url = `${resturls.deactivateUserFromCourse}?user_id=${selectedUserId}&function=${value.checked}&org_unit_id=${orgUnitId}`;
    } else {
      url = `${resturls.activateAndDeactivateUser}?user_id=${selectedUserId}&function=${value.checked}`;
    }

    GlobalService.generalSelect((respdata) => {
      const { estatus, emessage, data } = respdata;
      if (estatus && emessage && data) {
        setState((prevState) => ({ ...prevState, isDeactivateUser: value.checked }));
        obtainUserTaggedCourseandTeamlist();
      }
    }, url, {}, 'GET');
  };

  let headerText = '';

  if (currentRole === 'recruiter_head') {
    headerText = `${t('current_assessments')}`;
  } else if (currentRole === 'program_manager') {
    headerText = `${t('current_courses')}`;
  } else {
    headerText = `${t('current_courses_assessments')}`;
  }

  return (
    <>
      <SemanticToastContainer position="top-right" maxToasts={1} />
      {
        isProgramManager === undefined && (
          <DefaultHeader />
        )
      }
      <div className={styles.userWiseCourseScreen}>
        <div>
          <div fontas="beta header">{t('user_tracking')}</div>
          <div className={styles.userWiseCourseMainPanel}>
            <span fontas="beta">{t('username')}</span>
            <Dropdown
              className={styles.usernameListFormFiled}
              search
              clearable
              selection
              options={courseUserListOption}
              placeholder={t('search_username')}
              onSearchChange={handleSearchChange}
              onChange={(_, data) => {
                const { value } = data;
                if (!value) {
                  setState((prevState) => ({
                    ...prevState,
                    courseUserListOption: [],
                    selectedUserId: value,
                    searchQueryValue: '',
                    courseList: [],
                    activeListName: '',
                    sessionsandTeamtimingDetail: [],
                    isDeactivateUser: false,
                  }));
                } else {
                  const selectedUser = courseUserListOption.find((user) => user.value === value);
                  setState((prevState) => ({
                    ...prevState,
                    selectedUserId: value,
                    isDeactivateUser: selectedUser.active,
                    isAdminActive: selectedUser.adminActive
                  }));
                }
              }}
            />
            <div>
              <Button
                disabled={!selectedUserId || !isDeactivateUser}
                onClick={(() => obtainUserTaggedCourseandTeamlist())}
              >
                <p fontas="beta">{currentRole === 'recruiter_head' ? t('get_assesment') : t('get_courses')}</p>
              </Button>
            </div>
            {
              selectedUserId !== '' && (
                <div>
                  <span fontas="alpha">{t('user_status')}</span>
                  <div fontas="alpha">
                    {isDeactivateUser ? (
                      <div className={styles.activeUser}>
                        <span>
                          {t('active')}
                        </span>
                        <Popup
                          content={() => {
                            if (currentRole === 'admin') {
                              t('click_to_deactivete_user');
                            } else if (isAdminActive && currentRole !== 'admin') {
                              return t('click_to_deactivete_user');
                            } else {
                              return t('activate_admin_access');
                            }
                            return t('click_to_deactivete_user');
                          }}
                          position="top center"
                          trigger={<Icon name="eye" disabled={currentRole !== 'admin' && !isAdminActive} onClick={() => deactivateUserFromCourseFunct({ checked: false })} />} />
                      </div>
                    ) : (
                      <div className={styles.deactivateUser}>
                        <span>
                          {t('deactivated')}
                        </span>
                        <Popup
                          content={() => {
                            if (currentRole === 'admin') {
                              t('click_to_activete_user');
                            } else if (isAdminActive && currentRole !== 'admin') {
                              return t('click_to_activete_user');
                            } else {
                              return t('activate_admin_access');
                            }
                            return t('click_to_activete_user');
                          }}
                          position="top center"
                          trigger={<Icon name="eye slash" disabled={currentRole !== 'admin' && !isAdminActive} onClick={() => deactivateUserFromCourseFunct({ checked: true })} />} />
                      </div>
                    )}
                  </div>
                </div>
              )
            }
          </div>
          {(activeListName !== '' && selectedUserId !== '' && isDeactivateUser) && (
            <div className={styles.userWiseCourseList}>
              <div>
                <div>
                  <div />
                  <span fontas="beta header">
                    {`${headerText}  [${courseList?.user_name || ''}]`}
                    <Popup content={t('click_to_copy_email')} trigger={<Icon name="copy outline" onClick={copyEmailContent} />} />
                  </span>
                  <div fontas="gamma" className={activeListName === 'orgunit' ? styles.hideSkillOrRecBtnGroup : ''}>
                    <ButtonGroup>
                      {renderButtons()}
                    </ButtonGroup>
                  </div>
                </div>
                <div className={activeListName === 'orgunit' ? styles.orgUnitTreeWrapper : ''}>
                  {(ListViewComp && selectedUserId !== '') && (
                    <ListViewComp
                      key={listViewCompKey}
                      showRoleList
                      courseId={courseId}
                      courseList={courseList}
                      setState={setState}
                      isLoading={isLoading}
                      sessionsandTeamtimingDetail={sessionsandTeamtimingDetail}
                      sessionsList={sessionsList}
                      hrlsessionid={hrlsessionid}
                      teamId={teamId}
                      orgListData={courseList?.org_unit_list}
                      isMemberAddable={false}
                      searchtext={''}
                      activeIndex={orgAccordActiveIndices}
                      setActiveIndex={setOrgAccordActiveIndices}
                      accordOpenList={orgAccordOpenList}
                      setAccordOpenList={setOrgAccordOpenList}
                      adminRoleObj={{
                        role: currentRole,
                        callBack: () => { },
                      }}
                      userId={selectedUserId}
                      isDeactivateUser={isDeactivateUser}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation('admin')(UserWiseCourseScreen);
