import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import { HRLFormik, HRLModal } from '@hrlytics/corelytics';
import { Button, Form, Input } from 'semantic-ui-react';

function ExtendCreditModal(props) {
  const { t, setExtendCredits, vdiAssignmentId } = props;

  const addCreditToUser = (value) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { estatus, emessage } = respdata;
        if (estatus && emessage) {
          setExtendCredits((prevState) => (
            { ...prevState, creditModal: false }
          ));
        }
      },
      resturls.addCreditToUser,
      {
        vdi_assignment_id: vdiAssignmentId,
        add_credit: value,
      },
      'POST',
    );
  };

  return (
    <div>
      <HRLModal
        modalHeaderContent={t('add_credit')}
        open
        size="tiny"
        setOpen={
          () => setExtendCredits((prevState) => (
            { ...prevState, creditModal: false }
          ))
        }
      >
        <HRLFormik
          initialValues={{
            hours: '',
            minutes: '',
          }}
          validationSchema={Yup.object({
            hours: Yup.number()
              .required(t('hours_required'))
              .min(0, t('hours_min'))
              .max(23, t('hours_max'))
              .integer(t('hours_integer')),
            minutes: Yup.number()
              .required(t('minutes_required'))
              .min(0, t('minutes_min'))
              .max(59, t('minutes_max'))
              .integer(t('minutes_integer')),
          })}
          onSubmit={(values) => addCreditToUser(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Form.Field
                    name="hours"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hours}
                    width={8}
                    control={Input}
                    label={t('hours')}
                    placeholder="HH"
                    error={touched.hours && errors.hours ? { content: errors.hours } : null}
                  />
                  <Form.Field
                    name="minutes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.minutes}
                    width={8}
                    control={Input}
                    label={t('minutes')}
                    placeholder="MM"
                    error={touched.minutes && errors.minutes ? { content: errors.minutes } : null}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  content={t('save_next')}
                />
              </Form>
            </>
          )}
        </HRLFormik>
      </HRLModal>
    </div>
  );
}

export default withTranslation('recruiter')(ExtendCreditModal);
