// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../assets/less/config.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/bg/bg_curve.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.ArchiveResults__3MoMc {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  min-height: 100vh;\n  background-size: 100%;\n  background-repeat: no-repeat;\n  font-family: var(--font-name);\n  overflow-y: auto;\n}\n.ArchiveResults__3MoMc .resultMsg__39YtL {\n  border-radius: 1rem;\n  background-color: var(--color-white);\n  padding: 2rem;\n  margin: 1rem 5rem;\n  box-shadow: 0 0 10px var(--shadow-black);\n  min-height: calc(100vh - 250px);\n}\n.ArchiveResults__3MoMc .resultMsg__39YtL ul {\n  margin: 0 25%;\n}\n", ""]);
// Exports
exports.locals = {
	"ArchiveResults": "ArchiveResults__3MoMc",
	"resultMsg": "resultMsg__39YtL"
};
module.exports = exports;
