const Login = {
  name: 'Name',
  email: 'Email',
  mobile: 'Mobile Number',
  password: 'Password',
  new_password: 'New Password',
  repassword: 'Re-type Password',
  email_username: 'Username or Email Address',
  username: 'Username',
  target_username: 'Target Username',
  download: 'Download',
  // Login
  loginPageHeader: 'Welcome back to HRLytics',
  loginPageHeaderContent: 'Sign in to continue to your account',
  login_button: 'Login',
  forgotPassword: 'Forgot Password?',
  signupText: 'Don\'t have an account ?',
  signup_link: 'Sign up',
  otherSignInText: 'Sign In Via',
  one_platform: 'One Platform.',
  endless_possiblity: 'Endless Possibilities',
  workforce_plan: 'Workforce Planning, Talent Transformation to Employee Engagement,',
  manage_hr: 'manage all your HR needs seamlessly in one place.',
  // Forgot password
  fpHeader: 'Forgot Password',
  link_sent: 'Reset Link Sent',
  fpHeaderContent1: 'Reset password link sent to your registered email address. Click the link to reset the password.',
  fpHeaderContent2: 'Check your Inbox or Spam folder for the Password Reset E-Mail.',
  fp_link_button: 'SEND LINK',
  must_be_email: 'Email must be a valid Email',
  remember_password: 'Remember password ?',
  email_address: 'Enter your email',
  login_link: 'Log in',
  fp_link: 'Forgot Password',
  // Sign up
  signupPageHeader: 'Join HRLytics for Free',
  signupPageHeaderContent: 'Create an account to start learning. Happy Learning ahead !',
  already_account: 'Already have an account ? ',
  alreadyExistContent: 'You already have an account with E-box, kindly Login and Continue',
  signin_now: 'Sign In Now',
  signup_button: 'SIGNUP',
  // verify mobilenumber
  enterOTP: 'Enter OTP',
  verifyMobileNumberHeader: 'Verify your Mobile Number',
  verify_button: 'Verify & Proceed',
  OtpNotReceived: 'Didn\'t receive the Code? ',
  sendAgain: 'Send Again',
  resendOtp: 'Verification Code has been resent',
  verifyMailHeading: 'Verify your Email Address',
  Otp_error: 'Incorrect Code! Kindly provide the valid code.',
  // ResetPassword
  rpHeader: 'Reset Password',
  rpNewPassword: 'New Password',
  rpReTypePassword: 'Re-Type Password',
  rpButton: 'Reset',
  reset_success: 'Password Reset Successful!',
  back_to_login: 'Back To Login',
  password_reset: 'Password Reset',
  successful: 'Successful!',
  click_here_to: 'Click here to ',
  create_new_password: 'Create new password',
  reset_password_header_content: 'Your password must be different from previous used passwords',
  // Validate User
  accountVerifyHeader: 'Successfully Registered.',
  accountVerifyHeaderContent: 'Your email has been verified and your account has been activated.',
  account_success: 'Account Verified Successfully!',
  account_invalid: 'Fail to verify your account!',
  accountVerifySuccessInfo1: 'Your account has been verified. You can login with your credentials.',
  accountVerifySuccessInfo2: 'Note : Your email address(provided during Sign up) will be your Username.',
  accountVerifyFailInfo1: 'This can be because of Invalid URL or the URL would have expired.',
  accountVerifyFailInfo2: '',
  // Errors
  invalid_login: 'Username or Password is incorrect',
  invalid_fp: 'Please provide a valid Username or Email address',
  admin_access: "You don't have access. Contact Admin !",
  // Validations
  validation_username: 'Username or Email is required',
  validation_password: 'Password is required',
  validate_category: 'Category is required',
  validate_name: 'Name is required',
  validate_email: 'Email is required',
  validate_mobile: 'Mobile Number is required',
  validation_repassword: 'Re-type Password is required',
  invalid_email_format: 'Please provide a valid Email format',
  valid_mobileNumber: 'Please provide a valid Mobile Number',
  footerText: 'E-Box Colleges - We revolutionize Engineering & Technology Learning',
  validate_otp: 'Kindly enter the OTP',
  signUpError: 'Email / Mobile number already present.',
  invalid_email: 'Invalid Email',
  validation_otp: 'OTP is required',
  otp_regex: 'Enter only 6 digit numbers',
  emailVerifyText: 'Your email has been successfully verified',
  emailNotVerifyText: 'Fail to verify your account!',
  emailNotVerifyMsg: 'This can be because of Invalid URL or the URL would have expired.',
  emailVerifyContent: 'Email Verification.',
  validation_tusername: 'Target Username is required',
  registeredSuccessfully: ' Registered successfully',
  successContent: 'You have been registered successfully',
  match_password: 'Passwords must match',
  Dont_have_an_account: 'Don\'t have an account? ',
  loginSliderContent1: 'Skill Based Evaluation Approach with Efficient Workflows.',
  loginSliderContent2: 'Ready – Made Assessment Packages for best Solutions.',
  loginSliderContent3: 'Customizable Recruitment Process.',
  loginSliderContent4: 'Stay Connected.',
  access_denied: 'Access denied: Your account has been deactivated',
  validation_target_username: 'Target Username or Email is required',
  target_username_email: 'Target Username or Email Address',
  target_user_access_denied: 'Access denied: Target\'s account has been deactivated',
  target_user_invalid: 'Target User is invalid',
};
export default Login;
