import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, Dropdown, Table,
} from 'semantic-ui-react';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import styles from './less/CreateInstance.module.less';
import ExtendCreditModal from './ExtendCreditModal';

function InstanceList(props) {
  const {
    instanceDetailsList, obtainAssignedUserInstanceList,
    options, activeState, t, vmTeamId,
  } = props;
  const [extendCredits, setExtendCredits] = useState({ creditModal: false, vdiAssignmentId: '' });

  const { vdiAssignmentId, creditModal } = extendCredits;

  const updateInstanceAction = async (Action, id, instanceId) => {
    if (Action === 'launch') {
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage } = respdata;
          if (estatus && emessage) {
            obtainAssignedUserInstanceList(activeState, vmTeamId);
          }
        },
        `${resturls.lanchInstance}?instance_id=${instanceId}`,
        {},
        'GET',
      );
    } else {
      GlobalService.generalSelect(
        (respdata) => {
          const { estatus, emessage } = respdata;
          if (estatus && emessage) {
            obtainAssignedUserInstanceList(activeState, vmTeamId);
          }
        },
        resturls.updateInstanceAction,
        { vdi_instance_id: id, action: Action, instance_id: instanceId },
        'POST',
      );
    }
  };

  const handleDropdownChange = (value, instance) => {
    updateInstanceAction(value, instance.vdi_instance_id, instance.instance_id);
  };
  return (
    <div className={styles.showAllInstanceList}>

      <Table>
        <Table.Header>
          <Table.HeaderCell>{t('S.No')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('User Name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('User Batch')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Instance ID')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Instance Name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Time Slot')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Platform')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Available Credit')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Total Credit')}</Table.HeaderCell>
          <Table.HeaderCell>{t('State')}</Table.HeaderCell>
          {activeState !== 'terminated_virtual_machine' && (
            <>
              <Table.HeaderCell>{t('Action')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Add Credit')}</Table.HeaderCell>
            </>
          )}
        </Table.Header>
        <Table.Body>
          {instanceDetailsList.map((instance, i) => (
            <Table.Row key={instance.instance_id}>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>{instance.name}</Table.Cell>
              <Table.Cell>{instance.user_name}</Table.Cell>
              <Table.Cell>{instance.team_name}</Table.Cell>
              <Table.Cell>{instance.instance_id}</Table.Cell>
              <Table.Cell>{instance.instance_name}</Table.Cell>
              <Table.Cell>{instance.time_slot}</Table.Cell>
              <Table.Cell>{instance.platform}</Table.Cell>
              <Table.Cell>{instance.available_credit}</Table.Cell>
              <Table.Cell>{instance.total_credit}</Table.Cell>
              <Table.Cell>{instance.state}</Table.Cell>
              {activeState !== 'terminated_virtual_machine' && (
                <>
                  <Table.Cell>
                    <Dropdown
                      trigger={<Button content="Actions" />}
                      style={{ display: 'block ruby' }}
                      options={options}
                      pointing="top right"
                      onChange={(e, { value }) => handleDropdownChange(value, instance)}
                      icon="angle down"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={
                        () => setExtendCredits((prevState) => (
                          { ...prevState, creditModal: true, vdiAssignmentId: instance.id }
                        ))
                      }
                    >
                      {t('Add Credit')}
                    </Button>
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {
        creditModal
        && (
          <ExtendCreditModal
            vdiAssignmentId={vdiAssignmentId}
            setExtendCredits={setExtendCredits}
          />
        )
      }
    </div>
  );
}

export default withTranslation('recruiter')(InstanceList);
