import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { HRLFormik, FullPageLoader } from '@hrlytics/corelytics';
import GlobalService from '@services/GlobalService';
import { resturls } from '@utils/apiurls';
import * as Yup from 'yup';
import {
  Button, Form, Icon, Select,
} from 'semantic-ui-react';
import writeXlsxFile from 'write-excel-file';
import DatePicker from 'react-datepicker';
import useComponentDidMount from '@utils/useComponentDidMount';
import MentorSkillMetrics from '@screens/mentor/MentorSkillMetrics';
import SupportManagerStatistics from './SupportManagerStatistics';
import DoubtCompletionChart from './DoubtCompletionChart';
import MentorPerformanceTable from './MentorPerformanceTable';
import MentorDayWiseReport from './MentorDayWiseReport';
import DoubtDetailsTable from './DoubtDetailsTable';
import ConversationMessageModal from './ConversationMessageModal';
import styles from './supportManager.module.less';
import SLAMatrix from './SLAMatrix';

const SupportManagerDashboard = (props) => {
  const { t } = props;
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const isSupportManager = true;

  const [initialValues, setInitialValues] = useState({
    start_date: oneWeekAgo,
    end_date: today,
    course: '',
    user: '',
    status: '',
    mentor: [],
    batch: '',
    institution: '',
    skill: [],
    org_type: 'Organization',
  });
  const [analysisData, setanalysisData] = useState({
    solved_ticket_count: '',
    opened_ticket_count: '',
    total_users_count: 0,
    total_doubts: '',
    sla_completion: 0,
  });
  const [dashboardData, setData] = useState({
    mentorListOptions: [],
    courseOptions: [],
    batchOptions: [],
    userOptions: [],
    skillOptions: [],
    statusOptions: [],
    daywisedata: '',
    mentorWisePerformanceData: [],
    DoubtCompletionChartData: [],
    mentorDailyWiseReportData: [],
    doubtDetails: [],
    SLAMatrixData: [],
    institutionOptions: [],
  });
  const [skillMetrics, setSkillMetrics] = useState([]);
  const [sort, setSort] = useState('asc');
  const [isLoading, setLoading] = useState(false);
  const [isConversationShow, setIsConversationShow] = useState(false);
  const [conversationMessageList, setConversationMessageList] = useState([]);
  const [state, setState] = useState({
    totalCount: 0,
    doubtsPerPage: 10,
  });

  const { totalCount, doubtsPerPage } = state;

  const {
    start_date,
    end_date,
    course,
    mentor,
    batch,
    user,
    status,
    institution,
    skill,
  } = initialValues;

  const {
    skillOptions, courseOptions, daywisedata,
    mentorWisePerformanceData, DoubtCompletionChartData,
    mentorDailyWiseReportData, doubtDetails, mentorListOptions,
    statusOptions, SLAMatrixData, institutionOptions, batchOptions,
    userOptions,
  } = dashboardData;

  const startIso = start_date?.toISOString();
  const endIso = end_date?.toISOString();

  const submitObj = {
    start_date: startIso,
    end_date: endIso,
    course_id: initialValues.course || '',
    from_user_id: initialValues.user || '',
    status_list: initialValues.status || [],
    skill_list: initialValues.skill || [],
    org_unit_id: initialValues.institution || '',
  };

  const obtainSkillsForOrg = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        const skillList = data.map((obj) => (
          { key: obj.id, text: obj.name, value: obj.id }
        ));
        setData((prev) => ({
          ...prev,
          skillOptions: skillList,
        }));
      }
    }, resturls.obtainSkillsForOrg, {}, 'GET');
  };

  const obtainMentorListFunct = (id) => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { mentor_list } } = respData;
      if (estatus && emessage) {
        const mentorList = mentor_list.map((obj) => (
          { key: obj.mentor_id, text: obj.mentor_name, value: obj.mentor_id }
        ));
        setData((prev) => ({
          ...prev,
          mentorListOptions: mentorList,
        }));
      }
    }, `${resturls.obtainMentorList}?org_unit_id=${id || ''}`, {}, 'GET');
  };

  const obtainDateWiseDoubtThreadStatistics = () => {
    const newObj = {
      ...submitObj,
      to_user_id_list: initialValues?.mentor ? initialValues?.mentor : [],
    };
    setLoading(true);
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage && data) {
        const {
          total_other_doubt_count, solved_other_doubts_count, skill_ins_list,
        } = data.skill_metrics_list;
        const doubt_list = [
          ...skill_ins_list,
          {
            skill_name: 'Others',
            solved_doubt_count: solved_other_doubts_count,
            total_skill_doubt_count: total_other_doubt_count,
          }];
        setanalysisData((prev) => ({
          ...prev,
          solved_ticket_count: data.solved_ticket_count,
          opened_ticket_count: data.opened_ticket_count,
          total_users_count: data.total_users_count,
          total_doubts: data.total_doubt_count,
          sla_completion: data.sla_percentage,
        }));
        setData((prev) => ({
          ...prev,
          daywisedata: data,
          DoubtCompletionChartData: data.graph_values,
          mentorWisePerformanceData: data.mentor_datail_list,
          mentorDailyWiseReportData: data.mentor_datail_list,
          SLAMatrixData: data.sla_metrics_data,
        }));
        setSkillMetrics(doubt_list);
        setLoading(false);
      }
    }, resturls.obtainDoubtThreadDashboardDetailsForSManager, newObj, 'POST');
  };

  const obtainCourseListForOrgUnit = (id) => {
    if (id === '') {
      setData((prev) => ({
        ...prev,
        courseOptions: [],
      }));
      setInitialValues((prev) => ({
        ...prev,
        course: '',
      }));
    } else {
      GlobalService.generalSelect((respData) => {
        const { estatus, emessage, data } = respData;
        const { course_list } = data;
        if (estatus && emessage) {
          const courseList = course_list.map((obj) => (
            {
              key: obj.id, text: obj.name, value: obj.id, u_id: obj.u_id,
            }
          ));
          setData((prev) => ({
            ...prev,
            courseOptions: courseList,
          }));
        }
      }, `${resturls.obtainCourseListForOrgUnit}?org_unit_id=${id}`, {}, 'GET');
    }
  };

  const obtainDoubtThreadStatusFunct = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        const statusList = data.map((obj) => (
          { key: obj.id, text: obj.name, value: obj.id }
        ));
        setData((prev) => ({
          ...prev,
          statusOptions: statusList,
        }));
      }
    }, resturls.obtainDoubtThreadStatus, {}, 'GET');
  };

  const obtainOrgUnitForOrgFunct = () => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data: { org_unit_list } } = respData;
      if (estatus && emessage) {
        const statusList = org_unit_list.map((obj) => (
          { key: obj.id, text: obj.name, value: obj.id }
        ));
        setData((prev) => ({
          ...prev,
          institutionOptions: statusList,
        }));
      }
    }, resturls.obtainOrgUnitForOrg, {}, 'GET');
  };

  const obtainBatchesForOrgUnitFunct = (id) => {
    if (id === '') {
      setData((prev) => ({
        ...prev,
        batchOptions: [],
      }));
      setInitialValues((prev) => ({
        ...prev,
        batch: '',
      }));
    } else {
      GlobalService.generalSelect((respData) => {
        const { estatus, emessage, data } = respData;
        if (estatus && emessage) {
          const statusList = data.map((obj) => (
            { key: obj.id, text: obj.name, value: obj.id }
          ));
          setData((prev) => ({
            ...prev,
            batchOptions: statusList,
          }));
        }
      }, `${resturls.obtainBatchesForOrgUnit}?org_unit_id=${id}`, {}, 'GET');
    }
  };

  const obtainUsersForBatchFunct = (value) => {
    if (value === '') {
      setData((prev) => ({
        ...prev,
        userOptions: [],
      }));
      setInitialValues((prev) => ({
        ...prev,
        user: '',
      }));
    } else {
      GlobalService.generalSelect((respData) => {
        const { estatus, emessage, data } = respData;
        if (estatus && emessage) {
          const userList = data.map((obj) => (
            { key: obj.id, text: obj.email, value: obj.id }
          ));
          setData((prev) => ({
            ...prev,
            userOptions: userList,
          }));
        }
      }, resturls.obtainUsersForBatch, {
        batch_id: value,
      }, 'POST');
    }
  };

  const handleSkillOptions = (courseId) => {
    if (courseId !== '') {
      const courseUId = courseOptions.find((e) => e.value === courseId);
      GlobalService.generalSelect((respdata) => {
        const { estatus, emessage, data } = respdata;
        if (estatus && emessage) {
          const skillList = data.map((obj) => (
            { key: obj.id, text: obj.name, value: obj.id }
          ));
          if (skillList.length === 1) {
            skill.push(skillList[0].value);
            setInitialValues(() => ({
              // ...prev,
              skill,
            }));
          }
          setData((prev) => ({
            ...prev,
            skillOptions: skillList,
          }));
        }
      }, `${resturls.obtainSkillListForModule}/${courseUId?.u_id}`, {}, 'GET', 'cdn');
    } else {
      setData((prev) => ({
        ...prev,
        skillOptions: [],
      }));
      setInitialValues((prev) => ({
        ...prev,
        skill: '',
      }));
    }
  };

  const obtainDoubtThreadDetailsFunct = (activePage = 1) => {
    const lowerLimit = (activePage - 1) * doubtsPerPage;
    const upperLimit = activePage * doubtsPerPage;

    const newObj = {
      ...submitObj,
      lower_limit: lowerLimit,
      upper_limit: upperLimit,
    };

    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        setData((prev) => ({
          ...prev,
          doubtDetails: data,
        }));
        setState((prevState) => ({
          ...prevState,
          totalCount: data.total_doubt_count,
        }));
      }
    }, resturls.obtainDoubtThreadDetails, newObj, 'POST');
  };

  const obtainMessagesForDoubtThreadFunct = (uId) => {
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        setIsConversationShow(true);
        setConversationMessageList(data);
      }
    }, `${resturls.obtainSubmissionAndMessagesForDoubtThread}?doubt_thread_id=${uId}`, {}, 'GET');
  };

  useComponentDidMount(() => {
    obtainMentorListFunct();
    obtainSkillsForOrg();
    obtainDateWiseDoubtThreadStatistics();
    obtainDoubtThreadDetailsFunct();
    obtainDoubtThreadStatusFunct();
    obtainOrgUnitForOrgFunct();
  });

  const handleFormSubmit = (values) => {
    obtainDateWiseDoubtThreadStatistics(values);
    obtainDoubtThreadDetailsFunct();
  };

  const validationSchema = Yup.object().shape({
    end_date: Yup.date().min(Yup.ref('start_date'), 'End date must be equal or later than start date'),
  });

  const handleNameSort = (value) => {
    if (sort === 'default' || sort === 'desc') {
      skillMetrics.sort(({ [value]: a }, { [value]: b }) => a.localeCompare(b));
      setSort('asc');
    } else {
      skillMetrics.sort(({ [value]: a }, { [value]: b }) => b.localeCompare(a));
      setSort('desc');
    }
  };

  const exportDoubtThreadDetailForSManager = () => {
    const newObj = {
      ...submitObj,
      to_user_id_list: initialValues?.mentor ? initialValues?.mentor : [],
    };
    GlobalService.generalSelect((respData) => {
      const { estatus, emessage, data } = respData;
      if (estatus && emessage) {
        const commonHeaderStyles = {
          backgroundColor: '#133a1b',
          align: 'center',
          fontWeight: 'bold',
          borderColor: '#000000',
          fontSize: 9,
        };

        const bodyStyles = {
          align: 'center',
          rowspan: 3,
        };

        const formatDate = (dateStr) => {
          const date = new Date(dateStr);
          return date.toLocaleString();
        };

        const header1 = [
          { value: data[0].name, span: 4, ...commonHeaderStyles },
        ];

        const sheet3Header = [
          { value: 'Id', ...commonHeaderStyles },
          { value: 'Participant', ...commonHeaderStyles },
          { value: 'SME', ...commonHeaderStyles },
          { value: 'Status', ...commonHeaderStyles },
          { value: 'Created Time', ...commonHeaderStyles },
          { value: 'Course', ...commonHeaderStyles },
          { value: 'Skill', ...commonHeaderStyles },
          { value: 'Sla Time(hours)', ...commonHeaderStyles },
        ];

        const sheet3Body = data[2].map((mentors) => [
          { value: mentors.u_id, align: 'center' },
          { value: mentors.username, align: 'center' },
          { value: mentors.mentor_name, align: 'center' },
          { value: mentors.doubt_status_name, align: 'center' },
          { value: formatDate(mentors.created_time), align: 'center' },
          { value: mentors.course_name, align: 'center' },
          { value: mentors.skill_name, align: 'center' },
          { value: mentors.last_updated_sla_time, align: 'center' },
        ]);

        const body1 = [
          [null],
          [null],
          [{ value: 'Report Data' }, { value: formatDate(data[0].report_date) }],
          [{ value: 'From Data' }, { value: formatDate(data[0].from_date) }],
          [{ value: 'To Data' }, { value: formatDate(data[0].to_date) }],
          [null],
          [
            {
              value: `Total Doubts: ${data[0].total_doubt_count}`, span: 2, rowspan: 3, ...bodyStyles,
            },
            null,
            {
              value: `Solved Count: ${data[0].solved_ticket_count}`, span: 2, rowspan: 3, ...bodyStyles,
            },
            null,
          ],
          [
            {
              value: `Participants Count: ${data[0].total_users_count}`, span: 2, rowspan: 3, ...bodyStyles,
            },
            null,
            {
              value: `SLA Percentage: ${data[0].sla_percentage}`, span: 2, rowspan: 3, ...bodyStyles,
            },
            null,
          ],
        ];

        const header2 = [
          { value: 'Mentor Performance', span: 6, ...commonHeaderStyles },
          null, null, null, null, null, null,
          { value: 'Skill Metrics', span: 3, ...commonHeaderStyles },
          null, null,
        ];

        const header3 = [
          { value: 'Name', ...commonHeaderStyles },
          { value: 'Username', ...commonHeaderStyles },
          { value: 'Email', ...commonHeaderStyles },
          { value: 'Assigned Count', ...commonHeaderStyles },
          { value: 'Solved Count', ...commonHeaderStyles },
          { value: 'SLA', ...commonHeaderStyles },
          null,
          { value: 'Skill', ...commonHeaderStyles },
          { value: 'Total', ...commonHeaderStyles },
          { value: 'Resolved', ...commonHeaderStyles },
        ];

        const body2 = data[1].mentor_datail_list.map((mentors) => [
          { value: mentors.name, align: 'center' },
          { value: mentors.username, align: 'center' },
          { value: mentors.email, align: 'center' },
          { value: mentors.assigned_count, align: 'center' },
          { value: mentors.total_solved_count, align: 'center' },
          { value: mentors.sla_completion_percentage, align: 'center' },
        ]);

        const body3 = data[1].skill_metrics_list.skill_ins_list.map((skills) => [
          null, null, null, null, null, null, null,
          { value: skills.skill_name, align: 'center' },
          { value: skills.total_skill_doubt_count, align: 'center' },
          { value: skills.solved_doubt_count, align: 'center' },
        ]);

        const sheetData = [
          [header1, ...body1],
          [header2, header3, ...body2, ...body3],
          [sheet3Header, ...sheet3Body],
        ];

        const excelSheets = ['Doubts Statistics', 'Mentor and Skill Details', 'Doubt Details'];

        writeXlsxFile(sheetData, {
          columns: [
            [{ width: 10 }, { width: 20 }, { width: 10 }, { width: 10 }],
            [
              { width: 15 }, { width: 15 }, { width: 25 }, { width: 15 },
              { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 },
              { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 },
            ],
            [
              { width: 15 }, { width: 25 }, { width: 15 },
              { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 },
              { width: 15 },
            ],
          ],
          fileName: `${t('support_manager_report')}.xlsx`,
          sheets: excelSheets,
        });
      }
    }, resturls.exportDoubtThreadDetailForSManager, newObj, 'POST');
  };

  return (
    <div>
      {isLoading && <FullPageLoader />}
      <div className={styles.mentorDashboardLayout}>
        <div className={styles.report}>Report</div>
        <HRLFormik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => handleFormSubmit()}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
          }) => {
            const handleFieldChange = (data) => {
              const { name, value } = data;
              setInitialValues((prev) => ({
                ...prev,
                [name]: value,
              }));
            };
            const handleStartdateChange = (date) => {
              setInitialValues((prev) => ({
                ...prev,
                start_date: date,
              }));
            };

            const handleEnddateChange = (date) => {
              setInitialValues((prev) => ({
                ...prev,
                end_date: date,
              }));
            };

            const handleInstitutionChange = (e, data) => {
              if (!data.value) {
                setInitialValues((prev) => ({
                  ...prev,
                  course: '',
                  batch: '',
                  user: '',
                  skill: [],
                  status: '',
                  mentor: '',
                }));
                setData((prev) => ({
                  ...prev,
                  courseOptions: [],
                  batchOptions: [],
                  userOptions: [],
                  skillOptions: [],
                }));
                obtainMentorListFunct();
              } else {
                obtainBatchesForOrgUnitFunct(data.value);
                obtainCourseListForOrgUnit(data.value);
                obtainMentorListFunct(data.value);
              }
              handleFieldChange(data);
            };

            return (
              <Form>
                <div className={styles.formMentor}>
                  <Form.Group widths="equal" className={styles.mentorFields}>
                    <Form.Field
                      label={t('startdate')}
                      control={DatePicker}
                      name="start_date"
                      placeholder={t('start_date')}
                      selected={values.start_date}
                      onChange={(date) => handleStartdateChange(date)}
                      onBlur={handleBlur}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={25}
                      scrollableYearDropdown
                      error={touched.start_date && errors.start_date}
                      iconPosition="right"
                      animation="none"
                      touched={touched.start_date}
                      autoComplete="off"
                      closable
                      maxDate={new Date()}
                      max={values.start_date}
                      value={values.start_date}
                      closeOnMouseLeave={false}
                      dateFormat="dd-MM-yyyy"
                    />
                    <Form.Field
                      label={t('enddate')}
                      control={DatePicker}
                      name="end_date"
                      selected={values.end_date}
                      value={values.end_date}
                      onChange={(date) => handleEnddateChange(date)}
                      onBlur={handleBlur}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={25}
                      scrollableYearDropdown
                      error={touched.end_date && errors.end_date}
                      autoComplete="off"
                      closable
                      closeOnMouseLeave={false}
                      iconPosition="right"
                      touched={touched.end_date}
                      dateFormat="dd-MM-yyyy"
                      clearable
                      maxDate={new Date()}
                      max={values.start_date}
                    />
                    <Form.Field
                      control={Select}
                      search
                      fluid
                      clearable
                      onChange={handleInstitutionChange}
                      value={institution}
                      label={t('org_unit')}
                      name="institution"
                      options={institutionOptions}
                    />
                    <Form.Field
                      className={styles.courseSelect}
                      control={Select}
                      search
                      clearable
                      fluid
                      onChange={(e, data) => {
                        handleFieldChange(data);
                        handleSkillOptions(data.value);
                      }}
                      value={course}
                      label={t('course')}
                      name="course"
                      options={courseOptions}
                    />
                    <Form.Field
                      control={Select}
                      search
                      clearable
                      fluid
                      onChange={(e, data) => {
                        handleFieldChange(data);
                        obtainUsersForBatchFunct(data.value);
                      }}
                      value={batch}
                      label={t('batch')}
                      name="batch"
                      options={batchOptions}
                    />
                    <Form.Field
                      control={Select}
                      search
                      clearable
                      fluid
                      onChange={(e, data) => handleFieldChange(data)}
                      value={user}
                      label={t('user')}
                      name="user"
                      options={userOptions}
                    />
                  </Form.Group>
                  <Form.Group className={styles.supportManagerFiled}>
                    <Form.Field
                      control={Select}
                      search
                      fluid
                      multiple
                      onChange={(e, data) => handleFieldChange(data)}
                      value={skill}
                      label={t('skill')}
                      name="skill"
                      options={skillOptions}
                    />
                    <Form.Field
                      control={Select}
                      search
                      multiple
                      fluid
                      onChange={(e, data) => handleFieldChange(data)}
                      value={status}
                      label={t('status')}
                      name="status"
                      options={statusOptions}
                    />
                    <Form.Field
                      control={Select}
                      search
                      clearable
                      multiple
                      fluid
                      onChange={(e, data) => handleFieldChange(data)}
                      value={mentor}
                      label={t('mentor')}
                      name="mentor"
                      options={mentorListOptions}
                    />
                    <div
                      className={styles.getStatistics}
                    >
                      <Button
                        onClick={handleSubmit}
                      >
                        {t('getStatistics')}
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            );
          }}
        </HRLFormik>
        {daywisedata !== ''
          && (
            <div>
              <div className={styles.downloadXls}>
                <Button onClick={exportDoubtThreadDetailForSManager}>
                  <Icon name="download" />
                  {t('xls')}
                </Button>
              </div>
              <div className={styles.mentorStatistics}>
                <div className={styles.statisticsLeft}>
                  <SupportManagerStatistics
                    analysisData={analysisData}
                    isSupportManager={isSupportManager}
                  />
                  <DoubtCompletionChart
                    DoubtCompletionChartData={DoubtCompletionChartData}
                    totalCount={totalCount}
                  />
                </div>
                <div className={styles.statisticsRight}>
                  <MentorSkillMetrics
                    startDate={start_date.toISOString()}
                    endDate={end_date.toISOString()}
                    skill={skill}
                    course={course}
                    skillMetrics={skillMetrics}
                    handleNameSort={handleNameSort}
                  />
                </div>
              </div>
              {
                mentorWisePerformanceData.length > 0 && (
                  <MentorPerformanceTable mentorWisePerformanceData={mentorWisePerformanceData} />
                )
              }
              {
                mentorDailyWiseReportData.length > 0 && (
                  <MentorDayWiseReport
                    submitObj={submitObj}
                    mentorDailyWiseReportData={mentorDailyWiseReportData}
                    initialValues={initialValues}
                  />
                )
              }
              {
                <SLAMatrix SLAMatrixData={SLAMatrixData} />
              }
              {
                doubtDetails?.doubt_thread_list?.length > 0 && (
                  <DoubtDetailsTable
                    doubtDetails={doubtDetails}
                    setData={setData}
                    obtainMessagesForDoubtThreadFunct={obtainMessagesForDoubtThreadFunct}
                    obtainDoubtThreadDetailsFunct={obtainDoubtThreadDetailsFunct}
                    totalCount={totalCount}
                    doubtsPerPage={doubtsPerPage}
                  />
                )
              }
            </div>
          )}
      </div>
      {
        isConversationShow
        && (
          <ConversationMessageModal
            conversationMessageList={conversationMessageList}
            setIsConversationShow={setIsConversationShow}
          />
        )
      }
    </div>
  );
};
export default withTranslation('programManager')(SupportManagerDashboard);
