import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import styles from './less/MentorDashboard.module.less';

const MentorSkillMetrics = (props) => {
  const {
    skillMetrics, handleNameSort,
  } = props;
  return (
    <div className={styles.skillMetrics}>
      <div>Skill Metrics</div>
      <div>
        <Table basic="very" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Skill
                <Icon name="sort" onClick={() => handleNameSort('skill_name')} />
              </Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Resolved</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {skillMetrics.map((ele) => (
              <Table.Row>
                <Table.Cell>{ele.skill_name}</Table.Cell>
                <Table.Cell>{ele.total_skill_doubt_count}</Table.Cell>
                <Table.Cell>{ele.solved_doubt_count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation('mentor')(MentorSkillMetrics);
