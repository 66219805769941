import UserInfo from '@models/UserInfo';

const roleUrl = {
  recruiter_head: '/assessment',
  program_manager: '/programmanager/dashboard',
  organization_admin: '/orgadmin',
  participant: '/participant',
  admin: '/admin',
  mentor: '/mentordashboard',
  support_manager: '/supportmanager',
};

const getRoleUrl = (role = UserInfo.getRole()) => roleUrl[role] || '/';

export default getRoleUrl;
