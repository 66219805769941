const Mentor = {
  startdate: 'Start Date',
  enddate: 'End Date',
  course: 'Course',
  skill: 'Skill',
  getStatistics: 'Get Statistics',
  tickets_assigned: 'Tickets Assigned',
  tickets_resolved: 'Tickets Resolved',
  pending_tickets: 'Pending Tickets',
  sla_completion: 'SLA Completion',
  proactive_support: 'Pro-Active Support',
};

export default Mentor;
