import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import UserInfo from '@models/UserInfo';
import CommonHeader from '@globalComps/header/CommonHeader';
import ReclyticsHeader from '@globalComps/header/ReclyticsHeader';
import styles from './ArchiveResults.module.less';

function ArchiveResults() {
  const history = useHistory();
  const { state = {} } = useLocation();
  const { messageList } = state;
  const roleName = UserInfo.getRole();

  return (
    <div className={styles.ArchiveResults}>
      <CommonHeader>
        <div fontas="beta header">
          {navigator && (
            <Icon
              name="arrow left"
              fontas="beta"
              onClick={() => history.goBack()}
            />
          )}
        </div>
      </CommonHeader>
      {roleName !== 'program_manager' && (
        <ReclyticsHeader pageRedirect={() => history.goBack()} />
      )}
      <div className={styles.resultMsg} fontas="alpha">
        <ul>
          {messageList?.map((message) => (
            <li>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ArchiveResults;
