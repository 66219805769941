// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../assets/less/config.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/*-------------------\n     Breakpoints\n--------------------*/\n/* Responsive */\n.HRLEvent__3am25 {\n  position: fixed;\n  width: 96%;\n  bottom: -100%;\n  left: 2%;\n  padding: 15px;\n  border-radius: 5px 5px 0 0;\n  background-color: var(--light-red-bg);\n  transition: all 1.5s linear;\n  z-index: 9999;\n}\n.HRLEvent__3am25.activeAlert__3S2tR {\n  bottom: 0px;\n}\n.HRLEvent__3am25 ul {\n  padding: 0px 30px;\n  margin: 0px;\n}\n.HRLEvent__3am25 ul li {\n  margin: 0px;\n  padding-right: 30px;\n  margin-bottom: 5px;\n}\n.HRLEvent__3am25 ul li:last-child {\n  margin-bottom: 0px;\n}\n.HRLEvent__3am25 i {\n  position: absolute;\n  right: 20px;\n  top: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"HRLEvent": "HRLEvent__3am25",
	"activeAlert": "activeAlert__3S2tR"
};
module.exports = exports;
